import { DateRange } from "@mui/x-date-pickers-pro";
import { useEffect, useState } from "react";


export const useDateRangePicker = (
        dateRange: DateRange<Date>,
        onChange: (dateRange: DateRange<Date>) => void
    ) => {
    const [currentDateRange, setCurrentDateRange] = useState<DateRange<Date>>([null, null]);

    useEffect(() => {
        setCurrentDateRange(dateRange);
    }, [dateRange]);

    const handleDateRangeChange = (dateRange: DateRange<any>) => {
        setCurrentDateRange(dateRange);
        onChange(dateRange);
    };

    return {
        currentDateRange,
        handleDateRangeChange
    };
};