import { Divider, Grid } from "@mui/material";
import { ReactElement } from "react";

import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import LateralPanelBox from "../../../../components/Boxes/LateralPanelBox/LateralPanelBox";
import Button from "../../../../components/Buttons/Button/Button";
import DetailPanel from "../../../../components/DetailPanel/DetailPanel";
import ConfirmationDialog from "../../../../components/Modal/ConfirmationDialog";
import ProgressPanel from "../../../../components/Progress/ProgressPanel/ProgressPanel";
import useRole from "../../../../core/routing/useRole";
import { DetailViewType } from "../../../../utils/types/common.type";
import { SelectedInvestor } from "../../../../utils/types/investor.type";
import { ScopeRole } from "../../../../utils/types/user.type";
import InvestorDetails from "../../investorDetails/InvestorDetails";
import InvestorDetailsReadOnlyView from "../../investorDetails/InvestorDetailsReadOnlyView";
import InvestorFunds from "../../investorFunds/InvestorFunds";
import InvestorFundsReadOnly from "../../investorFunds/InvestorFundsReadOnly";
import InvestorWire from "../../investorWire/InvestorWire";
import InvestorWireReadOnly from "../../investorWire/InvestorWireReadOnly";
import InvestorPendingView from "../InvestorPendingView/InvestorPendingView";
import {
  useInvestorDetails,
  useTabs,
} from "./InvestorDetailPanel.hooks";
import { StyledBackdrop } from "./InvestorDetailPanel.styles";

type Props = {
  onDetailPanelClose: Function;
  selectedInvestor: SelectedInvestor;
  isSubmitting: boolean;
  setIsSubmitting: Function;
};

const InvestorDetailPanel: React.FC<Props> = (props: Props): ReactElement => {
  const {
    onDetailPanelClose,
    selectedInvestor,
    isSubmitting,
    setIsSubmitting
  } = props;

  const { investorListItem, investor, detailView } = selectedInvestor;
  const isNewInvestor = detailView === DetailViewType.New;

  const {
    form,
    isLoading,
    setIsLoading,
    showExitConfirmation,
    title,
    isUniqueNameError,
    setIsUniqueNameError,
    toggleDrawer,
    closeDrawer,
    keepDrawerOpen,
    createUpdateInvestor,
    approveChanges,
    declineChanges
  } = useInvestorDetails({
    investor,
    isNewInvestor,
    setIsSubmitting,
    onClose: onDetailPanelClose
  });

  const { hasRole: isClientPortalUser } = useRole([ScopeRole.BASIC_USER]);

  const { hasRole: isSuperAdminOrClientAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);

  const {
    currentTab,
    detailsRef,
    fundsRef,
    wiringRef,
    pageBottomRef,
    handleTabChange,
    scrollToBottom
  } = useTabs();

  const isPrimaryContact = investorListItem?.isPrimary;
  const canUpdateDetails = isClientPortalUser
    ? isPrimaryContact
    : isSuperAdminOrClientAdmin;
  const canUpdateFund = isClientPortalUser ? false : isSuperAdminOrClientAdmin;
  const canUpdateWireInfo = isClientPortalUser
    ? isPrimaryContact
    : isSuperAdminOrClientAdmin;
  const canViewWireInfo = isClientPortalUser ? isPrimaryContact : true;
  const showActionButtons =
    canUpdateDetails || canUpdateFund || canUpdateWireInfo;
  const canUpdateInvestorTin = isClientPortalUser
    ? false
    : isSuperAdminOrClientAdmin;

  return (
    <>
      <DetailPanel
        id="panel_investor_detail"
        title={
          isNewInvestor ?
            "Add Investor" :
            title ? title : investor?.fullName
        }
        open={Boolean(detailView)}
        onClose={toggleDrawer}
        variant={"temporary"}
        hasTabsPanel={true}
        tabsPanelValue={currentTab}
        tabsPanelHandleChange={handleTabChange}
        tabsPanelOptions={
          canViewWireInfo
            ? ["Details", "Funds", "Wiring"]
            : ["Details", "Funds"]
        }
      >
        <form
          autoComplete="off"
          onSubmit={form.handleSubmit(createUpdateInvestor)}
          noValidate>
          <Grid container rowSpacing={1}>

            <LateralPanelBox>
              <div ref={detailsRef}>
                {canUpdateDetails ? (
                  <>
                    {isSuperAdminOrClientAdmin &&
                      (investor?.pendingWireInfo || investor?.pendingInvestorChanges) && (
                        <InvestorPendingView
                          investor={investor}
                          approvePendingChanges={approveChanges}
                          declinePendingChanges={declineChanges}
                        />
                      )}
                    <InvestorDetails
                      investor={investor}
                      form={form}
                      setIsLoading={setIsLoading}
                      isUniqueNameError={isUniqueNameError}
                      setIsUniqueNameError={setIsUniqueNameError}
                      canUpdateInvestorTin={canUpdateInvestorTin}
                      canUpdateInvestorName={canUpdateInvestorTin}
                    />
                  </>
                ) : (
                  <>
                    {investor && (
                      <InvestorDetailsReadOnlyView
                        investor={investor}
                      />

                    )}
                  </>
                )
                }
              </div>

              <Divider variant="middle" />

              <div ref={fundsRef}>
                {canUpdateFund ? (
                  <InvestorFunds form={form} />
                ) : (
                  <InvestorFundsReadOnly form={form} />
                )}
              </div>

              <Divider variant="middle" />
              {canViewWireInfo && (
                <>
                  {canUpdateWireInfo ? (
                    <div ref={wiringRef}>
                      <InvestorWire
                        investor={investor}
                        form={form}
                        scrollToBottom={scrollToBottom}
                      />
                    </div>
                  ) : (
                    <>
                      {investor && investor.wireInfo && (
                        <div ref={wiringRef}>
                          <InvestorWireReadOnly
                            investorReceivingInfo={investor?.wireInfo?.receivingBankInfo}
                            investorBeneficiaryInfo={investor?.wireInfo?.beneficiaryBankInfo}
                            investorIntermediaryInfo={investor?.wireInfo?.intermediaryBankInfo}
                            investorWireInfo={investor?.wireInfo}
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              <div ref={pageBottomRef} />
            </LateralPanelBox>
          </Grid>

          {showActionButtons && (
            <HorizontalBox
              addTopShadow
              attachToBottom={true}
              hidden={false}
              fullWidth
            >
              <Button
                id={"btn_investor_detail_panel_cancel"}
                variant="outlined"
                onClick={toggleDrawer}
                text={"Cancel"}
                color={"secondary"}
                fullWidth
                addSpaceBetweenButtons
              />

              <Button
                id={"btn_investor_detail_panel_save"}
                variant="contained"
                type="submit"
                text={"Save & Close"}
                color={"primary"}
                fullWidth
                addSpaceBetweenButtons
              />
            </HorizontalBox>
          )}
        </form>
        <StyledBackdrop open={Boolean(isLoading)} />
        <ProgressPanel
          id={"progress_investor_panel"}
          showProgress={Boolean(isLoading)}
          text={isLoading ? `${isLoading} investor...` : ''}
        />
      </DetailPanel>

      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="investor_leave_confirmation"
        actions={[
          {
            label: "Keep Editing",
            onClick: keepDrawerOpen,
            id: "btn_keep_editing",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Discard Changes",
            onClick: closeDrawer,
            id: "btn_cancel_save",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Unsaved changes will be lost"
        title="You have unsaved changes"
      />
    </>
  );
};

export default InvestorDetailPanel;
