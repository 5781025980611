import { Box, FormControl, RadioGroup, styled, Table, TableCell, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

import { Typography } from "../../../../components/DetailPanel/DetailPanel.styles";

export const StatusRadioGroup = styled(RadioGroup)`
  display: inline;
`;

export const SelectFormControl = styled(FormControl)(({
  theme
}) => ({
  width: '100%',
  '.MuiInputLabel-formControl': {
    top: '-7px',
  },
  '.MuiInputLabel-shrink': {
    top: '0',
  },
}));

export const KeyValuePair = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const KeyLabel = styled(Box)`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;

export const ValueLabel = styled(Box)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

export const InfoTableCell = styled(TableCell)`
  fontSize = '10px';
`;

export const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export const SubSectionTitle = styled(Typography)`
  margin-top: -5px;
  `;