import { useContext, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';

import { AppContext } from '../../../core/context/appContextProvider';
import { getArkAccounts, getArkLedgers } from '../../../services/arkGL.service';
import { updateBankAccount } from '../../../services/bank.service';
import { getFundNames } from '../../../services/fund.service';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import {
  BankAccount,
  SelectedBankAccount,
} from '../../../utils/types/bank.type';
import { LoadingStatus } from '../../../utils/types/form.type';
import { GET_GL_ACCOUNT_LIST_ERROR } from '../../arkGL/accounts/accountList/AccountList.constants';
import { GET_LEDGER_LIST_ERROR } from '../../arkGL/setup/setupList/GLSetupList.constants';
import { GET_FUNDLIST_ERROR } from '../../fund/fundList/FundList.constants';
import {
  UPDATE_BANK_ACCOUNT_ERROR,
  UPDATE_BANK_ACCOUNT_SUCCESS,
} from './BankAccountDetails.constants';

type Props = {
  bankAccount?: BankAccount;
  onClose: Function;
  selectedBankAccount: SelectedBankAccount;
  fetchAllFilters: Function;
};
export const useBankAccountDetails = ({
  bankAccount,
  onClose,
  selectedBankAccount,
  fetchAllFilters,
}: Props) => {
  const [isLoading, setIsLoading] = useState<LoadingStatus>();
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);
  const [ledgerList, setLedgerList] = useState<any[]>([]);
  const [accountList, setAccountList] = useState<any[]>([]);
  const [fundList, setFundList] = useState<any[]>([]);
  const { informationAlert } = useContext(AppContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm<BankAccount>({
    defaultValues: bankAccount,
  });
  const { isDirty } = useFormState({ control });

  const fetchLedgerList = async (isCanceled?: () => boolean) => {
    setIsLoading(LoadingStatus.Loading);
    try {
      if (isCanceled?.()) return;

      const response = await getArkLedgers(bankAccount!.fundId);

      const ledgers = response.items
        .map((ledger: any) => {
          return {
            id: ledger.id,
            fundId: ledger.fundId,
            name: ledger.name,
            currency: ledger.currency,
            decimal: ledger.decimal,
          };
        })
        .sort();

      setLedgerList(ledgers);
    } catch (e) {
      informationAlert(GET_LEDGER_LIST_ERROR, 'error');
    }
    setIsLoading(undefined);
  };

  const fetchFundList = async (isCanceled?: () => boolean) => {
    setIsLoading(LoadingStatus.Loading);
    try {
      if (isCanceled?.()) return;

      const funds = await getFundNames();

      setFundList(funds);
    } catch (e) {
      informationAlert(GET_FUNDLIST_ERROR, 'error');
    }
    setIsLoading(undefined);
  };

  const fetchAccountList = async (isCanceled?: () => boolean) => {
    if (bankAccount?.fundId) {
      setIsLoading(LoadingStatus.Loading);
      try {
        if (isCanceled?.()) return;

        const response = await getArkAccounts(bankAccount?.fundId!);

        const accounts = response.items
          .map((account: any) => {
            return {
              id: account.id,
              name: `${account.number} - ${account.name}`,
              isEntityRequired: account.isEntityRequired,
            };
          })
          .sort();

        accounts.push({
          id: '+',
          name: 'Create new account',
          isEntityRequired: false,
        });

        setAccountList(accounts);
      } catch (e) {
        informationAlert(GET_GL_ACCOUNT_LIST_ERROR, 'error');
      }
      setIsLoading(undefined);
    } else {
      setAccountList([]);
    }
  };

  useEffectAsync(async (isCanceled) => {
    await fetchAccountList(isCanceled);
    await fetchLedgerList(isCanceled);
  }, []);

  const editBankAccount = async (data: BankAccount) => {
    try {
      const request = {
        name: data.name,
        fundId: bankAccount!.fundId,
        nickname: data.nickname,
        ledgerId: data.ledgerId,
        glAccountId: data.glAccountId,
        currencyCode: bankAccount!.currencyCode,
        type: bankAccount!.type,
      };

      await updateBankAccount(bankAccount!.id, request);

      informationAlert(UPDATE_BANK_ACCOUNT_SUCCESS, 'success');
      closeDrawer();
      fetchAllFilters();
    } catch (error) {
      informationAlert(UPDATE_BANK_ACCOUNT_ERROR, 'error');
    }
  };

  const updateBankAccountDetails = async (data: BankAccount) => {
    setIsLoading(LoadingStatus.Updating);

    await editBankAccount(data);

    setIsLoading(undefined);
  };

  const toggleDrawer = () => {
    if (isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    reset();
    onClose();
    setShowExitConfirmation(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  return {
    isLoading,
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    updateBankAccountDetails,
    closeDrawer,
    keepDrawerOpen,
    showExitConfirmation,
    toggleDrawer,
    ledgerList,
    accountList,
    fundList,
  };
};
