import {
  Box,
  ButtonBase,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { RouteProps } from 'react-router-dom';

import HorizontalBox from '../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../components/Buttons/Button/Button';
import DetailPanel from '../../../components/DetailPanel/DetailPanel';
import {
  FormBox,
  Panel,
  StyledBackdrop,
  Typography,
} from '../../../components/DetailPanel/DetailPanel.styles';
import ConfirmationDialog from '../../../components/Modal/ConfirmationDialog';
import ProgressPanel from '../../../components/Progress/ProgressPanel/ProgressPanel';
import StatusLabel from '../../../components/StatusLabel/StatusLabel';
import { NotifiedStatusType } from '../../../services/hooks/useCapitalCallsEffect/useCapitalCall.constants';
import { M_DASH_UNICODE } from '../../../utils/constants/constants';
import { CurrencyFormat } from '../../../utils/helpers/format.helper';
import { SelectedCashReceipt } from '../../../utils/types/cashReceipt.type';
import { DetailsType } from '../../../utils/types/form.type';
import { SelectedCashReceiptTransaction } from '../cashReceipts/CashReceipts.constants';
import { useCashReceiptDetails } from './CashReceiptDetails.hooks';
import {
  KeyLabel,
  KeyValuePair,
  ValueLabel,
} from './CashReceiptDetails.styles';

interface Props extends RouteProps {
  onDetailClose: Function;
  selectedTransaction: SelectedCashReceiptTransaction;
  fetchCapitalCallTransactions: Function;
  arkGlLocked: boolean;
  setSelectedJournalEntry: Function;
  onJournalEntryPanelClose: Function;
  onJournalEntrySuccessfulPostClose: Function;
  setSelectedMailing: Function;
  setNotifyList: Function;
  isNotifyChecked: boolean;
  setIsNotifyChecked: Function;
  isSendToGLChecked: boolean;
  setIsSendToGLChecked: Function;
  notify: Function;
  selectedCapitalCall: any;
  setSelectedJEList: Function;
}

export const CashReceiptDetailsReadOnly: React.FC<Props> = ({
  onDetailClose,
  selectedTransaction,
  fetchCapitalCallTransactions,
  selectedCapitalCall,
  arkGlLocked,
  setSelectedJournalEntry,
  onJournalEntryPanelClose,
  onJournalEntrySuccessfulPostClose,
  setSelectedMailing,
  setNotifyList,
  isNotifyChecked,
  isSendToGLChecked,
  setIsSendToGLChecked,
  notify,
  setSelectedJEList,
}: Props) => {
  const { type, transaction } = selectedTransaction;

  const {
    isLoading,
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    updateTransaction,
    closeDrawer,
    keepDrawerOpen,
    toggleDrawer,
    showExitConfirmation,
    notifiedStatus,
  } = useCashReceiptDetails({
    transaction,
    onClose: onDetailClose,
    fetchCapitalCallTransactions,
    selectedCapitalCall,
    selectedTransaction,
    arkGlLocked,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
    onJournalEntrySuccessfulPostClose,
    setSelectedMailing,
    setNotifyList,
    isNotifyChecked,
    isSendToGLChecked,
    setIsSendToGLChecked,
    notify,
    setSelectedJEList,
  });

  return (
    <>
      <DetailPanel
        id="detail_panel_cash_receipt"
        title={transaction?.name ? transaction.name : ''}
        open={Boolean(type)}
        variant={'temporary'}
        hasTabsPanel={false}
        onClose={toggleDrawer}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit(updateTransaction)}
          noValidate
        >
          <FormBox>
            <Panel id="details_panel">
              <Typography variant="h4">Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <KeyValuePair>
                    <KeyLabel>Fund Name</KeyLabel>
                    <ValueLabel>
                      {selectedCapitalCall.fundName || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyValuePair>
                    <KeyLabel>Investor Name</KeyLabel>
                    <ValueLabel>
                      {transaction?.investor || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyValuePair>
                    <KeyLabel>Capital Called</KeyLabel>
                    <ValueLabel>
                      {(transaction?.amount &&
                        CurrencyFormat('USD', 2).format(transaction?.amount)) ||
                        M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyValuePair>
                    <KeyLabel>Notified</KeyLabel>
                    <ValueLabel>
                      <StatusLabel
                        color={
                          (notifiedStatus === false
                            ? NotifiedStatusType['NO']
                            : NotifiedStatusType['YES']
                          ).color
                        }
                        isUpperCase={false}
                        label={
                          (notifiedStatus === false
                            ? NotifiedStatusType['NO']
                            : NotifiedStatusType['YES']
                          ).name ?? M_DASH_UNICODE
                        }
                      />{' '}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>

                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>Cash Received</KeyLabel>
                    <ValueLabel>
                      {transaction?.cashReceived ? 'Yes' : 'No'}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyValuePair>
                    <KeyLabel>Amount Received</KeyLabel>
                    <ValueLabel>
                      {(transaction?.amount &&
                        CurrencyFormat('USD', 2).format(transaction.amount)) ||
                        M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyValuePair>
                    <KeyLabel>Date</KeyLabel>
                    <ValueLabel>
                      {transaction?.receiptDate || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Typography variant="h4">Receivable Adjustments</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyValuePair>
                    <KeyLabel>Adjustment 1 Amount</KeyLabel>
                    <ValueLabel>
                      {(transaction?.adjustments &&
                        transaction?.adjustments[0] &&
                        CurrencyFormat('USD', 2).format(
                          transaction?.adjustments[0].amountPaid
                        )) ||
                        M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyValuePair>
                    <KeyLabel>Adjustment 1 Date</KeyLabel>
                    <ValueLabel>
                      {(transaction?.adjustments &&
                        transaction?.adjustments[0] &&
                        transaction?.adjustments[0].adjustmentDate) ||
                        M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyValuePair>
                    <KeyLabel>Adjustment 2 Amount</KeyLabel>
                    <ValueLabel>
                      {(transaction?.adjustments &&
                        transaction?.adjustments[1] &&
                        CurrencyFormat('USD', 2).format(
                          transaction?.adjustments[1].amountPaid
                        )) ||
                        M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyValuePair>
                    <KeyLabel>Adjustment 2 Date</KeyLabel>
                    <ValueLabel>
                      {(transaction?.adjustments &&
                        transaction?.adjustments[1] &&
                        transaction?.adjustments[1].adjustmentDate) ||
                        M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
              </Grid>
            </Panel>
          </FormBox>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
          >
            <Button
              id={'cash_receipt_close_button'}
              variant="outlined"
              onClick={closeDrawer}
              text={'Close'}
              color={'secondary'}
              fullWidth
            />
          </HorizontalBox>
        </form>
        <StyledBackdrop open={Boolean(isLoading)} />
        <ProgressPanel
          id={'progress_cash_receipt_panel'}
          showProgress={Boolean(isLoading)}
          text={isLoading ? `${isLoading}...` : ''}
        />
      </DetailPanel>
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_cash_receipt_confirmation"
        actions={[
          {
            label: 'Keep Editing',
            onClick: keepDrawerOpen,
            id: 'btn_cash_receipt_save',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Discard Changes',
            onClick: closeDrawer,
            id: 'btn_cash_receipt_cancel_save',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content="Unsaved changes will be lost"
        title="You have Unsaved Changes"
      />
    </>
  );
};
