import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { getCountryList, getStateList } from "../../../services/address.service";
import {
  autoCompleteAddress,
  getDetailsByIdPlace,
  getDetailsByZipCode,
} from "../../../services/autocomplete.service";
import { getLegalTypesList, getTypesList } from "../../../services/types.service";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { LoadingStatus } from "../../../utils/types/form.type";
import { InvestorDetail } from "../../../utils/types/investor.type";
import { ListItem, SelectionOptionItem } from "../../../utils/types/listItems";

type FormProps = {
  register: Function;
  control: any;
  errors: any;
  setError: Function;
  setValue: Function;
  watch: Function;
  reset: Function;
  resetField: Function;
}

export const useInvestorDetailsEffect = (
  setIsLoading: Function,
  form: FormProps,
  investor?: InvestorDetail,
) => {

  const { informationAlert } = useContext(AppContext);

  const { register, control, errors, setError, setValue, watch, resetField } = form;

  const mailingCountry = watch('mailingAddress.country');
  const mailingState = watch('mailingAddress.state');
  const taxCountry = watch('taxAddress.country');
  const taxState = watch('taxAddress.state');
  const mailingAddress = watch('mailingAddress');
  const taxAddress = watch('taxAddress');
  const useMailingForTax = watch('useMailingForTax');

  const [showMessageNoMatches, setShowMessageNoMatches] = useState<boolean>(false);

  const [countryList, setCountryList] = useState<ListItem[]>([]);
  const [addressSuggestions, setAddressSuggestions] = useState<any[]>([]);
  const [addressSuggestionsAnchorEl, setAddressSuggestionsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [stateList, setStateList] = useState<ListItem[]>([]);

  const [taxAddressSuggestions, setTaxAddressSuggestions] = useState<any[]>([]);
  const [taxAddressSuggestionsAnchorEl, setTaxAddressSuggestionsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [taxStateList, setTaxStateList] = useState<ListItem[]>([]);

  useEffectAsync(async (isCanceled) => {
    try {
      if (isCanceled()) return;
      setIsLoading(LoadingStatus.Loading);
      const countriesResponse = await getCountryList();

      countriesResponse.some(
        (country, idx) =>
          country.id === "US" &&
          countriesResponse.unshift(countriesResponse.splice(idx, 1)[0])
      );

      setCountryList(countriesResponse);

    } catch (e) {
      informationAlert("Error in getting country list", "error");
    } finally {
      setIsLoading('');
    }
  }, []);

  useEffectAsync(
    async (isCanceled) => {
      try {
        if (isCanceled()) return;
        setIsLoading(LoadingStatus.Loading);
        if (mailingCountry) {
          const statesResponse = await getStateList(mailingCountry);

          setStateList(statesResponse);
        }
      } catch (e) {
        informationAlert("Error in getting states", "error");
      } finally {
        setIsLoading('');
      }
    }, [mailingCountry, mailingState]);

  useEffectAsync(
    async (isCanceled) => {
      try {
        if (isCanceled()) return;
        if (taxCountry) {
          const statesResponse = await getStateList(taxCountry);

          setTaxStateList(statesResponse);
        }
      } catch (e) {
        informationAlert("Error in getting states", "error");
      }
    }, [taxCountry, taxState]);

  const suggestedStateList = stateList.find((state) => state.id === mailingState)
    ? stateList
    : [...stateList, { id: mailingState, label: mailingState }];
  const suggestedCountryList = countryList.find((country) => country.id === mailingCountry)
    ? countryList
    : [...countryList, { id: mailingCountry, label: mailingCountry }];

  const suggestedTaxStateList = taxStateList.find((state) => state.id === taxState)
    ? taxStateList
    : [...taxStateList, { id: taxState, label: taxState }];
  const suggestedTaxCountryList = countryList.find((country) => country.id === taxCountry)
    ? countryList
    : [...countryList, { id: taxCountry, label: taxCountry }];

  useEffect(() => {
    if (
      investor &&
      investor.id &&
      taxAddress &&
      mailingAddress &&
      mailingAddress.city === taxAddress.city &&
      mailingAddress.state === taxAddress.state &&
      mailingAddress.postalCode === taxAddress.postalCode &&
      mailingAddress.street1 === taxAddress.street1 &&
      mailingAddress.street2 === taxAddress.street2
    ) {
      setValue('useMailingForTax', true);
    }
  }, [mailingAddress, taxAddress]);

  useEffect(() => {
    useMailingForTax ?? copyTaxAddress();
  }, [mailingAddress]);

  const onTaxAddressCheck = (event: any) => {
    if (event.target.checked) {
      copyTaxAddress();
    } else {
      resetAddress('tax');
    }
  };

  const handleCloseArrowPopover = (addressType: string) => {
    switch (addressType) {
      case 'mailing':
        setAddressSuggestionsAnchorEl(null);
        break;
      case 'tax':
        setTaxAddressSuggestionsAnchorEl(null);
        break;
    }
  };

  const handleOnAddressChange = async ({ target, currentTarget }: any, addressType: string) => {
    const { value } = target;

    const result: any[] = await autoCompleteAddress(value);

    switch (addressType) {
      case 'mailing': {
        value ? setAddressSuggestionsAnchorEl(currentTarget) : setAddressSuggestionsAnchorEl(null);

        if (result) {
          const found = result.filter((item) => item.description.toLowerCase() === value.toLowerCase());

          found.length === 0 ? setShowMessageNoMatches(true) : setShowMessageNoMatches(false);
          setAddressSuggestions(result);
        } else {
          setAddressSuggestionsAnchorEl(null);
        }
        break;
      }
      case 'tax': {
        value ? setTaxAddressSuggestionsAnchorEl(currentTarget) : setTaxAddressSuggestionsAnchorEl(null);

        if (result) {
          const found = result.filter((item) => item.description.toLowerCase() === value.toLowerCase());

          found.length === 0 ? setShowMessageNoMatches(true) : setShowMessageNoMatches(false);
          setTaxAddressSuggestions(result);
        } else {
          setTaxAddressSuggestionsAnchorEl(null);
        }
        break;
      }
    }
  };

  const handleOnPhoneChange = (e: any) => {
    const newValue = e.target.value;
    const allowedChars = new RegExp(/^[0-9+() .-]*$/);

    if (newValue.match(allowedChars)) {
      setValue('phone', newValue);
    }
  };

  const handleOnNumberChange = (e: any, property: string) => {
    const newValue = e.target.value;
    const allowedChars = new RegExp(/^[0-9]*$/);

    if (newValue.match(allowedChars)) {
      setValue(property, newValue);
    }
  };

  const handleTinChange = (e:any) => {
    const newValue = e.target.value;
    const allowedChars = new RegExp(/^[a-zA-Z0-9-/-]*$/);

    if (newValue.match(allowedChars)) {
      setValue('taxIdentificationNumber', newValue);
    }
  };

  const handleSelectClick = async (placeId: string, routeName: string, addressType: string) => {
    const resp = await getDetailsByIdPlace(placeId);

    if (resp) {
      switch (addressType) {
        case 'mailing':
          setValue('mailingAddress.street1', routeName);
          setValue('mailingAddress.country', resp.country);
          setValue('mailingAddress.state', resp.state);
          setValue('mailingAddress.postalCode', resp.zipCode);
          setValue('mailingAddress.city', resp.city);
          setAddressSuggestionsAnchorEl(null);
          break;
        case 'tax':
          setValue('taxAddress.street1', routeName);
          setValue('taxAddress.country', resp.country);
          setValue('taxAddress.state', resp.state);
          setValue('taxAddress.postalCode', resp.zipCode);
          setValue('taxAddress.city', resp.city);
          setTaxAddressSuggestionsAnchorEl(null);
          break;
      }
    }
  };

  const copyTaxAddress = () => {
    setValue('taxAddress', { ...mailingAddress });
  };

  const resetAddress = (type: string) => {
    setValue(`${type}Address.id`, '');
    setValue(`${type}Address.country`, '');
    setValue(`${type}Address.state`, '');
    setValue(`${type}Address.city`, '');
    setValue(`${type}Address.postalCode`, '');
    setValue(`${type}Address.street1`, '');
    setValue(`${type}Address.street2`, '');
  };

  return {
    suggestedStateList,
    suggestedCountryList,
    suggestedTaxStateList,
    suggestedTaxCountryList,
    showMessageNoMatches,
    addressSuggestions,
    addressSuggestionsAnchorEl,
    taxAddressSuggestions,
    taxAddressSuggestionsAnchorEl,
    handleOnAddressChange,
    handleCloseArrowPopover,
    handleSelectClick,
    handleOnPhoneChange,
    handleOnNumberChange,
    handleTinChange,
    onTaxAddressCheck
  };
};

type InvestorTypesProps = {
  setIsLoading: Function;
};

export const useInvestorTypesEffect = ({
  setIsLoading
}: InvestorTypesProps) => {
  const [legalTypesList, setLegalTypesList] = useState<ListItem[]>([]);

  const { informationAlert } = useContext(AppContext);

  useEffectAsync(async (isCanceled) => {
    try {
      setIsLoading(LoadingStatus.Loading);
      
      const legalTypesResponse = await getLegalTypesList();
      
      if (isCanceled()) return;

      setLegalTypesList(legalTypesResponse);
    } catch (e) {
      informationAlert("Error in getting investor types", "error");
    } finally {
      setIsLoading('');
    }
  }, []);

  const handleTypeChange = (e: any, list: any) => {
    const newValue = e.target.value;

    return list.find((type: any) => type.id === newValue);
  };

  return {
    legalTypesList,
    handleTypeChange
  };
};
