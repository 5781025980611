import { DateRange } from "@mui/x-date-pickers-pro";
import { Dispatch, SetStateAction } from "react";

import { ProgressModal } from "../../../../../components";
import { DataWrapperBox } from "../../../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import ReportGrid from "../../../../../components/ReportGrid/ReportGrid";
import { GridData, GridRow, ValueFieldDef } from "../../../../../components/ReportGrid/ReportGrid.types";
import { DateColumnInfo, ReportAccount, TrialBalanceDetailsParams } from "../shared";
import { useIncomeStatementCustom } from "./IncomeStatementCustom.hooks";

interface Props {
    attributes: any,
    dateRange: DateRange<Date>,
    funds: any,
    isLoading: boolean,
    params: TrialBalanceDetailsParams,
    reportView: string,
    setIsLoading: (isLoading: boolean) => void,
    setMainReportListData: (gridDate: GridData) => void,
    suppressIfZero: boolean,
    viewFilters: any,
    handleValueLinkButtonClick: (gridRow: GridRow, valueFieldOrder: ValueFieldDef, dateColInfo: DateColumnInfo[], isCustom: boolean, currentDecimals: number) => void,
    onDataGridChange: (gridData: GridData, exportName: string) => void,
    handleDateRangeChange: (dateRange: DateRange<Date>, clearGrid: boolean) => void,
    fsView?: boolean,
    handleFsNameChange?: (params: any, gridData: GridRow[], allReportAccounts: ReportAccount[]) => void,
    triggerBuilder?: boolean,
    editModeActivated?: boolean,
    roundedReportActivated?: boolean,
    setRoundedReportActivated?: (roundedReportActivated: boolean) => void,
    fsAddPackage?: boolean,
    fsSavePackage?: boolean,
    setFsSaveFieldOrder?: Dispatch<SetStateAction<any[]>>,
    triggerFsPackageBuilder?: () => void,
    fsUpdateColumnOrder?: any,
    fsColumnBuilder?: boolean,
    setFsPackageFlag?: (fsPackageFlag: boolean) => void,
}

export const IncomeStatementCustom: React.FC<Props> = ({
    attributes,
    dateRange,
    funds,
    isLoading,
    params,
    reportView,
    setIsLoading,
    setMainReportListData,
    suppressIfZero,
    viewFilters,
    handleValueLinkButtonClick,
    onDataGridChange,
    handleDateRangeChange,
    fsView,
    handleFsNameChange,
    triggerBuilder,
    editModeActivated,
    roundedReportActivated,
    setRoundedReportActivated,
    fsAddPackage,
    fsSavePackage,
    setFsSaveFieldOrder,
    triggerFsPackageBuilder,
    fsUpdateColumnOrder,
    fsColumnBuilder,
    setFsPackageFlag,

}) => {

    const {
        allReportAccounts,
        categoryFieldOrder,
        currentCurrency,
        currentDecimals,
        dataFieldOrder,
        footerRollUp,
        handleCategoryFieldOrderChange,
        handleDataFieldOrderChange,
        handleDataGridChange,
        handleValueClick,
        hideUseerCategorySelector,
        pinActionColumn,
        reportGridBaseData,
        rollupMode,
    } = useIncomeStatementCustom(
        params,
        reportView,
        suppressIfZero,
        dateRange,
        funds,
        attributes,
        viewFilters,
        setIsLoading,
        handleValueLinkButtonClick,
        onDataGridChange,
        handleDateRangeChange,
        triggerBuilder,
        fsView,
        setRoundedReportActivated,
        fsAddPackage,
        fsSavePackage,
        setFsSaveFieldOrder,
        triggerFsPackageBuilder,
        fsUpdateColumnOrder,
        fsColumnBuilder,
        setFsPackageFlag,
    );
    
    return (
        <ReportGrid 
            id="gl_reports_income_statement_custom_grid"
            reportGridBaseData={reportGridBaseData}
            userCategoryFieldOrder={categoryFieldOrder}
            userColDefs={dataFieldOrder}
            noDataMessage={isLoading ? "" : undefined}
            summaryRowCategoryName="Totals"
            hideUseerCategorySelector={hideUseerCategorySelector}
            rollupMode={rollupMode}
            categoryColumnWidth={600}
            onDataGridChange={handleDataGridChange} 
            onCategoryFieldOrderChange={handleCategoryFieldOrderChange}
            onDataFieldOrderChange={handleDataFieldOrderChange}
            onValueLinkButtonClick={handleValueClick}
            reportView={reportView}
            suppressIfZero={suppressIfZero}
            currentDecimals={currentDecimals}
            currentCurrency={currentCurrency}
            pinActionColumn={pinActionColumn}
            footerRollUp={footerRollUp}
            fsView={fsView}
            handleFsNameChange={handleFsNameChange}
            editModeActivated={editModeActivated}
            roundedReportActivated={roundedReportActivated}
            allReportAccounts={allReportAccounts}
        />
    );
};