import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import {
  getAllFunds,
  getAllQuartersForFund,
  patchPublishQuarterForFund
} from "../../../services/capitalAccounts.service";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { PublishFund, Quarter } from "../../../utils/types/capitalAccounts.type";

type Props = {
  onPublishQuartersViewClose: Function;
  clientId: string;
}

export const QUARTER_PUBLISH_ERROR = "Error while trying to publish quarter.";

export const usePublishQuarters = ({
  onPublishQuartersViewClose,
  clientId
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allFunds, setAllFunds] = useState<PublishFund[]>([]);
  const [selectedFund, setSelectedFund] = useState<PublishFund>();
  const [quartersList, setQuartersList] = useState<Quarter[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const {
    informationAlert
  } = useContext(AppContext);

  const toggleDrawer = () => {
    closeDrawer();
  };

  const closeDrawer = () => {
    onPublishQuartersViewClose();
  };

  const fetchAllFundsForClientId = async (clientId: string, isCanceled?: Function) => {
    if (!clientId) return;
    try {
      setIsLoading(true);
      const funds: PublishFund[] = await getAllFunds(clientId);

      if(isCanceled?.()) return;

      if (funds?.length > 0) {
        setAllFunds(funds);
        setSelectedFund(funds[0]);
      }
    } catch (e) {
      informationAlert("Error in getting funds", "error");
    }
    setIsLoading(false);
  };

  const fetchAllQuartersForFundId = async (fundId: string, isCanceled?: Function) => {
    if (!fundId) return;
    try {
      setIsLoading(true);
      const quarters: Quarter[] = await getAllQuartersForFund(fundId) as Quarter[];
      
      if(isCanceled?.()) return;
      setQuartersList(quarters);
    } catch (e) {
      informationAlert("Error in getting fund quarters", "error");
    }
    setIsLoading(false);
  };

  useEffectAsync(async (isCanceled) => {
    await fetchAllFundsForClientId(clientId, isCanceled);
  }, [clientId]);

  useEffectAsync(async (isCanceled) => {
    if (selectedFund)
      await fetchAllQuartersForFundId(selectedFund.fund?.id ?? '', isCanceled);
  }, [selectedFund]);

  const onPublish = async (quarterId: string, publish: boolean) => {
    if (!quarterId || !selectedFund) return;

    try {
      setIsLoading(true);
      await patchPublishQuarterForFund(selectedFund.fund.id, quarterId, publish);

      setQuartersList(prevList => {
        return prevList.map(quarter => {
          return quarter.quarterId === quarterId
            ? {
              ...quarter,
              published: publish
            }
            : quarter;
        });
      });
    } catch (e) {
      informationAlert(QUARTER_PUBLISH_ERROR, 'error');
    }

    setIsLoading(false);
  };

  return {
    toggleDrawer,
    isLoading,
    searchQuery,
    setSearchQuery,
    allFunds,
    selectedFund,
    quartersList,
    setSelectedFund,
    onPublish
  };
};
