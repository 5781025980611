import React, { createContext, useMemo, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import InformationAlert from '../../components/InformationAlert/InformationAlert';
import { useClientConfig } from '../../components/NavBar/useClientConfig.hooks';
import PortalUnavailable from '../../components/PortalUnavailable/PortalUnavailable';
import Progress from '../../components/Progress/ProgressModal/ProgressModal';
import { getClientToken } from '../../services/client.service';
import { getSideNavs } from '../../services/navigation.service';
import {
  getClientConfigs,
  getClientPortalAvaiability,
} from '../../services/preAuthAPIs.service';
import { getCurrentUser } from '../../services/user.service';
import {
  CLIENT_PROXY_TOKEN_KEY,
  LOGIN_PAYLOAD_KEY,
  TOKEN_KEY,
} from '../../utils/constants/constants';
import { INFO_ALERT_SEVERTIY_TYPES } from '../../utils/constants/styles.constants';
import { getSubdomainAndDomain } from '../../utils/helpers/misc.helper';
import noop from '../../utils/helpers/noop';
import { useEffectAsync } from '../../utils/hooks/useEffectAsync.hook';
import useSessionStorage from '../../utils/hooks/useSessionStorage';
import { ClientConfigs } from '../../utils/types/client.type';
import { Menu } from '../../utils/types/navigation.type';
import { ADMIN_ROLES, LoginUser, ScopeRole } from '../../utils/types/user.type';
import { parseJwt } from '../authentication/parseJWT';

type StateType = {
  loginUser: LoginUser;
};

type InformationMessage = {
  text: string;
  open: boolean;
  severity: INFO_ALERT_SEVERTIY_TYPES;
  title?: string | undefined;
  actionTitle?: string;
  action?: any;
};

type timeoutCallbackContainerType = {
  callback: () => void;
};

const initialState: StateType = {
  loginUser: {
    clientId: '',
    clientName: '',
    email: '',
    currentUser: undefined,
  },
};

const timeoutCallbackContainer = {
  callback: noop,
};

const timeoutCallbackWarningContainer = {
  callback: noop,
};

const initialInformationMessageState: InformationMessage = {
  text: '',
  open: false,
  severity: 'info',
};

let { subdomain, domain } = getSubdomainAndDomain(window.location.hostname);

const substituteLocal = getSubdomainAndDomain(
  process.env.REACT_APP_API_ENDPOINT_URL as string
).domain.slice(0, -1);

if (domain === 'localhost') {
  domain = substituteLocal;
} else if (subdomain === 'localhost') {
  domain = substituteLocal;
  subdomain = 'platform';
}

const isLPPortal: boolean =
  domain && subdomain && subdomain !== 'platform' ? true : false;

const AppContext = createContext<{
  state: StateType;
  informationAlertMessage: InformationMessage;
  informationAlert: any;
  onLogout: () => void;
  onIdleWarning: () => void;
  setTimeoutCallback: (callback: () => void) => void;
  setTimeoutWarningCallback: (callback: () => void) => void;
  appHasChanges: boolean;
  setAppHasChanges: (value: boolean) => void;
  token: string;
  proxyToken: string;
  sideNavs?: Menu[];
  preAuthClientConfigs?: ClientConfigs;
  clientConfigList: ClientConfigs[];
  updateClient: (
    clientId: string,
    token: string,
    arkClientTag?: string | null
  ) => void;
  onClientChange: (clientId: string, refetchClientList?: boolean) => void;
  onLogin: (loginUser: LoginUser, token: string) => void;
  refetchSideNav: () => void;
  onProxyLogin: (
    loginUser: LoginUser,
    clientToken: string,
    proxyToken: string
  ) => void;
}>({
  state: initialState,
  informationAlertMessage: initialInformationMessageState,
  informationAlert: () => null,
  onLogout: noop,
  onIdleWarning: noop,
  setTimeoutCallback: noop,
  setTimeoutWarningCallback: noop,
  appHasChanges: false,
  setAppHasChanges: noop,
  updateClient: noop,
  onClientChange: noop,
  onLogin: noop,
  onProxyLogin: noop,
  refetchSideNav: noop,
  token: '',
  proxyToken: '',
  clientConfigList: [],
});

const AppProvider: React.FC = ({ children }) => {
  const [loginUser, setLoginUser, removeLogin] =
    useSessionStorage(LOGIN_PAYLOAD_KEY);
  const [token, setToken, removeToken] = useSessionStorage(TOKEN_KEY);
  const [proxyToken, setProxyToken, removeProxyToken] = useSessionStorage(
    CLIENT_PROXY_TOKEN_KEY
  );
  const [sideNavs, setSideNavs] = useState<Menu[]>([]);
  const [loading, setLoading] = useState(false);
  const [preAuthClient, setPreAuthClient] = useState<ClientConfigs>();
  const [informationAlertMessage, setInformationAlertMessage] = useState(
    initialInformationMessageState
  );
  const [appHasChanges, setAppHasChanges] = useState<boolean>(false);
  const [isPortalAvailable, setIsPortalAvailable] = useState<boolean>(
    isLPPortal ? false : true
  );
  const [isCheckingPortal, setIsCheckingPortal] = useState<boolean>(
    isLPPortal ? true : false
  );

  const isAdmin = useMemo(() => {
    return (
      token &&
      ADMIN_ROLES?.includes(
        (loginUser?.currentUser?.scopeRole as ScopeRole) ?? ''
      )
    );
  }, [loginUser, token]);

  const { clientConfigList, fetchClientConfigList } = useClientConfig({
    isAdmin,
  });

  const setTimeoutCallback = (callback: () => void) => {
    timeoutCallbackContainer.callback = callback;
  };

  const setTimeoutWarningCallback = (callback: () => void) => {
    timeoutCallbackWarningContainer.callback = callback;
  };

  const onLogout = async () => {
    timeoutCallbackContainer.callback();

    await setLoginUser(initialState.loginUser);
    await removeToken();
    await removeProxyToken();
  };

  const onIdleWarning = () => {
    timeoutCallbackWarningContainer.callback();
  };

  const informationAlert = (
    text: string,
    severity: INFO_ALERT_SEVERTIY_TYPES,
    title?: string | undefined,
    actionTitle?: string | undefined,
    action?: any
  ) => {
    setInformationAlertMessage({
      text: text,
      severity: severity,
      open: true,
      title: title,
      actionTitle: actionTitle,
      action: action,
    });
  };

  useEffectAsync(async (isCanceled) => {
    if (subdomain && domain && !isPortalAvailable) {
      try {
        const response = await getClientPortalAvaiability(domain, subdomain);

        if (isCanceled()) return;

        if (!response) onLogout();
        setIsCheckingPortal(false);
        setIsPortalAvailable(response);
      } catch (e) {
        informationAlert('Error fetching portal status', 'error');
      }
    }
  }, []);

  useEffectAsync(
    async (isCanceled) => {
      if (subdomain && domain && isPortalAvailable) {
        try {
          setLoading(true);
          const response = await getClientConfigs(domain, subdomain);

          if (isCanceled()) return;

          setPreAuthClient({
            ...response,
            baseDomain: domain,
            subdomain: subdomain,
          });
        } catch (e) {
          informationAlert('Error fetching client configs', 'error');
        } finally {
          setLoading(false);
        }
      }
    },
    [isPortalAvailable]
  );

  const currentToken = proxyToken || token;

  useEffectAsync(
    async (isCanceled) => {
      if (currentToken) {
        try {
          setLoading(true);
          const [user, sideNavResponse] = await Promise.all([
            getCurrentUser(),
            getSideNavs(),
          ]);

          const parsed = parseJwt(currentToken);

          if (isCanceled()) {
            return;
          }

          await setLoginUser({
            ...loginUser,
            clientName: parsed.clientName,
            clientId: parsed.clientId,
            currentUser: user,
          });

          setSideNavs(sideNavResponse);
        } catch (error) {
          informationAlert('Session expired', 'error');
        } finally {
          setLoading(false);
        }
      }
    },
    [currentToken]
  );

  const updateClient = async (
    clientId: string,
    token: string,
    arkClientTag: string | null = null
  ) => {
    const parsed = parseJwt(token);

    await setLoginUser({
      ...loginUser,
      clientName: parsed.clientName,
      clientId: clientId,
      arkClientTag: arkClientTag,
    });
    await setToken(token);
  };

  const onClientChange = async (
    clientId?: string,
    refetchClientList?: boolean
  ) => {
    try {
      setLoading(true);
      if (clientId) {
        const updatedToken = await getClientToken(clientId);

        if (!updatedToken?.access_token) {
          return null;
        }
        await updateClient(
          clientId,
          updatedToken.access_token as string,
          updatedToken.arkClientTag ?? null
        );

        if (refetchClientList) {
          await fetchClientConfigList();
        }
      }
    } catch (error) {
      informationAlert('Issue in getting client', 'error');
    } finally {
      setLoading(false);
    }
  };

  const onLogin = async (loginUser: LoginUser, token: string) => {
    const parsed = parseJwt(token);

    await setLoginUser({
      ...loginUser,
      clientName: parsed.clientName,
    });
    await setToken(token);
  };

  const onProxyLogin = async (
    loginUser: LoginUser,
    clientToken: string,
    proxyToken: string
  ) => {
    const parsed = parseJwt(proxyToken);

    await setLoginUser({
      ...loginUser,
      clientName: parsed.clientName,
    });
    await setToken(clientToken);
    await setProxyToken(proxyToken);
  };

  const handleInformationAlertMessageClose = () => {
    setInformationAlertMessage({
      text: '',
      severity: informationAlertMessage.severity,
      open: false,
    });
  };

  const refetchSideNav = async () => {
    const sideNavs = await getSideNavs();

    setSideNavs(sideNavs);
  };

  return (
    <>
      {!isCheckingPortal && !isPortalAvailable ? (
        <PortalUnavailable />
      ) : (
        <AppContext.Provider
          value={{
            state: {
              loginUser: loginUser,
            },
            clientConfigList,
            sideNavs,
            token,
            proxyToken,
            informationAlertMessage,
            informationAlert,
            onLogout,
            onIdleWarning,
            setTimeoutCallback,
            setTimeoutWarningCallback,
            updateClient,
            preAuthClientConfigs: preAuthClient,
            onLogin,
            onProxyLogin,
            onClientChange,
            refetchSideNav,
            appHasChanges,
            setAppHasChanges,
          }}
        >
          <HelmetProvider>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{preAuthClient?.tabName || 'Ark'}</title>
              <link
                id="favicon"
                rel="icon"
                href={
                  preAuthClient?.useFavicon && preAuthClient?.faviconUrl
                    ? preAuthClient?.faviconUrl
                    : '/favicon.ico'
                }
              />
            </Helmet>
          </HelmetProvider>
          <Progress id="global_loader" showProgress={loading} />
          {children}
          <InformationAlert
            id={'ark_notification'}
            text={informationAlertMessage.text}
            open={informationAlertMessage.open}
            severity={informationAlertMessage.severity}
            title={informationAlertMessage.title}
            actionTitle={informationAlertMessage.actionTitle}
            handleClose={handleInformationAlertMessageClose}
            action={informationAlertMessage.action}
          />
        </AppContext.Provider>
      )}
    </>
  );
};

export { AppProvider, AppContext };
