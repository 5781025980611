import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { CLIENT_PROXY_TOKEN_KEY, LOGIN_PAYLOAD_KEY, TOKEN_KEY } from "../../utils/constants/constants";
import { apiEndPoint, ServiceType } from './config';

export enum ClientType {
  Cached = 'Cached',
  NotCached = 'NotCached'
}

export class ApiClient {
  private client: AxiosInstance;

  private static clientsPool = new Map<string, ApiClient>();

  private constructor(private clientType: ClientType, private serviceType: ServiceType) {
    if (clientType === ClientType.NotCached) {
      this.client = this.getNonCachedClient();
    } else {
      this.client = this.getCachedClient();
    }
  }

  public get BearerToken() {
    const token = sessionStorage.getItem(CLIENT_PROXY_TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY);
    let bearer;

    if(token && token!== 'null' && token !== 'undefined') {
      bearer = `Bearer ${JSON.parse(token)}`;
    } else {
      bearer = null;
    }

    return bearer;
  }

  public get BaseUrl() {
    return apiEndPoint(this.serviceType);
  }

  private getNonCachedClient(): AxiosInstance {
    const axiosInstance = axios.create({
      baseURL: this.BaseUrl,
    });

    axiosInstance.interceptors.request.use(
      config => {
        if (this.BearerToken) {
          //config.headers.Authorization = `Bearer ${JSON.parse(this.BearerToken)}`;
          config.headers.Authorization = this.BearerToken;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    // Added interceptor to handle 401 errors
    axiosInstance.interceptors.response.use(
      response => response,
      error => {
        if (error?.response?.status === 401) {
          sessionStorage.removeItem(TOKEN_KEY);
          sessionStorage.removeItem(LOGIN_PAYLOAD_KEY);
          sessionStorage.removeItem(CLIENT_PROXY_TOKEN_KEY);
          window.location.reload();
        }
        return Promise.reject(error);
      });

    return axiosInstance;
  }

  private getCachedClient(): AxiosInstance {
    //TODO: Add real cache client
    const axiosInstance = axios.create({
      baseURL: apiEndPoint(this.serviceType),
    });

    axiosInstance.interceptors.request.use(
      config => {
        if (this.BearerToken) {
          //config.headers.Authorization = `Bearer ${JSON.parse(this.BearerToken)}`;
          config.headers.Authorization = this.BearerToken;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    // Added interceptor to handle 401 errors
    axiosInstance.interceptors.response.use(
      response => response,
      error => {
        if (error?.response?.status === 401) {
          sessionStorage.removeItem(TOKEN_KEY);
          sessionStorage.removeItem(LOGIN_PAYLOAD_KEY);
          sessionStorage.removeItem(CLIENT_PROXY_TOKEN_KEY);
          window.location.reload();
        }
        return Promise.reject(error);
      });

    return axiosInstance;
  }

  static Client(clientType: ClientType, serviceType: ServiceType): ApiClient {
    const key = clientType.toString() + '-' + serviceType.toString();
    let client = ApiClient.clientsPool.get(key);

    if (!client) {
      client = new ApiClient(ClientType.NotCached, serviceType);
      ApiClient.clientsPool.set(key, client);
    }
    return client;
  }

  public async get<T>(path: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.client.get(path, config);

    return response.data as T;
  }

  public async post<T>(path: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.client.post(path, data, config);

    return response.data as T;
  }

  public async put<T>(path: string, data?: T, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.client.put(path, data, config);

    return response.data as T;
  }

  public async patch<T>(path: string, data?: T, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.client.patch(path, data, config);

    return response.data as T;
  }
  public async delete<T>(path: string, data?: T): Promise<T> {
    const response = await this.client.delete(path, data);

    return response.data as T;
  }
}
