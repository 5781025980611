import { IconButton, TextFieldProps } from "@mui/material";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";

import IconClose from "../../assets/images/icons/icon_close.svg";
import IconSearch from "../../assets/images/icons/icon_search.svg";
import { DEBOUNCE_THRESHOLD } from "../../utils/constants/constants";
import { SearchInputAdornment } from "../DataGrid/Filter/Filter.style";
import ImgIcon from "../ImgIcon/ImgIcon";
import { SearchBarInput } from "./SearchBar.styles";

export interface Props extends Omit<TextFieldProps, "value"> {
  id: string;
  size?: "small" | "medium";
  useIcon?: boolean;
  placeHolder?: string;
  readOnly?: boolean;
  onChange: any;
  value: string;
}

const SearchBar = ({
  id,
  size = "small",
  useIcon = true,
  placeHolder = "Search",
  onChange,
  value,
  readOnly = false,
  ...other
}: Props): ReactElement => {
  const [timeoutHandler, setTimeoutHandler] = useState<NodeJS.Timeout | null>(
    null
  );
  const [searchValue, setSearchValue] = useState<string>(value);

  {
    /****
     * Implemented Debounce for the performance optimization,
     * As of now we are using Debounce Threshold of 300ms which is defined in constants as DEBOUNCE_THRESHOLD.
     ***/
  }
  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (timeoutHandler) {
      clearTimeout(timeoutHandler);
    }
    const timeout = setTimeout(() => {
      onChange(e.target.value);
    }, DEBOUNCE_THRESHOLD);

    setSearchValue(e.target.value);
    setTimeoutHandler(timeout);
  };

  const handleClear = () => {
    setSearchValue("");
    onChange("");
  };

  useEffect(() => {
    if (value !== searchValue) {
      setSearchValue(value);
    }
  }, [value]);

  return (
    <SearchBarInput
      id={id}
      size={size}
      placeholder={placeHolder}
      onChange={handleChange}
      value={searchValue}
      InputProps={{
        startAdornment: useIcon ? (
          <SearchInputAdornment position="start">
            <ImgIcon icon={IconSearch} useCursorPointer={true} />
          </SearchInputAdornment>
        ) : undefined,
        endAdornment:
          readOnly || !searchValue || searchValue?.length === 0 ? (
            <SearchInputAdornment position="end">
              <IconButton aria-label="clear">
                <ImgIcon icon={IconClose} useCursorPointer={true} hide/>
              </IconButton>
            </SearchInputAdornment>
          ) : (
            <SearchInputAdornment position="end">
              <IconButton onClick={handleClear} aria-label="clear">
                <ImgIcon icon={IconClose} useCursorPointer={true} />
              </IconButton>
            </SearchInputAdornment>
          ),
        readOnly,
      }}
      {...other}
    />
  );
};

export default SearchBar;
