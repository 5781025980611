import { DateRange } from "@mui/x-date-pickers-pro";

import { ProgressModal } from "../../../../../components";
import { DataWrapperBox } from "../../../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import ReportGrid from "../../../../../components/ReportGrid/ReportGrid";
import { GridData } from "../../../../../components/ReportGrid/ReportGrid.types";
import { TrialBalanceDetailsParams } from "../shared";
import { useTrialBalance } from "./TrialBalance.hooks";

interface Props {
    attributes: any,
    dateRange: DateRange<Date>,
    funds: any,
    isLoading: boolean,
    params: TrialBalanceDetailsParams,
    reportView: string,
    setIsLoading: Function,
    setMainReportListData: Function,
    suppressIfZero: boolean,
    viewFilters: any,
    handleValueLinkButtonClick: Function,
    onDataGridChange: (gridData: GridData, exportName: string) => void,
    handleDateRangeChange: (dateRange: DateRange<Date>, clearGrid: boolean) => void
}

export const TrialBalance: React.FC<Props> = ({
    attributes,
    dateRange,
    funds,
    isLoading,
    params,
    reportView,
    setIsLoading,
    setMainReportListData,
    suppressIfZero,
    viewFilters,
    handleValueLinkButtonClick,
    onDataGridChange,
    handleDateRangeChange
    }: Props) => {

    const {
        categoryFieldOrder,
        currentCurrency,
        currentDecimals,
        dataFieldOrder,
        footerRollUp,
        handleCategoryFieldOrderChange,
        handleDataFieldOrderChange,
        handleDataGridChange,
        handleValueClick,
        hideUseerCategorySelector,
        pinActionColumn,
        reportGridBaseData,
        rollupMode,
        setShowGroups,
        setShowMonthlyGroup,
        setShowQuarterlyGroup,
        setShowYearlyGroup,
        showGroups,
        showMonthlyGroup,
        showQuarterlyGroup,
        showYearlyGroup,
    } = useTrialBalance(
        params,
        reportView,
        suppressIfZero,
        dateRange,
        funds,
        attributes,
        viewFilters,
        setIsLoading,
        handleValueLinkButtonClick,
        onDataGridChange,
        handleDateRangeChange
        );
    
    return (
            <ReportGrid 
                id="gl_reports_trial_balance_grid"
                reportGridBaseData={reportGridBaseData}
                userCategoryFieldOrder={categoryFieldOrder}
                userColDefs={dataFieldOrder}
                noDataMessage={isLoading ? "" : undefined}
                summaryRowCategoryName="Totals"
                hideUseerCategorySelector={hideUseerCategorySelector}
                rollupMode={rollupMode}
                categoryColumnWidth={600}
                onDataGridChange={handleDataGridChange} 
                onCategoryFieldOrderChange={handleCategoryFieldOrderChange}
                onDataFieldOrderChange={handleDataFieldOrderChange}
                onValueLinkButtonClick={handleValueClick}
                reportView={reportView}
                suppressIfZero={suppressIfZero}
                currentDecimals={currentDecimals}
                currentCurrency={currentCurrency}
                pinActionColumn={pinActionColumn}
                footerRollUp={footerRollUp}
                hideFooter={true}
            />
    );
};