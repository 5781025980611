export type FundDetailInfo = {
  id: string;
  name: string;
  shortName?: string | null;
  clientId: string;
  fundFamily: string | null;
  type: string | null;
  entityType: string | null;
  legalType: string | null;
  reportingPeriod: string | null;
  taxIdentificationNumber: string | null;
  active: boolean;
  inceptionDate: string | null;
  currency: string | null;
  fee: number | string | null;
  phone: string | null;
  fax: string | null;
  mailingAddress: MailingAddress | undefined;
};

export type FundDetails = {
  fundDetail: FundDetailInfo;
  teams: Team[];
  investors: [];
  wireInfo: WireInfo;
};

export type MailingAddress = {
  id: string;
  street1?: string;
  street2?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  country?: string;
};

export type ReceivingBankInfo = {
  graphId?: number;
  id: string;
  abaNumber?: string | null;
  name?: string | null;
  type?: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
};

export type BeneficiaryBankInfo = {
  graphId?: number;
  id: string | null;
  abaNumber?: string | null;
  name?: string | null;
  type?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
};

export type IntermediaryBankInfo = {
  graphId?: number;
  id: string;
  abaNumber?: string | null;
  name?: string | null;
  type?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
};

export type WireInfo = {
  id: string | null;
  receivingBankInfo?: ReceivingBankInfo;
  beneficiaryAccount?: string | null;
  beneficiaryName?: string | null;
  beneficiaryAddressLine1?: string | null;
  beneficiaryAddressLine2?: string | null;
  changesPending: boolean;
  beneficiaryBankInfo?: BeneficiaryBankInfo;
  intermediaryBankInfo?: IntermediaryBankInfo;
  originatorToBeneficiaryInstructions1?: string | null;
  originatorToBeneficiaryInstructions2?: string | null;
  originatorToBeneficiaryInstructions3?: string | null;
  originatorToBeneficiaryInstructions4?: string | null;
  bankToBankInstructions1?: string | null;
  bankToBankInstructions2?: string | null;
  bankToBankInstructions3?: string | null;
  bankToBankInstructions4?: string | null;
  foreign?: boolean | null;
  // TODO: remove isForeign once api is fixed
  isForeign?: boolean | null;
};

type FundManagementTeam = {
  id: string;
  name: string;
  clientId: string;
  clientName: string;
  clientDefault: false;
  funds: [{ id: string; name: string }];
  teamMembers: [
    {
      id: string;
      email: string;
      location: string;
      name: string;
      phone: string;
      role: string;
    }
  ];
};

export type Fund = {
  fundDetail: FundDetailInfo;
  wireInfo: WireInfo;
  teams: FundManagementTeam[];
};

export type FundSimple = {
  id: string;
  name: string;
};

export type FundItem = {
  id: string;
  name: string;
  currencyCode: string;
  isChecked: boolean;
};

export type FundSummaryItem = {
  id: string;
  name: string;
  currencyCode: string;
  percentCalled: number;
  quarterAsOf: string;
  [key: string]: string | number;
};

export type FundSummary = {
  currencyCode: string;
  items: FundSummaryItem[];
};

export type Column = {
  id: string;
  colName: string;
  displayName: string;
  order: number;
  visible: boolean;
};

export type TransMapAccount = {
  dateMapped: string | null;
  name: string;
  id: string;
  parentId: string | null;
  transactionTypeId: string | null;
  transactionTypeName: string | null;
  doNotMap: boolean;
};

export type GlMapping = {
  fundName: string;
  items: TransMapAccount[];
};

export type GlConnection = {
  glPlatform: string;
  linkedBy: string;
  linkedOn: string;
};

export type TransType = {
  id: string;
  name: string;
};

export type TransTypeBucket = {
  label: string;
  id: string;
  code: string;
  value: number;
  index: number;
  tenantId: string;
};

export type NewTransType = {
  typeName: string;
  transactionBuckets: TransTypeBucket[];
  tenantId: string;
};

export type Team = {
  id: string;
  name: string;
};

export enum NewFundOptions {
  AddNew = "add_new",
  UploadFromTemplate = "upload_from_template",
  DownloadTemplate = "download_template",
}

export type FundFilters = {
  id: string;
  name: string;
  inceptionDate: string | null;
  investors: [];
};

export enum FundAction {
  ExportSelected = "export_selected",
  ExportAll = "export_all",
  DeleteSelected = "delete_selected",
  DeleteAll = "delete_all",
}
