import {
  Checkbox,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useParams } from 'react-router-dom';

import ContentColumnBox from "../../../components/Boxes/ContentColumnBox/ContentColumnBox";
import Container from "../../../components/Container/Container";
import ProgressModal from '../../../components/Progress/ProgressModal/ProgressModal';
import Switch from "../../../components/Switch/Switch";
import TabsPanel from "../../../components/TabsPanel/TabsPanel";
import { M_DASH_UNICODE } from "../../../utils/constants/constants";
import { KeyLabel, KeyValuePair, ValueLabel } from "../../contacts/contactInfo/contactDetailPanel/ContactDetails/ContactDetails.styles";
import { useFundDetailsEffect, useTabs, useWireEffect } from './FundDetails.hooks';
import { HeaderRow, Panel } from "./FundDetails.style";
import { Box, List } from "./FundDetails.style";

export const ReadOnlyFundDetails: React.FC = () => {

  const {
    fundId
  } = useParams<{ fundId: string }>();

  const {
    isLoadingData,
    fund,
    fundName,
    fundStatus,
    fundInceptionDate,
    activeFundTeams,
    addressValues,
  } = useFundDetailsEffect(fundId);

  const {
    currentTab,
    detailsRef,
    teamRef,
    wiringRef,
    pageBottomRef,
    handleTabChange
  } = useTabs();

  const {
    isIntermediaryBankInfoHidden,
  } = useWireEffect(fund.wireInfo, pageBottomRef, fundId);

  return (
    <Container id="fund_details_main" role="main">
      <ProgressModal
        id="modal_fund_details_loading"
        showProgress={isLoadingData}
      />

      <HeaderRow>
        <TabsPanel
          id={"fund_detail_panel_tabs"}
          value={currentTab}
          handleChange={handleTabChange}
          options={["Details", "Wiring", "Fund Team"]}
        />
      </HeaderRow>

      <Box>
        <Panel id="details_panel" ref={detailsRef}>
          <Typography variant="h4">Fund Details</Typography>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <KeyValuePair>
                <KeyLabel>Name</KeyLabel>
                <ValueLabel>{fundName}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={8}>
              <KeyValuePair>
                <KeyLabel>Short Name</KeyLabel>
                <ValueLabel>{fund.fundDetail.shortName || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={4}>
              <KeyValuePair>
                <KeyLabel>Inception Date *</KeyLabel>
                <ValueLabel>{fundInceptionDate || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={8}>
              <KeyValuePair>
                <KeyLabel>Status</KeyLabel>
                <ValueLabel>
                  <FormControlLabel
                    id="chk_fund_status"
                    label={fundStatus ? "ACTIVE" : "INACTIVE"}
                    control={
                      <Switch
                        id="swtch_status"
                        checked={fundStatus}
                        label="status"
                      />
                    }
                    disabled={true}
                  />
                </ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>TIN</KeyLabel>
                <ValueLabel>{fund.fundDetail.taxIdentificationNumber || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>Legal Type</KeyLabel>
                <ValueLabel>{fund.fundDetail.legalType || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>Reporting Period</KeyLabel>
                <ValueLabel>{fund.fundDetail.reportingPeriod || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>Fund Type</KeyLabel>
                <ValueLabel>{fund.fundDetail.type || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>Entity Type</KeyLabel>
                <ValueLabel>{fund.fundDetail.entityType || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>Fund Family</KeyLabel>
                <ValueLabel>{fund.fundDetail.fundFamily || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>Fund Currency *</KeyLabel>
                <ValueLabel>{fund.fundDetail.currency || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>Fund Fee</KeyLabel>
                <ValueLabel>{fund.fundDetail.fee || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
          </Grid>
        </Panel>
        <Panel id="address_panel">
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>Phone</KeyLabel>
                <ValueLabel>{fund.fundDetail.phone || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <KeyValuePair>
                <KeyLabel>Fax</KeyLabel>
                <ValueLabel>{fund.fundDetail.fax || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>Address Line 1</KeyLabel>
                <ValueLabel>{addressValues.street1 || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>Address Line 2</KeyLabel>
                <ValueLabel>{addressValues.street2 || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>Zip/Post-Code</KeyLabel>
                <ValueLabel>{addressValues.postalCode || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>City</KeyLabel>
                <ValueLabel>{addressValues.city || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>State/Province/Region</KeyLabel>
                <ValueLabel>{addressValues.state || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyValuePair>
                <KeyLabel>Country</KeyLabel>
                <ValueLabel>{addressValues.country || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
          </Grid>
        </Panel>

        <Panel id="wireinfo_panel" ref={wiringRef}>
          <Typography variant="h4">Wire Instructions</Typography>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" mt={1} mb={1}>Receiving Bank information</Typography>
              <Grid container>
                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>Receiving Bank ABA/Swift</KeyLabel>
                    <ValueLabel>{fund.wireInfo.receivingBankInfo.abaNumber || M_DASH_UNICODE}</ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>Receiving Bank Name</KeyLabel>
                    <ValueLabel>{fund.wireInfo.receivingBankInfo.name || M_DASH_UNICODE}</ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>Receiving Bank Address Line 1</KeyLabel>
                    <ValueLabel>{fund.wireInfo.receivingBankInfo.addressLine1 || M_DASH_UNICODE}</ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>Receiving Bank Address Line 2</KeyLabel>
                    <ValueLabel>{fund.wireInfo.receivingBankInfo.addressLine2 || M_DASH_UNICODE}</ValueLabel>
                  </KeyValuePair>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" mt={1} mb={1}>Originator to Beneficiary Bank Instructions</Typography>
              <KeyValuePair>
                <ValueLabel>{fund.wireInfo.originatorToBeneficiaryInstructions1 || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
              <KeyValuePair>
                <ValueLabel>{fund.wireInfo.originatorToBeneficiaryInstructions2 || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
              <KeyValuePair>
                <ValueLabel>{fund.wireInfo.originatorToBeneficiaryInstructions3 || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
              <KeyValuePair>
                <ValueLabel>{fund.wireInfo.originatorToBeneficiaryInstructions4 || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
          </Grid>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" mt={1} mb={1}>Beneficiary Bank information</Typography>
              <Grid container>
                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>Beneficiary Bank ABA/Swift</KeyLabel>
                    <ValueLabel>{fund.wireInfo.beneficiaryBankInfo.abaNumber || M_DASH_UNICODE}</ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>Beneficiary Bank Name</KeyLabel>
                    <ValueLabel>{fund.wireInfo.beneficiaryBankInfo.name || M_DASH_UNICODE}</ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>Beneficiary Bank Address Line 1</KeyLabel>
                    <ValueLabel>{fund.wireInfo.beneficiaryBankInfo.addressLine1 || M_DASH_UNICODE}</ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>Beneficiary Bank Address Line 2</KeyLabel>
                    <ValueLabel>{fund.wireInfo.beneficiaryBankInfo.addressLine2 || M_DASH_UNICODE}</ValueLabel>
                  </KeyValuePair>
                </Grid>
              </Grid>
              
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" mt={1} mb={1}>Bank to Bank Instructions</Typography>
              <KeyValuePair>
                <ValueLabel>{fund.wireInfo.bankToBankInstructions1 || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
              <KeyValuePair>
                <ValueLabel>{fund.wireInfo.bankToBankInstructions2 || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
              <KeyValuePair>
                <ValueLabel>{fund.wireInfo.bankToBankInstructions3 || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
              <KeyValuePair>
                <ValueLabel>{fund.wireInfo.bankToBankInstructions4 || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
          </Grid>

          <ContentColumnBox>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!isIntermediaryBankInfoHidden}
                  value={isIntermediaryBankInfoHidden}
                />}
              id="txt_Bank_Account_not_in_US"
              label="Bank Account not in the US."
              disabled={true}
            />
          </ContentColumnBox>
          <div hidden={isIntermediaryBankInfoHidden}>
            <KeyValuePair>
              <KeyLabel>Intermediary Bank ABA/Swift</KeyLabel>
              <ValueLabel>{fund.wireInfo.intermediaryBankInfo.abaNumber || M_DASH_UNICODE}</ValueLabel>
            </KeyValuePair>
            <KeyValuePair>
              <KeyLabel>Intermediary Bank Address Line 1</KeyLabel>
              <ValueLabel>{fund.wireInfo.intermediaryBankInfo.addressLine1 || M_DASH_UNICODE}</ValueLabel>
            </KeyValuePair>
            <KeyValuePair>
              <KeyLabel>Intermediary Bank Address Line 2</KeyLabel>
              <ValueLabel>{fund.wireInfo.intermediaryBankInfo.addressLine2 || M_DASH_UNICODE}</ValueLabel>
            </KeyValuePair>
            <div ref={pageBottomRef} />
          </div>
        </Panel>

        <Panel id="team_panel" ref={teamRef}>
          <Typography variant="h4">Teams</Typography>
          <List dense>
            {activeFundTeams.map(team => {
              return (
                <ListItem
                  key={team.id}
                >
                  <ListItemText
                    primary={team.label}
                  />
                </ListItem>
              );
            })}
          </List>
          <br />
        </Panel>

      </Box>
    </Container >
  );
};
