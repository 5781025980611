import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import FallbackScreen from './components/Fallback/FallbackScreen';
import { AppProvider } from './core/context/appContextProvider';
import ClientThemeProvider from './core/context/clientThemeContext';
import NavBarProvider from './core/context/navbarContext';
import PrivateRoute from './core/routing/PrivateRoute';
import RoutingPaths from './core/routing/routingPaths';
import { EntityList } from './pages/arkGL/entities/entityList/EntityList';
import { GLFinancialStatements } from './pages/arkGL/financialStatements/FinancialStatementsMain';
import { JournalEntryList } from './pages/arkGL/journalEntries/journalEntryList/JournalEntryList';
import { GlReportList } from './pages/arkGL/reports/NewGlReportList';
import { GLSetupList } from './pages/arkGL/setup/setupList/GLSetupList';
import { Login } from './pages/auth/Login';
import ResetPassword from './pages/auth/ResetPassword';
import { BankFeedsList } from './pages/banks/bankFeeds/BankFeedList';
import { BankRulesList } from './pages/banks/bankRules/BankRuleList';
import CapitalAccountList from './pages/capitalAccounts/capitalAccountList/CapitalAccountList';
import CapitalCallDetails from './pages/capitalCalls/capitalCallDetails/CapitalCallDetails';
import CapitalCalls from './pages/capitalCalls/CapitalCalls';
import { CashReceipts } from './pages/cashReceipts/cashReceipts/CashReceipts';
import ClientDetails from './pages/clientDetails/ClientDetails';
import ClientPortalCapitalCalls from './pages/clientPortal/capitalCalls/CapitalCalls';
import ClientPortalDistribution from './pages/clientPortal/distribution/Distribution';
import Clients from './pages/clients/Clients';
import ContactMain from './pages/contacts/contactList/ContactList';
import Dashboard from './pages/Dashboard/Dashboard';
import DistributionsDetails from './pages/distribution/DistributionDetails/DistributionDetails';
import Distributions from './pages/distribution/Distributions';
import DownloadDocument from './pages/documents/components/download/Download';
import Documents from './pages/documents/Documents';
import DownloadHistory from './pages/downloadHistory/DownloadHistory';
import { FundList } from './pages/fund/fundList/FundList';
import { FundNavigation } from './pages/fund/fundNavigation/FundNavigation';
import { InvestmentTransactionList } from './pages/fund/InvestmentTransactions/InvestmentTransactionList';
import { PortfolioCompanyList } from './pages/fund/portfolioCompanies/PortfolioCompanyList';
import { ScheduleOfInvestments } from './pages/fund/scheduleOfInvestments/ScheduleOfInvestments';
import { GeneralMailings } from './pages/generalMailings/GeneralMailings';
import InvestorList from './pages/investors/investorList/InvestorList';
import Reports from './pages/reports/Reports';
import { TeamList } from './pages/teams/teamlist/TeamList';
import TransactionList from './pages/transactions/transactionList/TransactionList';
import UploadHistory from './pages/uploadHistory/UploadHistory';
import Users from './pages/users/Users';
import ThemeConfig from './styles/theme/index';
import { ADMIN_ROLES, ScopeRole } from './utils/types/user.type';

function App() {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_X_KEY as string);

  useEffect(() => {
    if (!document.getElementById('apiKey')) {
      const googleMapScript = document.createElement('script');

      googleMapScript.setAttribute('id', 'apiKey');
      googleMapScript.setAttribute('async', '');
      googleMapScript.setAttribute('defer', '');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
    }
  }, []);

  return (
    <Router>
      <AppProvider>
        <ClientThemeProvider>
          <ThemeConfig>
            <ErrorBoundary FallbackComponent={FallbackScreen}>
              <NavBarProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <div className="App container">
                    <Switch>
                      <Route
                        exact
                        path={RoutingPaths.SigninPath}
                        component={Login}
                      />
                      <Route exact path={RoutingPaths.SigninPath2}>
                        <Redirect to={{ pathname: RoutingPaths.SigninPath }} />
                      </Route>
                      <Route
                        exact
                        path={RoutingPaths.ResetPassword}
                        component={ResetPassword}
                      />
                      <PrivateRoute
                        exact
                        path={RoutingPaths.AppDashboard}
                        redirectPath={RoutingPaths.SigninPath}
                        component={Dashboard}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        exact
                        path={RoutingPaths.FundList}
                        redirectPath={RoutingPaths.SigninPath}
                        component={FundList}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        exact
                        path={RoutingPaths.FundNav}
                        redirectPath={RoutingPaths.SigninPath}
                        component={FundNavigation}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        exact
                        path={RoutingPaths.Investors}
                        redirectPath={RoutingPaths.SigninPath}
                        component={InvestorList}
                      />
                      <PrivateRoute
                        exact
                        path={RoutingPaths.Contacts}
                        redirectPath={RoutingPaths.SigninPath}
                        component={ContactMain}
                      />
                      <PrivateRoute
                        path={RoutingPaths.Transactions}
                        redirectPath={RoutingPaths.SigninPath}
                        component={TransactionList}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.CapitalAccounts}
                        redirectPath={RoutingPaths.SigninPath}
                        component={CapitalAccountList}
                      />
                      <PrivateRoute
                        path={RoutingPaths.Users}
                        roles={[
                          ScopeRole.SUPER_ADMIN,
                          ScopeRole.ARK_CLIENT_ADMIN,
                        ]}
                        redirectPath={RoutingPaths.SigninPath}
                        component={Users}
                      />
                      <PrivateRoute
                        path={RoutingPaths.Clients}
                        exact
                        roles={[
                          ScopeRole.SUPER_ADMIN,
                          ScopeRole.ARK_CLIENT_ADMIN,
                        ]}
                        redirectPath={RoutingPaths.SigninPath}
                        component={Clients}
                      />
                      <PrivateRoute
                        path={RoutingPaths.CapitalCalls}
                        exact
                        redirectPath={RoutingPaths.CapitalCalls}
                        component={CapitalCalls}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.CapitalCallDetails}
                        exact
                        roles={ADMIN_ROLES}
                        redirectPath={RoutingPaths.CapitalCallDetails}
                        component={CapitalCallDetails}
                      />
                      <PrivateRoute
                        path={RoutingPaths.ClientsDetails}
                        roles={[
                          ScopeRole.SUPER_ADMIN,
                          ScopeRole.ARK_CLIENT_ADMIN,
                        ]}
                        redirectPath={RoutingPaths.SigninPath}
                        component={ClientDetails}
                      />
                      <PrivateRoute
                        path={RoutingPaths.UploadHistory}
                        redirectPath={RoutingPaths.SigninPath}
                        component={UploadHistory}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        exact
                        path={RoutingPaths.Distributions}
                        redirectPath={RoutingPaths.SigninPath}
                        component={Distributions}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.DistributionsDetails}
                        redirectPath={RoutingPaths.DistributionsDetails}
                        component={DistributionsDetails}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.BankFeeds}
                        redirectPath={RoutingPaths.SigninPath}
                        component={BankFeedsList}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.BankRules}
                        redirectPath={RoutingPaths.SigninPath}
                        component={BankRulesList}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.CashReceipts}
                        redirectPath={RoutingPaths.SigninPath}
                        component={CashReceipts}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.GeneralMailings}
                        redirectPath={RoutingPaths.SigninPath}
                        component={GeneralMailings}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.DownloadHistory}
                        redirectPath={RoutingPaths.SigninPath}
                        component={DownloadHistory}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.Reports}
                        redirectPath={RoutingPaths.SigninPath}
                        component={Reports}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.Teams}
                        redirectPath={RoutingPaths.SigninPath}
                        component={TeamList}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.Documents}
                        redirectPath={RoutingPaths.SigninPath}
                        component={Documents}
                      />
                      <PrivateRoute
                        path={RoutingPaths.GLSetup}
                        redirectPath={RoutingPaths.SigninPath}
                        component={GLSetupList}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.GLJournalEntries}
                        redirectPath={RoutingPaths.SigninPath}
                        component={JournalEntryList}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.GLEntities}
                        redirectPath={RoutingPaths.SigninPath}
                        component={EntityList}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.GLReports}
                        redirectPath={RoutingPaths.SigninPath}
                        component={GlReportList}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.GLFinancialStatements}
                        redirectPath={RoutingPaths.SigninPath}
                        component={GLFinancialStatements}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.ScheduleOfInvestments}
                        redirectPath={RoutingPaths.SigninPath}
                        component={ScheduleOfInvestments}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.Portfolio}
                        redirectPath={RoutingPaths.SigninPath}
                        component={PortfolioCompanyList}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.InvestmentTransactions}
                        redirectPath={RoutingPaths.SigninPath}
                        component={InvestmentTransactionList}
                        roles={ADMIN_ROLES}
                      />
                      <PrivateRoute
                        path={RoutingPaths.ClientPortalCapitalCalls}
                        redirectPath={RoutingPaths.SigninPath}
                        component={ClientPortalCapitalCalls}
                        roles={[ScopeRole.BASIC_USER]}
                      />
                      <PrivateRoute
                        path={RoutingPaths.ClientPortalDistributions}
                        redirectPath={RoutingPaths.SigninPath}
                        component={ClientPortalDistribution}
                        roles={[ScopeRole.BASIC_USER]}
                      />
                      <PrivateRoute
                        path={RoutingPaths.DocumentDownload}
                        redirectPath={RoutingPaths.SigninPath}
                        component={Documents}
                        roles={[ScopeRole.BASIC_USER]}
                      />
                      <PrivateRoute
                        path={RoutingPaths.ReportDownload}
                        redirectPath={RoutingPaths.SigninPath}
                        component={DownloadDocument}
                        roles={[ScopeRole.BASIC_USER]}
                      />
                    </Switch>
                  </div>
                </LocalizationProvider>
              </NavBarProvider>
            </ErrorBoundary>
          </ThemeConfig>
        </ClientThemeProvider>
      </AppProvider>
    </Router>
  );
}

export default App;
