import IconDownload from "../../../assets/images/icons/icon_download.svg";
import IconNewCircle from "../../../assets/images/icons/icon_new_circle.svg";
import IconUpload from "../../../assets/images/icons/icon_upload.svg";
import { ButtonOptions } from "../../../components/ButtonWithOptions/ButtonWithOptions";
import { AddNewButtonOptions } from "../../../utils/types/common.type";
import { PortfolioCompanyNew } from "../../../utils/types/portfolioCompany.type";

export const EXPORT_PORTFOLIO_COMPANY_ERROR = "Error in exporting portfolio companies.";
export const DELETE_PORTFOLIO_COMPANY_ERROR = "Error in deleting portfolio companies.";
export const DELETE_PORTFOLIO_COMPANY_SUCCESS = "Portfolio companies deleted successfully.";
export const DOWNLOAD_PORTFOLIO_COMPANY_ERROR = "Error in downloading portfolio companies.";
export const CREATE_PORTFOLIO_COMPANY_ERROR = "Error in creating portfolio company.";
export const CREATE_PORTFOLIO_COMPANY_SUCCESS = "Portfolio company created successfully.";
export const UPDATE_PORTFOLIO_COMPANY_ERROR = "Error in updating portfolio company.";
export const UPDATE_PORTFOLIO_COMPANY_SUCCESS = "Portfolio company updated successfully.";
export const ERROR_TAKEN_PORTFOLIO_COMPANY_NAME = "Portfolio company name is already used in the system";
export const CREATE_CUSTOM_PROPERTY_ERROR = "Error in creating custom property.";
export const CREATE_CUSTOM_PROPERTY_SUCCESS = "Custom property created successfully.";
export const UPDATE_CUSTOM_PROPERTY_ERROR = "Error in updating custom property.";
export const UPDATE_CUSTOM_PROPERTY_SUCCESS = "Custom property updated successfully.";
export const DELETE_CUSTOM_PROPERTY_ERROR = "Error in deleting custom property.";
export const DELETE_CUSTOM_PROPERTY_SUCCESS = "Custom property deleted successfully.";
export const GET_CUSTOM_PROPERTY_LIST_ERROR = "Error in getting list of custom properties.";
export const PORTFOLIO_COMPANIES_VIEW_KEY = "PORTFOLIO_COMPANIES_VIEW_KEY";
export const GET_VIEW_LIST_ERROR = "Error retrieving initial order of columns.";

export const PORTFOLIO_COMPANY_FORM_DEFAULT_VALUE: PortfolioCompanyNew = {
  id: '',
  name: '',
  tenantId: '',
  about: '',
  additionalFieldsForm: [],
  additionalProperties: [],
  holdingStatus: '',
  industrySector: '',
  funds: [],
  fundIds: [],
  ceo: '',
  isPublic: false,
  logo: null,
  numOfEmployees: 0,
  tickerSymbol: '',
  address: {
    id: '',
    tenantId: '',
    street1: '',
    street2: '',
    city: '',
    postalCode: '',
    state: '',
    country: ''
  },
  investmentTypes: [],
  website: null,
  yearFounded: null
};

export const PORTFOLIO_COMPANY_TYPE = [{
  id: "Public",
  name: "Public"
}, {
  id: "Private",
  name: "Private"
}];

export const PortfolioCompanyOptions: ButtonOptions[] = [{
  label: 'New Portfolio Company',
  id: AddNewButtonOptions.AddNew,
  icon: IconNewCircle,
},
{
  label: 'New Custom Field',
  id: AddNewButtonOptions.AddNewCustomField,
  icon: IconNewCircle,
},
{
  label: 'Upload From Template',
  id: AddNewButtonOptions.UploadFromTemplate,
  icon: IconUpload,
  type: 'upload'
},
{
  label: 'Download Template',
  id: AddNewButtonOptions.DownloadTemplate,
  icon: IconDownload,
}];

export const PORTAL_MAPPING = [{
  id: "Distribution",
  name: "Distribution"
}, {
  id: "CapitalCall",
  name: "CapitalCall"
}];
