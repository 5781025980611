import { DeleteOutlineOutlined, MailOutline } from "@mui/icons-material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, useFormState } from "react-hook-form";

import { AppContext } from "../../../core/context/appContextProvider";
import useRole from "../../../core/routing/useRole";
import { useRolesEffect } from "../../../services/hooks/useRolesEffect/useRolesEffect.hooks";
import { resendVerificationEmail } from "../../../services/user.service";
import { createUser as addUser, deleteUser, updateUser } from "../../../services/user.service.v2";
import { ImageItem } from "../../../utils/types/listItems";
import { TransactionCRUDStatus } from "../../../utils/types/transaction.type";
import { OperationType, ScopeRole, SelectedUser, User, UserAction } from "../../../utils/types/user.type";
import {
  AdminRoles,
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  RESEND_INVITATION_ERROR,
  RESEND_INVITATION_SUCCESS,
  Status,
  SuperAdminRoles,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
} from "../constants";

type Props = {
  selectedUser: SelectedUser
  onUserDetailClose: Function;
  resetPaginationAndFetch: Function;
}

export const useUserDetails = ({
  selectedUser,
  onUserDetailClose,
  resetPaginationAndFetch,
}: Props) => {
  const {
    type, user, arkClientTag
  } = selectedUser;
  const [loading, setLoading] = useState<TransactionCRUDStatus>();
  const [toBeDeleted, setToBeDeleted] = useState<string | undefined>();
  const [showExitConfirmation, setShowExitConfirmation] = useState<boolean>(false);
  const [showResendConfirmation, setShowResendConfirmation] = useState<boolean>(false);
  const [userToCreateUpdate, setUserToCreateUpdate] = useState<User | undefined>();
  const {
    informationAlert
  } = useContext(AppContext);
  const {
    roles
  } = useRolesEffect();

  const {
    hasRole: isSuperAdmin,
  } = useRole([ScopeRole.SUPER_ADMIN]);

  const {
    isNewUser,
    isAddingEditingSuperAdmin,
    isAddingEditingAdmin,
    isAddingClient,
  } = useMemo(() => ({
    isNewUser: [OperationType.AddNew, OperationType.AddNewClient, OperationType.AddNewUser, OperationType.AddSuperAdmin].includes(type as OperationType),
    isAddingEditingSuperAdmin: [OperationType.EditSuperAdmin, OperationType.AddSuperAdmin].includes(type as OperationType),
    isAddingClient: type === OperationType.AddNewClient,
    isAddingEditingAdmin: [OperationType.EditUser, OperationType.AddNewUser].includes(type as OperationType),
  }), [type]);

  const currentRoles = useMemo(() => {
    if(isSuperAdmin && isAddingEditingSuperAdmin) {
      return roles?.filter(role => SuperAdminRoles.includes(role?.name ?? ""));
    } else {
      return roles?.filter(role => AdminRoles.includes(role?.name ?? ""));
    }
  }, [roles, isSuperAdmin, isAddingEditingSuperAdmin]);

  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
    setValue,
    control,
    reset,
  } = useForm<User>({
    defaultValues: user ? user : {
      status: Status.ACTIVE,
    },
  });
  const {
    isDirty
  } = useFormState({
    control
  });

  useEffect(() => {
    if(isSuperAdmin && isAddingEditingSuperAdmin && roles && roles?.length > 0) {
      const superAdminRole = roles.find(role => role?.name === "Super Admin");

      reset({
        ...user,
        ...(isNewUser ? {
          status: Status.ACTIVE,
        } : {}),
        role: superAdminRole,
      });
    } else if(arkClientTag && isAddingEditingAdmin) {
      reset({
        ...user,
        ...(isNewUser ? {
          status: Status.ACTIVE,
        } : {}),
        arkClientTag
      });
    } else {
      reset(user);
    }
  }, [isSuperAdmin, roles, user, isAddingEditingSuperAdmin, reset, type, arkClientTag, isAddingEditingAdmin, isNewUser]);

  const createUser = async (data: User) => {
    try {
      await addUser(data);
      resetPaginationAndFetch();
      informationAlert(CREATE_USER_SUCCESS, "success");
      closeDrawer();
    } catch (error) {
      informationAlert(CREATE_USER_ERROR, "error");
    }
  };

  const editUser = async (data: User) => {
    try {
      await updateUser(data);
      resetPaginationAndFetch();
      informationAlert(UPDATE_USER_SUCCESS, "success");
      closeDrawer();
    } catch (error) {
      // TODO: Violation error handling
      informationAlert(UPDATE_USER_ERROR, "error");
    }
  };

  const createUpdateUser = async (data: User) => {
    const resultData = {
      ...(isNewUser ? {} : {}),
      ...data,
      email: data?.email?.toLowerCase(),
    };

    if(isNewUser) {
      setUserToCreateUpdate(resultData);
    } else {
      setLoading(TransactionCRUDStatus.Updating);
      await editUser(resultData);
    }

    setLoading(undefined);
  };

  const onCreateConfirm = async () => {
    if(!userToCreateUpdate) {
      return;
    }
    setLoading(TransactionCRUDStatus.Adding);
    await createUser(userToCreateUpdate);
    setLoading(undefined);
    setUserToCreateUpdate(undefined);
  };

  const onCreateCancel = () => {
    setUserToCreateUpdate(undefined);
  };

  const onResendConfirm = async () => {
    if(!user) {
      setShowResendConfirmation(false);
      return;
    }
    try {
      setLoading(TransactionCRUDStatus.Updating);
      await resendVerificationEmail([user.id] as string[]);
      informationAlert(RESEND_INVITATION_SUCCESS, "success");
      setLoading(undefined);
    } catch (error) {
      informationAlert(RESEND_INVITATION_ERROR, "error");
      setLoading(undefined);
    }
    setShowResendConfirmation(false);
  };

  const onResendCancel = () => {
    setShowResendConfirmation(false);
  };

  const toggleDrawer = () => {
    if(isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    reset({});
    onUserDetailClose();
    setShowExitConfirmation(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  const handleCancelDelete = () => {
    setToBeDeleted(undefined);
  };

  const handleConfirmDelete = async (userToBeDeleted: string) => {
    if(!userToBeDeleted) {
      return;
    }
    setToBeDeleted(undefined);
    try {
      setLoading(TransactionCRUDStatus.Deleting);
      await deleteUser(userToBeDeleted as string);
      resetPaginationAndFetch();
      informationAlert(DELETE_USER_SUCCESS, "success");
      closeDrawer();
      setLoading(undefined);
    } catch (error) {
      informationAlert(DELETE_USER_ERROR, "error");
      setLoading(undefined);
    }
  };

  const handleBulkAction = (actionId: UserAction) => {
    switch (actionId) {
      case UserAction.ResendEmail:
        setShowResendConfirmation(true);
        break;
      case UserAction.DeleteUser:
        setToBeDeleted(user?.id ?? '');
        break;
    }
  };

  const bulkActions: ImageItem[] = useMemo(() => [{
    id: UserAction.ResendEmail,
    text: 'Resend Email',
    icon: <MailOutline color="primary" />,
    optionsSelected: 0,
  },
  {
    id: UserAction.DeleteUser,
    text: 'Delete User',
    icon: <DeleteOutlineOutlined color="primary" />,
    optionsSelected: 0,
  }], []);

  return {
    currentRoles,
    isSuperAdmin,
    loading,
    register,
    setValue,
    handleSubmit,
    errors,
    control,
    createUpdateUser,
    toggleDrawer,
    toBeDeleted,
    handleCancelDelete,
    handleConfirmDelete,
    closeDrawer,
    showExitConfirmation,
    keepDrawerOpen,
    bulkActions,
    handleBulkAction,
    isNewUser,
    isAddingEditingSuperAdmin,
    isAddingClient,
    showResendConfirmation,
    userToCreateUpdate,
    onResendCancel,
    onResendConfirm,
    onCreateCancel,
    onCreateConfirm,
  };
};


