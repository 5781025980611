export enum DELETE_ACTION_TYPE {
  DELETE_ALL = 'DELETE_ALL',
  DELETE_SELECTED = 'DELETE_SELECTED',
}

export enum POST_ACTION_TYPE {
  POST_ALL = 'POST_ALL',
  POST_SELECTED = 'POST_SELECTED',
}

export enum FROM_VIEW {
  JOURNAL_ENTRIES = 'JOURNAL_ENTRIES',
  INVESTMENT_TRANSACTIONS = 'INVESTMENT_TRANSACTIONS',
  CASH_RECEIPTS = 'CASH_RECEIPTS',
}
