enum RoutingPaths {
  SigninPath = '/login',
  SigninPath2 = '/sign-in',
  ResetPassword = '/login/resetPassword',
  AppDashboard = '/',
  FundList = '/funds',
  FundNav = '/fund-nav/*/:fundId',
  FundDetails = '/fund-nav/fund-details',
  FundInvestorList = '/fund-nav/fund-investors',
  TransactionMapper = '/fund-nav/transaction-mapper',
  FundGLSetup = '/fund-nav/gl-setup',
  FundGLAccounts = '/fund-nav/chart-of-accounts',
  FundGLJournalEntries = '/fund-nav/journal-entries',
  FundGLEntityLists = '/fund-nav/entity-lists',
  FundGLReports = '/fund-nav/reports',
  FundAllocationsList = '/fund-nav/fund-allocations-list',
  FundAllocationsFile = '/fund-nav/fund-allocations-list-file',
  FundPortfolioCompanies = '/fund-nav/portfolio-companies',
  Investors = '/investors',
  Contacts = '/contacts',
  Transactions = '/transactions',
  NewTransactions = '/transactions/new',
  EditTransactions = '/transactions/:transactionId',
  CapitalAccounts = '/capital-accounts',
  Documents = '/documents',
  Mailings = '/mailings',
  CapitalCalls = '/capital-calls',
  ClientPortalCapitalCalls = '/portal/capital-calls',
  CapitalCallDetails = '/capital-calls/:id/:section',
  Distributions = '/distributions',
  ClientPortalDistributions = '/portal/distributions',
  DocumentDownload = '/portal/documents/:document_id',
  ReportDownload = '/portal/downloads/:report_id',
  DistributionsDetails = '/distributions/:id/:section',
  GeneralMailings = '/general-mailings',
  BankFeeds = '/banks/bank-feeds',
  BankRules = '/banks/bank-rules',
  CashReceipts = '/banks/cash-receipts',
  Reports = '/reports',
  Teams = '/teams',
  UploadHistory = '/upload-history',
  DownloadHistory = '/download-history',
  Users = '/users',
  Clients = '/clients',
  ClientsDetails = '/clients/:id/:arkTag/:section',
  GLSetup = '/gl/setup',
  GLEntities = '/gl/entities',
  GLJournalEntries = '/gl/journal-entries',
  GLReports = '/gl/reports',
  GLFinancialStatements = '/gl/financial-statements',
  PortfolioInvestments = '/portfolio-investments',
  ScheduleOfInvestments = '/schedule-of-investments',
  ScheduleOfInvestmentsFund = '/fund-nav/schedule-of-investments',
  Portfolio = '/portfolio',
  InvestmentTransactions = '/investment-transactions',
  InvestmentTransactionsFund = '/fund-nav/investment-transactions',
}

export default RoutingPaths;
