import { DateRange } from "@mui/x-date-pickers-pro";
import { cloneDeep, isTypedArray } from "lodash";
import { useContext, useState } from "react";

import { CategoryFieldDef, GridData, GridRow, NumberFormatTypes, ReportGridBaseData, ValueFieldDef } from "../../../../../components/ReportGrid/ReportGrid.types";
import { AppContext } from "../../../../../core/context/appContextProvider";
import { getAllArkAccounts } from "../../../../../services/arkGL.service";
import { getColumnOrder, saveColumnOrder } from "../../../../../services/columnOrder.service";
import { DateTimeFormat } from "../../../../../utils/helpers/format.helper";
import { awaitReactUpdate } from "../../../../../utils/helpers/timeoutFunctions";
import { useEffectAsync } from "../../../../../utils/hooks/useEffectAsync.hook";
import { ColumnOrder, ViewItemsEntity } from "../../../../../utils/types/columnOrder";
import { Account, AccountHierarchyItem, AccountNumber, ColumnTypes, createAccountAttrributeGridDataSums, createAccountChildrenSums, CreditRange, DateColumnInfo, DebitRange, EndBalanceRange, FSDisplayNotSet, getAccountCategories, getAccountParentHierarchy, getFieldDefColumns, getLineItemsForDateCol, getTrialBalanceDetailsDataNew, GridCallerData, insertMissingReportAccount, ReportAccount, StartBalanceRange, suppressGridRowsIfZero, TrialBalanceDetailsParams, ViewKeyCode, viewKeyOrderDefaults } from "../shared";

export const useIncomeStatement = (
    params: TrialBalanceDetailsParams,
    reportView: string, 
    suppressIfZero: boolean, 
    dateRange: DateRange<Date>,
    funds: any,
    attributes: any,
    viewFilters: any,
    setIsLoading: (isLoading: boolean) => void,
    handleValueLinkButtonClick: (gridRow: GridRow, valueFieldOrder: ValueFieldDef, dateColInfo: DateColumnInfo[], isCustom: boolean, currentDecimals: number) => void,
    onDataGridChange: (gridData: GridData, exportName: string) => void,
    handleDateRangeChange: (dateRange: DateRange<Date>, clearGrid: boolean) => void
    ) => {
    const { state } = useContext(AppContext);
    const clientId = state.loginUser.clientId;

    const [accounts, setAccounts] = useState<Account[]>([]);
    const [categoryFieldOrder, setCategoryFieldOrder] = useState<CategoryFieldDef[]>([]);
    const [currentCurrency, setCurrentCurrency] = useState('');
    const [currentDecimals, setCurrentDecimals] = useState(-1);
    const [dataFieldOrder, setDataFieldOrder] = useState<ValueFieldDef[]>([]);
    const [footerRollUp, setFooterRollUp] = useState(true);
    const [hideUseerCategorySelector, setHideUseerCategorySelector] = useState(true);
    const [pinActionColumn, setPinActionColumn] = useState<boolean>(true);
    const [reportGridBaseData, setReportGridBaseData] = useState<ReportGridBaseData[]>([]);
    const [dateColInfosResponse, setDateColInfosResponse] = useState<DateColumnInfo[]>([]);
    const [dateColInfosResponseArray, setDateColInfosResponseArray] = useState<DateColumnInfo[]>([]);
    const [rollupMode, setRollupMode] = useState(false);
    const [showGroups, setShowGroups] = useState(true);
    const [showMonthlyGroup, setShowMonthlyGroup] = useState(false);
    const [showQuarterlyGroup, setShowQuarterlyGroup] = useState(true);
    const [showYearlyGroup, setShowYearlyGroup] = useState(true);
    const [showStartBalance, setShowStartBalance] = useState(false);
    const [showSelected, setShowSelected] = useState(false);
    const [showDebit, setShowDebit] = useState(false);
    const [showCredit, setShowCredit] = useState(false);
    const [showItd, setShowItd] = useState(true);
    const [showEndBalance, setShowEndBalance] = useState(false);
    const [reportAccountsResponse, setReportAccountsResponse] = useState<ReportAccount[]>([]);
    const [firstLineItemDate, setFirstLineItemDate] = useState<Date>();
    const [buildReportDataGridCounter, setBuildReportDataGridCounter] = useState(0);
    const [hideAllZeros, setHideAllZeros] = useState(true); //only false for Trial Balance, all other views are true
    const [viewKeyResponse, setViewKeyResponse] = useState<ColumnOrder>();
    const [viewKeyCounter, setViewKeyCounter] = useState(0);
    const [viewKeyOrder, setViewKeyOrder] = useState<any>([]);
    const pushViewKeyOrder = (viewItem: any) => {
      setViewKeyOrder((prevArray: any) => [...prevArray, viewItem]);
    };
    
    useEffectAsync(async (isCanceled) => {
        setIsLoading(true);
        setViewKeyOrder([]);

        const trialBalanceResponse = await getTrialBalanceDetailsDataNew(params, isCanceled);

        if(!isCanceled() && !trialBalanceResponse ) return;

        const arkAccountsResponse = await getAllArkAccounts();

        if(!isCanceled() && !arkAccountsResponse ) return;

        const { reportAccounts, firstLineItemDate, decimals, currency } = trialBalanceResponse!;

        const viewKeyResponseData = await getColumnOrder(
          `${reportView.toUpperCase()}_FIELD_VIEW`,
          clientId
        );
        
        setViewKeyResponse(cloneDeep(viewKeyResponseData));

        viewKeyResponseData.viewItems?.map((viewItem) => {
          switch (viewItem.code) {
            case StartBalanceRange:
              viewItem.visible ? setShowStartBalance(true) : setShowStartBalance(false);
              pushViewKeyOrder(ViewKeyCode.START_BALANCE);
              break;
            case DebitRange:
              viewItem.visible ? setShowDebit(true) : setShowDebit(false);
              pushViewKeyOrder(ViewKeyCode.DEBIT);
              break;
            case CreditRange:
              viewItem.visible ? setShowCredit(true) : setShowCredit(false);
              pushViewKeyOrder(ViewKeyCode.CREDIT);
              break;
            case EndBalanceRange:
              viewItem.visible ? setShowEndBalance(true) : setShowEndBalance(false);
              pushViewKeyOrder(ViewKeyCode.END_BALANCE);
              break;
            default:
              if(viewItem.code.includes(ViewKeyCode.MONTHLY)){
                viewItem.visible ? setShowMonthlyGroup(true) : setShowMonthlyGroup(false);
                pushViewKeyOrder(ViewKeyCode.MONTHLY);
              }

              if(viewItem.code.includes(ViewKeyCode.QUARTERLY)){
                viewItem.visible ? setShowQuarterlyGroup(true) : setShowQuarterlyGroup(false);
                pushViewKeyOrder(ViewKeyCode.QUARTERLY);
              }

              if(viewItem.code.includes(ViewKeyCode.YEARLY)){
                viewItem.visible ? setShowYearlyGroup(true) : setShowYearlyGroup(false);
                pushViewKeyOrder(ViewKeyCode.YEARLY);
              }

              if(viewItem.code.includes(ViewKeyCode.ITD)){
                viewItem.visible? setShowItd(true) : setShowItd(false);
                pushViewKeyOrder(ViewKeyCode.ITD);
              }

              if(viewItem.code.includes(ViewKeyCode.SELECTED)){
                viewItem.visible? setShowSelected(true) : setShowSelected(false);
                pushViewKeyOrder(ViewKeyCode.SELECTED);
              }
          }
        });


        setCurrentCurrency(currency);
        setCurrentDecimals(decimals);
        setReportAccountsResponse(reportAccounts);

        if(params.startDate) { 
          setFirstLineItemDate(params.startDate);
        } else {
            setFirstLineItemDate(firstLineItemDate);
            handleDateRangeChange([firstLineItemDate, params.endDate], false);
        }
       
        let tempAccounts = arkAccountsResponse.items as Account[];

        tempAccounts.filter(x => !x.fsDisplayName).forEach(x => x.fsDisplayName = FSDisplayNotSet);
        tempAccounts = tempAccounts.sort((a,b) => a.name < b.name ? -1 : 1);

        setAccounts(tempAccounts);
        setBuildReportDataGridCounter(buildReportDataGridCounter+1);
        setIsLoading(false);
    }, [suppressIfZero, showMonthlyGroup, showQuarterlyGroup, showYearlyGroup]);

    useEffectAsync( async () => {
        if(buildReportDataGridCounter === 0) return;

        setIsLoading(true);
        await awaitReactUpdate();

        const currencyFormat = `Currency.${currentDecimals}` as  NumberFormatTypes;        
        const accountHierarchy = getAccountParentHierarchy(accounts);

        insertMissingReportAccount(accountHierarchy, reportAccountsResponse, params.attributes, currentDecimals);      

        if(suppressIfZero){ suppressGridRowsIfZero(reportAccountsResponse, true); }

        const currentReportCategoryFieldDefs = getAccountCategories(accountHierarchy,  'account');        

        let fieldDefsArray: ValueFieldDef[] = [];
        let dateColInfosArray: DateColumnInfo[] = [];

        let viewKeyOrderData = viewKeyOrder.filter((value: any, index: any) => viewKeyOrder.indexOf(value) === index);
        
        if(viewKeyOrderData.length < 9) viewKeyOrderData = viewKeyOrderDefaults;
      
        const startDate = dateRange[0] ? dateRange[0] : firstLineItemDate;
        const { fieldDefs, dateColInfos } = getFieldDefColumns(
              startDate!,
              dateRange[1]!,
              currencyFormat,
              hideAllZeros,
              showStartBalance,
              showMonthlyGroup,
              showQuarterlyGroup,
              showYearlyGroup,
              showSelected,
              showDebit,
              showCredit,
              showItd,
              showEndBalance,
              viewKeyResponse,
              viewKeyOrderData,
              false,
              reportView
            );
      
          fieldDefsArray = fieldDefs;
          dateColInfosArray = dateColInfos;
          setDateColInfosResponseArray(dateColInfos);
                    
          const filterDateColInfos: DateColumnInfo[] = [];
  
          dateColInfosArray.map((column) => {
            if(column.category === undefined){
                filterDateColInfos.push(column);
            }
            if(showMonthlyGroup){
                column.category === ColumnTypes.Monthly ? filterDateColInfos.push(column) : "";
            }
            if(showQuarterlyGroup){
                column.category === ColumnTypes.Quarterly ? filterDateColInfos.push(column) : "";
            }
            if(showYearlyGroup){
                column.category === ColumnTypes.Yearly ? filterDateColInfos.push(column) : "";
            }
          });
      
          fieldDefsArray.map((field) => {
            if(field.category === ColumnTypes.Monthly){
              field.visible = showMonthlyGroup;
            }
            if(field.category === ColumnTypes.Quarterly){
              field.visible = showQuarterlyGroup;
            }
            if(field.category === ColumnTypes.Yearly){
              field.visible = showYearlyGroup;
            }
          });

          const reportGridData = buildReportGridData(reportAccountsResponse, filterDateColInfos, currentCurrency, accountHierarchy, currentReportCategoryFieldDefs);

          const suppressed = reportGridData.filter((row: any) => {
              const categoryPropName = "account_name_2";
        
              const dataPropsValue = Object.entries(row.dataProps);
              const child = row.categoryProps && row.categoryProps[categoryPropName] !== undefined;
              
              return child || !dataPropsValue.every(([key, value]) => (key === "Account_Number") || value === 0);
            });

          const idsToRemove: any = [];

          const nextLevel = suppressed.filter((obj => {
            const { Account_Number, ...restProps } = obj.dataProps;

            if(obj.itemId.includes("Summary") && Object.values(restProps).every(value => value === 0)){
              idsToRemove.push(obj.categoryProps.account_id_1);
            } else {
              return obj;
            }
          }));

          const suppressedResults = nextLevel.filter(item => !idsToRemove.includes(item.categoryProps.account_id_1));

        setCategoryFieldOrder(cloneDeep(currentReportCategoryFieldDefs));
        setDataFieldOrder(cloneDeep(fieldDefsArray));
        setDateColInfosResponse(cloneDeep(filterDateColInfos));
        setPinActionColumn(true);
        setReportGridBaseData(suppressIfZero ? cloneDeep(suppressedResults) : cloneDeep(reportGridData)); 

        setIsLoading(false);
    }, [buildReportDataGridCounter]);

    const dateRangeFilter = { 
      name: 'Date Range',
      title: 'Report Range',
      visible: false,
      useLinkButton: false,
      startDate: dateRange[0]!, 
      endDate: dateRange[1]!
    };

    function buildReportGridData(
        reportAccounts: ReportAccount[], 
        dateColInfos: DateColumnInfo[], 
        responseCurrency: string, 
        accountHierarchy: AccountHierarchyItem[],
        categoryFieldDefs: CategoryFieldDef[]
    ): ReportGridBaseData[] {
        
        const reportGridData: ReportGridBaseData[] = [];

        for(let i=0; i<reportAccounts.length; i++) {
            const account = reportAccounts[i];
            const categoryProps = getAccountCategoryProperties(account.accountId, accountHierarchy, categoryFieldDefs);

            const dataProps = {} as any;

            // Set the dataProps based on the account api response data
            dataProps[EndBalanceRange] = account.endBalance;
            dataProps[StartBalanceRange] = account.startBalance;
            dataProps[AccountNumber] = account.accountNo;

            // Filter through each column and pass it the date range selected "dc" to filter items in range and type
            // getLineItems is part of the shared.tsx
            dateColInfos.filter(cd => 
                    cd.name !== StartBalanceRange && 
                    cd.name !== EndBalanceRange && 
                    cd.numberFormat !== 'Text'
                ).forEach(dc => {
                  let lineItemsInRange = getLineItemsForDateCol(account.lineItems, dc);
              
                  const groupedColumn = dc.name.includes('month') || dc.name.includes('quarter') || dc.name.includes('year') ? true : false;
              
                  if(groupedColumn) lineItemsInRange = getLineItemsForDateCol(lineItemsInRange, dateRangeFilter);
                                         
                  const value = lineItemsInRange.reduce((prevVal, lineItem) => prevVal + lineItem.amount, 0);
        
                  dataProps[dc.name] = value ?? 0;
                }); 
        
                const callerData: GridCallerData = {
                    reportAccounts: [account]
                };
        
                const gridData = {
                    itemId: account.accountId,
                    currencyCode: responseCurrency,
                    categoryProps: categoryProps,
                    dataProps: dataProps,
                    callerData: callerData,
                    decimals: currentDecimals
                } as ReportGridBaseData;

                if(gridData.categoryProps.attributeName === "2|Expense"){
                  gridData.footerMethod = "SUBTRACT";
                }
        
                reportGridData.push(gridData);
            }

        createAccountAttrributeGridDataSums(reportGridData, viewFilters.filteredAttributesFilter, dateColInfos, currentCurrency);
        createAccountChildrenSums(reportGridData, dateColInfos, categoryFieldDefs, currentCurrency, false); //false because is not custom

        return reportGridData;
    }

    function getAccountCategoryProperties(reportAccountId: string, accountHierarchy: AccountHierarchyItem[], currentReportCategoryFieldDefs: CategoryFieldDef[]): any {
        const acct = accountHierarchy!.find(a => a.accountId === reportAccountId)!;
        const attrib = attributes.find((f: any) => f.id === acct.attributeId);
        
        const categoryProps = {} as any;
  
        categoryProps.attributeId = attrib.id,
        categoryProps.attributeName = getCategoryNameWithOrder(attrib.type);
        const categoryFieldDefs = currentReportCategoryFieldDefs.filter(c => c.id !== 'attributeId');

  
        categoryFieldDefs.forEach((cat, index) => {
          const parentAccount = acct.parentAccounts[index];
  
          // @ts-ignore: expression error
          categoryProps[cat.id] = parentAccount?.id;
  
          // @ts-ignore: expression error
          categoryProps[cat.name] = parentAccount?.number ? parentAccount?.number + " - " + parentAccount?.name : parentAccount?.name;
        });
  
        return categoryProps;
      }

    function getCategoryNameWithOrder(name: string) {
        let order: string = '';
  
        switch(name) {
          case "Assets":
          case 'Income':
            order = '1'; 
            break;
          case 'Liabilities':
          case 'Expense':
            order = '2';
            break;
          case 'Partners Capital':
          case 'Gain/Loss':
            order = '3';
            break;
        }
  
        return `${order}|${name}`;
      }

    function handleCategoryFieldOrderChange(updatedCategoryFieldOrder: CategoryFieldDef[]) {
        setCategoryFieldOrder(cloneDeep(updatedCategoryFieldOrder));
    }

    function handleDataFieldOrderChange(updatedDataFieldOrder: ValueFieldDef[], fieldName?: string) {
      if(fieldName){
      if (fieldName === ColumnTypes.Monthly || fieldName === ColumnTypes.Quarterly || fieldName === ColumnTypes.Yearly){
        switch (fieldName) {
          case 'Months': {
            setShowMonthlyGroup(!showMonthlyGroup);
            break;
          }
          case 'Quarterly': {
            setShowQuarterlyGroup(!showQuarterlyGroup);
            break;
          }
          case 'Yearly': {
            setShowYearlyGroup(!showYearlyGroup);
          }
        }
        updatedDataFieldOrder.map((column: any) => {
          if (column.category === fieldName){
            column.visible = !column.visible;
          }
        });
        setDataFieldOrder(cloneDeep(updatedDataFieldOrder));
        setViewKeyCounter(viewKeyCounter+1);
        if(viewKeyCounter !== 0){
            saveFieldDefOrder(cloneDeep(updatedDataFieldOrder));
          }
      } else {
        const dataField = updatedDataFieldOrder.find(field => field.name === fieldName)!;
       
        dataField.visible = !dataField.visible;
    
        setDataFieldOrder(cloneDeep(updatedDataFieldOrder));
        setViewKeyCounter(viewKeyCounter+1);
        if(viewKeyCounter !== 0){
            saveFieldDefOrder(cloneDeep(updatedDataFieldOrder));
          }
    }
  } else {
    setDataFieldOrder(cloneDeep(updatedDataFieldOrder));
    setViewKeyCounter(viewKeyCounter+1);
    if(viewKeyCounter !== 0){
      saveFieldDefOrder(cloneDeep(updatedDataFieldOrder));
    }
  }
}
  async function saveFieldDefOrder(updatedDataFieldOrder: ValueFieldDef[]){
      const firstOccurence: any = {};
      const itemMap = updatedDataFieldOrder.filter(item => {
        const type = item.name.includes(ViewKeyCode.MONTHLY) ? ViewKeyCode.MONTHLY :
                        item.name.includes(ViewKeyCode.QUARTERLY) ? ViewKeyCode.QUARTERLY :
                        item.name.includes(ViewKeyCode.YEARLY) ? ViewKeyCode.YEARLY :
                        null;

            if (type && !firstOccurence[type]){
              firstOccurence[type] = true;
              return true;
            }

            return !type;
            });

      let orderNum = 0;
      const viewItems = itemMap.map((catCol) => {
          orderNum += 1;

        return {
          label: catCol.headerName,
          code: catCol.name,
          order: orderNum,
          visible: catCol.visible,
        } as ViewItemsEntity;
      });
      
      viewItems.sort((a,b) => a.order - b.order);
      const newColumnOrder: ColumnOrder = {
        clientId: clientId,
        viewKey: `${reportView.toUpperCase()}_FIELD_VIEW`,
        viewItems: viewItems,
      };
      
      try {
        await saveColumnOrder(newColumnOrder);
      } catch (e) {
        informationAlert("Error saving column order.", "error");
      }
    }

    function handleDataGridChange(gridData: GridData) {
        onDataGridChange(gridData, `report export ${DateTimeFormat.getFormattedDate(new Date())}.csv`);
    }

    function handleValueClick(gridRow: GridRow, valueFieldOrder: ValueFieldDef) {
        handleValueLinkButtonClick(gridRow, valueFieldOrder, dateColInfosResponse, false, currentDecimals); //false because not custom   
    }
    
    return {
        categoryFieldOrder,
        currentCurrency,
        currentDecimals,
        dataFieldOrder,
        footerRollUp,
        handleCategoryFieldOrderChange,
        handleDataFieldOrderChange,
        handleDataGridChange,
        handleValueClick,
        hideUseerCategorySelector,
        pinActionColumn,
        reportGridBaseData,
        rollupMode,
        setShowGroups,
        setShowMonthlyGroup,
        setShowQuarterlyGroup,
        setShowYearlyGroup,
        showGroups,
        showMonthlyGroup,
        showQuarterlyGroup,
        showYearlyGroup,
    };
};

function informationAlert(arg0: string, arg1: string) {
  throw new Error("Function not implemented.");
}

