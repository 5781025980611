export enum ServiceType {
  PreAuth = 'pre-auth-api',
  Api = 'api',
  Apiv2 = 'apiv2',
  DocumentsApi = 'DocumentsApi',
  Apiv2Admin = 'apiv2Admin',
  ReportApi = 'reportApi',
  AuthApi = 'auth-api',
  Email = 'email',
  EmailPreviews = 'emailPreviews',
  EmailPreviewsDynamic = 'emailPreviewsDynamic',
  Swagger = 'swagger',
  SwaggerEntity = 'swaggerEntity',
}

//let host = window.location.href.toLowerCase().startsWith('http://localhost') ? 'http://admin-dev.standishportal.com' : 'https://www.fundaccounting-dev.arkpes.com';
//let host = process.env.NODE_ENV === "development" ? 'http://admin-dev.standishportal.com' : 'https://www.fundaccounting-dev.arkpes.com';
const host = process.env.REACT_APP_API_ENDPOINT_URL;

export const apiEndPoint = (serviceType: ServiceType): string => {
  let baseUrl: string;

  switch (serviceType) {
    case ServiceType.ReportApi:
      baseUrl = `${host}report-api/`;
      break;
    case ServiceType.DocumentsApi:
      baseUrl = `${host}`;
      break;
    case ServiceType.PreAuth:
      baseUrl = `${host}preauth-api/`;
      break;
    case ServiceType.Apiv2:
      baseUrl = `${host}api/v2/`;
      break;
    case ServiceType.Apiv2Admin:
      baseUrl = `${host}api/v2/admin/`;
      break;
    case ServiceType.AuthApi:
      baseUrl = `${host}auth-api/`;
      break;
    case ServiceType.Swagger:
      baseUrl = 'https://virtserver.swaggerhub.com/ARKVENDORS/ark-gl-svc/1.1';
      break;
    case ServiceType.SwaggerEntity:
      baseUrl = 'https://virtserver.swaggerhub.com/ARKVENDORS/entity-svc/1.0.0';
      break;
    case ServiceType.Api:
    default:
      baseUrl = `${host}api/`;
      break;
  }

  return baseUrl;
};
