export const TEXT_NEW_ENTITY = "New Entity";
export const TEXT_UPLOAD_TEMPLATE = "Upload From Template";
export const TEXT_DOWNLOAD_TEMPLATE = "Download Template";
export const TEXT_CONFIRM = "Confirm";
export const TEXT_CONFIRM_OK = "Okay";
export const TEXT_CONFIRM_DELETE = "Confirm Delete";
export const TEXT_CANCEL = "Cancel";
export const UPLOAD_RESULTS_POPUP_TITLE = "Upload Processing";
export const CONFIRM_UPLOAD_POPUP_TITLE = "Confirm Upload";
export const UPLOAD_RESULTS_POPUP_DESCRIPTION =
  "The file is being processed. Large files may take a while to finish; Please check the Upload History page to check the status of the import.";
export const UNSAVED_CHANGES_SUBTITLE = "Unsaved changes will be lost";
export const TEXT_SAVE_CHANGES = "Save Changes";
export const TEXT_DISCARD_CHANGES = "Discard Changes";
export const UNABLE_TO_SAVE_SUBTITLE = "At least one investor association must be established before saving this contact.";
export const ERROR_TAKEN_INVESTOR_NAME =
  "Investor Name is already used in the system.";
export const ERROR_REQUIRED_FIELD = "This field is required";
export const GENERIC_CHANGED_INFO_SUCCESS_MESSAGE =
  "Changes successfully saved";
export const GENERIC_ERROR_MESSAGE = "An error occurred, please try again.";
export const INVESTOR_TYPE_INDIVIDUAL = "INDIVIDUAL";
export const INVESTOR_TYPE_ENTITY = "ENTITY";
export const INVESTORS_NO_FUNDS_ERROR =
  "At least one Investor Fund is required.";
export const ADD_NEW_INVESTOR = "Add new investor";
export const TEXT_NEW_CONTACT = "New Contact";
export const DOWNLOADED_TEMPLATE_CONTACT_FILE_NAME =
  "standard_contact_template";
export const CONTACT_INFO_ID = "contactInfo";
export const CONTACT_PERMISSIONS_ID = "contactPermissions";
export const CONTACT_PERMISSIONS_SVG_ICON =
  "M8 12.5h2v2H8zM11 12.5h2v2h-2zM14 12.5h2v2h-2zM17 12.5h2v2h-2zM20 12.5h2v2h-2zM8 9.5h2v2H8zM11 9.5h2v2h-2zM14 9.5h2v2h-2zM17 9.5h2v2h-2zM20 9.5h2v2h-2zM8 6.5h2v2H8zM11 6.5h2v2h-2zM14 6.5h2v2h-2zM17 6.5h2v2h-2zM20 6.5h2v2h-2zM8 3.5h2v2H8zM11 3.5h2v2h-2zM14 3.5h2v2h-2zM17 3.5h2v2h-2zM20 3.5h2v2h-2zM8 15.5h2v2H8zM11 15.5h2v2h-2zM14 15.5h2v2h-2zM17 15.5h2v2h-2zM20 15.5h2v2h-2zM8 18.5h2v2H8zM11 18.5h2v2h-2zM14 18.5h2v2h-2zM17 18.5h2v2h-2zM20 18.5h2v2h-2zM2 3.5h5v17H2z";
export const CONTACT_DETAILS_SVG_ICON = "M2 3.5h20v4H2zM2 9.5h20v11H2z";
export const CONFIRM_APPROVE_CONTACT_TITLE = "Confirm Approval";
export const APPROVE_CONTACT_POPUP_DESCRIPTION =
  "If you are approving a contact for the first time, they will receive an automatic, system generated welcome email to set their credentials and access the portal. Would you like to continue?";
export const DECLINE_CONTACT_POPUP_DESCRIPTION = "Pending updates will not be saved & will be deleted off the contact record";
export const CONFIRM_INACTIVATE_CONTACT_TITLE = "Inactivate Contact";
export const INACTIVATE_CONTACT_POPUP_DESCRIPTION =
  "Are you sure you want to delete this contact? This action cannot be undone.";
export const ACTIVATE_CONTACTS_POPUP_DESCRIPTION = "Are you sure you want to activate contact(s)?";
export const INACTIVATE_CONTACTS_POPUP_DESCRIPTION = "Are you sure you want to inactivate contact(s)?";
//Funds
export const ERROR_TAKEN_FUND_NAME = "Fund name is already used in the system";
export const ADD_NEW_CONTACT = "Add new contact";
export const SEND_EMAIL_CONTACT_CONFIRMATION =
  "This will send an automatic, system generated activation email for this user to set their credentials and access the portal. Would you like to continue?";
export const SAVE_CONTACT_CONFIRMATION_TEXT =
  "You are about to navigate away from this page. Any changes you have made will be submitted for approval by the management team.";
export const CONTACTS_COLUMN_ORDER_VIEW_KEY = "CONTACT_VIEW_KEY";
export const INVESTORS_COLUMN_ORDER_VIEW_KEY = "INVESTOR_VIEW_KEY";
export const CONTACTS_PERMISSION_COLUMN_ORDER_VIEW_KEY =
  "CONTACT_PERMISSION_VIEW_KEY";
export const DOCUMENT_SUB_FOLDERS_ORDER_VIEW_KEY = "DOCUMENT_SUB_FOLDERS_VIEW_KEY";
export const CONTACT_CHANGE_CONFIRMATION_TITLE =
  "You must save your changes before selecting new contact";
export const EMAIL_SENT_ALERT = "Email is sent successfully";
export const CAPITAL_CALL_ICON =
  "M15.41 5.41L10.58 0.58C10.21 0.21 9.7 0 9.17 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6.83C16 6.3 15.79 5.79 15.41 5.41ZM10 11C10.55 11 11 11.45 11 12V15C11 15.55 10.55 16 10 16H9C9 16.55 8.55 17 8 17C7.45 17 7 16.55 7 16H6C5.45 16 5 15.55 5 15C5 14.45 5.45 14 6 14H9V13H6C5.45 13 5 12.55 5 12V9C5 8.45 5.45 8 6 8H7C7 7.45 7.45 7 8 7C8.55 7 9 7.45 9 8H10C10.55 8 11 8.45 11 9C11 9.55 10.55 10 10 10H7V11H10ZM10 6C9.45 6 9 5.55 9 5V1.5L13.5 6H10Z";
export const DOWNLOAD_ICON =
  "M14.59 13.58L16 14.99L11.99 19L8 14.99L9.41 13.57L11 15.16L11 11L13 11L13 15.16L14.59 13.58Z";
export const FILE_ICON =
  "M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z";
export const DISTRIBUTION_ICON =
  "M15.41 5.41L10.58 0.58C10.21 0.21 9.7 0 9.17 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6.83C16 6.3 15.79 5.79 15.41 5.41ZM10 11C10.55 11 11 11.45 11 12V15C11 15.55 10.55 16 10 16H9C9 16.55 8.55 17 8 17C7.45 17 7 16.55 7 16H6C5.45 16 5 15.55 5 15C5 14.45 5.45 14 6 14H9V13H6C5.45 13 5 12.55 5 12V9C5 8.45 5.45 8 6 8H7C7 7.45 7.45 7 8 7C8.55 7 9 7.45 9 8H10C10.55 8 11 8.45 11 9C11 9.55 10.55 10 10 10H7V11H10ZM10 6C9.45 6 9 5.55 9 5V1.5L13.5 6H10Z";
export const CHANGES_APPROVED_SUCCESS_MESSAGE = "Changes approved successfully";
export const CHANGES_DECLINE_SUCCESS_MESSAGE = "Changes declined successfully";
export const LOCKED_PAGE_TOOLTIP_TEXT = "The page is locked";