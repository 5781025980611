

import { DateRange } from "@mui/x-date-pickers-pro";
import { cloneDeep } from "lodash";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { GridData, GridRow, ValueFieldDef } from "../../../components/ReportGrid/ReportGrid.types";
import { AppContext } from "../../../core/context/appContextProvider";
import RoutingPaths from "../../../core/routing/routingPaths";
import { getAllArkAccounts, getArkLedgers, getAttributes } from "../../../services/arkGL.service";
import { getFundsList } from "../../../services/fund.service";
import { getQuarterStartEndDateByDate } from "../../../utils/helpers/quarter.helper";
import { awaitReactUpdate } from "../../../utils/helpers/timeoutFunctions";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { GlGridData } from "./glReports/glReportList/GeneralLedgerReport.hooks";
import { GridCallerData } from "./glReports/shared";
import { AccountNumber, ColumnTypes, CreditRange, DateColumnInfo, DebitRange, EndBalanceRange, FSDisplayNotSet, getLineItemsForDateCol, getTrialBalanceDetailsData,GlStatuses,netIncomeTotalCatStr,netInvestmentIncomeCatStr,netRealizedCatStr,netUnrealizedCatStr,ReportAccount, ReportDetailsParams, ReportLineItem, retainedEarningsTotalCatStr, retainedInvestmentIncomeCatStr, retainedRealizedCatStr, retainedUnrealizedCatStr, StartBalanceRange, TrialBalanceDetailsParams } from "./glReports/shared";


type Account = {
    id: string,
    name: string,
    number: string,
    parentId: string,
    fundId: string,
    attributeId: string,
    fsDisplayName: string
  }

export type FilterItem = {
    id: string,
    name: string
  };

const defaultStartEndDates = getQuarterStartEndDateByDate(new Date());
const defaultEndDateToday = new Date();

const reportViewOptions = [
  { id: "trial_balance", label: "Trial Balance" }, 
  { id: "balance_sheet", label: "Balance Sheet" },
  { id: "balance_sheet_fs_mapping", label: "Balance Sheet - Custom" },
  { id: "income_statement", label: "Income Statement" },
  { id: "income_statement_fs_mapping", label: "Income Statement - Custom" },
  { id: "Unrealized_SOI", label: "Unrealized SOI" },
  { id: "Realized_SOI", label: "Realized SOI" },
  { id: 'general_ledger_report', label: 'General Ledger Report' }
];

export enum GlViews {
  trialBalance = 'trial_balance',
  balanceSheet = 'balance_sheet',
  balanceSheetFsMapping = 'balance_sheet_fs_mapping',
  incomeStatement = 'income_statement',
  incomeStatementFsMapping = 'income_statement_fs_mapping',
  unrealizedSOI = 'Unrealized_SOI',
  realizedSOI = 'Realized_SOI',
  generalLedgerReport = 'general_ledger_report'
};

const currentYear = new Date().getFullYear();
const incomeStateStartDate: Date = new Date(`${currentYear}-01-01`);
const incomeStateEndDate: Date = new Date(`${currentYear}-12-31`);
const incomeDefaultDate: DateRange<Date> = 
  [new Date(incomeStateStartDate.getUTCFullYear(), incomeStateStartDate.getUTCMonth(), incomeStateStartDate.getUTCDate()),
   defaultEndDateToday];

export const useGlReportList = (
  setCurrSOIview: Dispatch<SetStateAction<string>>,
  handleSoiViewChange: Function,
) => {
    const history = useHistory();

    const { state } = useContext(AppContext);
    const { fundId } = useParams<{ fundId: string }>();
    const clientId = state.loginUser.clientId;

    const [ isLoading, setIsLoading ] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState('');
    const [invalidSelectedLedgers, setInvalidSelectedledgers] = useState(false);
    const [dateRange, setDateRange] = useState<DateRange<Date>>([defaultStartEndDates.startDate, defaultEndDateToday]);
    const [dataFieldOrder, setDataFieldOrder] = useState<ValueFieldDef[]>([]);
    const [attributeFilterDisabled, setAttributeFilterDisabled] = useState(false);
    const [suppressIfZero, setSuppressIfZero] = useState(true);
    const [hideAllZeros, setHideAllZeros] = useState(false);

    const [mainReportListData, setMainReportListData] = useState<GridData>();
    const [reportView, setReportView] = useState(GlViews.trialBalance);
    const [runReportView, setRunReportView] = useState("");

    const [accounts, setAccounts] = useState<Account[]>([]);
    const [funds, setFunds] = useState<any[]>([]);
    const [ledgers, setLedgers] = useState<any[]>([]);
    const [attributes, setAttributes] = useState<any[]>([]);

    const [fundsFilter, setFundFilter] = useState<FilterItem[]>([]);
    const [statusFilter, setStatusFilter] = useState<FilterItem[]>([]);
    
    const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
    const [selectedAttributes, setSelectedAttributes] = useState<string[]>([]);
    const [selectedFunds, setSelectedFunds] = useState<string[]>([]);
    const [selectedLedgers, setSelectedLedgers] = useState<string[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<GlStatuses[]>([]);

    const [filteredAccounts, setFilteredAccounts] = useState<FilterItem[]>([]);
    const [filteredAttributes, setFilteredAttributes] = useState<FilterItem[]>([]);
    const [filteredLedgers, setFilteredLedgers] = useState<FilterItem[]>([]);
    const [tempAttribsFilterResponse, setTempAttribsFilterResponse] = useState<FilterItem[]>([]);
    const [generalAttributes, setGeneralAttributes] = useState<string[]>([]);

    const [reportParams, setReportParams] = useState<TrialBalanceDetailsParams>();
    const [viewFiltersState, setViewFiltersState] = useState<any>({});

    const [level1Clicked, setLevel1Clicked] = useState<boolean>(false);
    const [summaryRowClicked, setSummaryRowClicked] = useState<boolean>(false);
    const [zeroSumStartBalance, setZeroSumStartBalance] = useState<boolean>(false);
    const [reportDetailsApiParams, setReportDetailsApiParams] = useState<ReportDetailsParams>();

    const [gridData, setGridData] = useState<GridData | GlGridData>();
    const [exportName, setExportName] = useState('report_export');

    const [netRow, setNetRow] = useState(false);
    const [retainedRow, setRetainedRow] = useState(false);
    const [soiView, setSOIView] = useState<boolean>(false);


    let viewFilters: {
      selectedAttributes: any[],
      filteredAttributesFilter: FilterItem[],
      rollupMode: boolean,
      hideUseerCategorySelector: boolean
    };

    useEffectAsync(async (isCanceled) => {
      setIsLoading(true);
      const allResponses = await getFilterData(isCanceled);

      if(isCanceled() || !allResponses) return;

      const { tempLedgers, tempAccounts, tempAttribsFilter } = allResponses;
      const tempStatuses = [{ id: 'DRAFT', name: GlStatuses.DRAFT }, { id: 'POSTED', name: GlStatuses.POSTED }] as FilterItem[];
      const selectedStatusIDs = tempStatuses.map((item: any) => item.id);

      setSelectedAttributes(tempAttribsFilter.map(item =>  item.id));
      setFilteredAttributes(tempAttribsFilter);
      setTempAttribsFilterResponse(tempAttribsFilter);
      setStatusFilter(tempStatuses);
      setSelectedStatus(selectedStatusIDs);
      setIsLoading(false);

      if(fundId){
        setSelectedFunds([fundId]);
        setLedgerAndAccountFilters([fundId], tempLedgers, tempAttribsFilter.map(item =>  item.id), [], tempAccounts, []);
      }
    }, []);

    useEffectAsync( async () => {
      switch(reportView) {
        case GlViews.trialBalance: {
          setDateRange([ defaultStartEndDates.startDate, defaultEndDateToday ]);
          viewFilters = buildTrialBalanceFilters();
          setAttributeFilterDisabled(false);
          break;
        }
        case GlViews.balanceSheetFsMapping: {
          setDateRange([ null , defaultEndDateToday ]);
          viewFilters = buildBalanceSheetFilters();
          setHideAllZeros(true);
          setAttributeFilterDisabled(true);
          break;
        }
        case GlViews.balanceSheet: {
          setDateRange([ null , defaultEndDateToday ]);
          viewFilters = buildBalanceSheetFilters();
          setHideAllZeros(true);
          setAttributeFilterDisabled(true);
          break;
        }
        case GlViews.incomeStatement: {
          setDateRange(incomeDefaultDate);
          viewFilters = buildIncomeStatementFilters();
          setHideAllZeros(true);
          setAttributeFilterDisabled(true);
          break;
        }
        case GlViews.incomeStatementFsMapping: {
          setDateRange(incomeDefaultDate);
          viewFilters = buildIncomeStatementFilters();
          setHideAllZeros(true);
          setAttributeFilterDisabled(true);
          break;
        }
        case GlViews.realizedSOI: {
          handleSoiViewChange(reportView);
          break;
        }
        case GlViews.unrealizedSOI: {
          handleSoiViewChange(reportView);
          break;
        }
        case GlViews.generalLedgerReport: {
          setDateRange(incomeDefaultDate);
          viewFilters = buildGeneralLedgerFilters();
          break;
        }        
        default: throw `Invalid report view ${reportView}`;
      }

      if(!soiView){
        setViewFiltersState(viewFilters);
        setSelectedAttributes(viewFilters.selectedAttributes);
        setLedgerAndAccountFilters(selectedFunds, ledgers, viewFilters.selectedAttributes, selectedLedgers, accounts, []);
      }

      await awaitReactUpdate();
      runReportView && validFilters ? setRunReportView(reportView) : setRunReportView("");

      soiView ? setRunReportView(reportView) : '';

    }, [reportView]);

    useEffect(()=>{

      const reportListParams: TrialBalanceDetailsParams = {
        startDate: dateRange[0]!,
        endDate: dateRange[1]!,
        accounts: selectedAccounts,
        glStatus: selectedStatus,
        ledgerIDs: selectedLedgers,
        attributes: selectedAttributes,
      };

      setReportParams(reportListParams);

    }, [selectedAccounts, selectedAttributes, selectedFunds, selectedLedgers, selectedStatus, dateRange]);

      async function getFilterData(isCanceled: () => boolean) {
        let tempFunds: any;
        let tempFundFilters: FilterItem[];
        let tempLedgers: any[];
        let tempAccounts: Account[];
        let attribsResponse: any[];
  
        if(!funds?.length || !ledgers?.length || !accounts?.length || !attributes?.length) {
          const allResponses = await Promise.all([
            getFundsList(clientId),
            getAttributes(),
            getAllArkAccounts(),
            getArkLedgers()
          ]);
  
          if(isCanceled()) return;
  
          const fundsApiResponse = allResponses[0];
          const attributesApiResponse = allResponses[1];
          const accountsApiResponse = allResponses[2];
          const legdersApiResponse = allResponses[3];
  
          // *********  funds **************
          tempFunds = fundsApiResponse;
  
          tempFundFilters = tempFunds.sort((a:any,b:any) => a.fund.name < b.fund.name ? -1: 1).map((item:any) => { 
            return { id: item.fund.id, name: item.fund.name } as FilterItem; 
          }) as FilterItem[];
  
          if(fundId){
            const fundFilter: any = tempFundFilters.find(fund => fund.id === fundId);
            const tempFund = tempFunds.find((fund: any) => fund.fund.id === fundId);
  
            setFunds([tempFund]);
            setFundFilter([fundFilter]);
          } else {
            setFunds(tempFunds);
            setFundFilter(tempFundFilters);
          }
    
          // *********  ledgers **************
          tempLedgers = (legdersApiResponse.items as any[]).sort((a,b) => a.name < b.name ? -1 : 1);
    
          setLedgers(tempLedgers);
  
          // *********  accounts **************
          tempAccounts = accountsApiResponse.items as Account[];
          tempAccounts.filter(x => !x.fsDisplayName).forEach(x => x.fsDisplayName = FSDisplayNotSet);
          tempAccounts = tempAccounts.sort((a,b) => a.name < b.name ? -1 : 1);
    
          setAccounts(tempAccounts);
  
          // *********  attibutes **************
          attribsResponse = attributesApiResponse.items;
          setAttributes(attribsResponse);
        } else {
          tempFunds = funds;
          tempFundFilters = fundsFilter;
          tempLedgers = ledgers;
          tempAccounts = accounts;
          attribsResponse = attributes;
        }
  
        const tempAttribsFilter = attribsResponse.sort((a,b) => a.type < b.type ? -1 : 1).map((item:any) => {
          return { id: item.id, name: item.type } as FilterItem; 
        });
  
      
        return {
          tempFunds,
          tempFundFilters,
          tempLedgers,
          tempAccounts,
          tempAttribsFilter
        };
      }

        // Trial Balance View -- accepts all attributes
        function buildTrialBalanceFilters() {
          const attributeIds = tempAttribsFilterResponse.map(item =>  item.id);
    
          return {
            selectedAttributes: attributeIds,
            filteredAttributesFilter: tempAttribsFilterResponse,
            rollupMode: true,
            hideUseerCategorySelector: false
          };
        }
    
        // Balance Sheet Views --- filters 6 unique attributes 'Assets', 'Income', 'Expense', 'Gain/Loss', 'Liabilities', 'Partners Capital'
        function buildBalanceSheetFilters() {
          const defaultAttribFilters = ['Assets', 'Income', 'Expense', 'Gain/Loss', 'Liabilities', 'Partners Capital'];
          const generalAttribFilters = ['Assets', 'Liabilities', 'Partners Capital'];

          
          const tempFilteredAttribs =  tempAttribsFilterResponse.filter(a => defaultAttribFilters.find(df => a.name===df));
          const attributeIds = tempFilteredAttribs.map(item =>  item.id);
          const generalAttribs = tempAttribsFilterResponse.filter(attribute => generalAttribFilters.find(gf => attribute.name===gf));
          const generalAttributeIds = generalAttribs.map(item => item.id);

          setGeneralAttributes(generalAttributeIds);
          
          return {
            selectedAttributes: attributeIds,
            filteredAttributesFilter: tempFilteredAttribs,
            rollupMode: false,
            hideUseerCategorySelector: true
          };
        }
    
        // Income Statement Views -- filters three unique attributes 'Expense', 'Gain/Loss', 'Income' 
        function buildIncomeStatementFilters() {
          const defaultAttribFilters = ['Expense', 'Gain/Loss', 'Income'];
          const tempFilteredAttribs =  tempAttribsFilterResponse.filter(a => defaultAttribFilters.find(df => a.name===df));
          const attributeIds = tempFilteredAttribs.map(item =>  item.id);
    
          return {
            selectedAttributes: attributeIds,
            filteredAttributesFilter: tempFilteredAttribs,
            rollupMode: false,
            hideUseerCategorySelector: true
          };
        }

        function buildGeneralLedgerFilters() {
          const defaultAttribFilters = ['Assets', 'Income', 'Expense', 'Gain/Loss', 'Liabilities', 'Partners Capital'];

          const tempFilteredAttribs =  tempAttribsFilterResponse.filter(a => defaultAttribFilters.find(df => a.name===df));
          const attributeIds = tempFilteredAttribs.map(item =>  item.id);
          
          return {
            selectedAttributes: attributeIds,
            filteredAttributesFilter: tempFilteredAttribs,
            rollupMode: false,
            hideUseerCategorySelector: true
          };
        }
  

    function handleOptionSelection (evt: any) {
      if(evt.target.value === "Unrealized_SOI" || evt.target.value === "Realized_SOI"){
        setSOIView(true);
        setCurrSOIview(evt.target.value);
        setReportView(evt.target.value);
      } else {
        setSOIView(false);
        setReportView(evt.target.value);
      }
    }

    function handleFundFilterChange(_filter: any, fundIDs: string[]) {
      setRunReportView("");
      setSelectedFunds(fundIDs);
      setSelectedLedgers([]);
      setLedgerAndAccountFilters(fundIDs, ledgers, selectedAttributes, [], accounts, []);
    }

    function setLedgerAndAccountFilters(
        selectedFunds: string[], 
        ledgers: any[], 
        selectedAttributes: string[], 
        selectedLedgers: string[],  
        accounts: Account[], 
        selectedAccounts: string[]) {
        
        const newLedgerFilter = ledgers.filter(item => selectedFunds.includes(item.fundId));
        const tempLedgers = newLedgerFilter.sort((a,b) => a.currency < b.currency ? -1 : 1).map(item => {
          return { id: item.id, name: `${item.name} (${item.currency})` } as FilterItem; 
        });

        setFilteredLedgers(tempLedgers);
        setSelectedLedgers(selectedLedgers);
    
        const newAccountFilter1 = accounts.filter(item => selectedFunds.includes(item.fundId));

        const newAccountFilter2 = newAccountFilter1.filter(item => selectedAttributes.includes(item.attributeId));
    
        const tempFilteredAccounts = newAccountFilter2.map(item => {
          return { id: item.id, name: item.name } as FilterItem;
        });
    
        const newSelAcctIDs = tempFilteredAccounts.map(item => item.id);
    
        setFilteredAccounts(tempFilteredAccounts);
        setSelectedAccounts(newSelAcctIDs);
        setGridData(undefined);
      }

      function handleLedgerFilterChange(_filter: any, ledgerIDs: string[]) {
        setRunReportView("");
        let invalid = false;
    
        if(ledgerIDs.length) {
          const firstSelLedger = ledgers.find(l => l.id === ledgerIDs[0]);
    
          ledgerIDs.every(lId => {
            const ledger = ledgers.find(l => l.id === lId);
    
            if(firstSelLedger.currency === ledger.currency) {
              return true;
            } else {
              invalid = true;
              return false;
            }
          });
        }
    
        const firstSelLedgerId = ledgerIDs?.length ? ledgerIDs[0] : undefined;
        const gl = ledgers.find(l => l.id === firstSelLedgerId);
    
        setCurrentCurrency(gl?.currency??'');
        setInvalidSelectedledgers(invalid);
        setSelectedLedgers(ledgerIDs);
        setGridData(undefined);
      }

      function handleAccountFilterChange(_filter: any, accountIds: string[]) {
        setRunReportView("");
        setSelectedAccounts(accountIds);
        setGridData(undefined);
      }

      function handleAttributeFilterChange(_filter: any, attributeIds: string[]) {
        setRunReportView("");
        setSelectedAttributes(attributeIds);
        setLedgerAndAccountFilters(selectedFunds, ledgers, attributeIds, selectedLedgers, accounts, []);
        setGridData(undefined);
      }

      function handleStatusFilterChange(_filter: any, statusIds: GlStatuses[]) {
        setRunReportView("");
        setSelectedStatus(statusIds);
        setGridData(undefined);
      }

      function handleDateRangeChange(dateRange: DateRange<Date>, clearGrid = true) {
        if(clearGrid) {
          setRunReportView("");
          setGridData(undefined);
          setDataFieldOrder([]);
        }
        
        setDateRange(dateRange);
      }

      function handleSuppressIfZeroChange(_filter: any, suppressIfZero: boolean) {
        setRunReportView("");
        setSuppressIfZero(suppressIfZero);
        if(gridData) {
          handleRunReportButtonClick();
        }
      }

      function handleDataGridChange(gridData: GridData, exportName: string) {
        setGridData(gridData);
        setExportName(exportName);
      }

      function handleGlReportData(glGridData: GlGridData, exportName: string) {
        setGridData(glGridData);
        setExportName(exportName);
      }

      function areValidFilters() {
        return (
          !!selectedFunds.length && 
          !!selectedLedgers.length && 
          !!selectedAccounts.length && 
          !!selectedStatus.length &&
          !invalidSelectedLedgers
        );
      }

      function getNoDataMessage() {
        if(invalidSelectedLedgers) {
          return 'GL Name filters must be of the same currency.';
        } else if(!validFilters) {
          return 'Select filters above, then click Apply button to run report.';
        } else if (!isLoading && !runReportView) {
          return 'Click Apply to run report.';
        } else if (isLoading) {
          return '';
        } else {
          return undefined;
        }
      }

      const validFilters = areValidFilters();
      const noDataMessage = getNoDataMessage();
      
      function handleRunReportButtonClick() {
        setRunReportView(reportView);
      }

      function handleValueLinkButtonClick(
        gridRow: GridRow,
        valueFieldOrder: ValueFieldDef,
        dateColInfo: DateColumnInfo[],
        isCustom: boolean,
        currentDecimals: number,
        netCatStartDate?: Date,
        retainedCatEndDate?: Date) {

        const dateColumn: any = cloneDeep(dateColInfo.find(c => c.name === valueFieldOrder.name)!);

        const callerDateArray = gridRow.callerDataArray.filter(cd => !!cd) as GridCallerData[];
        const accountIds: string[] = [];
        let summaryRow: boolean = false;
    
        // Pass fsName into params for correctly parsing the returned data from the second API call for details
        const fsName = gridRow.callerDataArray[0].reportAccounts[0].fsName;
    
        // Pass summaryRow into params for currectly including parent row data in totals click through details
        if(gridRow.entityId.includes("Summary")){
          summaryRow = true;
        }
        
        valueFieldOrder.category === "Yearly"
        || valueFieldOrder.category === "Quarterly"
        || valueFieldOrder.category === "Months"
        || valueFieldOrder.name === CreditRange
        || valueFieldOrder.name === DebitRange
        || valueFieldOrder.name.includes("selected")
        ? setZeroSumStartBalance(true) : setZeroSumStartBalance(false);
    
        // Prop drilled setLevel1Clicked to indicate if a custom view top parent details view is clicked to include all parent values
        if(gridRow.level === 1 && isCustom) {
          setLevel1Clicked(true);
        } else {
          setLevel1Clicked(false);
        }
    
        // Now that summaryRow is passed as a param this prop drilled is believed to be obsolete
        gridRow.entityId.includes("Summary") ? setSummaryRowClicked(true) : setSummaryRowClicked(false);
        
        callerDateArray.forEach(cd => {
          cd.reportAccounts.forEach(ra => {
            accountIds.push(ra.accountId);
          });
        });

        const subtractDebits = (reportView === GlViews.balanceSheet || reportView === GlViews.balanceSheetFsMapping) && 
            gridRow.categoryName ==='ZZ1|Cumulative net investment income/(loss)';

        if(
          gridRow.categoryName === netInvestmentIncomeCatStr
          || gridRow.categoryName === netRealizedCatStr
          || gridRow.categoryName === netUnrealizedCatStr
          || gridRow.categoryName === netIncomeTotalCatStr){
            if(
              dateColumn.name.includes('monthly') ||
              dateColumn.name.includes('quarterly') ||
              dateColumn.name.includes('yearly')){
                '';
            } else {
            dateColumn.startDate = netCatStartDate;
            dateColumn.endDate = dateRange[1];
            }
            setNetRow(true);
          } else if(
            gridRow.categoryName === retainedInvestmentIncomeCatStr
            || gridRow.categoryName === retainedRealizedCatStr
            || gridRow.categoryName === retainedUnrealizedCatStr
            || gridRow.categoryName === retainedEarningsTotalCatStr){
              if(
                dateColumn.name.includes('monthly') ||
                dateColumn.name.includes('quarterly') ||
                dateColumn.name.includes('yearly') ){
                  '';
              } else {
              dateColumn.endDate = retainedCatEndDate;
              dateColumn.startDate = dateRange[0];
              }
              setRetainedRow(true);
          } else {
            setNetRow(false);
            setRetainedRow(false);
          }

        const params:ReportDetailsParams = {
          fundIds: selectedFunds,
          ledgerIds: selectedLedgers,
          accountIds: accountIds,
          attributeIds: selectedAttributes,
          journalEntryStates: selectedStatus,
          dateColInfos: dateColumn,
          currency: currentCurrency,
          decimals: currentDecimals,
          fsName: fsName,
          summaryRow: summaryRow,
          isCustom: isCustom,
          level1Clicked: gridRow.level === 1 && isCustom ? true : false,
          dateRange: dateRange
        };
    
        setReportDetailsApiParams(params);    
      }

      function handleCloseReportDetails() {
        setReportDetailsApiParams(undefined);
        setZeroSumStartBalance(false);
        setSummaryRowClicked(false);
        // setCustomEndBalanceClicked(false);
      }

    return {
        isLoading,
        setIsLoading,
        reportView,
        reportViewOptions,
        handleOptionSelection,
        selectedAccounts,
        selectedAttributes,
        selectedFunds,
        selectedLedgers,
        selectedStatus,
        handleFundFilterChange,
        fundsFilter,
        handleLedgerFilterChange,
        filteredLedgers,
        handleAccountFilterChange,
        filteredAccounts,
        handleAttributeFilterChange,
        filteredAttributes,
        handleStatusFilterChange,
        statusFilter,
        dateRange,
        handleDateRangeChange,
        attributeFilterDisabled,
        suppressIfZero,
        handleSuppressIfZeroChange,
        validFilters,
        handleRunReportButtonClick,
        noDataMessage,
        invalidSelectedLedgers,
        runReportView,
        mainReportListData,
        setMainReportListData,
        reportParams,
        funds,
        attributes,
        viewFiltersState,
        handleValueLinkButtonClick,
        summaryRowClicked,
        level1Clicked,
        zeroSumStartBalance,
        handleCloseReportDetails,
        reportDetailsApiParams,
        gridData,
        exportName,
        handleDataGridChange,
        netRow,
        retainedRow,
        soiView,
        generalAttributes,
        handleGlReportData
    };
};