import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";

import { FilterMap } from "../../../pages/fund/scheduleOfInvestments/ScheduleOfInvestments.types";
import { CapitalAccountsFilter } from "../../../utils/types/capitalAccounts.type";
import { Option } from "../../../utils/types/filter.type";
import { inlineFilterTypes } from "../../../utils/types/listItems";
import SearchBar from "../../SearchBarWithDebounce/SearchBar";
import { ButtonText, FormControlCheckAll } from "./Filter.style";
import {
  FilterAll,
  FilterCheckBox,
  FilterContent,
  FilterItem,
  FundsContent,
  SplitFilterContent,
} from "./PopoverFilter.style";

type Props = {
  handleFilter?: (filter: CapitalAccountsFilter[], selected: string[][]) => void;
  splitFilters: inlineFilterTypes[];
  filterMap?: FilterMap;
  clearable?: boolean;
};

type OptionItemProps = {
  filterName: string;
  id: string;
  label: string;
  onSelect: (item: Option, checked: boolean, name: string) => void;
  selected: boolean;
  emptyResult?: boolean;
}

const OptionItem: React.FC<OptionItemProps> = ({
  filterName,
  id,
  label,
  onSelect,
  selected = false,
  emptyResult = false,
}: OptionItemProps): ReactElement => {

  const onCheck = (e: any): void => {
    const isChecked = e.target.checked;

    onSelect({
      id,
      name: label
    }, isChecked, filterName);
  };

  return (
    <FilterItem color={emptyResult? "graytext" : "inherit"}>
      <FormControlLabel
        control={
          <FilterCheckBox
            id={`${id}_check`}
            name={label}
            checked={selected}
            onChange={onCheck}
            className={emptyResult? "emptyResult" : ""}
          />
        }
        label={label}
      />
    </FilterItem>
  );
};

const SplitFilter: React.FC<Props> = ({
  clearable = true,
  splitFilters,
  handleFilter,
  filterMap,
}: Props): ReactElement => {
  const [search, setSearch] = useState<any>();
  const [selectedOptionList, setSelectedOptionList] = useState<any>();
  const [filteredOptionList, setFilteredOptionList] = useState<any>();

  const filterSelectedOptions: any = {};
  const filterSearchKeys: any = {};
  const filteredOptions: any = {};

  splitFilters.forEach(item => {
    filterSearchKeys[item.inlineFilterName] = "";
    filterSelectedOptions[item.inlineFilterName]= item.inlineFilterSelected;
    filteredOptions[item.inlineFilterName] = item.inlineFilterOptions;
  });

  useEffect(() => {
    setSelectedOptionList(filterSelectedOptions);
    setSearch(filterSearchKeys);
  }, []);

  useEffect(() => {
    setFilteredOptionList(filteredOptions);
  }, []);

  const onSelect = (item: Option, checked: boolean, name: string): void => {
    const new_option_id = item.id;
    let selected_options = [...(selectedOptionList?.[name] || [])];

    if (checked) {
      selected_options.push(new_option_id);
    } else {
      selected_options = selected_options.filter(option => option !== new_option_id);
    }
    setSelectedOptionList((prevSelectedOptionsList: any) => ({ ...prevSelectedOptionsList, [name]: selected_options }));
  };

  const onCheckAll = (checked: boolean, name: string) => {
    const curSplitFilter = splitFilters.filter(
      filter => filter.inlineFilterName === name
    )[0];
    let allChecked: any[] = [];

    if (checked) {
      if(curSplitFilter.inlineFilterOptions.length > filteredOptionList[name].length) {
        allChecked = filteredOptionList[name].map((item: any) => item.id);
      } else {
        allChecked = curSplitFilter.inlineFilterOptions?.map(
          (item) => item[curSplitFilter.inlineFilterIDField]) ?? [];
      }
      setSelectedOptionList((prevSelectedOptions: any) => ({ ...prevSelectedOptions, [name]: allChecked }));
    } else {
      setSelectedOptionList((prevSelectedOptions: any) => ({ ...prevSelectedOptions, [name]: [] }));
    }
  };

  const onSearch = (value: string, name: string) => {
    const currentFilter = splitFilters.find(
      filter => filter.inlineFilterName === name
    );

    if(currentFilter) {
      const {
        inlineFilterLabelField: label,
        inlineFilterOptions: options,
      } = currentFilter;
  
      const regex = new RegExp(value, "igm");
      const listFilter = options.filter(item => item[label].search(regex) !== -1);

      setFilteredOptionList((prevSelectedOptions: any) => ({ ...prevSelectedOptions, [name]: listFilter }));
      setSearch((prevSearchKeys: any) => ({ ...prevSearchKeys, [name]: value }));
    }
  };

  const onClear = () => {
    const selectedOptions: any = {};
    const searchKeys: any = {};

    splitFilters.forEach(filter => {
      selectedOptions[filter.inlineFilterName] = filter.inlineFilterOptions.map(f => f[filter.inlineFilterIDField]) || [];
      searchKeys[filter.inlineFilterName] = "";
    });
    setSearch(searchKeys);
    setFilteredOptionList(filteredOptions);
    setSelectedOptionList(selectedOptions);
  };

  const handleApply = () => {
    const { filter, selected } = splitFilters.reduce((accumulator: any, f) => {
        let allChecked: any[] = [];

        if (selectedOptionList[f.inlineFilterName].length === 0) {
          allChecked = f.inlineFilterOptions?.map((item) => item[f.inlineFilterIDField]) ?? [];
          setSelectedOptionList((prevSelectedOptions: any) => ({
            ...prevSelectedOptions, [f.inlineFilterName]: allChecked, }));
        }
        return {
          filter: [...accumulator.filter, f.inlineFilterName],
          selected: [...accumulator.selected,
            selectedOptionList[f.inlineFilterName].length
              ? selectedOptionList[f.inlineFilterName]
              : allChecked,
          ],
        };
      }, { filter: [], selected: [] });

    handleFilter?.(filter, selected);
  };

  const emptyResultCheck = (name: any, id: string) => {
    if (!filterMap) return;
    const indFilter = filterMap.dependencies[name];

    return selectedOptionList[indFilter].some((indId: string) => {
      return filterMap.listItems[name][indId]?.includes(id);
    });
  };

  return search ? (
    <div id="popover_filter_text">
      <SplitFilterContent>
        {
          splitFilters.map((filter, index) => {
            const {
              inlineFilterName: name,
              inlineFilterOptions: options,
              inlineFilterLabelField: labelField,
              inlineFilterIDField: idField,
              inlineFilterTitle: title,
            } = filter;

            const isAllSelected: boolean = selectedOptionList[name].length >= filteredOptionList[name]?.length;

            return (
              <FilterContent key={name}>
                {title && (<Typography variant="formTitle2">
                  {title}
                </Typography>)}
                <SearchBar
                  id={`filter_search_${name}`}
                  onChange={(value: any) => 
                    onSearch(value || "", name)
                  }
                  value={search[name] || ""}
                />
                <FormGroup>
                  <FilterAll>
                    <b>
                      <FormControlCheckAll
                        id={"check_all_" + name}
                        key="All"
                        control={
                          <Checkbox
                            checked={isAllSelected}
                            indeterminate={
                              options &&
                              selectedOptionList[name].length > 0 &&
                              selectedOptionList[name].length < options?.length
                            }
                            onChange={(_, checked) => onCheckAll(checked, name)}
                          />
                        }
                        label="All"
                      />
                    </b>
                  </FilterAll>
                  <FundsContent>
                    {
                      options && options.length > 0
                        ? (
                          filteredOptionList[name].reduce((accumulator: Record<string, any>[], current: any) => {
                            if (
                              (search &&
                                current[labelField]
                                  .toLowerCase()
                                  .includes(search[name].toLowerCase())) ||
                              !search
                            ) {
                              return [
                                ...accumulator,
                                <OptionItem
                                  filterName={name}
                                  key={current[idField]}
                                  label={current[labelField]}
                                  id={current[idField]}
                                  onSelect={onSelect}
                                  selected={selectedOptionList[name]?.includes(current[idField])}
                                  emptyResult={filterMap? !emptyResultCheck(name, current[idField]) : false}
                                />
                              ];
                            }
                            return accumulator;
                          }, [])
                        ): (
                          <div>No records</div>
                        )
                    }
                  </FundsContent>
                </FormGroup>
              </FilterContent>
            );
          })
        }
      </SplitFilterContent>
      <Box display="flex" justifyContent="flex-end">
        <ButtonText id="btn_apply" variant="text" onClick={handleApply}>
          Apply Filter
        </ButtonText>
        {clearable && (
          <ButtonText id="btn_clear" variant="text" onClick={onClear}>
            Clear Filter
          </ButtonText>
        )}
      </Box>
    </div>
  ) : (
    <div>No records</div>
  );
};

export default SplitFilter;
