import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';

import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { AppContext } from '../../../core/context/appContextProvider';
import { getAvailableRoles } from '../../../services/contact.service';
import { getFundsFilterList } from '../../../services/filters.service';
import {
  addGeneralCashReceiptsMailing,
  addGeneralContactsMailing,
  addGeneralDocumentsMailing,
  getContactsEmails,
  getDocumentsPreviews,
  getEmailImage,
  getGeneralMailing,
  updateGeneralCashReceiptsMailing,
  updateGeneralContactsMailing,
  updateGeneralDocumentsMailing,
  uploadEmailImages,
} from '../../../services/generalMailings.service';
import { getInvestorFilters } from '../../../services/investor.service';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import { FundItem } from '../../../utils/types/fund.type';
import {
  defaultGeneralMailings,
  Fund,
  Investor,
  newGeneralMailings,
  Role,
  SelectedContacts,
  SelectedDocuments,
} from '../../../utils/types/generalMailings.type';
import { FilterItem } from '../../../utils/types/listItems';
import {
  CONTACT_RECIPIENT_CHECK,
  DRAFT_CREATE_SUCCESS,
  DRAFT_ERROR,
  DRAFT_UPDATE_SUCCESS,
  GET_ALL_EMAILS,
  IMAGE_SIZE_ERROR,
  INVALID_BC,
  INVALID_CC,
  recipientInputValidation,
  RECIPIENTS_ERROR,
  SUBJECT_ERROR,
  ViewType,
} from './MailingsComposerPanel.constants';

type MailingsComposerProps = {
  selectedMailingId: string;
  setSelectedMailingId: any;
  fetchAllGeneralMailings: any;
  view: ViewType | string | null | undefined;
  setView: Function;
  selectedMailing?: any;
  setSelectedMailing?: Function;
  onSuccessfulPostClose?: Function;
};

export const useMailingsComposerEffect = ({
  selectedMailingId,
  setSelectedMailingId,
  fetchAllGeneralMailings,
  view,
  setView,
  selectedMailing,
  setSelectedMailing,
  onSuccessfulPostClose,
}: MailingsComposerProps) => {
  const [isPanelVisible, setPanelVisible] = useState({
    right: Boolean(view),
    expand: false,
  });

  useEffect(() => {
    setPanelVisible({ ...isPanelVisible, right: Boolean(view) });
  }, [Boolean(view)]);

  const history = useHistory();
  const { state, informationAlert } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recipientsOpen, setRecipientsOpen] = React.useState(false);
  const [toRecipientsOpen, setToRecipientsOpen] = React.useState(false);
  const [recipientsPage, setRecipientsPage] = useState(0);
  const [model, setModel] = useState('');
  const [toFundRecipients, setToFundRecipients] = useState<Fund[]>([]);
  const [toInvestorRecipients, setToInvestorRecipients] = useState<Investor[]>(
    []
  );
  const [toRoleRecipients, setToRoleRecipients] = useState<Role[]>([]);
  const [mailingFundList, setMailingFundList] = useState<Fund[]>([]);
  const [fundList, setFundList] = useState<Fund[]>([]);
  const [fundListUpdated, setFundListUpdated] = useState(false);
  const [searchVal, setSearchVal] = useState<string>('');
  const [investorList, setInvestorList] = useState<Investor[]>([]);
  const [mailingInvestorList, setMailingInvestorList] = useState<Investor[]>(
    []
  );
  const [investorsListUpdated, setInvestorsListUpdated] = useState(false);
  const [searchValInvestors, setSearchValInvestors] = useState<string>('');
  const [rolesList, setRolesList] = useState<Role[]>([]);
  const [mailingRoleList, setMailingRoleList] = useState<Role[]>([]);
  const [rolesListUpdated, setRolesListUpdated] = useState(false);
  const [searchValRoles, setSearchValRoles] = useState<string>('');
  const [subjectInput, setSubjectInput] = useState('');
  const [ccInput, setCcInput] = useState('');
  const [bcInput, setBcInput] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [selectedMailingType, setSelectedMailingType] = useState<
    ViewType | undefined
  >();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [createNewToggle, SetCreateNewToggle] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [recipientCcError, setRecipientCcError] = useState(false);
  const [recipientBccError, setRecipientBccError] = useState(false);
  const [docDisplayTo, setDocDisplayTo] = useState('');
  const [docDisplayFunds, setDocDisplayFunds] = useState('');
  const [docDisplayInvestors, setDocDisplayInvestors] = useState('');
  const [docDisplayRoles, setDocDisplayRoles] = useState('');
  const [contactDisplayRoles, setContactDisplayRoles] = useState([]);
  const [contactDisplayFunds, setContactDisplayFunds] = useState([]);
  const [contactDisplayInvestors, setContactDisplayInvestors] = useState([]);
  const [contactDisplayTo, setContactDisplayTo] = useState(false);
  const [contactDisplayEmails, setContactDisplayEmails] = useState([]);
  const [selectedRoleOptions, setSelectedRoleOptions] = useState<any>([]);
  const [selectedFundOptions, setSelectedFundOptions] = useState<any>([]);
  const [selectedInvestorOptions, setSelectedInvestorOptions] = useState<any>(
    []
  );
  const [fundFilters, setFundFilters] = useState<FundItem[]>([]);
  const [roleFilters, setRoleFilters] = useState<FilterItem[]>([]);
  const [investorFilters, setInvestorFilters] = useState<Investor[]>([]);
  const [imageIds, setImageIds] = useState<any>([]);
  const [imageIdList, setImageIdList] = useState<any>([]);
  const [imageData, setImageData] = useState<any>([]);
  const [imagesOpen, setImagesOpen] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [idToRemove, setIdToRemove] = useState('');

  const handleOptionsFilter = (filterName: any, selected: any) => {
    if (filterName === 'investor_filter') {
      setSelectedInvestorOptions((prev: any) => [...selected]);
    }
    if (filterName === 'fund_filter') {
      setSelectedFundOptions((prev: any) => [...selected]);
    }

    if (filterName === 'role_filter') {
      setSelectedRoleOptions((prev: any) => [...selected]);
    }
  };

  useEffect(() => {
    const fundNames: any = [];
    const roleNames: any = [];
    const investorNames: any = [];

    selectedFundOptions.map((id: any) => {
      fundFilters.forEach((fund: any) => {
        if (fund.id === id) {
          fundNames.push(fund.name);
        }
      });
    });

    selectedInvestorOptions.map((id: any) => {
      investorFilters.forEach((investor: any) => {
        if (investor.id === id) {
          investorNames.push(investor.name);
        }
      });
    });

    selectedRoleOptions.map((id: any) => {
      roleFilters.forEach((role: any) => {
        if (role.id === id) {
          roleNames.push(role.name);
        }
      });
    });

    setContactDisplayInvestors(investorNames);
    setContactDisplayFunds(fundNames);
    setContactDisplayRoles(roleNames);
  }, [selectedFundOptions, selectedInvestorOptions, selectedRoleOptions]);

  const onlyUnique = (value: any, index: any, array: any) => {
    return array.indexOf(value) === index;
  };

  const defineContactEmails = async (selectedRecipients: any) => {
    const previews: any = await getContactsEmails(selectedRecipients);
    const data = Object.values(previews.previews);
    const emails: any = [];

    data.map((preview: any) => {
      preview.emails.forEach((email: any) => {
        emails.push(email);
      });
    });

    const uniqueEmails = emails.filter(onlyUnique);

    setContactDisplayEmails(uniqueEmails);
  };

  useEffectAsync(
    async (isCanceled) => {
      try {
        if (
          selectedFundOptions.length &&
          selectedInvestorOptions.length &&
          selectedRoleOptions.length
        ) {
          setContactDisplayTo(true);

          const payload: SelectedContacts = {
            funds: selectedFundOptions,
            investors: selectedInvestorOptions,
            roles: selectedRoleOptions,
          };

          await defineContactEmails(payload);
        }
      } catch (e) {
        informationAlert(GET_ALL_EMAILS, 'error');
      }
    },
    [selectedFundOptions, selectedInvestorOptions, selectedRoleOptions]
  );

  useEffectAsync(async (isCanceled) => {
    try {
      const roleFiltersResponse = await getAvailableRoles();

      if (isCanceled()) return;
      setRoleFilters(roleFiltersResponse);

      const investorFiltersResponse = await getInvestorFilters();

      if (isCanceled()) return;
      setInvestorFilters(investorFiltersResponse);

      const fundsFilterResponse = await getFundsFilterList();

      if (isCanceled()) return;
      setFundFilters(fundsFilterResponse);
    } catch (e) {
      informationAlert(RECIPIENTS_ERROR, 'error');
    }
  }, []);

  const selectedDocuments: any = sessionStorage.getItem('selectedDocs');

  const files = JSON.parse(selectedDocuments);
  const { register, setValue, reset } = useForm({
    defaultValues: {
      cc: '',
      bc: '',
      subject: '',
    },
  });

  const handleRecipientsOpen = (event: any) => setRecipientsOpen(true);

  type Anchor = 'right';

  const handleSubjectChange = (value: any) => {
    setSubjectInput(value);
  };

  const handleCcChange = (value: any) => {
    setCcInput(value);
  };

  const handleBcChange = (value: any) => {
    setBcInput(value);
  };

  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (params?.get('view') === ViewType.Documents) {
      setView(params?.get('view'));
      setModel(
        `<p fr-original-style="" style="box-sizing: inherit;"><a fr-original-style="" style="user-select: auto; box-sizing: inherit;">&lt;document_link&gt;</a></p>`
      );
    }

    if (files) {
      setSelectedFiles(files);
    }
  }, []);

  useEffect(() => {
    if (files) {
      setSelectedFiles(files);
    }

    if (
      view === ViewType.CashReceipts &&
      selectedMailing &&
      selectedMailing.investors &&
      selectedMailing.funds
    ) {
      const role = roleFilters.find((r) => r.name === 'Capital Call');

      setValue('subject', 'Your capital call funds have been received');
      setSubjectInput('Your capital call funds have been received');
      setSelectedInvestorOptions(selectedMailing.investors);
      setSelectedFundOptions(selectedMailing.funds);
      setSelectedRoleOptions([role?.id]);

      const header =
        '&lt;client_logo&gt;</br><h1>Capital call funds received</h1></br><h2><b>&lt;fund_name&gt;</b> received a payment for the following capital call:</h2></br>';
      const amountOwed =
        '<p><b>Partner name</b>: &lt;investor_name&gt;</p><p><b>Due date:</b> &lt;receivable_due_date&gt;</p><p><b>Amount called:</b> &lt;receivable_amount&gt;</p>';
      const originalAmount =
        '<p><b>Amount received:</b> &lt;receivable_amount_received&gt;</p><p><b>Date received:</b> &lt;receivable_date_received&gt;</p>';
      const adj1 =
        '<p><b>Amount received:</b> &lt;adjustment_1_amount_received&gt;</p><p><b>Date received:</b> &lt;adjustment_1_date_received&gt;</p><hr>';
      const adj2 =
        '<p><b>Amount received:</b> &lt;adjustment_2_amount_received&gt;</p><p><b>Date received:</b> &lt;adjustment_2_date_received&gt;</p><hr>';
      const footer =
        '<p><b>Total Amount:</b> &lt;total_amount&gt;</p><p><b>Remaining amount due:</b> &lt;receivable_amount_remaining&gt;</p></br><p>For questions, contact &lt;support_email&gt;</p></br>';

      let body = header;

      body = body + amountOwed;

      if (
        selectedMailing &&
        selectedMailing.data &&
        selectedMailing.data[0] &&
        !selectedMailing.data[0].notified
      ) {
        body += originalAmount;
      }

      if (
        selectedMailing &&
        selectedMailing.data &&
        selectedMailing.data[0] &&
        !selectedMailing.data[0].notified
      ) {
        body += '<hr>';
      }

      if (
        selectedMailing &&
        selectedMailing.data &&
        selectedMailing.data[0] &&
        selectedMailing.data[0].adjustments &&
        selectedMailing.data[0].adjustments[0] &&
        selectedMailing.data[0].adjustments[0].amountPaid &&
        selectedMailing.data[0].adjustments[0].adjustmentDate &&
        !selectedMailing.data[0].adjustments[0].notified
      ) {
        body += adj1;
      }

      if (
        selectedMailing &&
        selectedMailing.data &&
        selectedMailing.data[0] &&
        selectedMailing.data[0].adjustments &&
        selectedMailing.data[0].adjustments[1] &&
        selectedMailing.data[0].adjustments[1].amountPaid &&
        selectedMailing.data[0].adjustments[1].adjustmentDate &&
        !selectedMailing.data[0].adjustments[1].notified
      ) {
        body += adj2;
      }

      body += footer;

      setModel(body);
    }
  }, [selectedMailing]);

  useEffect(() => {
    if (selectedMailingId) {
      setSelectedId(selectedMailingId);
    }
  }, [selectedMailingId]);

  useEffectAsync(
    async (isCanceled) => {
      setIsLoading(true);

      if (selectedId) {
        const response = await getGeneralMailing(selectedMailingId);

        const subject: any = response.subject;
        const cc: any = response?.cc;
        const bc: any = response.bcc;
        const imageIds: any = response?.imageIds;
        const imageDataList: any = [];

        if (imageIds?.length) {
          setImagesOpen(true);

          await Promise.all(
            imageIds.map(async (id: any): Promise<any> => {
              const imageData = await getEmailImage(id);

              imageDataList.push(imageData);
            })
          );
        }

        setValue('subject', subject);
        setSubjectInput(subject);
        if (cc) {
          setValue('cc', cc.join(', '));
          setCcInput(cc.join(', '));
        }
        if (bc) {
          setValue('bc', bc.join(', '));
          setBcInput(bc.join(', '));
        }
        setImageIdList(imageIds);

        if (response.type === 'DOCUMENTS') {
          setView(ViewType.Documents);
          setSelectedMailingType(ViewType.Documents);
          const documents: any = response?.documents;

          const selectedDocs: SelectedDocuments = {
            fileIds: documents,
          };

          const previews: any = await getDocumentsPreviews(selectedDocs);

          const data = previews.previews;
          const emails: any = [];
          const funds: any = [];
          const investors: any = [];
          const roles: any = [];
          const body: any = response.messageBody;

          const el = document.createElement('div');

          if (imageIds?.length) {
            el.innerHTML = body;

            el.querySelectorAll('img').forEach((imgEl) => {
              imageDataList.map((imageData: any) => {
                if (imgEl.id === imageData.id) {
                  imgEl.src = `data:image/png;base64,${imageData.content}`;
                }
              });
            });

            setModel(el.innerHTML);
          } else {
            setModel(body);
            setImageIdList([]);
            setImageIds([]);
          }

          data.map((preview: any) => {
            preview.emails.forEach((email: string) => emails.push(email));
            funds.push(preview.fundName);
            investors.push(preview.investorName);
            preview.folderPermissionNames.forEach((name: string) =>
              roles.push(name)
            );
          });

          const displayTo: any = Array.from(new Set(emails));
          const displayFunds: any = Array.from(new Set(funds));
          const displayInvestors: any = Array.from(new Set(investors));
          const displayRoles: any = Array.from(new Set(roles));

          setDocDisplayTo(displayTo.join(', '));
          setDocDisplayFunds(displayFunds.join(', '));
          setDocDisplayInvestors(displayInvestors.join(', '));
          setDocDisplayRoles(displayRoles.join(', '));

          const newDocuments: any = [];
          const updatedFiles = previews.previews.map((file: any) => {
            const selectedFile = {
              name: file.fileName,
            };

            newDocuments.push(selectedFile);
          });

          const uniqueDocs = newDocuments.reduce(
            (accumulator: any, current: any) => {
              if (
                !accumulator.find((item: any) => item.name === current.name)
              ) {
                accumulator.push(current);
              }
              return accumulator;
            },
            []
          );

          setSelectedFiles(uniqueDocs);
          setSelectedMailingId('');
        }

        if (response.type === 'CONTACTS' || response.type === 'CAP_CALL') {
          if (response.type === 'CONTACTS') {
            setView(ViewType.Contacts);
            setSelectedMailingType(ViewType.Contacts);
          }

          if (response.type === 'CAP_CALL') {
            setView(ViewType.CashReceipts);
            setSelectedMailingType(ViewType.CashReceipts);

            setSelectedMailing?.({
              receivableId: response.data.receivableId,
              transactionIds: response.data.transactionIds,
            });
          }

          const toFunds: any = response?.to?.funds;
          const toInvestors: any = response?.to?.investors;
          const toRoles: any = response?.to?.roles;
          const body: any = response.messageBody;

          const el = document.createElement('div');

          if (imageIds?.length) {
            el.innerHTML = body;

            el.querySelectorAll('img').forEach((imgEl) => {
              imageDataList.map((imageData: any) => {
                if (imgEl.id === imageData.id) {
                  imgEl.src = `data:image/png;base64,${imageData.content}`;
                }
              });
            });

            setModel(el.innerHTML);
          } else {
            setModel(body);
            setImageIdList([]);
            setImageIds([]);
          }

          const payload: SelectedContacts = {
            funds: toFunds,
            investors: toInvestors,
            roles: toRoles,
          };

          if (toFunds.length && toInvestors.length && toRoles.length) {
            await defineContactEmails(payload);
          }
          setSelectedInvestorOptions(toInvestors);
          setSelectedFundOptions(toFunds);
          setSelectedRoleOptions(toRoles);
          setSelectedMailingId('');
        }
      }

      setIsLoading(false);
    },
    [selectedId]
  );

  useEffect(() => {
    reset();
  }, [createNewToggle]);

  const createMail = () => {
    setSelectedMailingType(ViewType.Contacts);
    SetCreateNewToggle(!createNewToggle);
    setView(ViewType.Contacts);
  };

  const handleTabChange = (event: any, newValue: any) => {
    setCurrentTab(newValue);
    switch (newValue) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        break;
    }
  };

  const handleModelChange = (model: any) => {
    setModel(model);
  };

  let imageCounterVar = 1;

  const hidePanel = () => {
    setMailingFundList([]);
    setToFundRecipients([]);
    setMailingInvestorList([]);
    setToInvestorRecipients([]);
    setMailingRoleList([]);
    setToRoleRecipients([]);
    setSelectedMailingId('');
    setValue('subject', '');
    setValue('cc', '');
    setValue('bc', '');
    setModel('');
    setSelectedId('');
    setSelectedMailingType(undefined);
    setSelectedFiles([]);
    fetchAllGeneralMailings();
    setRecipientBccError(false);
    setRecipientCcError(false);
    setSelectedInvestorOptions([]);
    setSelectedFundOptions([]);
    setSelectedRoleOptions([]);
    setContactDisplayEmails([]);
    setImageIdList([]);
    setImageIds([]);
    setImagesOpen(false);
    setImageUploaded(false);
    imageCounterVar = 1;
    if (view === ViewType.Documents) {
      sessionStorage.removeItem('selectedDocs');
      history.push(`/general-mailings`);
    }
    setView(undefined);
    setSelectedMailing?.(undefined);
  };

  const createGeneralMailing = async () => {
    switch (view) {
      case ViewType.Contacts:
        await createContactsGeneralMailing();
        break;
      case ViewType.Documents:
        await createDocumentsGeneralMailing();
        break;
      case ViewType.CashReceipts:
        await createCashReceiptsGeneralMailing();
        break;
    }
  };

  const createContactsGeneralMailing = async () => {
    if (ccInput) {
      const ccArray: any = ccInput.replace(/\s/g, '').split(',');

      ccArray.map((email: any) => {
        if (recipientInputValidation.test(email) === false) {
          informationAlert(INVALID_CC, 'error');
          setRecipientCcError(true);
          return;
        }
      });
    }

    if (bcInput) {
      const bcArray: any = bcInput.replace(/\s/g, '').split(',');

      bcArray.map((email: any) => {
        if (recipientInputValidation.test(email) === false) {
          informationAlert(INVALID_BC, 'error');
          setRecipientBccError(true);
          return;
        }
      });
    }

    const recipientsCheck: boolean[] = [];

    selectedInvestorOptions.length > 0
      ? recipientsCheck.push(true)
      : recipientsCheck.push(false);
    selectedFundOptions.length > 0
      ? recipientsCheck.push(true)
      : recipientsCheck.push(false);
    selectedRoleOptions.length > 0
      ? recipientsCheck.push(true)
      : recipientsCheck.push(false);

    const allEqual = recipientsCheck.every((val) => val === recipientsCheck[0]);

    if (allEqual === false) {
      informationAlert(CONTACT_RECIPIENT_CHECK, 'error');
      return;
    }

    const el = document.createElement('div');

    if (imageIdList?.length) {
      el.innerHTML = model;

      el.querySelectorAll('img').forEach((imgEl) => {
        if (!imgEl.id) {
          imgEl.id = imageIdList[imageIdList.length - 1];
        }
        imgEl.src = `cid:${imgEl.id}`;
      });
    }

    const contactsMailing: newGeneralMailings = {
      to: {
        investors: selectedInvestorOptions,
        funds: selectedFundOptions,
        roles: selectedRoleOptions,
      },
      cc: ccInput.split(', '),
      bcc: bcInput.split(', '),
      subject: subjectInput,
      messageBody: imageIdList?.length ? el.innerHTML : model,
    };

    if (imageIdList?.length) {
      contactsMailing.imageIds = imageIdList;
    }

    setIsLoading(true);
    try {
      if (selectedMailingType === ViewType.Contacts) {
        await updateGeneralContactsMailing(selectedId, contactsMailing);
        informationAlert(DRAFT_UPDATE_SUCCESS, 'success');
        hidePanel();
      } else {
        await addGeneralContactsMailing(contactsMailing);
        informationAlert(DRAFT_CREATE_SUCCESS, 'success');
        hidePanel();
      }
    } catch (error) {
      informationAlert(DRAFT_ERROR, 'error');
      if (subjectInput === '') {
        informationAlert(SUBJECT_ERROR, 'error');
      }
    }
    setIsLoading(false);
  };

  const createDocumentsGeneralMailing = async () => {
    if (ccInput) {
      const ccArray: any = ccInput.replace(/\s/g, '').split(',');

      ccArray.map((email: any) => {
        if (recipientInputValidation.test(email) === false) {
          informationAlert(INVALID_CC, 'error');
          setRecipientCcError(true);
          return;
        }
      });
    }

    if (bcInput) {
      const bcArray: any = bcInput.replace(/\s/g, '').split(',');

      bcArray.map((email: any) => {
        if (recipientInputValidation.test(email) === false) {
          informationAlert(INVALID_BC, 'error');
          setRecipientBccError(true);
          return;
        }
      });
    }

    const el = document.createElement('div');

    if (imageIdList?.length) {
      el.innerHTML = model;

      el.querySelectorAll('img').forEach((imgEl) => {
        if (!imgEl.id) {
          imgEl.id = imageIdList[imageIdList.length - 1];
        }
        imgEl.src = `cid:${imgEl.id}`;
      });
    }

    const documentsMailing: newGeneralMailings = {
      documents: selectedFiles.map((file: any) => file.id),
      cc: ccInput.split(', '),
      bcc: bcInput.split(', '),
      subject: subjectInput,
      messageBody: imageIdList?.length ? el.innerHTML : model,
    };

    if (imageIdList?.length && imageIdList !== null) {
      documentsMailing.imageIds = imageIdList;
    }

    setIsLoading(true);
    try {
      if (selectedMailingType === ViewType.Documents) {
        const response = await getGeneralMailing(selectedId);

        documentsMailing.documents = response.documents;
        await updateGeneralDocumentsMailing(selectedId, documentsMailing);
        informationAlert(DRAFT_UPDATE_SUCCESS, 'success');
        hidePanel();
      } else {
        await addGeneralDocumentsMailing(documentsMailing);
        informationAlert(DRAFT_CREATE_SUCCESS, 'success');
        hidePanel();
      }
    } catch (error) {
      informationAlert(DRAFT_ERROR, 'error');
      if (subjectInput === '') {
        informationAlert(SUBJECT_ERROR, 'error');
      }
    }
    setIsLoading(false);
  };

  const createCashReceiptsGeneralMailing = async () => {
    if (ccInput) {
      const ccArray: any = ccInput.replace(/\s/g, '').split(',');

      ccArray.map((email: any) => {
        if (recipientInputValidation.test(email) === false) {
          informationAlert(INVALID_CC, 'error');
          setRecipientCcError(true);
          return;
        }
      });
    }

    if (bcInput) {
      const bcArray: any = bcInput.replace(/\s/g, '').split(',');

      bcArray.map((email: any) => {
        if (recipientInputValidation.test(email) === false) {
          informationAlert(INVALID_BC, 'error');
          setRecipientBccError(true);
          return;
        }
      });
    }

    const recipientsCheck: boolean[] = [];

    selectedInvestorOptions.length > 0
      ? recipientsCheck.push(true)
      : recipientsCheck.push(false);
    selectedFundOptions.length > 0
      ? recipientsCheck.push(true)
      : recipientsCheck.push(false);
    selectedRoleOptions.length > 0
      ? recipientsCheck.push(true)
      : recipientsCheck.push(false);

    const allEqual = recipientsCheck.every((val) => val === recipientsCheck[0]);

    if (allEqual === false) {
      informationAlert(CONTACT_RECIPIENT_CHECK, 'error');
      return;
    }

    const el = document.createElement('div');

    if (imageIdList?.length) {
      el.innerHTML = model;

      el.querySelectorAll('img').forEach((imgEl) => {
        if (!imgEl.id) {
          imgEl.id = imageIdList[imageIdList.length - 1];
        }
        imgEl.src = `cid:${imgEl.id}`;
      });
    }

    const cashReceiptsMailing: newGeneralMailings = {
      receivableId: selectedMailing.receivableId,
      transactionIds: selectedMailing.transactionIds,
      draftType: 'CAP_CALL',
      to: {
        investors: selectedInvestorOptions,
        funds: selectedFundOptions,
        roles: selectedRoleOptions,
      },
      cc: ccInput.split(', '),
      bcc: bcInput.split(', '),
      subject: subjectInput,
      messageBody: imageIdList?.length ? el.innerHTML : model,
    };

    if (imageIdList?.length) {
      cashReceiptsMailing.imageIds = imageIdList;
    }

    setIsLoading(true);
    try {
      if (selectedMailingType === ViewType.CashReceipts) {
        await updateGeneralCashReceiptsMailing(selectedId, cashReceiptsMailing);
        informationAlert(DRAFT_UPDATE_SUCCESS, 'success');
        hidePanel();
      } else {
        await addGeneralCashReceiptsMailing(cashReceiptsMailing);
        informationAlert(DRAFT_CREATE_SUCCESS, 'success');
        hidePanel();
      }
      onSuccessfulPostClose?.();
    } catch (error) {
      informationAlert(DRAFT_ERROR, 'error');
      if (subjectInput === '') {
        informationAlert(SUBJECT_ERROR, 'error');
      }
    }
    setIsLoading(false);
  };

  let totalImageSize = 0;

  const froalaConfigs = {
    key: process.env.REACT_APP_FROALA_KEY,
    placeholderText: 'Draft your message here...',
    toolbarButtons: [
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'fontFamily',
      'fontSize',
      'textColor',
      'paragraphFormat',
      'lineHeight',
      'align',
      'formatOL',
      'formatUL',
      'outdent',
      'indent',
      'leftToRight',
      'rightToLeft',
      'insertTable',
      'insertLink',
      'insertButton',
      'clearFormatting',
      'undo',
      'redo',
    ],
    tableStyles: {
      'no-border': 'No border',
    },
    useClasses: false,
    attribution: false,
    toolbarSticky: false,
    charCounterCount: false,
    fontFamily: {
      'Roboto,sans-serif': 'Sans Serif',
      'Noto Serif,sans-serif': 'Serif',
      'Courier Prime,sans-serif': 'Fixed Width',
      'Archivo,sans-serif': 'Wide',
      'PT Sans Narrow,sans-serif': 'Narrow',
      'Open Sans Condensed,sans-serif': 'Sans Condensed',
      'Comic Neue,sans-serif': 'Comic Neue',
      'EB Garamond,sans-serif': 'Garamond',
      'Georgia,sans-serif': 'Georgia',
      'Tahoma,Geneva,sans-serif': 'Tahoma',
      'Allerta,sans-serif': 'Trebuchet MS',
      'Verdana,Geneva,sans-serif': 'Verdana',
    },
    fontFamilySelection: true,
    fontSizeDefaultSelection: '12',
    fontSizeSelection: true,
    paragraphFormatSelection: true,
    heightMin: 200,
    heightMax: 550,
    linkList: [
      {
        text: 'Document Link',
        href: '{{Doc_Mail_Merge_Field}}',
        target: '_blank',
      },
    ],
    zIndex: 9990,
    imageUpload: true,
    imageUploadParam: 'image_param',
    imageMaxSize: 100 * 1024,
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    fileUpload: false,
    events: {
      'image.beforeUpload': function (files: any) {
        const imageSize = files[0].size;
        const editor: any = this;
        const reader = new FileReader();

        totalImageSize = totalImageSize + imageSize;

        if (totalImageSize >= 6000000) {
          editor.popups.hideAll();
          informationAlert(IMAGE_SIZE_ERROR, 'error');
          return false;
        }

        uploadEmailImages(files).then((response: any) => {
          const responseId: any = response.images;
          const imageId: any = Object.keys(responseId);

          setImageIds(imageId[0]);

          if (files.length) {
            reader.onload = function (e) {
              const result = e?.target?.result;
              const img = editor.image.get();

              editor.image.insert(result, null, null, img);
            };

            reader.readAsDataURL(files[0]);
          }

          editor.popups.hideAll();
        });

        return false;
      },
      'image.inserted': function (img: any) {},
      'image.beforeRemove': function (img: any) {
        if (img[0].id) {
          setIdToRemove(img[0].id);
        } else {
          setIdToRemove('remove');
        }
      },
    },
  };

  useEffect(() => {
    if (idToRemove !== 'remove') {
      const updatedIdList = imageIdList.filter((id: any) => id !== idToRemove);

      setImageIdList(updatedIdList);
    } else {
      const updatedIdList = imageIdList;

      updatedIdList.pop();

      setImageIdList(updatedIdList);
    }
  }, [idToRemove]);

  useEffect(() => {
    if (imageIds.length) {
      if (imageIdList?.length === 0) {
        const idList = [imageIds];

        setImageIdList(idList);
      } else {
        const idList = [...imageIdList, imageIds];

        const parser = new DOMParser();

        const doc = parser.parseFromString(model, 'text/html');

        const imgWithTags = doc.querySelectorAll('img[id]');
        const imgTags = doc.querySelectorAll('img:not([id])');

        if (imgTags[0]) {
          imgTags[0].id = imageIdList[imgWithTags.length];
        }

        setModel(doc.documentElement.innerHTML);

        setImageIdList(idList);
      }
    }
  }, [imageIds]);

  return {
    isPanelVisible,
    setPanelVisible,
    recipientsOpen,
    handleRecipientsOpen,
    toRecipientsOpen,
    recipientsPage,
    toFundRecipients,
    setToFundRecipients,
    toInvestorRecipients,
    setToInvestorRecipients,
    toRoleRecipients,
    setToRoleRecipients,
    mailingFundList,
    setMailingFundList,
    fundList,
    setFundList,
    fundListUpdated,
    setFundListUpdated,
    searchVal,
    setSearchVal,
    investorList,
    setInvestorList,
    mailingInvestorList,
    setMailingInvestorList,
    investorsListUpdated,
    setInvestorsListUpdated,
    searchValInvestors,
    setSearchValInvestors,
    setRolesList,
    mailingRoleList,
    setMailingRoleList,
    rolesListUpdated,
    setRolesListUpdated,
    searchValRoles,
    setSearchValRoles,
    hidePanel,
    createContactsGeneralMailing,
    createDocumentsGeneralMailing,
    register,
    handleSubjectChange,
    handleCcChange,
    handleBcChange,
    setSelectedId,
    reset,
    createMail,
    model,
    handleModelChange,
    currentTab,
    handleTabChange,
    selectedFiles,
    isLoading,
    recipientCcError,
    setRecipientCcError,
    recipientBccError,
    setRecipientBccError,
    selectedRoleOptions,
    selectedFundOptions,
    selectedInvestorOptions,
    handleOptionsFilter,
    fundFilters,
    roleFilters,
    investorFilters,
    docDisplayTo,
    docDisplayFunds,
    docDisplayInvestors,
    docDisplayRoles,
    selectedDocuments,
    contactDisplayFunds,
    contactDisplayInvestors,
    contactDisplayRoles,
    contactDisplayEmails,
    froalaConfigs,
    createGeneralMailing,
  };
};
