import LockIcon from "@mui/icons-material/Lock";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import React from "react";

import { ProgressModal } from "../../../components";
import { DataWrapperBox } from "../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import DataGrid from "../../../components/DataGrid/DataGrid";
import LockConfirmationDialog from "../../../components/LockConfirmationDialog/LockConfirmationDailog";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import { IconBox } from "../fundList/FundList.styles";
import ExportButton from "./ExportButton/ExportButton";
import { getDefaultSoiViews } from "./ScheduleOfInvestments.defaults";
import { useScheduleOfInvestments } from "./ScheduleOfInvestments.hooks";
import { HeaderButtonBox, HeaderRow } from "./ScheduleOfInvestments.styles";

export const ScheduleOfInvestments: React.FC = () => {
  const {
    isLoadingList,
    currSOIview,
    handleSoiViewChange,
    soiGridData,
    handleColumnOrderChange,
    dateFilter,
    setCategoryFieldOrder,
    setDataFieldOrder,
    setDateFilter,
    lockedPrompt,
    showLockedDialog,
    client,
    setLockedPrompt,
  } = useScheduleOfInvestments();

  return (
    <DataWrapperBox id="soi_list_main">
        { isLoadingList && <ProgressModal
            id="gl_report_info_loading"
            showProgress={isLoadingList}
        />}
      <HeaderRow>
        <IconBox>
          <Typography variant="pageTitle">Schedule of Investments</Typography>
          {client?.soiLocked && (
            <IconButton aria-label="locked" onClick={showLockedDialog}>
              <LockIcon />
            </IconButton>
          )}
        </IconBox>
        <HeaderButtonBox>

          <Box id="select_box" width="250px">
            <SingleSelect
              id="select_view"
              value={currSOIview}
              optionList={ getDefaultSoiViews() }
              label={"SOI View"}
              handleOptionSelection={(evt: any) => { handleSoiViewChange(evt.target.value); }}
              width = "200px"
          />
          </Box>

          <Box id="export_view" width="175px" paddingTop="10px">
            <ExportButton
              id="btn_export_wrapper"
              hidden={!(soiGridData?.soiGridRows?.length > 0)}
              headers={soiGridData?.dataGridColDefs || []}
              date={dateFilter}
              data={soiGridData?.soiGridRows || []} 
            />
          </Box>

          <Box id = "dt_Picker_box" width="150px" paddingTop="10px">
            <DatePicker
              label="Date"
              renderInput={(params) => (
                <TextField {...params} size="small" id="filter_date" />
              )}
              onChange={(value) => {
                setDateFilter(value);
              }}
              value={dateFilter}
            />
          </Box>
        </HeaderButtonBox>
      </HeaderRow>
      <DataGrid
        id="soi_data_grid"
        dataList={soiGridData.soiGridRows || []}
        headerList={soiGridData.dataGridColDefs}
        noDataMessage={isLoadingList ? "Fetching Data..." : undefined}
        minHeight="1vh"
        autoHeight={false}
        activeHeaderFields={soiGridData.dataGridColDefs.length}
        checkboxSelection={false}
        onColumnOrderChange={(colDefs) => {
          handleColumnOrderChange(colDefs.oldIndex, colDefs.targetIndex);
        }}
      />

      <LockConfirmationDialog
        lockedPrompt={lockedPrompt}
        setLockedPrompt={setLockedPrompt}
      />
    </DataWrapperBox>
  );
};
