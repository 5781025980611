import { useState } from "react";

import { getColumnOrder } from "../../../services/columnOrder.service";
import { getAvailableRoles } from "../../../services/contact.service";
import { getFundsFilterList } from "../../../services/filters.service";
import { getInvestorFilters } from "../../../services/investor.service";
import {
  CONTACTS_COLUMN_ORDER_VIEW_KEY,
  CONTACTS_PERMISSION_COLUMN_ORDER_VIEW_KEY,
  GENERIC_ERROR_MESSAGE,
} from "../../../utils/constants/text.constants";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { ColumnOrder } from "../../../utils/types/columnOrder";
import { FundItem } from "../../../utils/types/fund.type";
import { InvestorFilter } from "../../../utils/types/investor.type";
import { FilterItem } from "../../../utils/types/listItems";

export const useContactListEffect = (
  clientId: string,
  informationAlert: any,
  isUser?: boolean,
) => {
  const [roleFilters, setRoleFilters] = useState<FilterItem[]>([]);
  const [investorFilters, setInvestorFilters] = useState<InvestorFilter[]>([]);
  const [fundFilters, setFundFilters] = useState<FundItem[]>([]);
  const [contactColumnOrder, setContactColumnOrder] =
    useState<ColumnOrder | null>(null);
  const [contactPermissionColumnOrder, setContactPermissionColumnOrder] =
    useState<ColumnOrder | null>(null);

  useEffectAsync(async (isCanceled) => {
    try {
      const roleFiltersResponse = await getAvailableRoles();

      if (isCanceled()) return;
      setRoleFilters(roleFiltersResponse);

      const investorFiltersResponse = await getInvestorFilters();

      if (isCanceled()) return;
      setInvestorFilters(investorFiltersResponse);

      const fundsFilterResponse = await getFundsFilterList();

      if (isCanceled()) return;
      setFundFilters(fundsFilterResponse);

      if (!isUser) {
        const columnOrder: ColumnOrder = await getColumnOrder(
          CONTACTS_COLUMN_ORDER_VIEW_KEY,
          clientId
        );

        const permissionGridColumnOrder: ColumnOrder = await getColumnOrder(
          CONTACTS_PERMISSION_COLUMN_ORDER_VIEW_KEY,
          clientId
        );

        setContactColumnOrder(columnOrder || null);
        setContactPermissionColumnOrder(permissionGridColumnOrder || null);

      }

    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
  }, []);

  return {
    fundFilters,
    roleFilters,
    investorFilters,
    contactColumnOrder,
    contactPermissionColumnOrder
  };
};

export const formatPermissionByInvestors = (
  investorList: any[],
  roleList: FilterItem[]
) => {
  const formattedInvestorList: any[] = [];

  investorList.forEach((investor) => {
    if (investor.hierarchy.length === 1) {
      formattedInvestorList.push({
        id: investor.id,
        name: investor.name,
        funds: [],
      });
    } else {
      const hierarchy = investor.hierarchy;
      const fundId = hierarchy[1].split("_")[1];
      const selectedRoleList = Object.getOwnPropertyNames(investor).filter(
        (property) =>
          property !== "id" &&
          property !== "investor" &&
          property !== "isLocked" &&
          property !== "hierarchy" &&
          property !== "name" &&
          investor[property] !== false
      );
      const savingRoleList: FilterItem[] = [];

      selectedRoleList.map((selectedRole) => {
        const roleToAdd = roleList.find((role) => role.id === selectedRole);

        if (roleToAdd) {
          savingRoleList.push(roleToAdd);
        }
      });

      formattedInvestorList
        .find((formatInvestor) => formatInvestor.id === hierarchy[0])
        .funds.push({
          id: fundId,
          name: investor.name,
          roles: savingRoleList.sort((a, b) => a.name.localeCompare(b.name)),
        });
    }
  });

  return formattedInvestorList;
};
