import DeleteOutline from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { GridAlignment } from "@mui/x-data-grid-pro";
import { ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import DeleteIcon from "../../../assets/images/icons/icon_delete.svg";
import ExportIcon from "../../../assets/images/icons/icon_export.svg";
import { RouterLink } from "../../../components/Link/RouterLink/RouterLink";
import { AppContext } from "../../../core/context/appContextProvider";
import RoutingPaths from "../../../core/routing/routingPaths";
import useRole from "../../../core/routing/useRole";
import { getClientConfigsList } from "../../../services/client.service";
import {
  deleteFund,
  downloadData,
  getFundSummary,
} from "../../../services/fund.service";
import { useClientEffect } from "../../../services/hooks/useClientsEffect/useClientEffect.hooks";
import { downloadTemplate } from "../../../services/template.service";
import { uploadFile } from "../../../services/uploads.service";
import {
  DELETE_FUND_ERROR,
  DELETE_FUND_SUCCESS,
  EXPORT_FUND_ERROR,
} from "../../../utils/constants/fund.constants";
import { GENERIC_ERROR_MESSAGE } from "../../../utils/constants/text.constants";
import downloadFile from "../../../utils/helpers/fileDownloader";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { AddNewButtonOptions } from "../../../utils/types/common.type";
import {
  Fund,
  FundAction,
  FundSummary,
  FundSummaryItem,
} from "../../../utils/types/fund.type";
import {
  CustomType,
  DataGridColDef,
  ImageItem,
} from "../../../utils/types/listItems";
import { ScopeRole } from "../../../utils/types/user.type";

const defaultHeaderList: DataGridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    hide: false,
    index: 1,
    sortable: false,
    renderCell: (params) => {
      if (params.row.name === "Total") {
        return `${params.row.name} (${params.row.currencyCode})`;
      }
      return (
        <RouterLink
          to={{ pathname: `${RoutingPaths.FundDetails}/${params.row.id}` }}
        >
          {params.row.name}
        </RouterLink>
      );
    },
    type: "string",
    align: "left" as GridAlignment,
    width: 200,
  },
  {
    field: "committed",
    headerName: "Committed",
    hide: false,
    index: 2,
    type: "number",
    customType: CustomType.PositiveCurrency,
    sortable: false,
    currencyCodeField: "currencyCode",
    decimalPlaces: 0,
    align: "right" as GridAlignment,
    width: 180,
  },
  {
    field: "contributions",
    headerName: "Contributed",
    hide: false,
    index: 3,
    type: "number",
    customType: CustomType.PositiveCurrency,
    sortable: false,
    currencyCodeField: "currencyCode",
    decimalPlaces: 0,
    align: "right" as GridAlignment,
    width: 180,
  },
  {
    field: "unfunded",
    headerName: "Unfunded",
    hide: false,
    index: 3,
    type: "number",
    customType: CustomType.PositiveCurrency,
    sortable: false,
    currencyCodeField: "currencyCode",
    decimalPlaces: 0,
    align: "right" as GridAlignment,
    width: 180,
  },
  {
    field: "distribution",
    headerName: "Distributions",
    hide: false,
    index: 4,
    type: "number",
    customType: CustomType.PositiveCurrency,
    sortable: false,
    currencyCodeField: "currencyCode",
    decimalPlaces: 0,
    align: "right" as GridAlignment,
    width: 180,
  },
  {
    field: "percentCalled",
    headerName: "% Called",
    hide: false,
    index: 5,
    sortable: false,
    type: "percent",
    align: "right" as GridAlignment,
    width: 180,
  },
  {
    field: "value",
    headerName: "Value",
    hide: false,
    index: 6,
    type: "number",
    customType: CustomType.Currency,
    sortable: false,
    currencyCodeField: "currencyCode",
    decimalPlaces: 0,
    align: "right" as GridAlignment,
    width: 180,
  },
  {
    field: "quarterAsOf",
    headerName: "As Of",
    hide: false,
    index: 7,
    type: "string",
    sortable: false,
    align: "center" as GridAlignment,
    width: 120,
  },
  {
    field: "action",
    headerName: "Columns",
    hide: false,
    hideable: false,
    index: 10,
    type: "action",
    customType: CustomType.Action,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    width: 200,
  },
];

type ActionProp = {
  row: any;
  allowDelete?: boolean;
  onView: () => void;
  onDelete: () => void;
};

const Actions = ({
  row,
  allowDelete = false,
  onView,
  onDelete,
}: ActionProp): ReactElement => {
  return (
    <Box>
      <Button
        id={`${row?.id}_view_button`}
        variant="text"
        disableElevation
        startIcon={<VisibilityIcon />}
        onClick={onView}
      >
        View
      </Button>
      {allowDelete && (
        <Button
          id={`${row?.id}_delete_button`}
          variant="text"
          color="error"
          disableElevation
          startIcon={<DeleteOutline />}
          onClick={onDelete}
        >
          Delete
        </Button>
      )}
    </Box>
  );
};

export const useFundList = () => {
  // const [columns, setColumns] = useState<Column[]>([]);
  const { state, informationAlert } = useContext(AppContext);
  const { clientId } = state.loginUser;
  const [fundSummaries, setFundSummaries] = useState<FundSummary[]>([]);
  const { client } = useClientEffect(clientId !== "new" ? clientId : undefined);
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultHeaderList.length - 1
  );
  const [search, setSearch] = useState<string>("");
  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const [fundSelectionModel, setFundSelectionModel] = useState<string[]>([]);
  const [showSuggestionPopover, setShowSuggestionPopover] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const [showDeletePrompt, setDeletePrompt] = useState<boolean>(false);
  const [selectedFund, setCurrentSelectedFund] = useState<
    FundSummaryItem | undefined
  >();

  const history = useHistory();

  const [fundSummaryResponse, setFundSummaryResponse] = useState<FundSummary[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);
  const { hasRole: isSuperAdmin } = useRole([ScopeRole.SUPER_ADMIN]);
  const { hasRole: isClientAdmin } = useRole([ScopeRole.ARK_CLIENT_ADMIN]);
  const [lockedPrompt, setLockedPrompt] = useState<boolean>(false);
  const readonly: boolean = !!isFundAdmin;

  useEffectAsync(async (isCanceled) => {
    try {
      setIsLoading(true);

      if (isFundAdmin) {
        const fundSummaryResponse = await getFundSummary(clientId);

        if (isCanceled()) return;

        setFundSummaryResponse(fundSummaryResponse);
      }

      if (!isFundAdmin) {
        const fundSummaryResponse = await getFundSummary(clientId);

        if (isCanceled()) return;

        setFundSummaryResponse(fundSummaryResponse);
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
  }, []);

  useEffect(() => {
    initializeHeaderList();
    initializeSearchOptions(fundSummaryResponse);
    setFundSummaries(fundSummaryResponse);
  }, [fundSummaryResponse]);

  const initializeSearchOptions = (response: FundSummary[]) => {
    const options: string[] = [];

    response.forEach((fundSummary) => {
      fundSummary.items.forEach((fund) => {
        if (fund.name !== "Total") {
          options.push(fund.name);
        }
      });
    });

    setSearchOptions(options);
  };

  const initializeHeaderList = () => {
    setHeaderList(
      defaultHeaderList.map((header) =>
        header.field === "action"
          ? {
              ...header,
              renderCell: ({ row }: any) => {
                if (row.id === "total") return null;
                return (
                  <Actions
                    row={row}
                    onView={() => {
                      handleOnView(row.id);
                    }}
                    allowDelete={!!(isClientAdmin || isSuperAdmin)}
                    onDelete={() => {
                      setCurrentSelectedFund(row);
                      setDeletePrompt(true);
                    }}
                  />
                );
              },
            }
          : header
      )
    );
  };

  const updateFundList = (searchText: string) => {
    if (fundSummaryResponse) {
      if (searchText === "") {
        setFundSummaries(fundSummaryResponse);
      } else if (typeof searchText === "string") {
        const fundSummaries = [...fundSummaryResponse];

        const updatedFundSummaries = fundSummaries.map((fundSummary) => {
          const updatedFundSummary = { ...fundSummary };
          const updatedItems = fundSummary.items.filter((fund) =>
            fund.name.match(new RegExp(searchText, "i"))
          );

          updatedFundSummary.items = updatedItems;
          return updatedFundSummary;
        });

        setFundSummaries(updatedFundSummaries);
      }
    }
  };

  const bulkActions: ImageItem[] = useMemo(() => {
    const actions = [];
    const exportSelected = {
      id: FundAction.ExportSelected,
      text: `Export Selected (${fundSelectionModel?.length || 0})`,
      icon: <img src={ExportIcon} alt="Export Selected" height="15" />,
      optionsSelected: 0,
    };
    const exportAll = {
      id: FundAction.ExportAll,
      text: "Export All",
      icon: <img src={ExportIcon} alt="Export All" height="15" />,
      optionsSelected: 0,
    };

    if (fundSelectionModel?.length > 0) {
      actions.push(exportSelected);
    }
    actions.push(exportAll);

    return actions;
  }, [fundSelectionModel]);

  const handleFilter = () => {};

  const clearUploadedFile = () => {
    setUploadedFile(undefined);
  };

  const clearUploadCompleted = () => {
    setIsUploadComplete(false);
  };

  const handleUploadTemplate = async () => {
    if (!uploadedFile) return;
    try {
      setIsLoading(true);
      await uploadFile("funds", uploadedFile);
      setUploadedFile(undefined);
      await setSearch("");
      await getFundSummary(clientId);
      setFundSummaryResponse(fundSummaryResponse);

      setIsLoading(false);
      setIsUploadComplete(true);
    } catch (exception) {
      setUploadedFile(undefined);
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
  };

  const handleNewButtonAction = async (actionId: string, event: any) => {
    switch (actionId) {
      case AddNewButtonOptions.AddNew:
        {
          history.push(`${RoutingPaths.FundDetails}/new`);
        }
        break;
      case AddNewButtonOptions.UploadFromTemplate:
        {
          setIsLoading(true);
          const file = event?.target?.files?.[0];

          if (file) {
            setUploadedFile(file);
          }
          setIsLoading(false);
        }
        break;
      case AddNewButtonOptions.DownloadTemplate:
        {
          setIsLoading(true);
          const data = await downloadTemplate("funds");

          downloadFile(data, "standard_funds_import_template", "csv");
          setIsLoading(false);
        }
        break;
    }
  };

  const exportFunds = async (ids: string[]) => {
    try {
      const downloadedData = await downloadData({
        clientId,
        type: "FUND",
        ids,
      });

      downloadFile(downloadedData, "Funds", "csv");
    } catch (error) {
      informationAlert(EXPORT_FUND_ERROR, "error");
    }

    setIsLoading(false);
  };

  const onDeleteConfirm = async () => {
    try {
      if (selectedFund) {
        await deleteFund(clientId, selectedFund.id);
        setFundSelectionModel([]);
        informationAlert(DELETE_FUND_SUCCESS, "success");
      }
      setDeletePrompt(false);
      setCurrentSelectedFund(undefined);
      setIsLoading(true);
      const fundSummaryResponse = await getFundSummary(clientId);

      setFundSummaryResponse(fundSummaryResponse);
      setIsLoading(false);
    } catch (error) {
      informationAlert(DELETE_FUND_ERROR, "error");
    }
  };

  const onDeleteCancel = () => {
    setDeletePrompt(false);
  };

  const handleBulkActions = (actionId: FundAction) => {
    switch (actionId) {
      case FundAction.ExportSelected:
        exportFunds(fundSelectionModel);
        break;
      case FundAction.ExportAll: {
        const fundIds: string[] = fundSummaryResponse.reduce(
          (fundArray, fundSummary) => {
            fundSummary.items.map((fund) => {
              if (fund.id && fund.id !== "total") fundArray.push(fund.id);
            });
            return fundArray;
          },
          [] as string[]
        );

        exportFunds(fundIds);
        break;
      }
      case FundAction.DeleteSelected:
        setIsLoading(false);
        setDeletePrompt(true);
        break;
    }
  };

  const handleUpdateHeader = async (field: string) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== "action"
    );

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      return {
        ...header,
        hide:
          header.field === field && !(!header.hide && activeFields.length <= 1)
            ? !header.hide
            : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }
  };

  const onSearch = (
    event: any,
    newValue: React.SetStateAction<string> | null
  ) => {
    if (typeof newValue === "string") {
      setSearch(newValue);
    } else if (newValue === null) {
      setSearch("");
    }

    if (newValue !== null) setShowSuggestionPopover(false);
  };

  const handleOnView = (fundId: string) => {
    history.push(`${RoutingPaths.FundDetails}/${fundId}`);
  };

  useEffect(() => {
    updateFundList(search);
  }, [search]);

  const showLockedDialog = () => {
    setLockedPrompt(true);
  };

  return {
    bulkActions,
    fundSummaryResponse,
    fundSummaries,
    headerList,
    activeHeaderFields,
    readonly,
    showDeletePrompt,
    selectedFund,
    handleBulkActions,
    handleUpdateHeader,
    handleOnView,
    handleFilter,
    handleNewButtonAction,
    search,
    searchOptions,
    onSearch,
    showSuggestionPopover,
    fundSelectionModel,
    setFundSelectionModel,
    setShowSuggestionPopover,
    isLoading,
    uploadedFile,
    clearUploadedFile,
    handleUploadTemplate,
    isUploadComplete,
    clearUploadCompleted,
    onDeleteCancel,
    onDeleteConfirm,
    setCurrentSelectedFund,
    client,
    showLockedDialog,
    lockedPrompt,
    setLockedPrompt,
  };
};
