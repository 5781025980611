import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { GridCallbackDetails, GridRenderCellParams, GridSelectionModel } from "@mui/x-data-grid-pro";
import { useState } from "react";

import { getAvailableFunds } from "../../../services/investor.service";
import { getTypesList } from "../../../services/types.service";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { FormProps } from "../../../utils/types/form.type";
import { FundItem } from "../../../utils/types/fund.type";
import { Fund,FundInvestorType } from "../../../utils/types/investor.type";
import { DataGridColDef, ListItem } from "../../../utils/types/listItems";

type GridFunditem = FundItem & { investorTypeId: string };
  

export const useFundListEffect = (
    isReadOnly: boolean,
    form: FormProps
  ) => {

  const [fundList, setFundList] = useState<FundItem[]>([]);
  const { watch } = form;
  const investorFunds: Fund[] = watch('funds');

  useEffectAsync(
    async (isCanceled) => {
      let fundList: FundItem[];

      if(isReadOnly) {
        fundList = investorFunds.map(f => {
          return {
            id: f.id,
            name: f.name
          } as FundItem;
        });
      } else {
        fundList = await getAvailableFunds();
      }

      if (isCanceled()) return;

      setFundList([...fundList]);
    },
    []
  );
  return { fundList };
};


export const useUpdateFundListEffect = (
  fundList: FundItem[] = [],
  form: FormProps,
  isReadOnly: boolean
) => {
  const [gridFundList, setGridFundList] = useState<GridFunditem[]>([]);
  const [investorTypeList, setInvestorTypesList] = useState<ListItem[]>([]);

  const { setValue, watch } = form;

  const investorFunds: Fund[] = watch('funds');
  const investorFundIds = investorFunds?.map(i => i.id);
  const fundInvestorTypes: FundInvestorType[] = watch('fundInvestorTypes');
  

  useEffectAsync(async(isCanceled) => {

    if(!fundList?.length || !fundInvestorTypes?.length) return;

    const typesResponse = await getTypesList();

    if(isCanceled()) return;

    setInvestorTypesList(typesResponse);
 

    const tempGridFundList = fundList.map(fund => {
      const fundType = fundInvestorTypes.find(t => t.fundId === fund.id);

      return {
        ...fund,
        isChecked: investorFunds ? investorFundIds.includes(fund.id) : false,
        investorTypeId: fundType ? fundType.investorType.id : ''
      };
    });

    setGridFundList(tempGridFundList);
  }, [fundList]);

  const [searchVal, setSearchVal] = useState<string>("");

  const handleOnSearchChange = ({ target }: any) => {
    const { value } = target;

    setSearchVal(value);
  };


  const onFundCheck = (selectionModel: GridSelectionModel, _details: GridCallbackDetails) => {
  
    gridFundList.forEach(gf=>gf.isChecked=false);

    const tempFunds: Fund[] = [];
    const tempFundInvestorTypes: FundInvestorType[] = [];

    for (const id of Object.values(selectionModel)) {
      const gridFundItem = gridFundList.find(gf => gf.id === id)!;

      gridFundItem.isChecked = true;


      const newFund = Object({
        id: gridFundItem.id,
        name: gridFundItem.name,
        isChecked: gridFundItem.isChecked,
      });

      tempFunds.push(newFund);


      let newType: FundInvestorType; 
      const existingType = fundInvestorTypes.find(t => t.fundId === id);

      if(existingType) {
        newType = existingType;
      } else {
          newType = {
          fundId: gridFundItem.id,
          investorType: {
            id: '',
            label: '' 
          }
        };
      }

      tempFundInvestorTypes.push(newType);
    }

    gridFundList.filter(gf => !gf.isChecked).forEach(gf=> gf.investorTypeId = '');
  
    setGridFundList([...gridFundList]);
    setValue('funds', [...tempFunds]);
    setValue('fundInvestorTypes', tempFundInvestorTypes);
};




  const gridHeaderList:DataGridColDef[] = 
  [
    {
      field: "name",
      headerName: "Fund",
      hide: false,
      index: 1,
      type: "string",
      sortable: false,
      align: "left",
      width: 80,
      disableReorder: true
    },
    {
      field: "investorTypeId",
      headerName: "Investor Type",
      hide: false,
      index: 2,
      type: "string",
      sortable: false,
      align: "left",
      width: 250,
      disableReorder: true,
      renderCell: dataGridInvestorTypeSelect
    }
  ];

  function dataGridInvestorTypeSelect(params: GridRenderCellParams<any, any, any>) {
    const gridRow = params.row as GridFunditem;

    if (!gridRow.isChecked) {
        return '';
    }

    if(isReadOnly) {
      if(!investorTypeList) return ''; 

      const type = investorTypeList.find(t => t.id === gridRow.investorTypeId);

      return type?.label;
    }

    return (
        <FormControl sx={{
            minWidth: '100%'
        }}>
          <Select
              id={`investor_row_${gridRow.id}`}
              labelId={`investor_row_${gridRow.id}_label`}
              label="Investor"
              value={gridRow.investorTypeId}
              onChange={e => handleRowTypeChange(gridRow, e)}
          >
            {
              investorTypeList.map(t => {
                return (
                  <MenuItem 
                      sx={ { minWidth: '100%' } } 
                      key={ t.id } 
                      value={ t.id }
                  >
                      {t.label}
                  </MenuItem>
                );
              })
            }
          </Select>

        </FormControl>
    );

    function handleRowTypeChange(gridRow: GridFunditem, e: SelectChangeEvent<string>) {
        const typeId = e.target.value;
        const updates = updateGridRowItem(gridRow, typeId);

        setGridFundList([...updates.updatedGridFundList]);
        setValue('fundInvestorTypes', updates.updatedFundInvestorTypes);
    }

  }

  function handleEnabledInvestorTypId(e: SelectChangeEvent<string>) {
    const typeId = e.target.value;
    let updates: any;

    gridFundList.filter(gridRow => gridRow.isChecked).forEach(gridRow => {
      updates = updateGridRowItem(gridRow, typeId);      
    });

    
    setGridFundList([...updates.updatedGridFundList]);
    setValue('fundInvestorTypes', updates.updatedFundInvestorTypes);
  }


  function updateGridRowItem(gridRow: GridFunditem, typeId: string) {
    gridRow.investorTypeId = typeId;

    const fundInvestorType = fundInvestorTypes.find(t => t.fundId === gridRow.id);
    const investorType = investorTypeList.find(t => t.id === typeId)!;

    fundInvestorType!.investorType = {
      id: typeId,
      label: investorType.label
    };

    return {
      updatedGridFundList: gridFundList,
      updatedFundInvestorTypes: fundInvestorTypes
    };
  }




  function getGridFundList() {
    if(searchVal) {
      const sv = searchVal.toLowerCase();
      const filteredList = gridFundList.filter(item =>
        item.name.toLowerCase().includes(sv)
      );

      return filteredList;
    } else {
      return gridFundList;
    }
  }


  return {
    gridHeaderList,
    getGridFundList,
    investorTypeList,
    handleEnabledInvestorTypId,
    searchVal,
    handleOnSearchChange,
    onFundCheck
  };
};