import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

import IconDelete from "../../../assets/images/icons/icon_delete.svg";
import ExportIcon from "../../../assets/images/icons/icon_export.svg";
import { DataWrapperBox } from "../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import SplitButton from "../../../components/Buttons/SplitButton/SplitButton";
import ButtonWithOptions from "../../../components/ButtonWithOptions/ButtonWithOptions";
import DataGrid from "../../../components/DataGrid/DataGrid";
import LockConfirmationDialog from "../../../components/LockConfirmationDialog/LockConfirmationDailog";
import ConfirmationDialog from "../../../components/Modal/ConfirmationDialog";
import DeleteConfirmationDialog from "../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog";
import ProgressModal from "../../../components/Progress/ProgressModal/ProgressModal";
import SearchBar from "../../../components/SearchBar/SearchBar";
import RoutingPaths from "../../../core/routing/routingPaths";
import { ImageItem } from "../../../utils/types/listItems";
import { IconBox } from "../fundList/FundList.styles";
import { PortfolioCompanyDetails } from "../portfolioCompanies/portfolioCompanyDetails/portfolioCompanyDetails";
import { ReadOnlyPortfolioCompanyDetails } from "../portfolioCompanies/portfolioCompanyDetails/ReadOnlyPortfolioCompanyDetails";
import { InvestmentTransactionDetails } from "./investmentTransactionDetails/investmentTransactionDetails";
import { ReadOnlyInvestmentTransactionDetails } from "./investmentTransactionDetails/ReadOnlyInvestmentTransactionDetails";
import { NewInvestmentTransactionOptions } from "./InvestmentTransactionList.constants";
import {
  useBulkActionOptionSelectionEffect,
  useInvestmentTransactionList,
} from "./InvestmentTransactionList.hooks";
import { ButtonBox, HeaderRow } from "./InvestmentTransactionList.styles";

export const InvestmentTransactionList: React.FC = () => {
  const history = useHistory();

  const {
    headerList,
    activeHeaderFields,
    investmentTransactionFilteredList,
    investmentTransactionSelectionModel,
    selectedInvestmentTransaction,
    readonly,
    clearUploadedFile,
    clearUploadCompleted,
    isUploadComplete,
    setinvestmentTransactionSelectionModel,
    setSelectedInvestmentTransaction,
    isLoading,
    fetchAllInvestmentTransactions,
    handleNewButtonAction,
    handleOnView,
    handleFilter,
    handleSearch,
    handleUpdateHeader,
    onColumnOrderChange,
    handleUploadTemplate,
    onInvestmentTransactionPanelClose,
    search,
    searchOptions,
    showSuggestionPopover,
    setShowSuggestionPopover,
    handleBulkOptionClick,
    lockedPrompt,
    showLockedDialog,
    client,
    setLockedPrompt,
    uploadedFile,
    showDeleteSelConfirmationBox,
    handleCloseDeleteSelConfirmationBox,
  } = useInvestmentTransactionList();

  const defaultBulkActions: ImageItem[] = [
    {
      id: "export",
      text: "Export",
      icon: <img src={ExportIcon} alt="" height="15" />,
      optionsSelected: 0,
    },
    {
      id: "delete-selected",
      text: "Delete Selected",
      icon: <img src={IconDelete} alt="Delete Selected" height="15" />,
      optionsSelected: 0,
    },
  ];

  const { bulkActionOptions } = useBulkActionOptionSelectionEffect(
    defaultBulkActions,
    investmentTransactionSelectionModel
  );

  const selRecordsCount = investmentTransactionSelectionModel
    ? investmentTransactionSelectionModel.length
    : 0;

  return (
    <DataWrapperBox id="investment_transaction_list_main" role="main">
      <ProgressModal
        id="modal_investment_transaction_list_loading"
        showProgress={isLoading}
      />
      <HeaderRow>
        <IconBox>
          <Typography variant="pageTitle">Investment Transactions</Typography>
          {client?.soiLocked && (
            <IconButton aria-label="locked" onClick={showLockedDialog}>
              <LockIcon />
            </IconButton>
          )}
        </IconBox>
        <SearchBar
          id="investment_transaction_list_search"
          size="small"
          onChange={handleSearch}
          options={searchOptions}
          searchText={search}
          showPopover={showSuggestionPopover}
          setShowPopover={setShowSuggestionPopover}
        />
        <ButtonBox>
          <SplitButton
            id={"btn_bulk_action_options"}
            options={bulkActionOptions}
            hidden={investmentTransactionSelectionModel.length === 0}
            handleOptionClick={handleBulkOptionClick}
            ariaLabelMessage="Select bulk action option"
          />

          {!readonly && (
            <ButtonWithOptions
              buttonID="btn_add_investment_transaction"
              popoverID="popover_add_investment_transaction"
              onClick={handleNewButtonAction}
              buttonLabel="Add New"
              buttonIcon={<AddIcon />}
              options={NewInvestmentTransactionOptions}
            />
          )}
        </ButtonBox>
      </HeaderRow>
      <DataGrid
        id={"investment_transaction_list_data_grid"}
        dataList={
          !isLoading && investmentTransactionFilteredList?.length > 0
            ? investmentTransactionFilteredList
            : []
        }
        minHeight="1px"
        autoHeight={false}
        headerList={headerList}
        handleOnView={handleOnView}
        selectionModel={investmentTransactionSelectionModel}
        activeHeaderFields={activeHeaderFields}
        setSelectionModel={setinvestmentTransactionSelectionModel}
        handleUpdateHeader={handleUpdateHeader}
        onColumnOrderChange={onColumnOrderChange}
        handleFilter={handleFilter}
      />
      {!!selectedInvestmentTransaction?.type &&
        (readonly ? (
          <ReadOnlyInvestmentTransactionDetails
            selectedInvestmentTransaction={selectedInvestmentTransaction}
            onDetailClose={onInvestmentTransactionPanelClose}
            fetchAllInvestmentTransactions={fetchAllInvestmentTransactions}
            setSelectedInvestmentTransaction={setSelectedInvestmentTransaction}
          />
        ) : (
          <InvestmentTransactionDetails
            selectedInvestmentTransaction={selectedInvestmentTransaction}
            onDetailClose={onInvestmentTransactionPanelClose}
            fetchAllInvestmentTransactions={fetchAllInvestmentTransactions}
            setSelectedInvestmentTransaction={setSelectedInvestmentTransaction}
          />
        ))}
      <ConfirmationDialog
        open={Boolean(uploadedFile)}
        onClose={clearUploadedFile}
        id="confirmation_upload"
        actions={[
          {
            label: "Upload",
            onClick: handleUploadTemplate,
            id: "btn_upload",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: clearUploadedFile,
            id: "btn_cancel",
            variant: "outlined",
            color: "error",
          },
        ]}
        content={
          <Box component="span">
            Are you sure you want to upload{" "}
            <strong>{uploadedFile?.name}</strong>?
          </Box>
        }
        title="Confirm Upload"
      />
      <ConfirmationDialog
        open={isUploadComplete}
        onClose={clearUploadedFile}
        id="confirmation_upload_complete"
        actions={[
          {
            label: "Upload History",
            onClick: () => {
              clearUploadCompleted();
              history.push(RoutingPaths.UploadHistory);
            },
            id: "btn_upload_history",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Close",
            onClick: clearUploadCompleted,
            id: "btn_close",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Investment transaction file uploaded successfully. The file is being processed. Large files may take a while to finish; Please check the Upload History page to check the status of the upload."
        title="Upload Results"
      />
      <LockConfirmationDialog
        lockedPrompt={lockedPrompt}
        setLockedPrompt={setLockedPrompt}
      />

      <DeleteConfirmationDialog
        open={showDeleteSelConfirmationBox}
        id="confirmation_delete_selection"
        primaryButtonAction={() => handleCloseDeleteSelConfirmationBox(false)}
        secondaryButtonAction={() => handleCloseDeleteSelConfirmationBox(true)}
        content={
          <>
            This action is permanent and cannot be undone, It is recommended
            that you export and save these records first. <br />
            <br />
            <b>
              {selRecordsCount} Purchase Record
              {selRecordsCount === 1 ? null : "s"} and all of their follow-on
              transactions will be permanently deleted{" "}
            </b>
            <br />
          </>
        }
      />
    </DataWrapperBox>
  );
};
