import { Box, styled } from "@mui/material";

export const HeaderRow = styled(Box)(({
  theme
}) => ({
  display: "flex",
  gridTemplateColumns: "15% 61% 23%",
  gap: theme.spacing(1),
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  '& #btn_publish_quarters': {
    marginRight: theme.spacing(1)
  }
}));

export const CellBox = styled(Box)(() => ({
  width: '100%',
}));