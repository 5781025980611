import { CardActions, Divider, Grid, Tooltip, Typography } from '@mui/material';
import { RouteProps } from 'react-router-dom';

import { M_DASH_UNICODE } from '../../../../utils/constants/constants';
import { FormatIntegerWithCurrencyOffset } from '../../../../utils/helpers/format.helper';
import {
  BankAccount,
  BankFeedView,
  BankSummary,
  BankTypeOptions,
} from '../../../../utils/types/bank.type';
import { BankFeedViewOptions } from '../../bankFeeds/BankFeedList.defaults';
import {
  BankCardsListGrid,
  BankCardsListItem,
  BanksCardContent,
  BankSummaryCard,
  DetailsButtonBase,
  IndividualBankCard,
  IndividualBankCardsListGrid,
  SummaryCardHeaderBox,
  SummaryCardValueLabel,
} from './BankCards.styles';

interface Props extends RouteProps {
  aggregateAccountsList: BankSummary[];
  handleAggregateDetailsClick: (bankIds: string[]) => void;
  viewState: BankFeedView;
  handleOnBankAccountView: (bankAccount: BankAccount) => void;
}

export const BankCards: React.FC<Props> = ({
  aggregateAccountsList,
  handleAggregateDetailsClick,
  viewState,
  handleOnBankAccountView,
}: Props) => {
  const currencyFormatOrDash = (value: number | null, currency: string) => {
    return value === null
      ? M_DASH_UNICODE
      : FormatIntegerWithCurrencyOffset(value, currency);
  };

  const getSummaryCardValues = (aggregate: BankSummary) => {
    switch (aggregate.type) {
      case BankTypeOptions.Depository:
        return {
          title: 'Accounts',
          balanceLabel: 'Total Cash',
          balanceSum: currencyFormatOrDash(
            aggregate.balanceSum,
            aggregate.currencyCode
          ),
          footer: `Total Accounts: ${aggregate.accounts.length}`,
        };
      case BankTypeOptions.Credit:
        return {
          title: 'Line of Credit',
          balanceLabel: 'Line of Credit',
          balanceSum: currencyFormatOrDash(
            aggregate.balanceSum,
            aggregate.currencyCode
          ),
          footer: `Available Credit: ${currencyFormatOrDash(
            aggregate.availableCreditSum,
            aggregate.currencyCode
          )} of ${currencyFormatOrDash(
            aggregate.lineOfCreditSum,
            aggregate.currencyCode
          )}`,
        };
      default:
        return {
          title: 'Other Bank Accounts',
          balanceLabel: 'Line of Credit-Current Balance',
          balanceSum: currencyFormatOrDash(
            aggregate.balanceSum,
            aggregate.currencyCode
          ),
          footer: `Total Accounts: ${aggregate.accounts.length}`,
        };
    }
  };

  return (
    <BankCardsListGrid id={'bank_cards_list_grid_container'} container>
      {aggregateAccountsList.map((acctGroup, index) => {
        const { title, balanceLabel, balanceSum, footer } =
          getSummaryCardValues(acctGroup);

        return (
          <BankCardsListItem
            id="bank_cards_list_grid_item"
            key={index}
            item
            viewState={viewState}
          >
            <BankSummaryCard type={acctGroup.type}>
              <BanksCardContent>
                <SummaryCardHeaderBox>
                  <Typography variant="h6">{title}</Typography>
                  <Tooltip title="View transactions" enterDelay={1000}>
                    <DetailsButtonBase
                      disableRipple
                      onClick={() => {
                        handleAggregateDetailsClick(
                          acctGroup.accounts.map((acc) => acc.id)
                        );
                      }}
                    >
                      Details
                    </DetailsButtonBase>
                  </Tooltip>
                </SummaryCardHeaderBox>
                <SummaryCardValueLabel>{balanceLabel}</SummaryCardValueLabel>
                <Typography variant="h4">
                  <strong>{balanceSum}</strong>
                </Typography>
              </BanksCardContent>
              <Divider />
              <CardActions>
                <Typography>
                  <small>{footer}</small>
                </Typography>
              </CardActions>
            </BankSummaryCard>

            {viewState === BankFeedViewOptions.DASHBOARD && (
              <IndividualBankCardsListGrid container>
                {acctGroup.accounts.map((acct, index) => (
                  <Grid key={index} item>
                    <IndividualBankCard key={index} type={acctGroup.type}>
                      <BanksCardContent>
                        <DetailsButtonBase
                          onClick={() => handleOnBankAccountView(acct)}
                        >
                          {acct.name}
                        </DetailsButtonBase>
                        <Typography variant="body2">{`Fund: ${acct.fundName}`}</Typography>
                        <Typography variant="body1">
                          <strong>
                            {FormatIntegerWithCurrencyOffset(
                              acct.balanceTotal,
                              acct.currencyCode
                            )}
                          </strong>
                        </Typography>
                      </BanksCardContent>
                    </IndividualBankCard>
                  </Grid>
                ))}
              </IndividualBankCardsListGrid>
            )}
          </BankCardsListItem>
        );
      })}
    </BankCardsListGrid>
  );
};
