import { DateRange } from "@mui/x-date-pickers-pro";
import { cloneDeep } from "lodash";

import { CategoryFieldDef, NumberFormatTypes, ReportGridBaseData, ValueFieldDef } from "../../../../components/ReportGrid/ReportGrid.types";
import { getReportTrialBalanceDetails } from "../../../../services/arkGL.service";
import { DateTimeFormat } from "../../../../utils/helpers/format.helper";
import { getCurrentQuarter } from "../../../../utils/helpers/getCurrentQuarter";
import { getQuarterStartEndDateByDate } from "../../../../utils/helpers/quarter.helper";
import { awaitReactUpdate } from "../../../../utils/helpers/timeoutFunctions";
import { ColumnOrder } from "../../../../utils/types/columnOrder";

export const FSDisplayNotSet = '(FS Display Not set)';

export const DebitRange = 'Debit_Range';
export const CreditRange = 'Credit_Range';
export const StartBalanceRange = 'Start_Balance_Range';
export const EndBalanceRange = 'End_Balance_Range';
export const AccountNumber = 'Account_Number';

export const netInvestmentIncomeCatStr = 'ZZ1|Net investment income/(loss)';
export const netRealizedCatStr = 'ZZ2|Net realized gain/(loss) on investments';
export const netUnrealizedCatStr = 'ZZ3|Net unrealized gain/(loss) on investments';
export const netIncomeTotalCatStr = 'ZZ4|Net income/(loss) total';
export const retainedInvestmentIncomeCatStr = 'ZZ5|Retained earnings: net investment income/(loss)';
export const retainedRealizedCatStr = 'ZZ6|Retained earnings: net realized gain/(loss) on investments';
export const retainedUnrealizedCatStr = 'ZZ7|Retained earnings: net unrealized gain/(loss) on investments';
export const retainedEarningsTotalCatStr = 'ZZ8|Retained earnings total';


export type AccountHierarchyItem = {
  accountId: string,
  accountName: string,
  number: string,
  attributeId: string,
  parentAccountId: string,
  parentAccounts: Account[]
};

export type Account = {
  id: string,
  name: string,
  number: string,
  parentId: string,
  fundId: string,
  attributeId: string,
  fsDisplayName: string,
  fsMappingId: string
};


export type ReportDetailsParams = {
  fundIds: string[],
  ledgerIds: string[],
  accountIds: string[],
  attributeIds: string[],
  journalEntryStates: GlStatuses[],
  dateColInfos: DateColumnInfo,
  currency: string,
  decimals: number,
  fsName?: string,
  summaryRow?: boolean,
  isCustom?: boolean,
  level1Clicked?: boolean,
  dateRange: DateRange<Date>
}

export type GridCallerData = {
  reportAccounts: ReportAccount[]
};

export type AccountCategoryProps = {
  attributeName: string,
  attributeId?: string,
  account_id_1?: string,
  account_id_2?: string, 
  account_id_3?: string,
  account_id_4?: string,
  account_id_5?: string,
  account_id_6?: string,
  account_name_1?: string,
  account_name_2?: string,
  account_name_3?: string,
  account_name_4?: string,
  account_name_5?: string,
  account_name_6?: string,
  fsDisplay_name_1?: string,
}

export type FSDisplayCategoryProps = {
  attributeName: string,
  attributeId?: string,
  fsDisplay_id_1?: string,
  fsDisplay_id_2?: string, 
  fsDisplay_id_3?: string,
  fsDisplay_id_4?: string,
  fsDisplay_id_5?: string,
  fsDisplay_id_6?: string,
  fsDisplay_name_1?: string,
  fsDisplay_name_2?: string,
  fsDisplay_name_3?: string,
  fsDisplay_name_4?: string,
  fsDisplay_name_5?: string,
  fsDisplay_name_6?: string,
  account_name_1?: string,

}

export type ReportAccount = {
  accountId: string,
  accountName: string,
  accountNo: string,
  fundId: string,
  attributeId: string,
  fsMappingId: string,
  fsName: string,
  state: string,
  startBalance: number,
  endBalance: number,
  totalAmount: number,
  lineItems: ReportLineItem[],
  parentAccountId: string,
  childAccounts: ReportAccount[],
  decimals: number,
  categoryProps?: AccountCategoryProps | FSDisplayCategoryProps
}

export type ReportLineItem = {
  amount: number,
  date: Date,
  ledgerId: string,
  ledgerName: string,
  journalEntryNum: number,
  adjustingJournalEntry: boolean,
  memo: string,
  type?: string,
  state?: string,
  subtract?: boolean,
};

export enum ColumnTypes {
  Monthly = 'Months',
  Quarterly = 'Quarterly',
  Yearly = 'Yearly'
}

export type DateColumnInfo = {
  startDate: Date,
  endDate: Date,
  name: string,
  title: string,
  visible: boolean,
  useLinkButton: boolean,
  numberFormat?: NumberFormatTypes,
  category?: ColumnTypes,
  hideZero?: boolean,
};

export enum GlStatuses {
  DRAFT = 'Draft',
  POSTED= 'Posted'
}

export enum ViewKeyCode {
  START_BALANCE = 'startBalance',
  DEBIT = 'debit',
  CREDIT = 'credit',
  END_BALANCE = "endBalance",
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly',
  ITD = 'itd',
  SELECTED = 'selected'

}

export type TrialBalanceDetailsParams = {
  startDate: Date, 
  endDate: Date, 
  accounts: string[],
  ledgerIDs: string[], 
  glStatus: GlStatuses[],
  attributes: string[],
  fsName?: string,
  summaryRow?: boolean,
  isCustom?: boolean,
}

export const viewKeyOrderDefaults = ['startBalance', 'monthly', 'quarterly', 'yearly', 'selected', 'credit', 'debit', 'itd', 'endBalance'];
let lineItemDates: any = [];


// Used by both list and detail views to flatten response data and provide a shared library of helper functions
// preview params functionality may no longer be necessary in the way it's implemented with pending re-write and ITD calls
export async function getTrialBalanceDetailsData(
  params: any, 
  isCanceled: () => boolean,
  prevParamsJson?: string, 
  prevRunReportAccounts?: ReportAccount[],
  prevDecimals?: number, 
) {
  const apiParams = {
    ledgerIds: params.ledgerIDs,
    startDate: params.startDate ? DateTimeFormat.getReversedDate(params.startDate) : "",
    endDate: params.endDate ? DateTimeFormat.getReversedDate(params.endDate) : DateTimeFormat.getReversedDate(new Date()),
  } as any;

  const journalEntryState = params.glStatus?.length === 1 ? params.glStatus[0] : null;

  if(journalEntryState) {
    apiParams.journalEntryState = journalEntryState;
  }

  let paramsJson = JSON.stringify(apiParams);
  let reportAccounts: ReportAccount[];
  let decimals: number;  
  let currency: string = "";

  if(!prevParamsJson || !prevRunReportAccounts?.length || prevParamsJson !== paramsJson) {

    try{
      const reportResponseBase = await getReportTrialBalanceDetails(apiParams);

      if(isCanceled()) return;
    
      decimals = reportResponseBase.decimals;
      currency = reportResponseBase.currency;
      const decimalFraction = Math.pow(10, decimals);

      reportAccounts = getReportAccountsDenormalized(reportResponseBase.accounts, decimalFraction);
      reportAccounts.filter(a => !a.fsName).forEach(a => a.fsName = FSDisplayNotSet);
    } catch {
        reportAccounts = [];
        decimals = prevDecimals!;
        paramsJson = prevParamsJson!;      
    }
  } else {
      reportAccounts = prevRunReportAccounts!;
      decimals = prevDecimals!;
      paramsJson = prevParamsJson!;
  }

  let reportAccountsFiltered = reportAccounts.filter(ra => params.attributes.find((sa: any) => sa === ra.attributeId));

  reportAccountsFiltered = reportAccountsFiltered.filter(ra => params.accounts.find((sa: any) => sa === ra.accountId));

  return {
    reportAccounts,
    reportAccountsFiltered,
    decimals,
    currency,
    paramsJson
  };
}

// Used by both list and detail views to flatten response data and provide a shared library of helper functions
// preview params functionality may no longer be necessary in the way it's implemented with pending re-write and ITD calls
export async function getTrialBalanceDetailsDataNew(
  params: TrialBalanceDetailsParams, 
  isCanceled: () => boolean
) {
  const apiParams = {
    ledgerIds: params.ledgerIDs,
    startDate: "",
    endDate: DateTimeFormat.getReversedDate(params.endDate)
  } as any;

  const journalEntryState = params.glStatus?.length === 1 ? params.glStatus[0] : null;

  if(journalEntryState) {
    apiParams.journalEntryState = journalEntryState;
  }

  let reportAccounts: ReportAccount[] = [];
  let decimals = 0;  
  let currency = '';
  let firstLineItemDate = new Date();

  try{
    const reportResponseBase = await getReportTrialBalanceDetails(apiParams);

    if(isCanceled()) return;
  
    decimals = reportResponseBase.decimals;
    currency = reportResponseBase.currency;

    const decimalFraction = Math.pow(10, decimals);
    const startLineItemDateRange = new Date(0);
    const endLineItemDateRange = params.startDate ? DateTimeFormat.subtractDate(params.startDate, 1) : undefined;

    lineItemDates = [];
    const returnObj = getReportAccountsDenormalizedNew(reportResponseBase.accounts, decimalFraction, startLineItemDateRange, endLineItemDateRange);

    reportAccounts = returnObj.denormalizedAccounts
      .filter(ra => params.attributes.find((sa: any) => sa === ra.attributeId) && params.accounts.find((sa: any) => sa === ra.accountId));

    reportAccounts.filter(a => !a.fsName).forEach(a => a.fsName = FSDisplayNotSet);

    firstLineItemDate = DateTimeFormat.subtractDate(returnObj.firstLineItemDate, 1);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }

  return {
    reportAccounts,
    firstLineItemDate,
    decimals,
    currency
  };
}

function getReportAccountsDenormalized(reportAccounts: ReportAccount[], decimals: number): ReportAccount[] {
  let tempReportAccounts: ReportAccount[] = [];

  for(let i=0; i<reportAccounts.length; i++) {
    const account = reportAccounts[i];

    tempReportAccounts.push(account);

    if(account.childAccounts?.length) {
      const childAccounts = getReportAccountsDenormalized(account.childAccounts, decimals);

      tempReportAccounts = tempReportAccounts.concat(childAccounts);
    }

    // all values come in as whole integer numbers with no decimal.  
    // this will convert integer to appropriate decimal value
    account.startBalance/=decimals;
    account.endBalance/=decimals;  
    account.lineItems.forEach(li => {
      li.amount/=decimals;
    });
  }


  return tempReportAccounts;
}

function getReportAccountsDenormalizedNew(reportAccounts: ReportAccount[], decimals: number, startLineItemDateRange: Date, endLineItemDateRange?: Date) {
  let denormalizedAccounts: ReportAccount[] = [];
  let firstLineItemDate = new Date();

  for(let i=0; i<reportAccounts.length; i++) {
    const account = reportAccounts[i];

    denormalizedAccounts.push(account);

    if(account.childAccounts?.length) {
      const childAccounts = getReportAccountsDenormalizedNew(account.childAccounts, decimals, startLineItemDateRange, endLineItemDateRange);

      denormalizedAccounts = denormalizedAccounts.concat(childAccounts.denormalizedAccounts);
    }

    // all values come in as whole integer numbers with no decimal.  
    // this will convert integer to appropriate decimal value
    account.endBalance/=decimals;  
    account.lineItems.forEach(li => { li.amount/=decimals; });

    if(endLineItemDateRange) {
      const itdLineItems = account.lineItems.filter(li => DateTimeFormat.isDateBetween(li.date, startLineItemDateRange, endLineItemDateRange));

      account.startBalance = itdLineItems.reduce((prevLi, currValue) => currValue.amount + prevLi, 0);
    } else {
      account.startBalance = 0;
    }

    const firstItemDate = account.lineItems.reduce(
      (prevDate, currValue) => DateTimeFormat.compareDateOnly(prevDate, currValue.date) === -1 ? prevDate : DateTimeFormat.getDateOnly(currValue.date), firstLineItemDate);

    if(DateTimeFormat.compareDateOnly(firstItemDate, firstLineItemDate) === -1) {
      firstLineItemDate = firstItemDate;
      lineItemDates.push(firstLineItemDate);
    }
  }

  const earliestDate: Date = new Date(Math.min(...lineItemDates));

  firstLineItemDate = earliestDate;

  return {
    denormalizedAccounts,
    firstLineItemDate
  };
}

// Line items response data have been updated to include property "type"
export function getLineItemsForDateCol(lineItems: ReportLineItem[], dc: DateColumnInfo) {
  let lineItemsInRange = lineItems.filter(li => DateTimeFormat.isDateBetween(li.date, dc.startDate, dc.endDate));

  switch (dc.name) {
    case DebitRange:
      lineItemsInRange = lineItemsInRange.filter(li => li.type === 'DEBIT');
      break;
    case CreditRange:
      lineItemsInRange = lineItemsInRange.filter(li => li.type === 'CREDIT');
      break;
  }

  return lineItemsInRange;
}

// Helper function to assure custom views include all related accounts based on fsName given
export function getAllChildLineItemsWithSameFsName(parentAccount: ReportAccount, fsName: string) {
  let lineItems = parentAccount.fsName === fsName ? parentAccount.lineItems : [];

  parentAccount.childAccounts.forEach(account => {
    const tempLi = getAllChildLineItemsWithSameFsName(account, fsName);

    lineItems = lineItems.concat(tempLi);
  });

  return lineItems;
}

// Helper function to assure end balance is accurately collected with hierarchy
export function calculateEndBalance(account: any) {
  if (!account.childAccounts || account.childAccounts.length === 0) {
    return account.endBalance;
  }

  const childAccountsEndBalanceSum = account.childAccounts.reduce((acc: any, child: any) => {
    const childEndBalance = calculateEndBalance(child);

    return acc + childEndBalance;
  }, 0);

  const totalEndBalance = account.endBalance + childAccountsEndBalanceSum;

  return totalEndBalance;
}

// Helper function for detail views start balance
export function calculateBalance(account: ReportAccount, fsName?: string) {
  let tempSum = 0;
  
  if(fsName && account.fsName === fsName){
    tempSum += account.lineItems.reduce((prevLi, currValue) => currValue.amount + prevLi, 0);
  }

  account.childAccounts.forEach((child) => {
      const sum = calculateBalance(child, fsName);

      tempSum += sum;
  });

  return tempSum;
}

export function checkForMultiChildren(parentAccount: ReportAccount): boolean {
  if(parentAccount.childAccounts.length === 0) return false;

  if(parentAccount.childAccounts.length === 1){
    
    return checkForMultiChildren(parentAccount.childAccounts[0]);
  }

  return true;
}

export function findChildWithDifferentFsName (parentAccount: ReportAccount): boolean {
  if(parentAccount.childAccounts.length === 0) return false;

  for (let i = 0; i < parentAccount.childAccounts.length; i++){
    const account = parentAccount.childAccounts[i];

    if(account.fsName !== parentAccount.fsName) return true;

    const diff = findChildWithDifferentFsName(account);

    if(diff) return true;
  }

  return false;
}

export function suppressGridRowsIfZero(reportAccounts: ReportAccount[], checkForMultiChildren: boolean) {
  let acctWitNoChildrenIdx: number;

  do {
    if(!checkForMultiChildren) {
      acctWitNoChildrenIdx = reportAccounts.findIndex(acct => !acct.lineItems?.length);
    } else {
      acctWitNoChildrenIdx = reportAccounts.findIndex(acct => !acct.lineItems?.length && !acct.childAccounts?.length);
    }
    
    if(acctWitNoChildrenIdx >= 0) {        
      const acctWitNoChildren = reportAccounts[acctWitNoChildrenIdx];

      const parentAcct = reportAccounts.find(acct => acct.accountId === acctWitNoChildren!.parentAccountId);

      if(parentAcct) {
        const childIdx = parentAcct.childAccounts.findIndex(ca => ca.accountId === acctWitNoChildren!.accountId);

        parentAcct.childAccounts.splice(childIdx, 1);
      }

      reportAccounts.splice(acctWitNoChildrenIdx, 1);
    }
  } while(acctWitNoChildrenIdx >= 0);
}

export  function getFieldDefColumns(
  startDate: Date, 
  endDate: Date, 
  currencyFormat: NumberFormatTypes, 
  hideAllZeros: boolean,
  showStartBalance: boolean,
  showMonths: boolean,
  showQuarters: boolean,
  showYears: boolean,
  showSelected: boolean,
  showDebit: boolean,
  showCredit: boolean,
  showItd: boolean,
  showEndBalance: boolean,
  viewKey?: ColumnOrder,
  viewKeyOrder?: any,
  fsView?: boolean,
  view?: string,
  ) {

  const columnFunctionMap: any = {
    startBalance: () => getStartBalanceRange(startDate, showStartBalance, currencyFormat, hideAllZeros),
    monthly: () => getMonthsRange(startDate, endDate, showMonths, currencyFormat),
    quarterly: () => getQuartersRange(startDate, endDate, showQuarters, currencyFormat),
    yearly: () => getYearsRange(startDate, endDate, showYears, currencyFormat),
    selected: () => getUserSelectedRange(startDate, endDate, showSelected, currencyFormat),
    debit: () => getDebitRange(startDate, endDate, showDebit, currencyFormat),
    credit: () => getCreditRange(startDate, endDate, showCredit, currencyFormat),
    itd: () => getItdRange(endDate, showItd, currencyFormat, hideAllZeros),
    endBalance: () => getEndBalanceRange(endDate, showEndBalance, currencyFormat, hideAllZeros)
  };

  const sortedColumns = viewKeyOrder.map((order: any) => columnFunctionMap[order]);

  const viewColumns: DateColumnInfo[] = sortedColumns.reduce((acc: any, fn: any) => acc.concat(fn()), []);

  let allDateCols: DateColumnInfo[] = 
  getStartBalanceRange(startDate, showStartBalance, currencyFormat, hideAllZeros)
  .concat(getMonthsRange(startDate, endDate, showMonths, currencyFormat))
  .concat(getQuartersRange(startDate, endDate, showQuarters, currencyFormat))
  .concat(getYearsRange(startDate, endDate, showYears, currencyFormat))
  .concat(getUserSelectedRange(startDate, endDate, showSelected, currencyFormat))
  .concat(getDebitRange(startDate, endDate, showDebit, currencyFormat))
  .concat(getCreditRange(startDate, endDate, showCredit, currencyFormat))
  .concat(getItdRange(endDate, showItd, currencyFormat, hideAllZeros))
  .concat(getEndBalanceRange(endDate, showEndBalance, currencyFormat, hideAllZeros));
  
  let allFieldDefs = viewColumns.map((col, index) => {
    return {
      name: col.name,
      headerName: col.title,
      order: index+1,
      visible: col.visible,
      numberFormat: col.numberFormat,
      useLinkButton: col.useLinkButton,
      category: col.category,
      hideZero: col.hideZero
    } as ValueFieldDef;
  });

  if(view === 'trial_balance'){
    allDateCols = 
    getStartBalanceRange(startDate, showStartBalance, currencyFormat, hideAllZeros)
    .concat(getCreditRange(startDate, endDate, showCredit, currencyFormat))
    .concat(getDebitRange(startDate, endDate, showDebit, currencyFormat))
    .concat(getUserSelectedRange(startDate, endDate, showSelected, currencyFormat))
    .concat(getEndBalanceRange(endDate, showEndBalance, currencyFormat, hideAllZeros));

    allFieldDefs = allFieldDefs.filter((col) => 
      col.category !== 'Months' &&
      col.category !== 'Quarterly' &&
      col.category !== 'Yearly' &&
      !col.name.includes('itd')
      );
  }

  if(view === 'balance_sheet' || view === 'balance_sheet_fs_mapping' && !fsView){
    allDateCols = 
    getMonthsRange(startDate, endDate, showMonths, currencyFormat)
    .concat(getQuartersRange(startDate, endDate, showQuarters, currencyFormat))
    .concat(getYearsRange(startDate, endDate, showYears, currencyFormat))
    .concat(getItdRange(endDate, showItd, currencyFormat, hideAllZeros));

    allFieldDefs = allFieldDefs.filter((col) => 
      col.name !== StartBalanceRange &&
      col.name !== CreditRange &&
      col.name !== DebitRange &&
      col.name !== EndBalanceRange &&
      !col.name.includes('selected')
    );
  }

  if(fsView && view === 'balance_sheet_fs_mapping'){
    allDateCols = 
    getQuartersRange(startDate, endDate, showQuarters, currencyFormat)
    .concat(getYearsRange(startDate, endDate, showYears, currencyFormat))
    .concat(getUserSelectedRange(startDate, endDate, showSelected, currencyFormat))
    .concat(getItdRange(endDate, showItd, currencyFormat, hideAllZeros));

    allFieldDefs = allFieldDefs.filter((col) => 
      col.category !== 'Months' &&
      col.name !== StartBalanceRange &&
      col.name !== CreditRange &&
      col.name !== DebitRange &&
      col.name !== EndBalanceRange
    );
  }

  if(view === 'income_statement' || view === 'income_statement_fs_mapping' && !fsView){
    allDateCols = 
    getMonthsRange(startDate, endDate, showMonths, currencyFormat)
    .concat(getQuartersRange(startDate, endDate, showQuarters, currencyFormat))
    .concat(getYearsRange(startDate, endDate, showYears, currencyFormat))
    .concat(getItdRange(endDate, showItd, currencyFormat, hideAllZeros));

    allFieldDefs = allFieldDefs.filter((col) => 
      col.name !== StartBalanceRange &&
      col.name !== CreditRange &&
      col.name !== DebitRange &&
      col.name !== EndBalanceRange &&
      !col.name.includes('selected')
    );
  }

  if(fsView && view === 'income_statement_fs_mapping'){
    allDateCols = 
    getQuartersRange(startDate, endDate, showQuarters, currencyFormat)
    .concat(getYearsRange(startDate, endDate, showYears, currencyFormat))
    .concat(getItdRange(endDate, showItd, currencyFormat, hideAllZeros));

    allFieldDefs = allFieldDefs.filter((col) => 
      col.category !== 'Months' &&
      !col.name.includes('selected') &&
      col.name !== StartBalanceRange &&
      col.name !== CreditRange &&
      col.name !== DebitRange &&
      col.name !== EndBalanceRange
    );
  }

  return {
    fieldDefs: allFieldDefs,
    dateColInfos: allDateCols
  };
}

export function getEndBalanceRange(endDate: Date, visible: boolean, currencyFormat: NumberFormatTypes, hideAllZeros: boolean) : DateColumnInfo[] {
  const dateCol: DateColumnInfo = {
    startDate: new Date(0),
    endDate: endDate,
    name: EndBalanceRange,
    title: "End Balance",
    numberFormat: currencyFormat,
    visible: visible,
    useLinkButton: true,
    hideZero: hideAllZeros,
  };

  return [dateCol];
}

export function getStartBalanceRange(startDate: Date, visible: boolean, currencyFormat: NumberFormatTypes, hideAllZeros: boolean) : DateColumnInfo[] {
  const endDate = new Date(startDate);

  endDate.setTime(endDate.getTime() - 24*60*60*1000); // subtract a day

  const dateCol: DateColumnInfo = {
    startDate: new Date(0),
    endDate: endDate,
    name: StartBalanceRange,
    title: "Start Balance",
    numberFormat: currencyFormat,
    visible: visible,
    useLinkButton: true,
    hideZero: hideAllZeros,
  };

  return [dateCol];
}

export function getMonthsRange(startDate: Date, endDate: Date, visible: boolean, currencyFormat: NumberFormatTypes) : DateColumnInfo[] {
  const endDateAdjusted = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
  const monthlyCols: DateColumnInfo[] = [];

  let currDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);

  while(currDate <= endDateAdjusted) {
    const nextMonth = new Date(currDate.getFullYear(), currDate.getMonth()+1, 1); 
    const lastDayCurrMonth = new Date(nextMonth.getTime()-24*60*60*1000); // subtract a day

    const dateCol: DateColumnInfo = {
      startDate: currDate,
      endDate: lastDayCurrMonth,
      name: `monthly_${currDate.getFullYear()}_${currDate.getMonth()+1}`,
      title: `Month ${DateTimeFormat.getMonthAndYearAsString(currDate)}`,
      numberFormat: currencyFormat,
      visible: visible,
      useLinkButton: true,
      category: ColumnTypes.Monthly,
      hideZero: true,
    };

    monthlyCols.push(dateCol);

    currDate = nextMonth;
  }

  return monthlyCols;
}

export function getQuartersRange(startDate: Date, endDate: Date, visible: boolean, currencyFormat: NumberFormatTypes) : DateColumnInfo[] {
  const startQuarterRange = getQuarterStartEndDateByDate(startDate);
  const endQuarterRange = getQuarterStartEndDateByDate(endDate);

  const quarterlyCols: DateColumnInfo[] = [];
  let currQuarter = startQuarterRange;

  while(currQuarter.startDate <= endQuarterRange.startDate) {
    const quarterStr = getCurrentQuarter(currQuarter.startDate).quarterWithYear;
    const title = 
      `${DateTimeFormat.getMonthString(currQuarter.startDate)}-${`${DateTimeFormat.getMonthAndYearAsString(currQuarter.endDate)}`}`;

    const dateCol: DateColumnInfo = {
      startDate: currQuarter.startDate,
      endDate: currQuarter.endDate,
      name: `quarterly_${quarterStr.replace(' ', '_')}`,
      title: `Quarter ${title}`,
      numberFormat: currencyFormat,
      visible: visible,
      useLinkButton: true,
      category: ColumnTypes.Quarterly,
      hideZero: true,
    };

    quarterlyCols.push(dateCol);
    
    const currEndDate = currQuarter.endDate;
    const nextDate = new Date(currEndDate.getFullYear(), currEndDate.getMonth()+1, 1); 

    currQuarter = getQuarterStartEndDateByDate(nextDate);
  }

  return quarterlyCols;
}

export function getYearsRange(startDate: Date, endDate: Date, visible: boolean, currencyFormat: NumberFormatTypes) : DateColumnInfo[] {
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const yearlyCols: DateColumnInfo[] = [];

  for(let y = startYear; y<= endYear; y++) {
    const dateCol: DateColumnInfo = {
      startDate: new Date(y, 0, 1),
      endDate: new Date(y, 11, 31),
      name: `yearly${y}`,
      title: `Year ${y.toString()}`,
      numberFormat: currencyFormat,
      visible: visible,
      useLinkButton: true,
      category: ColumnTypes.Yearly,
      hideZero: true,
    };

    yearlyCols.push(dateCol);
  }

  return yearlyCols;
}

export function getUserSelectedRange(startDate: Date, endDate: Date, visible: boolean, currencyFormat: NumberFormatTypes) : DateColumnInfo[] {
  const startDateStr = DateTimeFormat.shortDateString(startDate.toISOString());
  const endDateStr = DateTimeFormat.shortDateString(endDate.toISOString());

  const isoStart = DateTimeFormat.isoDateString(startDate);
  const isoEnd = DateTimeFormat.isoDateString(endDate);

  const dateColumnInfo: DateColumnInfo = {
    name: `selected__${isoStart}-${isoEnd}`,
    title:  `${startDateStr}-${endDateStr}`,
    numberFormat: currencyFormat,
    startDate: startDate,
    endDate: endDate,
    visible: visible,
    useLinkButton: true,
    hideZero: true,
  };

  return [dateColumnInfo];
}

export function getDebitRange(startDate: Date, endDate: Date, visible: boolean, currencyFormat: NumberFormatTypes) : DateColumnInfo[] {  
  const dateColumnInfo: DateColumnInfo = {
    name: DebitRange,
    title:  `Debits`,
    numberFormat: currencyFormat,
    startDate: startDate,
    endDate: endDate,
    visible: visible,
    useLinkButton: true,
    hideZero: true
  };

  return [dateColumnInfo];
}

export function getCreditRange(startDate: Date, endDate: Date, visible: boolean, currencyFormat: NumberFormatTypes) : DateColumnInfo[] {  
  const dateColumnInfo: DateColumnInfo = {
    name: CreditRange,
    title:  `Credits`,
    numberFormat: currencyFormat,
    startDate: startDate,
    endDate: endDate,
    visible: visible,
    useLinkButton: true,
    hideZero: true,
  };

  return [dateColumnInfo];
}

export function getItdRange(endDate: Date, visible: boolean, currencyFormat: NumberFormatTypes, hideAllZeros: boolean): DateColumnInfo[] {
  const endDateStr = DateTimeFormat.shortDateString(endDate.toISOString());      
  const dateCol: DateColumnInfo = {
    startDate: new Date(0),
    endDate: endDate,
    name: `itd_${endDate.getFullYear()}_${endDate.getMonth()+1}`,
    title: `ITD as of ${endDateStr}`,
    numberFormat: currencyFormat,
    visible: visible,
    useLinkButton: true,
    hideZero: hideAllZeros,
  };

  return [dateCol];
}

export function createAccountAttrributeGridDataSums(
  reportGridData: ReportGridBaseData[], 
  filteredAttributesFilter: any, 
  dateColInfos: DateColumnInfo[],
  currentCurrency: string) {
  const numberCols = dateColInfos.filter(c => c.numberFormat !== 'Text');

  filteredAttributesFilter.forEach((attrib: any) => {
    const attributeName = getCategoryNameWithOrder(attrib.name);
    const attribCategoryProps = {
      attributeId: attrib.id,
      attributeName: attributeName
    };
    
    const attribRows = reportGridData.filter(r => r.categoryProps.attributeName === attributeName);
    const attribDataProps = {} as any;

    numberCols.forEach(col => {
      attribDataProps[col.name] = 0;
    });

    const callerData: GridCallerData = {
      reportAccounts: []
    };

    attribRows.forEach(row => {
      callerData.reportAccounts = callerData.reportAccounts.concat(row.callerData.reportAccounts);

      numberCols.forEach(col => {
        attribDataProps[col.name] += row.dataProps[col.name];
      });
    });

    const newGridData = {
      itemId: attrib.id,
      currencyCode: currentCurrency ?? '',
      categoryProps: attribCategoryProps,
      dataProps: attribDataProps,
      callerData: callerData
    } as ReportGridBaseData;

    reportGridData.push(newGridData);

  });
}

function getCategoryNameWithOrder(name: string) {
  let order: string = '';

  switch(name) {
    case "Assets":
    case 'Income':
      order = '1'; 
      break;
    case 'Liabilities':
    case 'Expense':
      order = '2';
      break;
    case 'Partners Capital':
    case 'Gain/Loss':
      order = '3';
      break;
  }

  return `${order}|${name}`;
}

export function createAccountChildrenSums(
  reportGridData: ReportGridBaseData[],
  dateColInfos: DateColumnInfo[],
  currentReportCategoryFieldDefs: CategoryFieldDef[],
  currentCurrency: string,
  isCustom: boolean
  ) {
  const numberCols = dateColInfos.filter(c => c.numberFormat !== 'Text');
  const newAccountSummaryGridRows: ReportGridBaseData[] = [];
  const fieldDefs = currentReportCategoryFieldDefs.sort((a,b) => a.order - b.order);

  for(let level = 1; level < fieldDefs.length-1; level++) {
    const fd = fieldDefs[level];
    const nextFd = fieldDefs[level+1];

    const categoryProps = reportGridData.map(row => row.categoryProps[fd.name]);
    const uniqueCats = Array.from(new Set(categoryProps.filter(cp => cp)));

    uniqueCats.forEach(cat => {
      const allCatRows = reportGridData.filter(row => row.categoryProps[fd.name] === cat);
      const parentRow = allCatRows.find(row => !row.categoryProps[nextFd.name])!;
      const childrenRows = allCatRows.filter(row => row.categoryProps[nextFd.name]);

      if(!parentRow) return;

      //Switch hierarchy naming of data based on report view
      if(!childrenRows?.length) {              
        const sumChildren = isCustom ? !parentRow.categoryProps['fsDisplay_id_2'] : !parentRow.categoryProps['account_id_2'];

        if(sumChildren){
          const categoryProps = cloneDeep(parentRow.categoryProps);

          categoryProps[nextFd.name] = `Total ${categoryProps[fd.name]}`;

          const newGridData = {
            itemId: `SummaryAccountFor-${parentRow.itemId}`,
            currencyCode: currentCurrency ?? '',
            categoryProps: categoryProps,
            dataProps: parentRow.dataProps,
            callerData: parentRow.callerData
          } as ReportGridBaseData;

          isCustom ? "" : newAccountSummaryGridRows.push(newGridData);
        }
        return;
      }

      const dataProps = {} as any; 
      
      // If parent row as values to be included for each column
      numberCols.forEach(col => {
        dataProps[col.name] = parentRow ? parentRow.dataProps[col.name] : 0;
      });

      const callerData: GridCallerData = {
        reportAccounts: []
      };

      // Add together the property values for total summary line
      childrenRows.forEach(row => {
        callerData.reportAccounts = callerData.reportAccounts.concat(row.callerData.reportAccounts);

        numberCols.forEach(col => {
          dataProps[col.name] += row.dataProps[col.name];
        });
      });

      const categoryProps = cloneDeep(parentRow.categoryProps);

      categoryProps[nextFd.name] = `Total ${categoryProps[fd.name]}`;

      const newGridData = {
        itemId: `SummaryAccountFor-${parentRow.itemId}`,
        currencyCode: currentCurrency ?? '',
        categoryProps: categoryProps,
        dataProps: dataProps,
        callerData: callerData
      } as ReportGridBaseData;

      if(isCustom && categoryProps.fsDisplay_id_2 === undefined) return;

      newAccountSummaryGridRows.push(newGridData);
    });
  }

  newAccountSummaryGridRows.forEach(row => reportGridData.push(row));
}

export function getAccountParentHierarchy(accounts: Account[]): AccountHierarchyItem[] {
  const tempAccountHierarchyList:AccountHierarchyItem[] = [];

  accounts.forEach(account => {
    const newAccountHierarchy:AccountHierarchyItem = {
      accountId: account.id,
      accountName: account.name,
      number: account.number.toString(),
      attributeId: account.attributeId,
      parentAccountId: account.parentId,
      parentAccounts: [account]
    };

    tempAccountHierarchyList.push(newAccountHierarchy);
    
    let currParentAccount:Account|undefined = account;

    do{
      currParentAccount = accounts.find(a => a.id === currParentAccount!.parentId);

      if(currParentAccount) {
        newAccountHierarchy.parentAccounts.splice(0, 0, currParentAccount);
      }
    } while(currParentAccount);
  });

  return tempAccountHierarchyList;
}

export function insertMissingReportAccount(accountHierarchy:AccountHierarchyItem[] | undefined, reportAccounts: ReportAccount[], selectedAttributes: string[], decimals: number) {
  accountHierarchy!.filter(ah => selectedAttributes.find(sa => sa === ah.attributeId)).forEach(ah => {
    if(!reportAccounts.find(ra => ra.accountId === ah.accountId)) {
      const children = accountHierarchy!.filter(ah2 => ah2.parentAccountId === ah.accountId);
     
      reportAccounts.push({
        accountId: ah.accountId,
        accountName: ah.accountName,
        accountNo: ah.number.toString(),
        attributeId: '',
        fsMappingId: ah.parentAccounts[0].fsMappingId,
        fsName: ah.parentAccounts[0].fsDisplayName,
        fundId: ah.parentAccounts[0].fundId,
        state: '',
        startBalance: 0,
        endBalance: 0,
        totalAmount: 0,
        parentAccountId: ah.parentAccountId,
        childAccounts: children as any,
        lineItems: [],
        decimals: decimals
      });
    }
  });
}

export function getAccountCategories(accountHierarchy:AccountHierarchyItem[], accountType: 'account' | 'fsDisplay'): CategoryFieldDef[]  {
  const tempAccountCats: CategoryFieldDef[] = [];

  tempAccountCats.push({
    id: 'attributeId',
    name: 'attributeName',
    headerName: 'Attribute',
    visible: true,
    order: 1,
    useLinkButton: true
  });

  const maxCategoryCount = accountHierarchy!.
    reduce((prevVal, account) => account.parentAccounts.length > prevVal ? account.parentAccounts.length : prevVal, 0);

  for(let i=1; i<=maxCategoryCount; i++ ) {
    tempAccountCats.push({
      id: `${accountType}_id_${i}`,
      name: `${accountType}_name_${i}`,
      headerName: `${accountType} level ${i}`,
      order: i+1,
      useLinkButton: false,
      visible: true
    });
  }

  return tempAccountCats;
}

// Parse the data to merge child accounts into parent for custom
export function mergeSameFsNameToParent(reportAccounts: ReportAccount[]) {
  let accountsToRemove: ReportAccount[] = [];
  let foundSameFsName: boolean;

  do {
    foundSameFsName = false;

    reportAccounts.forEach(child => {
      if(!child.parentAccountId) return;

      const parent = reportAccounts.find(a => a.accountId === child.parentAccountId)!;

      if(!parent) return;

      if(parent.fsName === child.fsName) {
        parent.lineItems = parent.lineItems.concat(child.lineItems);
        child.childAccounts.forEach(ca => { ca.parentAccountId = parent.accountId; });
        accountsToRemove.push(child);

        parent.startBalance += child.startBalance;
        parent.endBalance += child.endBalance;

        foundSameFsName = true;
      }
    });

    // Once merged remove the existing account to prevent duplicate data
    // needs to be tested with new correct QA data sets (12/11/23)
    accountsToRemove.forEach(ar => {
      const index = reportAccounts.findIndex(a => a.accountId === ar.accountId);

      reportAccounts.splice(index, 1);
    });
    
    accountsToRemove = [];
  } while(foundSameFsName);
}

export function mergeLandPDataProps(liabilitiesRow: ReportGridBaseData, partnersCapRow: ReportGridBaseData) {
  const lAndPDataProps = {} as any;

  for (const key in liabilitiesRow.dataProps){
    if (partnersCapRow.dataProps.hasOwnProperty(key)){
      lAndPDataProps[key] = liabilitiesRow.dataProps[key] + partnersCapRow.dataProps[key];
    }
  }

  return lAndPDataProps;
}

export function mergeDataProps(gridData: ReportGridBaseData[]){
  const mergedDataProps: any = {};

  gridData.forEach(obj => {
    for (const key in obj.dataProps) {
      if (!mergedDataProps.hasOwnProperty(key)) {
        mergedDataProps[key] = obj.dataProps[key];
      } else {
        mergedDataProps[key] += obj.dataProps[key];
      }
    }
  });

  return mergedDataProps;
}

export function mergeCallerData(gridData: ReportGridBaseData[]){
  const callerDataArray = [] as any;

  gridData.forEach((row: ReportGridBaseData)=>{
    row.callerData.reportAccounts.map((account: ReportAccount) => {
      callerDataArray.push(account);
    });
  });
  
  return callerDataArray;
}

export function mergeLandPCallerData(liabilitiesRow: ReportGridBaseData, partnersCapRow: ReportGridBaseData) {
  const lAndPCallerDataArray = [] as any;

  liabilitiesRow.callerData.reportAccounts.map((account: ReportAccount) => {
    lAndPCallerDataArray.push(account);
  });

  partnersCapRow.callerData.reportAccounts.map((account: ReportAccount) => {
    lAndPCallerDataArray.push(account);
  });

  return lAndPCallerDataArray;
}