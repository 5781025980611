import {
  GridAlignment,
  GridColumnOrderChangeParams,
} from "@mui/x-data-grid-pro";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { ActionLink } from "../../../components/Link/ActionLink/ActionLink";
import { AppContext } from "../../../core/context/appContextProvider";
import useRole from "../../../core/routing/useRole";
import {
  getColumnOrder,
  saveColumnOrder,
} from "../../../services/columnOrder.service";
import { useClientEffect } from "../../../services/hooks/useClientsEffect/useClientEffect.hooks";
import {
  deleteInvestmentTransactions,
  downloadFileInvestmentTransaction,
  getInvestmentTransactions,
} from "../../../services/investmentTransaction.service";
import { uploadFile } from "../../../services/uploads.service";
import { GENERIC_ERROR_MESSAGE } from "../../../utils/constants/text.constants";
import {
  arrayIndexUpdate,
  arrayVisibilityUpdate,
} from "../../../utils/helpers/columnOrder.helper";
import downloadFile from "../../../utils/helpers/fileDownloader";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { useQuery } from "../../../utils/hooks/useQuery.hook";
import { ColumnOrder } from "../../../utils/types/columnOrder";
import { AddNewButtonOptions } from "../../../utils/types/common.type";
import { DetailsType } from "../../../utils/types/form.type";
import {
  InvestmentTransaction,
  InvestmentTransactionNewView,
  InvestmentTransactionResponse,
  SelectedInvestmentTransaction,
} from "../../../utils/types/investmentTransaction.type";
import {
  CustomType,
  DataGridColDef,
  ImageItem,
} from "../../../utils/types/listItems";
import { SelectedPortfolioCompany } from "../../../utils/types/portfolioCompany.type";
import { ScopeRole } from "../../../utils/types/user.type";
import {
  GET_VIEW_LIST_ERROR,
  INVESTMENT_TRANSACTION_VIEW_KEY,
} from "./InvestmentTransactionList.constants";
import {
  DataGridCell,
  formatStringToUpperCase,
  quantityWithCommas,
} from "./InvestmentTransactionList.styles";

export enum InvestmentTransactionFilter {
  Fund = "fund",
  PortfolioCompany = "portfolioCompany",
  SecurityType = "securityType",
  Security = "security",
}

export type InvestmentTransactionDefaults = {
  filters: {
    portfolioCompanies: string[] | undefined;
  };
};

const defaultHeaderList: DataGridColDef[] = [
  {
    field: "name",
    headerName: "Transaction Name",
    hide: false,
    index: 1,
    sortable: false,
    renderCell: (params: any) => {
      return (
        <ActionLink
          id={`link_investment_transaction_${params.row.id}`}
          onClick={() => undefined}
        >
          {params.value}
        </ActionLink>
      );
    },
    type: "string",
    align: "left" as GridAlignment,
    width: 240,
  },
  {
    field: "portfolioCompany.name",
    headerName: "Portfolio Investment",
    hide: false,
    index: 2,
    sortable: true,
    type: "string",
    align: "left" as GridAlignment,
    width: 240,
  },
  {
    field: "fund.name",
    headerName: "Fund",
    hide: false,
    index: 3,
    type: "string",
    align: "left" as GridAlignment,
    width: 180,
  },
  {
    field: "investmentTransactionType",
    renderCell: (params: any) => {
      if (params.row.investmentTransactionType) {
        return (
          <DataGridCell>
            {formatStringToUpperCase(params.row.investmentTransactionType)}
          </DataGridCell>
        );
      } else {
        return "";
      }
    },
    headerName: "Type",
    hide: false,
    index: 4,
    sortable: true,
    type: "string",
    align: "left" as GridAlignment,
    width: 240,
  },
  {
    field: "securityType",
    headerName: "Security Type",
    hide: false,
    index: 5,
    type: "string",
    sortable: false,
    align: "left" as GridAlignment,
    width: 220,
  },
  {
    field: "investmentSecurity",
    headerName: "Security",
    hide: false,
    index: 6,
    type: "string",
    align: "left" as GridAlignment,
    width: 220,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    hide: false,
    index: 7,
    type: "number",
    decimalPlaces: 0,
    align: "left" as GridAlignment,
    width: 180,
  },
  {
    field: "totalAmount",
    headerName: "Amount",
    hide: false,
    index: 8,
    type: "number",
    customType: CustomType.PositiveCurrency,
    currencyCodeField: "currency",
    decimalPlaces: 2,
    sortable: false,
    align: "right" as GridAlignment,
    width: 180,
  },
  {
    field: "amountPerQuantity",
    headerName: "Amount per Quantity",
    hide: false,
    index: 9,
    type: "number",
    customType: CustomType.PositiveCurrency,
    currencyCodeField: "currency",
    decimalPlaces: 2,
    sortable: false,
    align: "right" as GridAlignment,
    width: 180,
  },
  {
    field: "date",
    headerName: "Date of Transaction",
    hide: false,
    index: 10,
    type: "date",
    align: "left" as GridAlignment,
    width: 200,
  },
  {
    field: "commitmentAmount",
    headerName: "Commitment Amount",
    hide: false,
    index: 11,
    sortable: false,
    type: "number",
    customType: CustomType.PositiveCurrency,
    currencyCodeField: "currency",
    decimalPlaces: 2,
    align: "right" as GridAlignment,
    width: 200,
  },
  {
    field: "noteAgreement",
    headerName: "Note Agreement",
    hide: false,
    index: 12,
    sortable: false,
    type: "string",
    align: "left" as GridAlignment,
    width: 180,
  },
  {
    field: "interestRate",
    headerName: "Interest Rate",
    hide: false,
    index: 13,
    sortable: false,
    type: "percent",
    align: "left" as GridAlignment,
    width: 180,
  },
  {
    field: "action",
    headerName: "Columns",
    hide: false,
    hideable: false,
    index: 14,
    type: "action",
    customType: CustomType.Action,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    width: 100,
  },
];

const initialInvestmentTransaction: SelectedInvestmentTransaction = {
  investmentTransactionType: "",
  investmentTransaction: undefined,
  type: undefined,
};

const initialPortfolioCompany: SelectedPortfolioCompany = {
  portfolioCompany: undefined,
  type: undefined,
};

export const useInvestmentTransactionList = () => {
  const portLocation = useLocation<InvestmentTransactionDefaults>();
  const { state, informationAlert } = useContext(AppContext);
  const { clientId } = state.loginUser;
  const { fundId } = useParams<{ fundId: string }>();
  const urlParams = useQuery();
  const portfolioCompanyId = urlParams.get("portfolio-company");
  const [investmentTransactionList, setInvestmentTransactionList] = useState<
    InvestmentTransaction[]
  >([]);
  const [
    investmentTransactionFilteredList,
    setInvestmentTransactionFilteredList,
  ] = useState<InvestmentTransaction[]>([]);
  const [investmentTransactionResponse, setInvestmentTransactionResponse] =
    useState<InvestmentTransactionResponse>();
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultHeaderList.length - 1
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrder | null>(null);
  const [search, setSearch] = useState<string>("");
  const [searchOptions, setSearchOptions] = useState<any[]>([]);
  const [
    investmentTransactionSelectionModel,
    setinvestmentTransactionSelectionModel,
  ] = useState<string[]>([]);
  const [showSuggestionPopover, setShowSuggestionPopover] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const [fundList, setFundList] = useState<any[]>([]);
  const [portfolioCompanyList, setPortfolioCompanyList] = useState<any[]>([]);
  const [securityTypeList, setSecurityTypeList] = useState<any[]>([]);
  const [securityList, setSecurityList] = useState<any[]>([]);
  const [selectedInvestmentTransaction, setSelectedInvestmentTransaction] =
    useState<SelectedInvestmentTransaction>(initialInvestmentTransaction);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lockedPrompt, setLockedPrompt] = useState<boolean>(false);
  const { client } = useClientEffect(clientId !== "new" ? clientId : undefined);

  const [showDeleteSelConfirmationBox, setShowDeleteSelConfirmationBox] =
    useState(false);

  const selectedFundList = useMemo(
    () => fundList?.map((item) => item.id),
    [fundList]
  );
  const selectedPortfolioCompanyList = useMemo(
    () => portfolioCompanyList?.map((item) => item.id),
    [portfolioCompanyList]
  );
  const selectedSecurityTypeList = useMemo(
    () => securityTypeList?.map((item) => item.id),
    [securityTypeList]
  );
  const selectedSecurityList = useMemo(
    () => securityList?.map((item) => item.id),
    [securityList]
  );

  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const readonly: boolean = !!isFundAdmin;

  const fetchAllInvestmentTransactions = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);
      const response = await getInvestmentTransactions();

      setInvestmentTransactionResponse(response);
      if (isCanceled?.()) return;

      setIsLoading(false);
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
  };

  const fetchColumnOrder = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);

      const columnOrderResponse = await getColumnOrder(
        INVESTMENT_TRANSACTION_VIEW_KEY,
        clientId
      );

      if (isCanceled?.()) return;

      setColumnOrder(columnOrderResponse || null);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      informationAlert(GET_VIEW_LIST_ERROR, "error");
    }
  };

  useEffectAsync(async (isCanceled) => {
    await fetchAllInvestmentTransactions(isCanceled);
    await fetchColumnOrder(isCanceled);
  }, []);

  useEffect(() => {
    initializeHeaderList();
  }, [columnOrder]);

  useEffect(() => {
    if (
      investmentTransactionResponse &&
      investmentTransactionResponse.items.length > 0
    ) {
      let filteredList = investmentTransactionResponse.items;

      if (fundId) {
        filteredList = filteredList.filter((item) => item?.fund?.id === fundId);
      }

      if (portfolioCompanyId) {
        filteredList = filteredList.filter(
          (item) => item?.portfolioCompany?.id === portfolioCompanyId
        );
      }

      setInvestmentTransactionList(filteredList);

      setFilters(investmentTransactionResponse.items);

      const portfolioCompanyFilters =
        portLocation.state?.filters?.portfolioCompanies;

      if (portfolioCompanyFilters) {
        // this needs to run asyncronously to let above states to register first
        setTimeout(() => {
          handleFilter(
            InvestmentTransactionFilter.PortfolioCompany,
            portfolioCompanyFilters
          );
        }, 1);
      }
    }
  }, [investmentTransactionResponse, fundId, portfolioCompanyId]);

  useEffect(() => {
    if (fundList && portfolioCompanyList && securityTypeList && securityList) {
      initializeHeaderList();
    }
  }, [fundList, portfolioCompanyList, securityTypeList, securityList]);

  useEffect(() => {
    initializeSearchOptions(investmentTransactionList);
  }, [investmentTransactionList]);

  const initializeSearchOptions = (list: InvestmentTransaction[]) => {
    const options = Array.from(new Set(list.map((company) => company.name)));

    setSearchOptions(options);
  };

  useEffect(() => {
    updateInvestmentTransactionList(search);
  }, [search]);

  useEffect(() => {
    let filteredList: any[] | undefined = investmentTransactionList;

    headerList?.map((header) => {
      const auxList = filteredList ?? investmentTransactionList;

      switch (header.inlineFilterName) {
        case InvestmentTransactionFilter.Fund:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((transaction) => {
              return header?.inlineFilterSelected?.some(
                (selectedTransaction) => {
                  if (transaction.fund && transaction.fund.name) {
                    return selectedTransaction === transaction.fund.name;
                  }
                }
              );
            });
          }
          break;
        case InvestmentTransactionFilter.PortfolioCompany:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((transaction) => {
              return header?.inlineFilterSelected?.some(
                (selectedTransaction) => {
                  if (
                    transaction.portfolioCompany &&
                    transaction.portfolioCompany.name
                  ) {
                    return (
                      selectedTransaction === transaction.portfolioCompany.name
                    );
                  }
                }
              );
            });
          }
          break;
        case InvestmentTransactionFilter.SecurityType:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((transaction) => {
              return header?.inlineFilterSelected?.some(
                (selectedTransaction) => {
                  if (transaction.securityType) {
                    return selectedTransaction === transaction.securityType;
                  }
                }
              );
            });
          }
          break;
        case InvestmentTransactionFilter.Security:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((transaction) => {
              return header?.inlineFilterSelected?.some(
                (selectedTransaction) => {
                  if (transaction.investmentSecurity) {
                    return (
                      selectedTransaction === transaction.investmentSecurity
                    );
                  }
                }
              );
            });
          }
          break;
      }
    });

    setInvestmentTransactionFilteredList(filteredList);
  }, [headerList, investmentTransactionList]);

  const updateInvestmentTransactionList = (searchText: string) => {
    if (investmentTransactionResponse) {
      if (searchText === "") {
        setInvestmentTransactionList(investmentTransactionResponse.items);
      } else if (typeof searchText === "string") {
        const companies = [...investmentTransactionResponse.items];

        const updatedCompanyList = companies.filter((company: any) =>
          company.name.match(new RegExp(searchText, "i"))
        );

        setInvestmentTransactionList(updatedCompanyList);
      }
    }
  };

  const initializeHeaderList = () => {
    const updatedHeaderList = [
      {
        field: "name",
        headerName: "Transaction Name",
        hide: false,
        index: 1,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <ActionLink
              id={`link_investment_transaction_${params.row.id}`}
              onClick={() => handleOnView(params.row.id, params.row)}
            >
              {params.value}
            </ActionLink>
          );
        },
        type: "string",
        align: "left" as GridAlignment,
        width: 240,
      },
      {
        field: "portfolioCompany.name",
        headerName: "Portfolio Investment",
        hide: false,
        index: 2,
        sortable: true,
        valueGetter: (params: any) => params.row.portfolioCompany.name,
        type: "string",
        align: "left" as GridAlignment,
        width: 240,
        inlineFilter: true,
        inlineFilterName: InvestmentTransactionFilter.PortfolioCompany,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "label",
        inlineFilterOptions: portfolioCompanyList,
        inlineFilterSelected: selectedPortfolioCompanyList,
        emptySelectionOnClear: false,
      },
      {
        field: "fund.name",
        headerName: "Fund",
        hide: false,
        index: 3,
        type: "string",
        sortable: true,
        valueGetter: (params: any) => params.row.fund.name,
        align: "left" as GridAlignment,
        width: 180,
        inlineFilter: true,
        inlineFilterName: InvestmentTransactionFilter.Fund,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "label",
        inlineFilterOptions: fundList,
        inlineFilterSelected: selectedFundList,
        emptySelectionOnClear: false,
      },
      {
        field: "investmentTransactionType",
        renderCell: (params: any) => {
          if (params.row.investmentTransactionType) {
            return (
              <DataGridCell>
                {formatStringToUpperCase(params.row.investmentTransactionType)}
              </DataGridCell>
            );
          } else {
            return "";
          }
        },
        headerName: "Type",
        hide: false,
        index: 4,
        sortable: true,
        type: "string",
        align: "left" as GridAlignment,
        width: 240,
      },
      {
        field: "securityType",
        headerName: "Security Type",
        hide: false,
        index: 5,
        type: "string",
        sortable: true,
        align: "left" as GridAlignment,
        width: 220,
        inlineFilter: true,
        inlineFilterName: InvestmentTransactionFilter.SecurityType,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "label",
        inlineFilterOptions: securityTypeList,
        inlineFilterSelected: selectedSecurityTypeList,
        emptySelectionOnClear: false,
      },
      {
        field: "investmentSecurity",
        headerName: "Security",
        hide: false,
        index: 6,
        type: "string",
        align: "left" as GridAlignment,
        width: 220,
        inlineFilter: true,
        inlineFilterName: InvestmentTransactionFilter.Security,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "label",
        inlineFilterOptions: securityList,
        inlineFilterSelected: selectedSecurityList,
        emptySelectionOnClear: false,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        hide: false,
        index: 7,
        type: "string",
        renderCell: (params: any) => {
          if (params.value) {
            return (
              <DataGridCell>{quantityWithCommas(params.value)}</DataGridCell>
            );
          } else {
            return "";
          }
        },
        decimalPlaces: 0,
        align: "left" as GridAlignment,
        width: 180,
      },
      {
        field: "totalAmount",
        headerName: "Amount",
        hide: false,
        index: 8,
        type: "number",
        customType: CustomType.PositiveCurrency,
        currencyCodeField: "currency",
        decimalPlaces: 2,
        sortable: false,
        align: "right" as GridAlignment,
        width: 180,
      },
      {
        field: "amountPerQuantity",
        headerName: "Amount per Quantity",
        hide: false,
        index: 9,
        type: "number",
        customType: CustomType.PositiveCurrency,
        currencyCodeField: "currency",
        decimalPlaces: 2,
        sortable: false,
        align: "right" as GridAlignment,
        width: 180,
      },
      {
        field: "date",
        headerName: "Date of Transaction",
        hide: false,
        index: 10,
        type: "date",
        align: "left" as GridAlignment,
        width: 200,
      },
      {
        field: "commitmentAmount",
        headerName: "Commitment Amount",
        hide: false,
        index: 11,
        sortable: false,
        type: "number",
        customType: CustomType.PositiveCurrency,
        currencyCodeField: "currency",
        decimalPlaces: 2,
        align: "right" as GridAlignment,
        width: 180,
      },
      {
        field: "noteAgreement",
        headerName: "Note Agreement",
        hide: false,
        index: 12,
        sortable: false,
        type: "string",
        renderCell: (params: any) => {
          if (params.value) {
            return (
              <DataGridCell>
                {params.value === 1
                  ? `${params.value} day`
                  : `${params.value} days`}
              </DataGridCell>
            );
          } else {
            return "";
          }
        },
        decimalPlaces: 0,
        align: "left" as GridAlignment,
        width: 180,
      },
      {
        field: "interestRate",
        headerName: "Interest Rate",
        hide: false,
        index: 13,
        sortable: false,
        type: "string",
        renderCell: (params: any) => {
          if (params.value) {
            return <DataGridCell>{`${params.value}%`}</DataGridCell>;
          } else {
            return "";
          }
        },
        align: "left" as GridAlignment,
        width: 180,
      },
      {
        field: "action",
        headerName: "Columns",
        hide: false,
        hideable: false,
        index: 14,
        type: "action",
        customType: CustomType.Action,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        width: 100,
      },
    ];

    let sortedHeaders: any;

    if (columnOrder && columnOrder.viewItems) {
      columnOrder.viewItems.map((item) => {
        const header = updatedHeaderList.find(
          (header: any) => header.field === item.code
        );

        if (header) {
          header.index = item.order;
          header.hide = !item.visible;
        }
      });

      sortedHeaders = updatedHeaderList.sort((a: any, b: any) =>
        a.index > b.index ? 1 : -1
      );

      const activeHeaders = headerList.filter((header) => !header.hide);

      setActiveHeaderFields(activeHeaders.length - 1);
    } else {
      sortedHeaders = updatedHeaderList.sort(
        (item1: any, item2: any) => item1.index - item2.index
      );
    }

    setHeaderList(sortedHeaders);
  };

  const setFilters = (list: any) => {
    if (list && list.length > 0) {
      setFundList(
        uniqueArray(
          list.map((transaction: any) => transaction.fund),
          "name"
        )
      );
      setPortfolioCompanyList(
        uniqueArray(
          list.map((transaction: any) => transaction.portfolioCompany),
          "name"
        )
      );
      setSecurityTypeList(uniqueArray(list, "securityType"));
      setSecurityList(uniqueArray(list, "investmentSecurity"));
    }
  };

  const uniqueArray = (list: any[], key: string) => {
    const uniqueValues = Array.from(
      list
        .reduce((list, item) => {
          if (item && item[key]) {
            list.set(item[key], {
              id: item[key],
              label: item[key],
            });
          }
          return list;
        }, new Map())
        .values()
    );

    return uniqueValues;
  };

  const handleSearch = (
    event: any,
    newValue: React.SetStateAction<string> | null
  ) => {
    if (typeof newValue === "string") {
      setSearch(newValue);
    } else if (newValue === null) {
      setSearch("");
    }

    if (newValue !== null) setShowSuggestionPopover(false);
  };

  const handleNewButtonAction = (actionId: string, event: any) => {
    switch (actionId) {
      case AddNewButtonOptions.AddNew:
        {
          setSelectedInvestmentTransaction({
            investmentTransactionType: "",
            investmentTransaction: undefined,
            type: DetailsType.New,
            newView: InvestmentTransactionNewView.Purchase,
          });
        }
        break;
      case AddNewButtonOptions.UploadFromTemplate:
        {
          const file = event?.target?.files?.[0];

          if (file) {
            setUploadedFile(file);
          }
        }
        break;
      case AddNewButtonOptions.DownloadTemplate:
        {
          setIsLoading(true);
          const cdnEndpoint: string = process.env
            .REACT_APP_CDN_ENDPOINT_URL as string;

          location.href = cdnEndpoint.concat(
            "templates/Investment_Transaction_Template.csv"
          );
          setIsLoading(false);
        }
        break;
    }
  };

  const handleOnView = (
    investmentTransactionId: string,
    investmentTransaction: InvestmentTransaction
  ) => {
    setSelectedInvestmentTransaction({
      investmentTransactionType: "PURCHASE",
      investmentTransaction,
      viewPurchaseTransactionId: investmentTransactionId,
      type: DetailsType.Edit,
    });
  };

  const onInvestmentTransactionPanelClose = () => {
    setSelectedInvestmentTransaction(initialInvestmentTransaction);
  };

  const handleFilter = (
    filterName: InvestmentTransactionFilter,
    selected: string[]
  ) => {
    setHeaderList((prevHeaderList) =>
      prevHeaderList?.map((header) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected.length
              ? selected
              : header.inlineFilterOptions?.map((option) => option.id),
          };
        }

        return header;
      })
    );
  };

  const onColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const newIndex = params.targetIndex;
    const oldIndex = params.oldIndex;

    const columnOrderToSave = arrayIndexUpdate(
      headerList,
      oldIndex - 1,
      newIndex - 1,
      clientId,
      INVESTMENT_TRANSACTION_VIEW_KEY
    );

    saveColumnOrder(columnOrderToSave);
  };

  const handleUpdateHeader = async (
    field: string,
    inlineFilterName?: InvestmentTransactionFilter
  ) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== "action"
    );
    const targetField = headerList.find((header) => header.field === field);

    if (activeFields.length <= 1 && !targetField?.hide) {
      return;
    }

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      if (header?.inlineFilterName === inlineFilterName) {
        let inlineFilterSelected;

        if (inlineFilterName === InvestmentTransactionFilter.Fund) {
          inlineFilterSelected = selectedFundList;
        } else if (
          inlineFilterName === InvestmentTransactionFilter.PortfolioCompany
        ) {
          inlineFilterSelected = selectedPortfolioCompanyList;
        } else if (
          inlineFilterName === InvestmentTransactionFilter.SecurityType
        ) {
          inlineFilterSelected = selectedSecurityTypeList;
        } else if (inlineFilterName === InvestmentTransactionFilter.Security) {
          inlineFilterSelected = selectedSecurityList;
        }

        return {
          ...header,
          hide:
            header.field === field &&
            !(!header.hide && activeFields.length <= 1)
              ? !header.hide
              : header.hide,
          inlineFilterSelected: inlineFilterSelected,
        };
      }
      return {
        ...header,
        hide:
          header.field === field && !(!header.hide && activeFields.length <= 1)
            ? !header.hide
            : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }

    const visiblityUpdate = arrayVisibilityUpdate(
      headerList,
      clientId,
      INVESTMENT_TRANSACTION_VIEW_KEY,
      field
    );

    saveColumnOrder(visiblityUpdate);
  };

  const clearUploadedFile = () => {
    setUploadedFile(undefined);
  };

  const clearUploadCompleted = () => {
    setIsUploadComplete(false);
  };

  const handleUploadTemplate = async () => {
    if (!uploadedFile) return;
    try {
      setIsLoading(true);
      await uploadFile("Investment_transaction", uploadedFile);
      setUploadedFile(undefined);
      setIsLoading(false);
      setIsUploadComplete(true);
      await fetchAllInvestmentTransactions();
    } catch (exception) {
      setUploadedFile(undefined);
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
  };

  const showLockedDialog = () => {
    setLockedPrompt(true);
  };

  const closeLockedDialog = () => {
    setLockedPrompt(false);
  };

  const handleBulkOptionClick = async (selectedOption: string) => {
    if (selectedOption === "export") {
      const invTransactionIds: string[] =
        headerList.find((item) => item.field === "Transaction Name")
          ?.inlineFilterSelected || [];

      setIsLoading(true);

      const file = await downloadFileInvestmentTransaction(
        investmentTransactionSelectionModel,
        invTransactionIds,
        "INVESTMENT TRANSACTION"
      );

      downloadFile(file, "Investment_Transactions", "csv");

      setIsLoading(false);
    }

    if (selectedOption === "delete-selected") {
      setShowDeleteSelConfirmationBox(true);
    }
  };

  const handleCloseDeleteSelConfirmationBox = async (cancel: boolean) => {
    if (!cancel) {
      setIsLoading(true);

      await deleteInvestmentTransactions(investmentTransactionSelectionModel);
      await fetchAllInvestmentTransactions();

      setIsLoading(false);
    }

    setShowDeleteSelConfirmationBox(false);
  };

  return {
    headerList,
    activeHeaderFields,
    investmentTransactionFilteredList,
    investmentTransactionSelectionModel,
    selectedInvestmentTransaction,
    readonly,
    clearUploadedFile,
    clearUploadCompleted,
    isUploadComplete,
    closeLockedDialog,
    fetchAllInvestmentTransactions,
    setinvestmentTransactionSelectionModel,
    setSelectedInvestmentTransaction,
    isLoading,
    handleNewButtonAction,
    handleOnView,
    handleFilter,
    handleSearch,
    handleUpdateHeader,
    onColumnOrderChange,
    handleUploadTemplate,
    onInvestmentTransactionPanelClose,
    search,
    searchOptions,
    showSuggestionPopover,
    setShowSuggestionPopover,
    handleBulkOptionClick,
    client,
    lockedPrompt,
    showLockedDialog,
    setLockedPrompt,
    uploadedFile,
    showDeleteSelConfirmationBox,
    handleCloseDeleteSelConfirmationBox,
  };
};

export const useBulkActionOptionSelectionEffect = (
  bulkActionList: ImageItem[],
  investmentTransactionSelectionModel: any
) => {
  const { hasRole: superAdminORClientAdminUser } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
  ]);

  const { hasRole: fundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const { hasRole: clientPortalUser } = useRole([ScopeRole.BASIC_USER]);

  const [bulkActionOptions, setBulkActionOptions] = useState<ImageItem[]>([]);

  useEffect(() => {
    let optionList: ImageItem[] = [];

    if (superAdminORClientAdminUser) {
      optionList = bulkActionList;
    }

    if (!fundAdmin && !clientPortalUser) {
      bulkActionList = bulkActionList.filter((item) => item.id !== "delete");
    }

    if (clientPortalUser || fundAdmin) {
      bulkActionList = bulkActionList.filter((item) => item.id === "export");
    }

    const updatedOptions: ImageItem[] = bulkActionList.map((option) => {
      option.text =
        option.text + "(" + investmentTransactionSelectionModel.length + ")";
      return option;
    });

    setBulkActionOptions(updatedOptions);
  }, [investmentTransactionSelectionModel]);

  return {
    bulkActionOptions,
  };
};
