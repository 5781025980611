import { ClientConfigs, Theme } from "../utils/types/client.type";
import { ClientTransMapping } from "../utils/types/clientTransMapping.type";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.PreAuth);
};

export const getClientPortalAvaiability = async (
  baseDomain: string,
  subDomain: string
) => await apiClient().get<any>(`clients/${baseDomain}/${subDomain}/available`);

export const getClientConfigs = async (portalName: string, client: string) =>
  await apiClient().get<ClientConfigs>(`loginthemes/${portalName}/${client}`);

export const getThemes = async (clientId: string) =>
  await apiClient().get<Theme>(`clients/${clientId}/themes`);
