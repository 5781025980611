import FileSaver from "file-saver";

const downloadFile = (data: any, fileName: string, fileType: string) => {
  switch (fileType) {
    case "msword": {
      const fileData = new Blob([data], { type: `application/${fileType}` });

      FileSaver.saveAs(fileData, `${fileName}.doc`);
      break;
    }
    case "vnd.openxmlformats-officedocument.wordprocessingml.document": {
      const fileData = new Blob([data], { type: `application/${fileType}` });

      FileSaver.saveAs(fileData, `${fileName}.docx`);
      break;
    }
    case "msexcel":
    case "vnd.ms-excel": {
      const fileData = new Blob([data], { type: `application/${fileType}` });

      FileSaver.saveAs(fileData, `${fileName}.xls`);
      break;
    }
    case "vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
      const fileData = new Blob([data], { type: `application/${fileType}` });

      FileSaver.saveAs(fileData, `${fileName}.xlsx`);
      break;
    }
    case "csv": {
      const csvData = new Blob([data], {
        type: "text/csv;charset=utf-8;",
      });

      FileSaver.saveAs(csvData, `${fileName}.${fileType}`);
      break;
    }
    case "octet-stream": {
      const pdfData = new Blob([data], {
        type: "binary/octet-stream",
      });

      FileSaver.saveAs(pdfData, `${fileName}.pdf`);
      break;
    }
    case "pdf": {
      const pdfData = new Blob([data], {
        type: "application/octet-stream",
      });

      FileSaver.saveAs(pdfData, `${fileName}.${fileType}`);
      break;
    }
    case "zip": {
      FileSaver.saveAs(data, `${fileName}.${fileType}`);
      break;
    }
    default: {
      const fileData = new Blob([data], { type: `application/${fileType}` });

      FileSaver.saveAs(fileData, `${fileName}.${fileType}`);
    }
  }
};

export default downloadFile;
