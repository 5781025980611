import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {
  CircularProgress,
  Collapse,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';

import HorizontalBox from '../../../../components/Boxes/HorizontalBox/HorizontalBox';
import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import { AppContext } from '../../../../core/context/appContextProvider';
import { updateBankTransactions } from '../../../../services/bank.service';
import {
  BankFeedsDataGridTab,
  EditedRows,
  UpdateBankTransactionsPayload,
} from '../../../../utils/types/bank.type';
import { OrangeErrorOutlineOutlinedIcon } from '../../bankFeeds/BankFeedList.styles';
import {
  CustomTooltip,
  InlineEditsToolbarBox,
  NowrapButton,
  NowrapTypography,
  OrangeErrorOutlinedIcon,
  TooltipContent,
} from './DataGridToolbar.styles';

interface Props extends RouteProps {
  selectedDataGridTab: string;
  editedRows: EditedRows;
  setEditedRows: React.Dispatch<React.SetStateAction<EditedRows>>;
  resetBankFeedsDataList: () => void;
  showUnsavedInlineEditsPrompt: boolean;
  setShowUnsavedInlineEditsPrompt: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  fetchBankTransactions: () => void;
  handleTabChange: (
    event: React.SyntheticEvent,
    newTab: BankFeedsDataGridTab
  ) => void;
}

export const DataGridToolbar: React.FC<Props> = ({
  selectedDataGridTab,
  editedRows,
  resetBankFeedsDataList,
  setEditedRows,
  showUnsavedInlineEditsPrompt,
  setShowUnsavedInlineEditsPrompt,
  fetchBankTransactions,
  handleTabChange,
}: Props) => {
  const { informationAlert } = useContext(AppContext);

  const [editedRowsTotal, setEditedRowsTotal] = useState<number>(0);
  const [rowsRequiringEntity, setRowsRequiringEntity] = useState<number>(0);
  const [showDiscardChangesPrompt, setShowDiscardChangesPrompt] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    setEditedRowsTotal(Object.keys(editedRows).length);

    let countEntityRequired = 0;

    Object.keys(editedRows).forEach((key) => {
      if (
        (editedRows[key].isEditedRow.isEntityRequired ||
          editedRows[key].isEntityRequiredByBankAcc) &&
        (editedRows[key].isEditedRow.isGlAccountEdited ||
          editedRows[key].isEditedRow.isEntityEdited) &&
        !editedRows[key].memoEntityId
      ) {
        countEntityRequired++;
      }
    });

    setRowsRequiringEntity(countEntityRequired);
  }, [editedRows]);

  const handleDiscardChangesConfirmation = () => {
    setShowDiscardChangesPrompt(true);
  };

  const handleKeepChanges = () => {
    setShowDiscardChangesPrompt(false);
  };

  const handleDiscardConfirmation = () => {
    setEditedRows({});
    resetBankFeedsDataList();
    setShowDiscardChangesPrompt(false);
  };

  const handleSaveAllChanges = async () => {
    const payload: UpdateBankTransactionsPayload[] = [];

    for (const [key, data] of Object.entries(editedRows)) {
      payload.push({
        id: data.id,
        status: data.status,
        isPlaid: data.isPlaid,
        ...(data.isEditedRow.isGlAccountEdited && {
          glAccountId: data.journalEntry[0].accountId,
        }),
        ...(data.isEditedRow.isEntityEdited && {
          memoEntityId: data.memoEntityId,
        }),
        ...(data.isEditedRow.isCapCallEdited && {
          receivableTransactionId: data.receivableTransactionId,
        }),
        journalEntryLineItem: data.journalEntry,
      });
    }

    try {
      setIsSaving(true);
      await updateBankTransactions(payload);
      setIsSaving(false);
      fetchBankTransactions();
      setEditedRows({});
    } catch (e) {
      setIsSaving(false);
      informationAlert('Error updating Bank Transactions', 'error');
    }
  };

  useEffect(() => {
    if (showUnsavedInlineEditsPrompt) {
      const timer = setTimeout(() => {
        setShowUnsavedInlineEditsPrompt(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showUnsavedInlineEditsPrompt]);

  const handleClose = () => {
    setShowUnsavedInlineEditsPrompt(false);
  };

  return (
    <HorizontalBox noPadding>
      <Tabs value={selectedDataGridTab} onChange={handleTabChange}>
        <Tab label="For Review" value="FOR_REVIEW" id="for_review_tab" />
        <Tab label="All" value="ALL" id="all_tab" />
      </Tabs>
      <Collapse in={!!editedRowsTotal} orientation="horizontal">
        <div>
          <CustomTooltip
            open={showUnsavedInlineEditsPrompt}
            title={
              <TooltipContent>
                <OrangeErrorOutlinedIcon fontSize="medium" />
                <NowrapTypography>
                  Save or Discard Changes First
                </NowrapTypography>
                <IconButton size="small" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </TooltipContent>
            }
            placement="top"
            arrow
          >
            <span>
              <InlineEditsToolbarBox highlighted={showUnsavedInlineEditsPrompt}>
                <NowrapTypography>
                  {editedRowsTotal === 1
                    ? `${editedRowsTotal} transaction has been edited`
                    : `${editedRowsTotal} transactions have been edited`}
                </NowrapTypography>
                {!!rowsRequiringEntity && (
                  <Tooltip
                    id={'memo_tab_required_warning'}
                    title={
                      <Typography variant="body2">
                        {rowsRequiringEntity === 1
                          ? `${rowsRequiringEntity} Edited row requires a Memo Tag`
                          : `${rowsRequiringEntity} Edited rows require a Memo Tag`}
                      </Typography>
                    }
                    arrow
                    children={<OrangeErrorOutlineOutlinedIcon />}
                  />
                )}
                <NowrapButton
                  id="btn_save_inline_edits"
                  variant="contained"
                  disabled={isSaving || !!rowsRequiringEntity}
                  onClick={() => {
                    handleSaveAllChanges();
                  }}
                  disableElevation
                  endIcon={
                    isSaving ? (
                      <CircularProgress size={20} />
                    ) : (
                      <SaveOutlinedIcon />
                    )
                  }
                  size="small"
                >
                  Save
                </NowrapButton>

                <NowrapButton
                  id="btn_discard_inline_edits"
                  variant="outlined"
                  color="error"
                  onClick={handleDiscardChangesConfirmation}
                  disableElevation
                  endIcon={<RestoreIcon />}
                  size="small"
                >
                  Discard Changes
                </NowrapButton>
              </InlineEditsToolbarBox>
            </span>
          </CustomTooltip>
        </div>
      </Collapse>
      <ConfirmationDialog
        open={!!showDiscardChangesPrompt}
        onClose={handleKeepChanges}
        id="discard_changes_confirmation_dialog"
        actions={[
          {
            label: `Discard`,
            onClick: handleDiscardConfirmation,
            id: 'btn_discard_changes_confirmation',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Cancel',
            onClick: handleKeepChanges,
            id: 'btn_cancel_and_keep_changes',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        title="Discard all changes?"
        content="Changes will be permanently discarded."
      />
    </HorizontalBox>
  );
};
