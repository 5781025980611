import { Box, styled, Typography } from "@mui/material";

export const HeaderRow = styled(Box)(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

export const HeaderButtonBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));