import { Button, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Radio, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { ReactElement, SyntheticEvent } from "react";
import { Controller } from "react-hook-form";

import { MessageContent, Option, OptionListContent, PlaceAddress, ResultContent } from "../../../components/AddressAutoComplete/AddressAutocomplete.style";
import ArrowPopover from "../../../components/ArrowPopover/ArrowPopover";
import HorizontalBox from "../../../components/Boxes/HorizontalBox/HorizontalBox";
import { StyledStack } from "../../../components/ChipsGroup/ChipsGroup.style";
import Container from "../../../components/Container/Container";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import { investorEntityTypes, prefixOptions } from "../../../utils/constants/form.constants";
import { ERROR_TAKEN_INVESTOR_NAME, INVESTOR_TYPE_ENTITY, INVESTOR_TYPE_INDIVIDUAL } from "../../../utils/constants/text.constants";
import { Prediction } from "../../../utils/types/address.type";
import { FormProps } from "../../../utils/types/form.type";
import { InvestorDetail } from "../../../utils/types/investor.type";
import { CheckBox, EntityTypeRadioGroup, TypeFormControl } from "../investorList/investorDetailPanel/InvestorDetailPanel.styles";
import { useInvestorDetailsEffect, useInvestorTypesEffect } from "./InvestorDetails.hooks";

type Props = {
  form: FormProps;
  investor?: InvestorDetail;
  setIsLoading: Function;
  isUniqueNameError: boolean;
  setIsUniqueNameError: Function;
  canUpdateInvestorTin?: boolean;
  canUpdateInvestorName?: boolean;
};

const InvestorDetails: React.FC<Props> = (props: Props): ReactElement => {
  const {
    form,
    setIsLoading,
    investor,
    isUniqueNameError,
    setIsUniqueNameError,
    canUpdateInvestorTin,
    canUpdateInvestorName,
  } = props;

  const { register, control, errors, setError, setValue, watch } = form;
  const entityType = watch('entityType');
  const disregardedEntity = watch('disregardedEntity');
  const useMailingForTax = watch('useMailingForTax');

  const {
    suggestedStateList,
    suggestedCountryList,
    suggestedTaxStateList,
    suggestedTaxCountryList,
    showMessageNoMatches,
    addressSuggestions,
    addressSuggestionsAnchorEl,
    taxAddressSuggestions,
    taxAddressSuggestionsAnchorEl,
    handleOnAddressChange,
    handleCloseArrowPopover,
    handleSelectClick,
    handleOnPhoneChange,
    handleOnNumberChange,
    handleTinChange,
    onTaxAddressCheck
  } = useInvestorDetailsEffect(
    setIsLoading,
    form,
    investor
  );

  const {
    legalTypesList,
    handleTypeChange
  } = useInvestorTypesEffect({
    setIsLoading
  });

  return (
    <Container id="container_investor_main" role="main">
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Controller
            name={`entityType`}
            render={({ field: { onChange, value } }) => {
              return (
                <FormControl>
                  <FormLabel component="legend">This investor is an</FormLabel>
                  <EntityTypeRadioGroup
                    value={value}
                    aria-labelledby={`radio_btn_group_entity`}
                    name={`radio_btn_group_entity`}
                    onChange={(e: SelectChangeEvent) => {
                      onChange(e.target.value);
                      setIsUniqueNameError(false);
                    }}
                  >
                    {investorEntityTypes.map((value: any) => (
                      <FormControlLabel key={value.value} value={value.value} control={<Radio />} label={value.label} />
                    ))}
                  </EntityTypeRadioGroup>
                </FormControl>
              );
            }}
            control={control}
          />
        </Grid>

        {canUpdateInvestorName ? (
          <>
            {entityType === INVESTOR_TYPE_INDIVIDUAL && (
              <>
                <Grid item xs={2}>
                  <Controller
                    name='pre'
                    render={({ field: { onChange, value } }) => {
                      return (
                        <SingleSelect
                          id="select_investor_prefix"
                          noGutter
                          isShort
                          optionList={prefixOptions}
                          label="Prefix"
                          value={value}
                          idField="id"
                          labelField="label"
                          handleOptionSelection={(e: SelectChangeEvent) => {
                            onChange({
                              target: {
                                value: e.target.value
                              }
                            });
                          }}
                        />
                      );
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="txt_investor_name"
                    label="First Name"
                    size="small"
                    fullWidth
                    required
                    error={!!errors.firstName || isUniqueNameError}
                    helperText={errors.firstName && errors.firstName.type === 'required' && "First Name is required"}
                    {...register("firstName", {
                      required: true,
                      validate: (value: any) => !!value?.trim(),
                    })}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <TextField
                    id="txt_investor_middle_name"
                    label="M.I"
                    size="small"
                    isShort
                    error={isUniqueNameError}
                    {...register("middle")}
                  />
                </Grid>
                <Grid item xs={4.5}>
                  <TextField
                    id="txt_investor_last_name"
                    label="Last Name"
                    size="small"
                    fullWidth
                    required
                    error={!!errors.lastName || isUniqueNameError}
                    helperText={errors.lastName && errors.firstName.type === 'required' && "Last Name is required"}
                    {...register("lastName", {
                      required: true,
                      validate: (value: any) => !!value?.trim(),
                    })}
                  />
                </Grid>
              </>
            )}
            {entityType === INVESTOR_TYPE_INDIVIDUAL && isUniqueNameError &&
              <Grid xs={12} item>
                <HorizontalBox noPadding>
                  <FormControl error>
                    <FormHelperText id="helper_text_name">
                      {ERROR_TAKEN_INVESTOR_NAME}
                    </FormHelperText>
                  </FormControl>
                </HorizontalBox>
              </Grid>
            }
            {entityType === INVESTOR_TYPE_ENTITY && (
              <Grid xs={6} item>
                <TextField
                  id="txt_investor_entity_name"
                  label="Name"
                  size="small"
                  fullWidth
                  required
                  error={!!errors.name || isUniqueNameError}
                  helperText={
                    (errors.name && errors.name.type === 'required' && "Name is required") ||
                    (isUniqueNameError && ERROR_TAKEN_INVESTOR_NAME)}
                  {...register("name", {
                    required: true,
                    validate: (value: any) => !!value?.trim(),
                  })}
                />
              </Grid>
            )}
            <Grid xs={6} item>
              <Controller
                name="phone"
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  return (
                    <TextField
                      id="txt_investor_phone"
                      label="Phone"
                      value={value}
                      size="small"
                      fullWidth
                      onChange={handleOnPhoneChange}
                    />
                  );
                }}
                control={control}
              />
            </Grid>
            {entityType === INVESTOR_TYPE_INDIVIDUAL && (
              <Grid xs={6} item></Grid>
            )}
          </>
        ) : (
          <>
            <Grid xs={6} item>
              <Typography variant="label">Name</Typography>
              <Typography className="field-value">
                {entityType === INVESTOR_TYPE_ENTITY
                  ? investor?.name
                  : investor?.fullName}
              </Typography>
            </Grid>
            <Grid xs={6} item>
              <Typography variant="label">Phone</Typography>
              <Typography className="field-value">
                {investor?.phone ?? "-"}
              </Typography>
            </Grid>
          </>
        )}


        <Grid xs={6} item>
          <Controller
            name="mailingAddress.street1"
            render={({
              field: { onChange, value },
              fieldState: { error }
            }) => {
              return (
                <>
                  <TextField
                    id="txt_investor_mailing_address_street1"
                    size="small"
                    fullWidth
                    label="Address line 1"
                    onChange={({ target, currentTarget }: any) => {
                      handleOnAddressChange({ target, currentTarget }, 'mailing');
                      onChange(target.value);
                    }
                    }
                    value={value}
                  />
                  <PlaceAddress id="placeid" />
                  <ArrowPopover
                    id="arrowPopover"
                    anchorEl={addressSuggestionsAnchorEl}
                    showPopover={Boolean(addressSuggestionsAnchorEl)}
                    size="lg"
                    handleOnPopoverClose={() => handleCloseArrowPopover('mailing')}
                    content={
                      <ResultContent>
                        <MessageContent>
                          {showMessageNoMatches
                            ? `An exact match could not be found. 
                Please select from the options below.`
                            : ""}
                        </MessageContent>
                        <OptionListContent>
                          {addressSuggestions?.map((option: Prediction, index) => {
                            return (
                              <div key={option.place_id}>
                                <StyledStack direction="row">
                                  <Option>{option.description || "Not found"}</Option>
                                  <Button
                                    id={`btn_option_${option.place_id}`}
                                    variant="text"
                                    disableElevation
                                    onClick={() =>
                                      handleSelectClick(
                                        option.place_id,
                                        option.structured_formatting.main_text,
                                        'mailing'
                                      )
                                    }

                                    name="Select"
                                  >
                                    Select
                                  </Button>
                                </StyledStack>

                                <Divider />
                              </div>
                            );
                          })}
                        </OptionListContent>
                      </ResultContent>
                    }
                  />
                </>
              );
            }}
            control={control}
          />
        </Grid>
        <Grid xs={6} item>
          <TextField
            id="txt_investor_mailing_address_street2"
            label="Address line 2"
            fullWidth
            size="small"
            {...register("mailingAddress.street2")}
          />
        </Grid>
        <Grid xs={6} item>
          <Controller
            name="mailingAddress.postalCode"
            render={({
              field: { onChange, value },
              fieldState: { error }
            }) => {
              return (
                <TextField
                  id="txt_investor_mailing_address_zip"
                  label="Zip/Post-Code"
                  size="small"
                  fullWidth
                  value={value}
                  InputLabelProps={{ shrink: !!value }}
                  onChange={onChange}
                  inputProps={{ minLength: 5, maxLength: 7, pattern: "[0-9]" }}
                />
              );
            }}
            control={control}
          />
        </Grid>
        <Grid xs={6} item>
          <Controller
            name="mailingAddress.city"
            render={({
              field: { onChange, value },
              fieldState: { error }
            }) => {
              return (
                <TextField
                  id="txt_investor_mailing_address_city"
                  label="City"
                  size="small"
                  fullWidth
                  value={value}
                  InputLabelProps={{ shrink: !!value }}
                  onChange={onChange}
                />
              );
            }}
            control={control}
          />
        </Grid>
        <Grid xs={6} item>
          <Controller
            name="mailingAddress.state"
            render={({
              field: { onChange, value },
              fieldState: { error }
            }) => {
              return (
                <SingleSelect
                  id="select_investor_mailing_address_state"
                  fullWidth
                  noGutter
                  optionList={suggestedStateList}
                  label="State/Province/Region"
                  value={value}
                  handleOptionSelection={(e: SelectChangeEvent) => {
                    onChange({
                      target: {
                        value: e.target.value
                      }
                    });
                  }}
                />
              );
            }}
            control={control}
          />
        </Grid>
        <Grid xs={6} item>
          <Controller
            name="mailingAddress.country"
            render={({
              field: { onChange, value },
              fieldState: { error }
            }) => {
              return (
                <SingleSelect
                  id="select_investor_mailing_address_country"
                  fullWidth
                  noGutter
                  optionList={suggestedCountryList}
                  label="Country"
                  value={value}
                  handleOptionSelection={(e: SelectChangeEvent) => {
                    onChange({
                      target: {
                        value: e.target.value
                      }
                    });
                  }}
                />
              );
            }}
            control={control}
          />
        </Grid>
        <Grid xs={12} item>
          <Controller
            name="useMailingForTax"
            render={({
              field: { onChange, value },
              fieldState: { error }
            }) => {
              return (
                <CheckBox>
                  <FormControlLabel
                    label="Tax Address"
                    control={
                      <Checkbox
                        id="check_investor_tax_address"
                        checked={value || false}
                        value={value}
                        onChange={(newValue) => {
                          onChange(newValue);
                          onTaxAddressCheck(newValue);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />}
                  />
                  <FormHelperText>
                    Check if same as primary address above.
                  </FormHelperText>
                </CheckBox>
              );
            }}
            control={control}
          />
        </Grid>

        {/* TAX ADDRESS */}
        {!useMailingForTax && (
          <>
            <Grid xs={6} item>
              <Controller
                name="taxAddress.street1"
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  return (
                    <>
                      <TextField
                        id="txt_investor_tax_address_street1"
                        label="Address line 1"
                        size="small"

                        fullWidth
                        onChange={({ target, currentTarget }: any) => {
                          handleOnAddressChange({ target, currentTarget }, 'tax');
                          onChange(target.value);
                        }
                        }
                        value={value}
                      />
                      <PlaceAddress id="taxplaceid" />
                      <ArrowPopover
                        id="arrowPopover"
                        anchorEl={taxAddressSuggestionsAnchorEl}
                        showPopover={Boolean(taxAddressSuggestionsAnchorEl)}
                        size="lg"
                        handleOnPopoverClose={() => handleCloseArrowPopover('tax')}
                        content={
                          <ResultContent>
                            <MessageContent>
                              {showMessageNoMatches
                                ? `An exact match could not be found. 
                Please select from the options below.`
                                : ""}
                            </MessageContent>
                            <OptionListContent>
                              {taxAddressSuggestions?.map((option: Prediction, index) => {
                                return (
                                  <div key={option.place_id}>
                                    <StyledStack direction="row">
                                      <Option>{option.description || "Not found"}</Option>
                                      <Button
                                        id={`btn_option_${option.place_id}`}
                                        variant="text"
                                        disableElevation
                                        onClick={() =>
                                          handleSelectClick(
                                            option.place_id,
                                            option.structured_formatting.main_text,
                                            'tax'
                                          )
                                        }
                                        name="Select"
                                      >
                                        Select
                                      </Button>
                                    </StyledStack>

                                    <Divider />
                                  </div>
                                );
                              })}
                            </OptionListContent>
                          </ResultContent>
                        }
                      />
                    </>
                  );
                }}
                control={control}
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                id="txt_investor_tax_address_street2"
                label="Address line 2"
                size="small"
                fullWidth
                {...register("taxAddress.street2")}
              />
            </Grid>
            <Grid xs={6} item>
              <Controller
                name="taxAddress.postalCode"
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  return (
                    <TextField
                      id="txt_investor_tax_address_zip"
                      label="Zip/Post-Code"
                      size="small"
                      fullWidth
                      value={value}
                      InputLabelProps={{ shrink: !!value }}
                      onChange={onChange}
                      inputProps={{ minLength: 5, maxLength: 7, pattern: "[0-9]" }}
                    />
                  );
                }}
                control={control}
              />
            </Grid>
            <Grid xs={6} item>
              <Controller
                name="taxAddress.city"
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  return (
                    <TextField
                      id="txt_investor_tax_address_city"
                      label="City"
                      size="small"
                      value={value}
                      InputLabelProps={{ shrink: !!value }}
                      fullWidth
                    />
                  );
                }}
                control={control}
              />

            </Grid>
            <Grid xs={6} item>
              <Controller
                name="taxAddress.state"
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  return (
                    <SingleSelect
                      id="select_investor_tax_address_state"
                      label="State/Province/Region"
                      fullWidth
                      noGutter
                      optionList={suggestedTaxStateList}
                      value={value}
                      handleOptionSelection={(e: SelectChangeEvent) => {
                        onChange({
                          target: {
                            value: e.target.value
                          }
                        });
                      }}
                    />
                  );
                }}
                control={control}
              />
            </Grid>
            <Grid xs={6} item>
              <Controller
                name="taxAddress.country"
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  return (
                    <SingleSelect
                      id="select_investor_tax_address_country"
                      label="Country"
                      fullWidth
                      noGutter
                      optionList={suggestedTaxCountryList}
                      value={value}
                      handleOptionSelection={(e: SelectChangeEvent) => {
                        onChange({
                          target: {
                            value: e.target.value
                          }
                        });
                      }}
                    />
                  );
                }}
                control={control}
              />
            </Grid>
          </>
        )}
        <br />

        {canUpdateInvestorTin ? (
          <Grid xs={6} item>
            <Controller
              name="taxIdentificationNumber"
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                return (
                  <TextField
                    id="txt_investor_tin"
                    label="TIN"
                    size="small"
                    fullWidth
                    value={value || ""}
                    inputProps={{ minLength: 5, maxLength: 32 }}
                    onChange={(e) => handleTinChange(e)}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.taxIdentificationNumber}
                    helperText={error?.message ?? ""}
                  />
                );
              }}
              control={control}
            />
          </Grid>
        ) : (
          <Grid xs={6} item>
            <Typography variant="subInfo">TIN</Typography>
            <Typography className="field-value">
              {investor?.taxIdentificationNumber
                ? investor?.taxIdentificationNumber
                : "-"}
            </Typography>
          </Grid>
        )}
        <Grid xs={6} item></Grid>
        <Grid xs={6} item>
          <Controller
            name="legalType"
            render={({
              field: { onChange, value },
              fieldState: { error }
            }) => {
              return (
                <TypeFormControl>
                  <InputLabel id='label_legal_type'>Legal Type</InputLabel>
                  <Select
                    id="select_legal_type"
                    labelId="label_legal_type"
                    label="Legal Type"
                    size="small"
                    value={(value && value.id) ? value.id : ''}
                    onChange={(e) => onChange(handleTypeChange(e, legalTypesList))}
                  >
                    {legalTypesList.map((value: any, index: any) => (
                      <MenuItem key={value.id} value={value.id}>{value.label}</MenuItem>
                    ))}
                  </Select>
                </TypeFormControl>
              );
            }}
            control={control}
          />
        </Grid>

        <Grid xs={6} item>
          <Controller
            name="disregardedEntity"
            render={({
              field: { onChange, value },
              fieldState: { error }
            }) => {
              return (
                <CheckBox>
                  <FormControlLabel
                    id="check_investor_disregarded"
                    label="Disregarded Entity"
                    control={<Checkbox />}
                    checked={value || false}
                    onChange={onChange}
                  />
                  <FormHelperText>Check if yes</FormHelperText>
                </CheckBox>
              );
            }}
            control={control}
          />
        </Grid>
        {disregardedEntity && (
          <>
            <Grid xs={6} item>
              <TextField
                id="txt_owner_name"
                size="small"
                fullWidth
                label="Owner Name"
                required
                error={!!errors.ownerName}
                helperText={errors.ownerName && "Owner Name is required"}
                {...register("ownerName", {
                  required: true,
                  validate: (value: any) => !!value?.trim()
                })}
              />
            </Grid>
            <Grid xs={6} item>
              <Controller
                name="ownerSSN"
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  return (
                    <TextField
                      id="txt_owner_ssn"
                      label="Owner SSN"
                      size="small"
                      fullWidth
                      required
                      value={value || ""}
                      inputProps={{ maxLength: 32 }}
                      onChange={(e) => handleOnNumberChange(e, "ownerSSN")}
                      error={!!errors.ownerSSN}
                      helperText={error?.message ?? ""}
                    />
                  );
                }}
                rules={{
                  required: "Owner SSN is required",
                }}
                control={control}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default InvestorDetails;
