import { DateRange } from "@mui/x-date-pickers-pro";
import { cloneDeep } from "lodash";
import { useContext, useEffect, useState } from "react";

import { CategoryFieldDef, GridData, GridRow, NumberFormatTypes, ReportGridBaseData, ValueFieldDef } from "../../../../../components/ReportGrid/ReportGrid.types";
import { AppContext } from "../../../../../core/context/appContextProvider";
import { getColumnOrder, saveColumnOrder } from "../../../../../services/columnOrder.service";
import { DateTimeFormat } from "../../../../../utils/helpers/format.helper";
import { awaitReactUpdate } from "../../../../../utils/helpers/timeoutFunctions";
import { useEffectAsync } from "../../../../../utils/hooks/useEffectAsync.hook";
import { ColumnOrder, ViewItemsEntity } from "../../../../../utils/types/columnOrder";
import { GlViews } from "../../NewGlReportList.hooks";
import { AccountNumber, ColumnTypes, createAccountAttrributeGridDataSums, createAccountChildrenSums, CreditRange, DateColumnInfo, DebitRange, EndBalanceRange, getFieldDefColumns, getLineItemsForDateCol, getTrialBalanceDetailsData, getTrialBalanceDetailsDataNew,GridCallerData,ReportAccount,StartBalanceRange,suppressGridRowsIfZero,TrialBalanceDetailsParams, ViewKeyCode, viewKeyOrderDefaults } from "../shared";

export const useTrialBalance = (
    params: TrialBalanceDetailsParams, 
    reportView: string, 
    suppressIfZero: boolean, 
    dateRange: DateRange<Date>,
    funds: any,
    attributes: any,
    viewFilters: any,
    setIsLoading: Function,
    handleValueLinkButtonClick: Function,
    onDataGridChange: (gridData: GridData, exportName: string) => void,
    handleDateRangeChange: (dateRange: DateRange<Date>, clearGrid: boolean) => void
  ) => {
    const [buildReportDataGridCounter, setBuildReportDataGridCounter] = useState(0);
    const [categoryFieldOrder, setCategoryFieldOrder] = useState<CategoryFieldDef[]>([]);
    const [currentCurrency, setCurrentCurrency] = useState('');
    const [currentDecimals, setCurrentDecimals] = useState(-1);
    const [dataFieldOrder, setDataFieldOrder] = useState<ValueFieldDef[]>([]);
    const [dateColInfosResponse, setDateColInfosResponse] = useState<DateColumnInfo[]>([]);
    const [dateColInfosResponseArray, setDateColInfosResponseArray] = useState<DateColumnInfo[]>([]);
    const [firstLineItemDate, setFirstLineItemDate] = useState<Date>();
    const [footerRollUp, setFooterRollUp] = useState(false);
    const [hideAllZeros, setHideAllZeros] = useState(false); //only false for Trial Balance, all other views are true
    const [hideUseerCategorySelector, setHideUseerCategorySelector] = useState(false);
    const [pinActionColumn, setPinActionColumn] = useState<boolean>(true);
    const [reportAccountsResponse, setReportAccountsResponse] = useState<ReportAccount[]>([]);
    const [reportGridBaseData, setReportGridBaseData] = useState<ReportGridBaseData[]>([]);
    const [rollupMode, setRollupMode] = useState(true);
    const [showGroups, setShowGroups] = useState(true);
    const [showMonthlyGroup, setShowMonthlyGroup] = useState(false);
    const [showQuarterlyGroup, setShowQuarterlyGroup] = useState(false);
    const [showYearlyGroup, setShowYearlyGroup] = useState(false);
    const [showStartBalance, setShowStartBalance] = useState(true);
    const [showSelected, setShowSelected] = useState(false);
    const [showDebit, setShowDebit] = useState(true);
    const [showCredit, setShowCredit] = useState(true);
    const [showItd, setShowItd] = useState(false);
    const [showEndBalance, setShowEndBalance] = useState(true);

    const [viewKeyResponse, setViewKeyResponse] = useState<ColumnOrder>();
    const { state, informationAlert } = useContext(AppContext);
    const clientId = state.loginUser.clientId;
    const [viewKeyCounter, setViewKeyCounter] = useState(0);
    const [viewKeyOrder, setViewKeyOrder] = useState<any>([]);

    const pushViewKeyOrder = (viewItem: any) => {
      setViewKeyOrder((prevArray: any) => [...prevArray, viewItem]);
    };


    useEffectAsync(async (isCanceled) => {
        setIsLoading(true);
        setViewKeyOrder([]);
        
        const response = await getTrialBalanceDetailsDataNew(params, isCanceled);

        if(!isCanceled() && !response ) return;

        const { reportAccounts, firstLineItemDate, decimals, currency } = response!;

        const viewKeyResponseData = await getColumnOrder(
          `${reportView.toUpperCase()}_FIELD_VIEW`,
          clientId
        );
        
        setViewKeyResponse(cloneDeep(viewKeyResponseData));

        viewKeyResponseData.viewItems?.map((viewItem) => {
          switch (viewItem.code) {
            case StartBalanceRange:
              viewItem.visible ? setShowStartBalance(true) : setShowStartBalance(false);
              pushViewKeyOrder(ViewKeyCode.START_BALANCE);
              break;
            case DebitRange:
              viewItem.visible ? setShowDebit(true) : setShowDebit(false);
              pushViewKeyOrder(ViewKeyCode.DEBIT);
              break;
            case CreditRange:
              viewItem.visible ? setShowCredit(true) : setShowCredit(false);
              pushViewKeyOrder(ViewKeyCode.CREDIT);
              break;
            case EndBalanceRange:
              viewItem.visible ? setShowEndBalance(true) : setShowEndBalance(false);
              pushViewKeyOrder(ViewKeyCode.END_BALANCE);
              break;
            default:
              if(viewItem.code.includes(ViewKeyCode.MONTHLY)){
                viewItem.visible ? setShowMonthlyGroup(true) : setShowMonthlyGroup(false);
                pushViewKeyOrder(ViewKeyCode.MONTHLY);
              }

              if(viewItem.code.includes(ViewKeyCode.QUARTERLY)){
                viewItem.visible ? setShowQuarterlyGroup(true) : setShowQuarterlyGroup(false);
                pushViewKeyOrder(ViewKeyCode.QUARTERLY);
              }

              if(viewItem.code.includes(ViewKeyCode.YEARLY)){
                viewItem.visible ? setShowYearlyGroup(true) : setShowYearlyGroup(false);
                pushViewKeyOrder(ViewKeyCode.YEARLY);
              }

              if(viewItem.code.includes(ViewKeyCode.ITD)){
                viewItem.visible? setShowItd(true) : setShowItd(false);
                pushViewKeyOrder(ViewKeyCode.ITD);
              }

              if(viewItem.code.includes(ViewKeyCode.SELECTED)){
                viewItem.visible? setShowSelected(true) : setShowSelected(false);
                pushViewKeyOrder(ViewKeyCode.SELECTED);
              }
          }
        });

        setCurrentCurrency(currency);
        setCurrentDecimals(decimals);
        setReportAccountsResponse(reportAccounts);
        if(params.startDate) { 
          setFirstLineItemDate(params.startDate);
        } else {
            setFirstLineItemDate(firstLineItemDate);
            handleDateRangeChange([firstLineItemDate, params.endDate], false);
        }

        setBuildReportDataGridCounter(buildReportDataGridCounter+1);

        setIsLoading(false);
    }, [suppressIfZero]);

    useEffectAsync(async (isCanceled) => {
      if(buildReportDataGridCounter === 0) return;
      setIsLoading(true);
      
      const response = await getTrialBalanceDetailsDataNew(params, isCanceled);

      if(!isCanceled() && !response ) return;

      const { reportAccounts, firstLineItemDate, decimals, currency } = response!;

      setCurrentCurrency(currency);
      setCurrentDecimals(decimals);
      setReportAccountsResponse(reportAccounts);
      if(params.startDate) { 
        setFirstLineItemDate(params.startDate);
      } else {
          setFirstLineItemDate(firstLineItemDate);
          handleDateRangeChange([firstLineItemDate, params.endDate], false);
      }

      setBuildReportDataGridCounter(buildReportDataGridCounter+1);

      setIsLoading(false);
  }, [showMonthlyGroup, showQuarterlyGroup, showYearlyGroup]);

    useEffectAsync( async () => {
        if(buildReportDataGridCounter === 0) return;

        setIsLoading(true);
        await awaitReactUpdate();

        const currencyFormat = `Currency.${currentDecimals}` as  NumberFormatTypes;
        const currentReportCategoryFieldDefs = await getCategoryFieldOrderData();//Will be connected to API when done

        if(suppressIfZero) suppressGridRowsIfZero(reportAccountsResponse, false); //will be true for other reports
        let fieldDefsArray: ValueFieldDef[] = [];
        let dateColInfosArray: DateColumnInfo[] = [];

        let viewKeyOrderData = viewKeyOrder.filter((value: any, index: any) => viewKeyOrder.indexOf(value) === index);
        
        if(viewKeyOrderData.length < 9) viewKeyOrderData = viewKeyOrderDefaults;
    

        const startDate = dateRange[0] ? dateRange[0] : firstLineItemDate;
        const { fieldDefs, dateColInfos } = getFieldDefColumns(
              startDate!,
              dateRange[1]!,
              currencyFormat,
              hideAllZeros,
              showStartBalance,
              showMonthlyGroup,
              showQuarterlyGroup,
              showYearlyGroup,
              showSelected,
              showDebit,
              showCredit,
              showItd,
              showEndBalance,
              viewKeyResponse,
              viewKeyOrderData,
              false,
              reportView);
      
            fieldDefsArray = fieldDefs;
            dateColInfosArray = dateColInfos;
            setDateColInfosResponseArray(dateColInfos);
          
          const filterDateColInfos: DateColumnInfo[] = [];
  
          dateColInfosArray.map((column) => {
            if(column.category === undefined){
                filterDateColInfos.push(column);
            }
            if(showMonthlyGroup){
                column.category === ColumnTypes.Monthly ? filterDateColInfos.push(column) : "";
            }
            if(showQuarterlyGroup){
                column.category === ColumnTypes.Quarterly ? filterDateColInfos.push(column) : "";
            }
            if(showYearlyGroup){
                column.category === ColumnTypes.Yearly ? filterDateColInfos.push(column) : "";
            }
          });
      
          fieldDefsArray.map((field) => {
            if(field.category === ColumnTypes.Monthly){
              field.visible = showMonthlyGroup;
            }
            if(field.category === ColumnTypes.Quarterly){
              field.visible = showQuarterlyGroup;
            }
            if(field.category === ColumnTypes.Yearly){
              field.visible = showYearlyGroup;
            }
          });

          const reportGridData = buildReportGridData(reportAccountsResponse, filterDateColInfos, currentCurrency);

          const suppressed = reportGridData.filter((row: any) => {
              const categoryPropName = "account_name_2";
        
              const dataPropsValue = Object.entries(row.dataProps);
              const child = row.categoryProps && row.categoryProps[categoryPropName] !== undefined;
              
              return child || !dataPropsValue.every(([key, value]) => (key === "Account_Number") || value === 0);
            });

        setCategoryFieldOrder(cloneDeep(currentReportCategoryFieldDefs));
        setDataFieldOrder(cloneDeep(fieldDefsArray));
        setDateColInfosResponse(cloneDeep(filterDateColInfos));
        setPinActionColumn(true);
        setReportGridBaseData(suppressIfZero ? cloneDeep(suppressed) : cloneDeep(reportGridData)); 

        setIsLoading(false);
    }, [buildReportDataGridCounter]);

    const dateRangeFilter = { 
      name: 'Date Range',
      title: 'Report Range',
      visible: false,
      useLinkButton: false,
      startDate: dateRange[0]!, 
      endDate: dateRange[1]!
    };

    function buildReportGridData(reportAccounts: ReportAccount[], dateColInfos: DateColumnInfo[], responseCurrency: string): ReportGridBaseData[] {
        const reportGridData: ReportGridBaseData[] = [];

            for(let i=0; i<reportAccounts.length; i++) {
                const account = reportAccounts[i];
                const fund = funds.find((f: any) => f.fund.id === account.fundId);
                const categoryProps = getDefaultCategoryProperties(account, fund);

                const dataProps = {} as any;

                // Set the dataProps based on the account api response data
                dataProps[EndBalanceRange] = account.endBalance;
                dataProps[StartBalanceRange] = account.startBalance;
                dataProps[AccountNumber] = account.accountNo;

                // Filter through each column and pass it the date range selected "dc" to filter items in range and type
                // getLineItems is part of the shared.tsx
                dateColInfos.filter(cd => 
                        cd.name !== StartBalanceRange && 
                        cd.name !== EndBalanceRange && 
                        cd.numberFormat !== 'Text'
                    ).forEach(dc => {
                        let lineItemsInRange = getLineItemsForDateCol(account.lineItems, dc);
                        
                        const groupedColumn = dc.name.includes('month') || dc.name.includes('quarter') || dc.name.includes('year') ? true : false;
              
                        if(groupedColumn) lineItemsInRange = getLineItemsForDateCol(lineItemsInRange, dateRangeFilter);
                                                
                        const value = lineItemsInRange.reduce((prevVal, lineItem) => prevVal + lineItem.amount, 0);
            
                        dataProps[dc.name] = value ?? 0;

                        if(dc.name === CreditRange || dc.name === DebitRange) dataProps[dc.name] = Math.abs(dataProps[dc.name]);
                    }); 
            
                    const callerData: GridCallerData = {
                      reportAccounts: [account]
                    };
            
                    const gridData = {
                        itemId: account.accountId,
                        currencyCode: responseCurrency,
                        categoryProps: categoryProps,
                        dataProps: dataProps,
                        callerData: callerData,
                        decimals: currentDecimals
                    } as ReportGridBaseData;
            
                    reportGridData.push(gridData);
                }

            createAccountAttrributeGridDataSums(reportGridData, viewFilters.filteredAttributesFilter, dateColInfos, currentCurrency);
            createAccountChildrenSums(reportGridData, dateColInfos, categoryFieldOrder, currentCurrency, false); //false because is not custom

            return reportGridData;
    }

    function getDefaultCategoryProperties(account: ReportAccount, fund: any): any {
            const attrib = attributes.find((f: any) => f.id === account.attributeId);
    
            const categoryProps = {
            fundId: fund.fund.id,
            fundName: fund.fund.fullName,
            attributeId: attrib.id,
            attributeName: attrib.type,
            accountId: account.accountId,
            accountName: account?.accountNo ? account.accountNo + " - " + account.accountName : account.accountName,
            fsDisplayId: account.fsMappingId,
            fsDisplay: account.fsName
            };
    
            return categoryProps;
    }

    function handleCategoryFieldOrderChange(updatedCategoryFieldOrder: CategoryFieldDef[]) {
        setCategoryFieldOrder(cloneDeep(updatedCategoryFieldOrder));
        saveCategoryFieldOrder(updatedCategoryFieldOrder);
      }

    function handleDataFieldOrderChange(updatedDataFieldOrder: ValueFieldDef[], fieldName?: string) {
        if(fieldName){
        if (fieldName === ColumnTypes.Monthly || fieldName === ColumnTypes.Quarterly || fieldName === ColumnTypes.Yearly){
          switch (fieldName) {
            case 'Months': {
              setShowMonthlyGroup(!showMonthlyGroup);
              break;
            }
            case 'Quarterly': {
              setShowQuarterlyGroup(!showQuarterlyGroup);
              break;
            }
            case 'Yearly': {
              setShowYearlyGroup(!showYearlyGroup);
            }
          }

          updatedDataFieldOrder.map((column: any) => {
            if (column.category === fieldName){
              column.visible = !column.visible;
            }
          });
          setDataFieldOrder(cloneDeep(updatedDataFieldOrder));
          setViewKeyCounter(viewKeyCounter+1);

          if(viewKeyCounter !== 0){
              saveFieldDefOrder(cloneDeep(updatedDataFieldOrder));
            }
        } else {

          const dataField = updatedDataFieldOrder.find(field => field.name === fieldName)!;
         
          dataField.visible = !dataField.visible;
      
          setDataFieldOrder(cloneDeep(updatedDataFieldOrder));
          setViewKeyCounter(viewKeyCounter+1);

          if(viewKeyCounter !== 0){
              saveFieldDefOrder(cloneDeep(updatedDataFieldOrder));
            }
      }
    } else {
      setDataFieldOrder(cloneDeep(updatedDataFieldOrder));
      setViewKeyCounter(viewKeyCounter+1);

      if(viewKeyCounter !== 0){
        saveFieldDefOrder(cloneDeep(updatedDataFieldOrder));
      }
    }
  }

    async function saveFieldDefOrder(updatedDataFieldOrder: ValueFieldDef[]){
        const firstOccurence: any = {};
        const itemMap = updatedDataFieldOrder.filter(item => {
          const type = item.name.includes(ViewKeyCode.MONTHLY) ? ViewKeyCode.MONTHLY :
                        item.name.includes(ViewKeyCode.QUARTERLY) ? ViewKeyCode.QUARTERLY :
                        item.name.includes(ViewKeyCode.YEARLY) ? ViewKeyCode.YEARLY :
                        null;

              if (type && !firstOccurence[type]){
                firstOccurence[type] = true;
                return true;
              }

              return !type;
              });

        let orderNum = 0;     
        const viewItems = itemMap.map((catCol) => {
          orderNum += 1;

          return {
            label: catCol.headerName,
            code: catCol.name,
            order: orderNum,
            visible: catCol.visible,
          } as ViewItemsEntity;
        });
        
        viewItems.sort((a,b) => a.order - b.order);
        const newColumnOrder: ColumnOrder = {
          clientId: clientId,
          viewKey: `${reportView.toUpperCase()}_FIELD_VIEW`,
          viewItems: viewItems,
        };
        
        try {
          await saveColumnOrder(newColumnOrder);
        } catch (e) {
          informationAlert("Error saving column order.", "error");
        }
      }

    function handleValueClick(gridRow: GridRow, valueFieldOrder: ValueFieldDef) {
        handleValueLinkButtonClick(gridRow, valueFieldOrder, dateColInfosResponse, false, currentDecimals); //false because not custom   
      }

    function handleDataGridChange(gridData: GridData) {
        onDataGridChange(gridData, `report export ${DateTimeFormat.getFormattedDate(new Date())}.csv`);
      }

      function getDefaultCategories(): CategoryFieldDef[] {
        return[
          {
            id: 'fundId',
            name: 'fundName',
            headerName: 'Fund',
            visible: false,
            order: 1,
            useLinkButton: false
          }, {
            id: 'attributeId',
            name: 'attributeName',
            headerName: 'Attribute',
            visible: false,
            order: 2,
            useLinkButton: false
          }, {
            id: 'accountId',
            name: 'accountName',
            headerName: 'Account Name',
            visible: true,
            order: 3,
            useLinkButton: false
          }, {
            id: 'fsDisplayId',
            name: 'fsDisplay',
            headerName: 'FS Display',
            visible: false,
            order: 4,
            useLinkButton: false
          }
        ];
      }

    function ensureCorrectColSortOrder(cols: CategoryFieldDef[]) {
        const orderedCols = cols.sort((a, b) => a.order - b.order);
    
        for (let i = 0; i < orderedCols.length; i++) {
          const col = orderedCols[i];
    
          col.order = i + 1;
        }
      }
    

    async function getCategoryFieldOrderData() {
      let colOrderResponse: any;

      try {
        colOrderResponse = await getColumnOrder(
          `${reportView.toUpperCase()}_CATEGORY_VIEW`,
          clientId
        );
      } catch (e) {
        informationAlert("Error retrieving initial order of categories.", "error");
      }

      const defaultCatOrder = getDefaultCategories();

      if (
        !colOrderResponse ||
        !colOrderResponse.viewItems ||
        colOrderResponse.viewItems!.length === 0
      ) {
        return defaultCatOrder;
      }

      const savedCatOrder = defaultCatOrder.map((col) => {
        const savedCol = colOrderResponse.viewItems?.find(
          (colItem: { code: string }) => colItem.code === col.name
        );
  
        if (savedCol) {
          return {
            ...col,
            order: savedCol.order,
            visible: savedCol.visible,
          } as CategoryFieldDef;
        }
        return col;
      });

      ensureCorrectColSortOrder(savedCatOrder);

      return savedCatOrder!;
    }

    async function saveCategoryFieldOrder(
      updatedCategoryFieldOrder: CategoryFieldDef[]
    ) {
      if (!updatedCategoryFieldOrder || updatedCategoryFieldOrder.length === 0)
        return;
  
      const viewItems = updatedCategoryFieldOrder.map((catCol) => {
        return {
          label: catCol.headerName,
          code: catCol.name,
          order: catCol.order,
          visible: catCol.visible,
        } as ViewItemsEntity;
      });
  
      const viewKey = `${reportView.toUpperCase()}_CATEGORY_VIEW`;
  
      const newColumnOrder: ColumnOrder = {
        clientId: clientId,
        viewKey: viewKey,
        viewItems: viewItems,
      };
  
      try {
        await saveColumnOrder(newColumnOrder);
      } catch (e) {
        informationAlert("Error saving categories order.", "error");
      }
    }

    return {
        categoryFieldOrder,
        currentCurrency,
        currentDecimals,
        dataFieldOrder,
        footerRollUp,
        handleCategoryFieldOrderChange,
        handleDataFieldOrderChange,
        handleDataGridChange,
        handleValueClick,
        hideUseerCategorySelector,
        pinActionColumn,
        reportGridBaseData,
        rollupMode,
        setShowGroups,
        setShowMonthlyGroup,
        setShowQuarterlyGroup,
        setShowYearlyGroup,
        showGroups,
        showMonthlyGroup,
        showQuarterlyGroup,
        showYearlyGroup,
    };
};
