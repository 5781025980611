import { SetStateAction, useMemo, useState } from "react";

import {
  getAvailableRoles,
  getContact,
  getContactFilterList,
  getContactInvestmentsByPage,
  getContacts,
} from "../../../services/contact.service";
import {
  getFundsFilterList,
  getStatusFilterList,
} from "../../../services/filters.service";
import { getInvestorFilters } from "../../../services/investor.service";
import { GENERIC_ERROR_MESSAGE } from "../../../utils/constants/text.constants";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { ContactPermission } from "../../../utils/types/contactPermission.type";
import { FundItem } from "../../../utils/types/fund.type";
import { InvestorFilter } from "../../../utils/types/investor.type";
import {
  FilterItem,
  ImageItem,
  ListItem,
} from "../../../utils/types/listItems";
import { StatusFilter } from "../../../utils/types/statusFilter.type";
import { formattedContactInvestmentsList } from "../contactsHelpers/contactHelper";


const defaultBulkActions: ImageItem[] = [
  {
    id: "export",
    text: "Export All",
    icon: undefined,
    optionsSelected: 0,
  },
  {
    id: "approve",
    text: "Approve All",
    icon: undefined,
    optionsSelected: 0,
  },
];

export const useLockGridScreen = () => {
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  useMemo(() => setIsScreenLocked(isScreenLocked), []);
  return {
    isScreenLocked,
    setIsScreenLocked,
  };
};

export const useGridPermissionChange = () => {
  const [isGridPermissionChanged, setIsGridPermissionChanged] =
    useState<boolean>(false);

  useMemo(() => setIsGridPermissionChanged(isGridPermissionChanged), []);
  return {
    isGridPermissionChanged,
    setIsGridPermissionChanged,
  };
};

export const useSelectChange = () => {
  const [currentSelectedVal, setCurrentSelectedVal] = useState<string>("");

  useMemo(() => setCurrentSelectedVal(currentSelectedVal), []);
  return {
    currentSelectedVal,
    setCurrentSelectedVal,
  };
};

let contactsCount: number = 0;

export const useContactsPemissionsEffect = (
  clientId: string,
  informationAlert: any,
  preloadedContactId: string,
  setPermissionsByInvestorList: any,
  setLoadingContactInPanel: any
) => {
  const [contactSelectionList, setContactSelectionList] = useState<ListItem[]>(
    []
  );
  const [isLoadingContactList, setLoadingContactList] = useState(false);
  const [bulkActionOptions, setBulkActionOptions] =
    useState<ImageItem[]>(defaultBulkActions);
  const [contactDetail, setContactDetail] = useState<ContactPermission>();
  const [fundFilters, setFundFilters] = useState<FundItem[]>([]);
  const [statusFilters, setStatusFilters] = useState<StatusFilter[]>([]);
  const [investorFilters, setInvestorFilters] = useState<InvestorFilter[]>([]);
  const [roleFilters, setRoleFilters] = useState<FilterItem[]>([]);
  const [selectedContact, setSelectedContact] = useState("");
  const [selectedInvestors, setSelectedInvestors] = useState<Set<string>>(new Set());
  const [loadingContacts, setLoadingContacts] = useState<boolean>(false);
  const [openContactChangeConfirmation, setOpenContactChangeConfirmation] =
    useState<boolean>(false);

  useEffectAsync(async (isCanceled) => {
    try {
      if (!preloadedContactId) setLoadingContactList(true);
      else setLoadingContactInPanel(true);
      setSelectedInvestors(new Set());
      const fundsFilterResponse = await getFundsFilterList();

      if (isCanceled()) return;
      setFundFilters(fundsFilterResponse);

      const statusResponse = await getStatusFilterList();

      if (isCanceled()) return;
      setStatusFilters(statusResponse);

      const investorFiltersResponse = await getInvestorFilters();

      if (isCanceled()) return;
      setInvestorFilters(investorFiltersResponse);

      const roleFiltersResponse = await getAvailableRoles();

      if (isCanceled()) return;
      setRoleFilters(roleFiltersResponse);

      if (!preloadedContactId) {
        const contactsResponse = await getContactFilterList();

        contactsCount = contactsResponse.length;
        if (isCanceled()) return;

        const selectionList = contactsResponse.map((client) => {
          return { id: client.id, label: (`${client.name} (${client.email})`).trim(), name: client.name.trim().toLowerCase(), email: client.email.trim() };
        }).sort((a, b) => -b.name.localeCompare(a.name));

        setContactSelectionList(selectionList);
      }
      setSelectedContact(preloadedContactId);
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    } finally {
      if (!preloadedContactId) setLoadingContactList(false);
      else setLoadingContactInPanel(false);
    }
  }, []);

  useEffectAsync(
    async (isCanceled) => {
      if (!selectedContact) return;
      try {
        if (!preloadedContactId) setLoadingContactList(true);
        else setLoadingContactInPanel(true);

        if (preloadedContactId !== "new") {
          const contactInvestmentsResponse: ContactPermission = {
            ...await getContactInvestmentsByPage(
              selectedContact,
              fundFilters,
              investorFilters,
              roleFilters
            )
          };

          if (isCanceled()) return;

          const formattedList = formattedContactInvestmentsList(
            contactInvestmentsResponse,
            preloadedContactId,
            investorFilters,
            fundFilters,
          );

          setPermissionsByInvestorList(formattedList);
          setContactDetail(contactInvestmentsResponse);
        }
      } catch (e) {
        informationAlert(GENERIC_ERROR_MESSAGE, "error");
      } finally {
        if (!preloadedContactId) setLoadingContactList(false);
        else setLoadingContactInPanel(false);
      }
    },
    [selectedContact]
  );

  const onInvestorFilterChange = async (items: any) => {
    try {
      setLoadingContacts(true);
      const selectedInvestors = items.filter((item: any) => item.selected);
      const ids: Array<string> = selectedInvestors.length === 0 ? items.map((item: any) => item.id) : selectedInvestors.map((item: any) => item.id);

      setSelectedInvestors(new Set(selectedInvestors.length === 0 ? [] : ids));

      const contactsResponse = await getContacts(
        fundFilters,
        statusFilters,
        ids,
        roleFilters,
        "",
        0,
        contactsCount,
      );

      const selectionList = contactsResponse.map((client) => {
        return { id: client.id, label: (`${client.name} (${client.email})`).trim(), name: client.name.trim().toLowerCase(), email: client.email.trim() };
      }).sort((a, b) => -b.name.localeCompare(a.name));

      setContactSelectionList(selectionList);
    } catch (e) {
      informationAlert("Unable to filter contacts by investor.", "error");
    } finally {
      setLoadingContacts(false);
    }
  };

  return {
    isLoadingContactList,
    bulkActionOptions,
    contactSelectionList,
    contactDetail,
    fundFilters,
    statusFilters,
    investorFilters,
    roleFilters,
    selectedContact,
    selectedInvestors,
    openContactChangeConfirmation,
    setSelectedContact,
    setLoadingContactList,
    setContactDetail,
    setOpenContactChangeConfirmation,
    onInvestorFilterChange,
    loadingContacts,
  };
};
