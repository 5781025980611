import {
  Autocomplete,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { RouteProps } from "react-router-dom";

import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../../components/Buttons/Button/Button";
import DetailPanel from "../../../../components/DetailPanel/DetailPanel";
import { FormBox, Panel, Typography } from "../../../../components/DetailPanel/DetailPanel.styles";
import ConfirmationDialog from "../../../../components/Modal/ConfirmationDialog";
import Switch from "../../../../components/Switch/Switch";
import { EMAIL_REGEX, PHONE_REGEX } from "../../../../utils/constants/constants";
import { ArkGLEntity, SelectedEntity } from "../../../../utils/types/arkGLEntity.type";
import { DetailsType } from "../../../../utils/types/form.type";
import { useEntityDetails } from "./EntityDetails.hooks";
import { SelectFormControl, StyledTypography } from "./EntityDetails.styles";

interface Props extends RouteProps {
  onDetailClose: Function;
  selectedEntity: SelectedEntity;
  setSelectedEntity: Function;
  fetchAllEntities: Function;
  entityResponse: ArkGLEntity[];
  fundId?: string;
}

export const EntityDetails: React.FC<Props> = ({
  onDetailClose,
  entityResponse,
  selectedEntity,
  setSelectedEntity,
  fetchAllEntities,
}: Props) => {
  const { type, entity } = selectedEntity;
  const isNewEntity = type === DetailsType.New;

  const {
    isLoading,
    toggleDrawer,
    handleSubmit,
    createUpdateEntity,
    control,
    isSubmitted,
    trigger,
    getValues,
    setValue,
    register,
    errors,
    entityTypeList,
    entityTypeValidator,
    countryList,
    stateList,
    showExitConfirmation,
    keepDrawerOpen,
    closeDrawer,
  } = useEntityDetails({
    entity,
    entityResponse,
    onClose: onDetailClose,
    fetchAllEntities,
    isNewEntity,
  });

  return (
    <>
      <DetailPanel
          id="detail_panel_entity"
          title={isNewEntity ? "Add Entity" : entity?.name}
          open={Boolean(type)}
          variant={"temporary"}
          hasTabsPanel={false}
          onClose={toggleDrawer}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit(createUpdateEntity)}
          noValidate
        >
          <FormBox>
            <Panel id="entity_details_panel">
              <Typography variant="h4">Details</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="name"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <TextField
                            id="txt_entity_name"
                            label="Entity Name"
                            aria-describedby="name"
                            placeholder="Entity Name"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={value ?? ""}
                            onChange={(event) => {
                              const name = event.target.value;

                              onChange(event);
                              if (name) {
                                setValue("name", name);
                              }
                            }}
                            InputLabelProps={
                              !isNewEntity ? { shrink: !!value } : {}
                            }
                            error={!!error}
                            helperText={error?.message ?? ""}
                          />
                        );
                      }}
                      rules={{
                        validate: (value) =>
                          value?.trim() ? true : "Entity Name is required",
                      }}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="txt_entity_email"
                      label="Email"
                      aria-describedby="Email"
                      placeholder="Email"
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={!!errors.emailAddress}
                      {...register("emailAddress", {
                        required: false,
                        pattern: EMAIL_REGEX,
                      })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="txt_entity_phoneNumber"
                      label="Phone"
                      aria-describedby="Phone"
                      placeholder="Phone"
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={!!errors.phoneNumber}
                      {...register("phoneNumber", {
                        required: false,
                        pattern: PHONE_REGEX,
                      })}
                      helperText={errors.phoneNumber ? "Valid phone number required" : ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="txt_entity_steet1"
                      label="Address line 1"
                      aria-describedby="Address line 1"
                      placeholder="Address line 1"
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={!!errors.mailingAddress?.street1}
                      {...register("mailingAddress.street1", {
                        required: false,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="txt_entity_steet2"
                      label="Address line 2"
                      aria-describedby="Address line 2"
                      placeholder="Address line 2"
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={!!errors.mailingAddress?.street2}
                      {...register("mailingAddress.street2", {
                        required: false,
                      })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="txt_entity_zip"
                      label="Zip/Postal Code"
                      aria-describedby="Zip/Postal Code"
                      placeholder="Zip/Postal Code"
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={!!errors.mailingAddress?.postalCode}
                      {...register("mailingAddress.postalCode", {
                        required: false,
                      })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="txt_entity_city"
                      label="City"
                      aria-describedby="City"
                      placeholder="City"
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={!!errors.mailingAddress?.city}
                      {...register("mailingAddress.city", {
                        required: false,
                      })}
                    />
                  </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="mailingAddress.country"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <SelectFormControl>
                          <InputLabel id="label_country" error={!!error}>
                            Country
                          </InputLabel>
                          <Select
                            id="select_country"
                            labelId="label_country"
                            label="Country"
                            size="small"
                            value={value ? value : ""}
                            onChange={(e) => onChange(e.target.value)}
                            error={!!error}
                          >
                            {countryList.map((country: any) => (
                              <MenuItem key={country.id} value={country.id}>
                                {country.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error>
                            {error?.message ?? ""}
                          </FormHelperText>
                        </SelectFormControl>
                      );
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="mailingAddress.state"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <SelectFormControl>
                          <InputLabel id="label_state/province" error={!!error}>
                            State/Province/Region
                          </InputLabel>
                          <Select
                            id="select_state/province"
                            labelId="label_state/province"
                            label="State/Province"
                            size="small"
                            value={value ? value : ""}
                            onChange={(e) => onChange(e.target.value)}
                            error={!!error}
                          >
                            {stateList.length ? stateList.map((state: any) => (
                              <MenuItem key={state.id} value={state.id}>
                                {state.label}
                              </MenuItem>
                            )) : (<MenuItem key={"1"} value={"Select Country First"}>
                              Select Country first for options.
                            </MenuItem>)}
                          </Select>
                          <FormHelperText error>
                            {error?.message ?? ""}
                          </FormHelperText>
                        </SelectFormControl>
                      );
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                      id="txt_entity_TIN"
                      label="TIN"
                      aria-describedby="TIN"
                      placeholder="TIN"
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={!!errors.tin}
                      {...register("tin", {
                        required: false,
                        pattern: /^[a-zA-Z0-9\\/-]{1,32}$/
                      })}
                      helperText={errors.tin ? "Check formatting of TIN. Max 32 character limit." : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="type"
                    render={() => {
                      return (
                        <Autocomplete
                          id="entry_type_txt_dropdown"
                          aria-describedby="Type"
                          freeSolo
                          defaultValue={{ name: getValues("type") }}
                          includeInputInList={true}
                          forcePopupIcon={true}
                          disableClearable={false}
                          options={entityTypeList.map((option) => {
                            return { name: option };
                          })}
                          getOptionLabel={(option) => option.name || ""}
                          onChange={(_: any, newValue: any) => {
                            setValue("type", newValue.name);
                            if (isSubmitted) trigger("type");
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="entry_type_txt"
                              label="Type"
                              variant="outlined"
                              placeholder="Type"
                              size="small"
                              fullWidth
                              error={!!errors.type}
                              {...register("type", {
                                validate: (value) => entityTypeValidator(value),
                              })}
                              helperText={errors.type?.message ?? ""}
                            />
                          )}
                        />
                      );
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={2}>
                  <StyledTypography>is 1099?</StyledTypography>
                </Grid>
                <Grid item xs={2} sx={{ marginTop: "4px", marginLeft: -3 }}>
                  <Controller
                    control={control}
                    name="is1099"
                    defaultValue={false}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (<>
                        <FormControlLabel
                          id="check_1099"
                          label={value ? "YES" : "NO"}
                          control={
                            <Switch
                              id="switch_1099"
                              checked={value}
                              label="1099"
                              onChange={(event: any, val: any) => {
                                if (val) {
                                  setValue(`is1099`, value);
                                }
                                return onChange(val);
                              }}
                            />
                          }
                        />
                      </>);
                    }}
                  />
                </Grid>
                </Grid>
              </Panel>
            </FormBox>
            <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
            >
              <Button
                id={"btn_account_cancel"}
                variant="outlined"
                onClick={toggleDrawer}
                text={"Cancel"}
                color={"secondary"}
                fullWidth
                addSpaceBetweenButtons
              />
              <Button
                id={"btn_account_save"}
                variant="contained"
                text={"Save & Close"}
                color={"primary"}
                type="submit"
                fullWidth
                addSpaceBetweenButtons
              />
            </HorizontalBox>
          </form>
        </DetailPanel>
        <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_account_confirmation"
        actions={[
          {
            label: "Keep Editing",
            onClick: keepDrawerOpen,
            id: "btn_account_save",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Discard Changes",
            onClick: closeDrawer,
            id: "btn_account_cancel_save",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Unsaved changes will be lost"
        title="You have Unsaved Changes"
      />
      </>
  );
};

 