import { Files } from '../utils/types/documents.type';
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Apiv2);
};

// Accounts
export const getTransAccounts = async (fundId: string) =>
  await apiClient().get<any>(
    `/ledgers/accounts?flat=true&fundId=${fundId}&glPlatform=ARK_LEDGER&sync=true`
  );

export const getArkAccounts = async (fundId: string) =>
  await apiClient().get<any>(`ark-ledger/accounts?fundId=${fundId}`);

export const getArkAccountNames = async (fundId?: string) => {
  return fundId
    ? await apiClient().get<any>(
        `ark-ledger/accounts?fundId=${fundId}&simple=true`
      )
    : await apiClient().get<any>(`ark-ledger/accounts?simple=true`);
};

export const createAccount = async (data: any) =>
  await apiClient().post<any>(`ark-ledger/accounts`, data);

export const updateAccount = async (accountId: string, data: any) =>
  await apiClient().put<any>(`ark-ledger/accounts/${accountId}`, data);

export const deleteAccount = async (accountId: string) =>
  await apiClient().delete<any>(`ark-ledger/accounts/${accountId}`);

export const getAttributes = async () =>
  await apiClient().get<any>(`ark-ledger/account-attributes`);

export const createAccountsFromTemplate = async (templateId: string) =>
  await apiClient().get<any>(`ark-ledger/accounts/template/${templateId}`);

export const bulkSaveAccounts = async (data: any) =>
  await apiClient().post<any>(`ark-ledger/accounts/bulk-save`, data);

export const bulkUpdateAccounts = async (data: any) =>
  await apiClient().put<any>(`ark-ledger/accounts/bulk-update`, data);

export const postAccounts = async (accountIds: string[]) =>
  await apiClient().put<any>(`ark-ledger/accounts/state`, {
    accountIds,
  });

export const bulkCreateAccounts = async (data: any, fundId: string) => {
  const formData = new FormData();

  formData.append('file', data);
  await apiClient().post<any>(
    `ark-ledger/accounts/templates/template/${fundId}/accounts?saveAccounts=true`,
    formData
  );
};

export const bulkDeleteAccounts = async (accountIds: string[]) =>
  await apiClient().post<any>(`ark-ledger/accounts/delete`, { accountIds });

export const importAccountsTemplate = async (
  fundId: any,
  templateType: string
) => {
  const templateFile = await apiClient().get(
    `ark-ledger/accounts/templates/template/00000000-0000-0000-0000-000000000000/${templateType}`
  );

  return templateFile;
};

export const downloadAccountsTemplate = async () => {
  const templateFile = await apiClient().get(
    `ark-ledger/accounts/templates/00000000-0000-0000-0000-000000000000`
  );

  return templateFile;
};

// Ledgers
export const getLedgers = async (fundId?: string) => {
  return fundId
    ? await apiClient().get<any>(`ledgers?fundId=${fundId}`)
    : await apiClient().get<any>(`ledgers`);
};

export const getArkLedgers = async (fundId?: string) => {
  return fundId
    ? await apiClient().get<any>(`ark-ledger/ledgers?fundId=${fundId}`)
    : await apiClient().get<any>(`ark-ledger/ledgers`);
};

export const getLedgerDetails = async (ledgerId: any) =>
  await apiClient().get<any>(`ark-ledger/ledgers/${ledgerId}`);

export const createLedger = async (data: any) =>
  await apiClient().post<any>(`ark-ledger/ledgers`, data);

export const updateLedger = async (ledgerId: string, data: any) =>
  await apiClient().put<any>(`ark-ledger/ledgers/${ledgerId}`, data);

export const deleteSelectedLedgers = async (data: any) =>
  await apiClient().post<any>(`ark-ledger/ledgers/delete`, data);

export const postLedgers = async (ledgerIds: string[]) =>
  await apiClient().put<any>(`ark-ledger/ledgers/state`, {
    ledgerIds,
  });

// Journal Entries
export const getJournalEntry = async (id: string) =>
  await apiClient().get<any>(`ark-ledger/journal-entries/${id}`);

export const getJournalEntries = async (fundId?: string, legerId?: string) => {
  return fundId
    ? await apiClient().get<any>(`ark-ledger/journal-entries?fundId=${fundId}`)
    : await apiClient().get<any>(`ark-ledger/journal-entries`);
};

export const createJournalEntry = async (
  data: any,
  files: FileList | File[] | null
) => {
  if (files) {
    const formData = new FormData();

    formData.append(
      'journalEntry',
      new Blob([JSON.stringify(data)], {
        type: 'application/json',
      })
    );

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    return apiClient().post(`ark-ledger/journal-entries`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } else {
    return await apiClient().post<any>(`ark-ledger/journal-entries`, data);
  }
};

export const updateJournalEntry = async (
  journalEntryId: string,
  data: any,
  files: FileList | File[] | null
) => {
  if (files) {
    const formData = new FormData();

    formData.append(
      'journalEntry',
      new Blob([JSON.stringify(data)], {
        type: 'application/json',
      })
    );

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    return await apiClient().put<any>(
      `ark-ledger/journal-entries/${journalEntryId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  } else {
    return await apiClient().put<any>(
      `ark-ledger/journal-entries/${journalEntryId}`,
      data
    );
  }
};

export const deleteJournalEntry = async (journalEntryId: string) =>
  await apiClient().delete<any>(`ark-ledger/journal-entries/${journalEntryId}`);

export const getAllArkAccounts = async () =>
  await apiClient().get<any>(`ark-ledger/accounts`);

export const postJournalEntries = async (data: any) =>
  await apiClient().post<any>(
    `ark-ledger/journal-entries/state/bulk-post`,
    data
  );

export const searchAllJournalEntries = async (reqBody: any) =>
  await apiClient().post<any>(`ark-ledger/journal-entries/search`, reqBody);

export const deleteBulkJournalEntries = async (data: any) =>
  await apiClient().post<any>(`ark-ledger/journal-entries/bulk-delete`, data);

export const deleteBulkJournalEntriesPreview = async (data: any) =>
  await apiClient().post<any>(
    `ark-ledger/journal-entries/bulk-delete?preview=true`,
    data
  );

export const exportJournalEntries = async (data: any) =>
  await apiClient().post<any>(`ark-ledger/journal-entries`, data);

export const exportAllJournalEntries = async () =>
  await apiClient().post<any>(`ark-ledger/journal-entries/export`);

export const exportSelectedJournalEntries = async (data: any) =>
  await apiClient().post<any>(`ark-ledger/journal-entries/export`, data);

export const connectInvestmentTransactionToJournalEntry = async (data: any) =>
  await apiClient().post<any>(`ledgers/ark/journal-entries/transaction`, data);

export const connectTransactionsToJournalEntry = async (
  id: string,
  data: any
) =>
  await apiClient().post<any>(
    `ledgers/ark/journal-entries/${id}/transactions`,
    data
  );

export const connectCashReceiptAdjustmentToJournalEntry = async (
  journalEntryId: string,
  data: any
) =>
  await apiClient().post<any>(
    `ledgers/ark/journal-entries/${journalEntryId}/:transactionAdjustments`,
    data
  );

export const getJournalEntriesByInvestmentTransaction = async (
  investmentTransactionId: string
) =>
  await apiClient().get<any>(
    `ledgers/journal-entries?investmentTransactionId=${investmentTransactionId}`
  );

export const getInvestmentTransactionsByJournalEntry = async (
  journalEntryId: string
) =>
  await apiClient().get<any>(
    `ledgers/journal-entries/transactions/${journalEntryId}`
  );

export const getJournalEntryAttachment = async (
  journalEntryId: string,
  filename: string
) =>
  await apiClient().get<any>(
    `ark-ledger/journal-entries/${journalEntryId}/attachments/${filename}`,
    {
      responseType: 'blob',
    }
  );

//Entities

export const getAllEntities = async () =>
  await apiClient().get<any>(`entities`);

export const getAllGeneralEntities = async () =>
  await apiClient().get<any>(`entities/general-entities`);

export const getEntity = async (entityId: any) =>
  await apiClient().get<any>(`entities/general-entities/${entityId}`);

export const createNewEntity = async (data: any) =>
  await apiClient().post<any>(`entities/general-entities`, data);

export const updateEntity = async (entityId: any, data: any) =>
  await apiClient().put<any>(`entities/general-entities/${entityId}`, data);

export const deleteEntity = async (entityId: any) =>
  await apiClient().delete(`/entities/general-entities/${entityId}`);

// Reports

export const getReportTrialBalanceDetails = async (data: any) =>
  await apiClient().post<any>(`/ark-ledger/reports/trial-balance-detail`, data);

export const getReportTemplates = async () =>
  await apiClient().get(`/financials/packages`);

export const createReportTemplate = async (data: any) =>
  await apiClient().post(`/financials/packages`, data);

export const updateReportTemplate = async (
  packageId: string,
  type: string,
  data: any
) =>
  await apiClient().put(
    `/financials/packages/${packageId}/templates/${type}`,
    data
  );

export const addReportTemplate = async (packageId: string, data: any) =>
  await apiClient().put(`/financials/packages/${packageId}`, data);

export const exportFinancialStatements = async (data: any) =>
  await apiClient().post<Blob>(`/ark-ledger/financial-statements/file`, data, {
    responseType: 'blob',
  });

export const getChangesInPartnersCapital = async (data: any) =>
  await apiClient().post('/financials/statement-of-changes', data);

//Uploads

export const glRollback = async (id: string) =>
  await apiClient().post(`/ark-ledger/rollback/audit/${id}`);
