import { useContext, useEffect, useRef, useState } from "react";
import { useForm, useFormState } from "react-hook-form";

import { AppContext } from "../../../../core/context/appContextProvider";
import {
  createAccount,
  deleteAccount,
  getArkAccounts,
  getAttributes,
  getTransAccounts,
  updateAccount,
} from "../../../../services/arkGL.service";
import {
  getTransactionTypes,
  postMapsForFund,
} from "../../../../services/fund.service";
import { useEffectAsync } from "../../../../utils/hooks/useEffectAsync.hook";
import { ArkGLAccount } from "../../../../utils/types/arkGLAccount.type";
import { LoadingStatus } from "../../../../utils/types/form.type";
import {
  ACCOUNT_FORM_DEFAULT_VALUE,
  CREATE_ACCOUNT_ERROR,
  CREATE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_ERROR,
  DELETE_ACCOUNT_SUCCESS,
  GET_ACCOUNT_ATTRIBUTE_LIST_ERROR,
  GET_ARK_TRANSACTION_LIST_ERROR,
  GET_GL_ACCOUNT_LIST_ERROR,
  MAP_ACCOUNT_ERROR,
  MAP_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_ERROR,
  UPDATE_ACCOUNT_SUCCESS,
} from "../accountList/AccountList.constants";

type Props = {
  account?: ArkGLAccount;
  onClose: Function;
  fetchAllAccounts: Function;
  isNewAccount: boolean;
  fundId: string;
};

export const useAccountDetails = ({
  account,
  onClose,
  fetchAllAccounts,
  isNewAccount,
  fundId,
}: Props) => {
  const [isLoading, setIsLoading] = useState<LoadingStatus>();
  const [toBeDeleted, setToBeDeleted] = useState<string | undefined>();
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);
  const [showFSNameConfirmation, setShowFSNameConfirmation] =
    useState<boolean>(false);
  const [isSubAccount, setIsSubAccount] = useState<boolean>(false);
  const [accountList, setAccountList] = useState<any[]>([]);
  const [arkTransactionList, setArkTransactionList] = useState<any[]>([]);
  const [attributeList, setAttributeList] = useState<any[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const detailsRef = useRef<HTMLInputElement>(null);
  const generalLedgerRef = useRef<HTMLInputElement>(null);

  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
    reset,
    trigger,
  } = useForm<ArkGLAccount>({
    defaultValues: account ?? ACCOUNT_FORM_DEFAULT_VALUE,
    mode: "all",
  });
  const { isDirty, isSubmitted, isValid } = useFormState({ control });
  const cashFlowMappingSet = watch('cashFlowMapping');
  const cashFlowNameSet = watch('cashFlowName');

  useEffect(() => {
    setIsSubAccount(Boolean(account?.parentId));
  }, [account?.parentId]);

  const fetchAccounts = async (isCanceled?: () => boolean) => {
    if (fundId) {
      try {
        setIsLoading(LoadingStatus.Loading);

        const response = await getArkAccounts(fundId);

        if (isCanceled?.()) return;

        setAccountList(response.items);

        setIsLoading(undefined);
      } catch (e) {
        informationAlert(GET_GL_ACCOUNT_LIST_ERROR, "error");
      }
    }
  };

  const fetchArkTransactions = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(LoadingStatus.Loading);

      const response = await getTransactionTypes(clientId);

      if (isCanceled?.()) return;

      setArkTransactionList(response);

      setIsLoading(undefined);
    } catch (e) {
      informationAlert(GET_ARK_TRANSACTION_LIST_ERROR, "error");
    }
  };

  const fetchAttributes = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(LoadingStatus.Loading);

      const response = await getAttributes();

      if (isCanceled?.()) return;

      setAttributeList(response.items);

      setIsLoading(undefined);
    } catch (e) {
      informationAlert(GET_ACCOUNT_ATTRIBUTE_LIST_ERROR, "error");
    }
  };

  useEffectAsync(async (isCanceled) => {
    await fetchAccounts(isCanceled);
    await fetchArkTransactions(isCanceled);
    await fetchAttributes(isCanceled);
  }, []);

  const mapAccount = async (accountId: string, data: ArkGLAccount) => {
    try {
      const transAccountsResponse = await getTransAccounts(fundId);

      const transAccount = transAccountsResponse.items.find(
        (t: any) => t.accountId === accountId
      );

      const transactionData = {
        fundId: data.fundId,
        glPlatform: "ARK_LEDGER",
        mappings: [
          {
            id: transAccount.id,
            transactionTypeId: data.transactionTypeId,
          },
        ],
      };

      await postMapsForFund(transactionData);

      informationAlert(MAP_ACCOUNT_SUCCESS, "success");
      closeDrawer();
      fetchAllAccounts();
    } catch (error) {
      informationAlert(MAP_ACCOUNT_ERROR, "error");
      closeDrawer();
      fetchAllAccounts();
    }
  };

  const createNewAccount = async (data: ArkGLAccount) => {
    try {
      const request: any = {
        parentId: data.parentId,
        number: data.number,
        name: data.name,
        description: data.description,
        isTaxable: data.isTaxable,
        isEntityRequired: data.isEntityRequired,
        attributeId: data.attributeId,
        fsDisplayName: data.fsDisplayName,
        fsMappingId: data.fsMappingId,
        doNotMap: data.doNotMap,
        fundId: data.fundId,
        cashFlowMapping: data.cashFlowMapping,
        cashFlowName: data.cashFlowName
      };

      const accountResponse = await createAccount(request);

      informationAlert(CREATE_ACCOUNT_SUCCESS, "success");
      await mapAccount(accountResponse.id, data);
    } catch (error) {
      informationAlert(CREATE_ACCOUNT_ERROR, "error");
    }
  };

  const editAccount = async (data: ArkGLAccount) => {
    if (account && account.id) {
      let request: any;

      if (data.state === "POSTED") {
        request = {
          fsMappingId: data.fsMappingId,
          fsDisplayName: data.fsDisplayName,
          isEntityRequired: data.isEntityRequired,
          isTaxable: data.isTaxable,
          cashFlowMapping: data.cashFlowMapping,
          cashFlowName: data.cashFlowName,
        };
      } else {
        request = {
          parentId: data.parentId,
          number: data.number,
          name: data.name,
          description: data.description,
          isTaxable: data.isTaxable,
          isEntityRequired: data.isEntityRequired,
          attributeId: data.attributeId,
          fsDisplayName: data.fsDisplayName,
          fsMappingId: data.fsMappingId,
          cashFlowMapping: data.cashFlowMapping,
          cashFlowName: data.cashFlowName,
          doNotMap: data.doNotMap,
        };
      }

      try {
        await updateAccount(account.id, request);
        await mapAccount(account.id, data);
        informationAlert(UPDATE_ACCOUNT_SUCCESS, "success");
      } catch (error) {
        informationAlert(UPDATE_ACCOUNT_ERROR, "error");
      }
    }
  };

  const handleFSNameCheck = (data: ArkGLAccount) => {
    const foundFSNameId = accountList?.find(
      (acc) => acc.fsMappingId === data.fsMappingId && acc.id !== data.id
    );

    if (foundFSNameId && foundFSNameId?.fsDisplayName !== data.fsDisplayName) {
      setShowFSNameConfirmation(true);
    } else {
      createUpdateAccount(data);
    }
  };

  const validateUniqueFSName = (): boolean => {
    const currentValue = getValues();

    const foundFSNameId = accountList?.find(
      (acc) =>
        acc.fsDisplayName === currentValue.fsDisplayName &&
        acc.fsMappingId !== currentValue.fsMappingId
    );

    return !!foundFSNameId;
  };

  const createUpdateAccount = async (data: ArkGLAccount) => {
    if (data.fsMappingId === "1") {
      data = {
        ...data,
        fsMappingId: null,
        fsDisplayName: null,
      };
    }

    if (!isSubAccount) {
      data.parentId = null;
    }

    if (isNewAccount) {
      setIsLoading(LoadingStatus.Adding);
      await createNewAccount({
        ...data,
        fundId,
      });
    } else {
      setIsLoading(LoadingStatus.Updating);
      await editAccount(data);
    }
    setIsLoading(undefined);
  };

  const toggleDrawer = () => {
    if (isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    reset();
    onClose();
    setShowExitConfirmation(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
    setShowFSNameConfirmation(false);
  };

  const handleDelete = () => {
    setToBeDeleted(account?.id ?? "");
  };

  const handleCancelDelete = () => {
    setToBeDeleted(undefined);
  };

  const handleConfirmDelete = async (accountToBeDeleted: string) => {
    if (!accountToBeDeleted) {
      return;
    }
    setToBeDeleted(undefined);
    try {
      setIsLoading(LoadingStatus.Deleting);
      await deleteAccount(accountToBeDeleted as string);
      fetchAllAccounts();
      informationAlert(DELETE_ACCOUNT_SUCCESS, "success");
      closeDrawer();
      setIsLoading(undefined);
    } catch (error) {
      informationAlert(DELETE_ACCOUNT_ERROR, "error");
      setIsLoading(undefined);
    }
  };
  const handleFSNameChange = () => {
    setShowFSNameConfirmation(false);
    handleSubmit(createUpdateAccount)();
  };

  const status = watch("state");
  const fsMappingId = watch("fsMappingId");
  const fsDisplayName = watch("fsDisplayName");
  const parentId = watch("parentId");
  const accountName = watch("name");
  const doNotMap = watch("doNotMap");
  const accountNumber = watch("number");

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleTabChange = (event: any, newValue: any) => {
    setCurrentTab(newValue);
    switch (newValue) {
      case 0:
        detailsRef.current?.scrollIntoView({
          behavior: "smooth",
        });
        break;
      case 1:
        generalLedgerRef.current?.scrollIntoView({
          behavior: "smooth",
        });
      break;
    }
  };

  return {
    isLoading,
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    isValid,
    trigger,
    isSubmitted,
    createUpdateAccount,
    toBeDeleted,
    handleDelete,
    handleCancelDelete,
    handleConfirmDelete,
    handleFSNameCheck,
    handleFSNameChange,
    closeDrawer,
    keepDrawerOpen,
    showExitConfirmation,
    showFSNameConfirmation,
    toggleDrawer,
    isSubAccount,
    setIsSubAccount,
    accountList,
    arkTransactionList,
    attributeList,
    status,
    fsMappingId,
    fsDisplayName,
    parentId,
    accountName,
    accountNumber,
    doNotMap,
    validateUniqueFSName,
    isFocused,
    setIsFocused,
    handleBlur,
    handleFocus,
    cashFlowMappingSet,
    cashFlowNameSet,
    currentTab,
    handleTabChange,
    generalLedgerRef,
    detailsRef
  };
};
