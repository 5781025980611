import { useContext, useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useParams } from "react-router-dom";

import { MultiSelectItem } from "../../../components/MultiSelect/MultiSelect";
import { AppContext } from "../../../core/context/appContextProvider";
import {
  updateFeatureConfig,
  updateLockedFeatures,
} from "../../../services/client.service.v2";
import { useClientEffect } from "../../../services/hooks/useClientsEffect/useClientEffect.hooks";
import { getSFTPVendors } from "../../../services/sftp.service";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { FeatureConfig } from "../../../utils/types/client.type";
import { GET_SFTP_VENDOR_LIST_ERROR } from "../constants";

interface RouteProp {
  id: string;
  arkTag: string;
  section: string;
}

const defaultValues = {
  glLocked: false,
  allocationLocked: false,
  soiLocked: false,
  arkGlLocked: false,
  sftpLocked: false,
  clientSftpVendors: [],
  bankLocked: false
};

export const useFeature = () => {
  const { id } = useParams<RouteProp>();
  const [isLoading, setIsLoading] = useState(false);
  const [sftpVendorList, setSftpVendorList] = useState<MultiSelectItem[]>([]);

  const {
    client,
    fetchClient,
    loading: loadingClient,
  } = useClientEffect(id !== "new" ? id : undefined);

  const { informationAlert, refetchSideNav } = useContext(AppContext);
  const { handleSubmit, control, reset, watch, setValue } =
    useForm<FeatureConfig>({
      defaultValues,
    });

  const { isDirty } = useFormState({
    control,
  });

  const fetchAllSFTPVendors = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);

      const response = await getSFTPVendors();

      if (isCanceled?.()) return;

      const vendors = response
        .map((v: any, index: number) => {
          return {
            name: v.name,
            id: index,
            selected: false,
          };
        })
        .sort((a: any, b: any) =>
          a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        );

      setSftpVendorList(vendors);

      setIsLoading(false);
    } catch (e) {
      informationAlert(GET_SFTP_VENDOR_LIST_ERROR, "error");
    }
  };

  useEffectAsync(async (isCanceled) => {
    await fetchAllSFTPVendors(isCanceled);
  }, []);

  useEffect(() => {
    if (client?.id) {
      reset({
        id: client.id,
        glLocked: client?.glLocked === false ? false : true || true,
        allocationLocked:
          client?.allocationLocked === false ? false : true || true,
        soiLocked: client?.soiLocked === false ? false : true || true,
        arkGlLocked: client?.arkGlLocked === false ? false : true || true,
        sftpLocked: client?.sftpLocked === false ? false : true || true,
        clientSftpVendors: client?.clientSftpVendors,
        bankLocked: client?.bankLocked
      });

      if (client.clientSftpVendors && sftpVendorList) {
        const vendors = sftpVendorList.map((vendor) => {
          const matchingVendor = client?.clientSftpVendors?.find(
            (v) => v.name === vendor.name
          );

          return {
            ...vendor,
            selected: matchingVendor ? true : false,
          };
        });

        setSftpVendorList(vendors);
      }
    }
  }, [client]);

  const onSubmit = async (data: FeatureConfig) => {
    setIsLoading(true);
    try {
      const vendors: any[] = [];

      if (data && data.clientSftpVendors && data.clientSftpVendors.length > 0) {
        const clientSftpVendors: any[] = [...data.clientSftpVendors];

        clientSftpVendors.map((v) => {
          vendors.push({
            sftpProvider:
              typeof v === "string" ? v.toUpperCase() : v.name.toUpperCase(),
          });
        });
      }

      const requestBody = {
        clientId: id,
        glLocked: data.glLocked,
        allocationLocked: data.allocationLocked,
        soiLocked: data.soiLocked,
        sftpLocked: data.sftpLocked,
        arkGlLocked: data.arkGlLocked,
        clientSftpVendors: sftpLocked ? [] : vendors,
        bankLocked: data.bankLocked
      };

      await updateFeatureConfig(data);

      // ok to ignore errors for updateLockedFeatures() since it still updates the valid values
      try{
        await updateLockedFeatures(id, requestBody);
      } catch {}

      await Promise.all([fetchClient(), refetchSideNav()]);
    } catch (error) {
      informationAlert("Error in updating feature config", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    fetchClient();
  };

  const sftpLocked = watch("sftpLocked");

  return {
    handleSubmit,
    isDirty,
    control,
    setValue,
    onSubmit,
    onCancel,
    loading: loadingClient || isLoading,
    sftpVendorList,
    setSftpVendorList,
    sftpLocked,
  };
};
