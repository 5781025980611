import { useContext, useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";

import { AppContext } from "../../../../core/context/appContextProvider";
import useRole from "../../../../core/routing/useRole";
import { addTransaction, deleteTransaction, updateTransaction } from "../../../../services/transaction.service";
import { Transaction, TransactionCRUDStatus } from "../../../../utils/types/transaction.type";
import { ScopeRole } from "../../../../utils/types/user.type";
import {
  CREATE_TRANSACTION_ERROR,
  CREATE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_ERROR,
  DELETE_TRANSACTION_SUCCESS,
  TRANSACTION_FORM_DEFAULT_VALUE,
  UPDATE_TRANSACTION_ERROR,
  UPDATE_TRANSACTION_SUCCESS
} from "../constants";

type Props = {
  transaction?: Transaction;
  onTransactionDetailClose: Function;
  resetPaginationAndFetch: Function;
  isNewTransaction: boolean;
}

export const useTransactionDetails = ({
  transaction,
  onTransactionDetailClose,
  resetPaginationAndFetch,
  isNewTransaction
}: Props) => {
  const [loading, setLoading] = useState<TransactionCRUDStatus>();
  const [toBeDeleted, setToBeDeleted] = useState<string | undefined>();
  const [showExitConfirmation, setShowExitConfirmation] = useState<boolean>(false);
  const {
    state,
    informationAlert
  } = useContext(AppContext);
  const clientId = state.loginUser.clientId;

  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
    control,
    watch,
    reset
  } = useForm<Transaction>({
    defaultValues: transaction ? transaction : {
      date: new Date(),
    },
  });
  const {
    isDirty
  } = useFormState({
    control
  });

  const {
    hasRole: isSuperAdminOrClientAdmin
  } = useRole([ScopeRole.SUPER_ADMIN, ScopeRole.ARK_CLIENT_ADMIN, ScopeRole.BASIC_ADMIN]);

  useEffect(() => {
    reset(transaction);
  }, [transaction, reset]);

  const createTransaction = async (data: Transaction) => {
    try {
      await addTransaction(data, clientId);
      resetPaginationAndFetch();
      informationAlert(CREATE_TRANSACTION_SUCCESS, "success");
      closeDrawer();
    } catch (error) {
      informationAlert(CREATE_TRANSACTION_ERROR, "error");
    }
  };

  const editTransaction = async (data: Transaction) => {
    try {
      await updateTransaction(data, transaction?.id ?? '', clientId);
      resetPaginationAndFetch();
      informationAlert(UPDATE_TRANSACTION_SUCCESS, "success");
      closeDrawer();
    } catch (error) {
      informationAlert(UPDATE_TRANSACTION_ERROR, "error");
    }
  };

  const createUpdateTransaction = async (data: Transaction) => {
    const resultData = {
      ...(isNewTransaction ? TRANSACTION_FORM_DEFAULT_VALUE : {}),
      ...data,
      amount: data.amount.toString().replace(/,/g,''),
      date: typeof data.date === 'object' ? (data.date as Date).toISOString() : data.date,
      reviewableType: {
        id: data.reviewableType.name,
        name: data.reviewableType.name
      },
    };

    if(isNewTransaction) {
      setLoading(TransactionCRUDStatus.Adding);
      await createTransaction(resultData);
    } else {
      setLoading(TransactionCRUDStatus.Updating);
      await editTransaction(resultData);
    }

    setLoading(undefined);
  };

  const toggleDrawer = () => {
    if(isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    reset({
      date: new Date(),
    });
    onTransactionDetailClose();
    setShowExitConfirmation(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  const handleDelete = () => {
    setToBeDeleted(transaction?.id ?? '');
  };

  const handleCancelDelete = () => {
    setToBeDeleted(undefined);
  };

  const handleConfirmDelete = async (transactionToBeDeleted: string) => {
    if(!transactionToBeDeleted) {
      return;
    }
    setToBeDeleted(undefined);
    try {
      setLoading(TransactionCRUDStatus.Deleting);
      await deleteTransaction(transactionToBeDeleted as string);
      resetPaginationAndFetch();
      informationAlert(DELETE_TRANSACTION_SUCCESS, "success");
      closeDrawer();
      setLoading(undefined);
    } catch (error) {
      informationAlert(DELETE_TRANSACTION_ERROR, "error");
      setLoading(undefined);
    }
  };

  const selectedFund = watch('fund');

  return {
    loading,
    register,
    handleSubmit,
    errors,
    control,
    selectedFund,
    createUpdateTransaction,
    toggleDrawer,
    toBeDeleted,
    handleDelete,
    handleCancelDelete,
    handleConfirmDelete,
    closeDrawer,
    showExitConfirmation,
    keepDrawerOpen,
    isSuperAdminOrClientAdmin
  };
};


