import AddIcon from "@mui/icons-material/Add";
import { Box, IconButton, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import SplitButton from "../../../components/Buttons/SplitButton/SplitButton";
import ButtonWithOptions from "../../../components/ButtonWithOptions/ButtonWithOptions";
import ConfirmationDialog from "../../../components/Modal/ConfirmationDialog";
import DeleteConfirmationDialog from "../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog";
import ProgressModal from "../../../components/Progress/ProgressModal/ProgressModal";
import SearchBar from "../../../components/SearchBar/SearchBar";
import RoutingPaths from "../../../core/routing/routingPaths";
import { NewFundOptions } from "../../../utils/constants/fund.constants";
import useWindowSize from "../../../utils/hooks/useWindowSize.hook";
import { FundSummaryItem } from "../../../utils/types/fund.type";
import { useFundList } from "./FundList.hooks";
import {
  ButtonBox,
  FundWrapper,
  HeaderRow,
  IconBox,
  StyledDataGrid,
} from "./FundList.styles";

export const FundList: React.FC = () => {
  const {
    bulkActions,
    fundSummaries,
    headerList,
    activeHeaderFields,
    readonly,
    showDeletePrompt,
    handleBulkActions,
    handleUpdateHeader,
    handleFilter,
    handleNewButtonAction,
    search,
    searchOptions,
    onSearch,
    showSuggestionPopover,
    fundSelectionModel,
    setFundSelectionModel,
    setShowSuggestionPopover,
    isLoading,
    uploadedFile,
    clearUploadedFile,
    handleUploadTemplate,
    isUploadComplete,
    selectedFund,
    clearUploadCompleted,
    onDeleteCancel,
    onDeleteConfirm,
    setCurrentSelectedFund,
  } = useFundList();

  const history = useHistory();
  const window = useWindowSize();

  const wrapperHeight = window.height - 80;

  return (
    <FundWrapper id="fundlist_main" role="main" wrapperHeight={wrapperHeight}>
      <ProgressModal id="modal_fundlist_loading" showProgress={isLoading} />

      <HeaderRow>
        <IconBox>
          <Typography variant="pageTitle" display="flex">
            Funds
          </Typography>
        </IconBox>

        <SearchBar
          id="funds_search"
          size="small"
          onChange={onSearch}
          options={searchOptions}
          searchText={search}
          showPopover={showSuggestionPopover}
          setShowPopover={setShowSuggestionPopover}
        />
        <ButtonBox>
          <SplitButton
            id={"btn_bulk_action_options"}
            options={bulkActions}
            hidden={false}
            handleOptionClick={handleBulkActions}
            ariaLabelMessage="Select bulk action option"
          />
          {!readonly && (
            <ButtonWithOptions
              buttonID="btn_add_fund"
              popoverID="popover_add_fund"
              onClick={handleNewButtonAction}
              buttonLabel="Add New"
              buttonIcon={<AddIcon />}
              options={NewFundOptions}
            />
          )}
        </ButtonBox>
      </HeaderRow>
      <Box>
        {!isLoading &&
          fundSummaries &&
          fundSummaries?.length &&
          fundSummaries.map((fund, index) => {
            return (
              fund.items &&
              fund.items.length > 0 && (
                <Box key={index}>
                  <StyledDataGrid
                    id={`fundlist_data_grid_${index}`}
                    dataList={fund.items}
                    headerList={headerList}
                    minHeight="100px"
                    selectionModel={fundSelectionModel}
                    activeHeaderFields={activeHeaderFields}
                    setSelectionModel={(selectionModel: string[]) => {
                      setFundSelectionModel(selectionModel);
                      const currentFund: FundSummaryItem | undefined =
                        fund.items.find((item) =>
                          selectionModel.includes(item.id)
                        );

                      setCurrentSelectedFund(currentFund);
                    }}
                    handleUpdateHeader={handleUpdateHeader}
                    handleFilter={handleFilter}
                    isRowSelectable={(params) => {
                      return params.row.name === "Total" ? false : true;
                    }}
                  />
                </Box>
              )
            );
          })}
      </Box>
      {!isLoading && fundSummaries && fundSummaries.length === 0 && (
        <Typography id="no_data" align="center">
          No Data Available
        </Typography>
      )}
      <ConfirmationDialog
        open={Boolean(uploadedFile)}
        onClose={clearUploadedFile}
        id="upload_confirmation"
        actions={[
          {
            label: "Upload",
            onClick: handleUploadTemplate,
            id: "upload_button",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: clearUploadedFile,
            id: "cancel_button",
            variant: "outlined",
            color: "error",
          },
        ]}
        content={
          <Box component="span">
            Are you sure you want to upload{" "}
            <strong>{uploadedFile?.name}</strong>?
          </Box>
        }
        title="Confirm Upload"
      />
      <ConfirmationDialog
        open={isUploadComplete}
        onClose={clearUploadedFile}
        id="upload-complete-confirmation"
        actions={[
          {
            label: "Upload History",
            onClick: () => {
              clearUploadCompleted();
              history.push(RoutingPaths.UploadHistory);
            },
            id: "upload_history_button",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Close",
            onClick: clearUploadCompleted,
            id: "close_button",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="The file is being processed. Large files may take a while to finish; Please check the Upload History page to check the status of the import."
        title="Upload Results"
      />
      <DeleteConfirmationDialog
        open={showDeletePrompt}
        onClose={() => {}}
        id="delete_confirmation"
        primaryButtonAction={onDeleteConfirm}
        secondaryButtonAction={onDeleteCancel}
        content={
          <Box component="span">
            This action will remove this entity and everything connected to it.
            This action is permanent and cannot be undone.
          </Box>
        }
        title="Are you sure you want to delete?"
        actionType={`DELETE ${selectedFund?.name}`}
      />
    </FundWrapper>
  );
};
