import { DownloadFileParam } from '../utils/types/download.type';
import {
  Fund,
  FundDetails,
  FundFilters,
  FundSummary,
  FundSummaryItem,
  GlMapping,
  NewTransType,
  TransType,
  TransTypeBucket,
} from '../utils/types/fund.type';
import { Fund as FundV2 } from '../utils/types/fund.v2.type';
import { ListItem } from '../utils/types/listItems';
import { NameIdPair } from '../utils/types/transaction.type';
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => ApiClient.Client(ClientType.NotCached, ServiceType.Api);
const apiClientV2 = () =>
  ApiClient.Client(ClientType.NotCached, ServiceType.Apiv2);

export const getFunds = async (): Promise<FundV2[]> => {
  const response: FundV2[] = await apiClient().get<FundV2[]>('funds');

  return response;
};

export const getFundFilters = async (): Promise<FundFilters[]> => {
  const response: FundFilters[] = await apiClient().get<FundFilters[]>(
    'funds/filters'
  );

  return response;
};

export const getFund = async (fundId: string) =>
  await apiClientV2().get<Fund>(`/entities/funds/${fundId}`);

export const getFundsList = async (clientId: string) => {
  const funds = await apiClient().get<any>(`funds/client/${clientId}`);

  return funds;
};

export const getFundSummary = async (clientId: string) => {
  const funds = await apiClient().get<any>(`funds/client/${clientId}`);

  const fundListSummaries: FundSummary[] = [];

  for (const fundSummary of funds) {
    const currCurrencyCode: string = fundSummary.fund.currency;

    const newSummaryItem: FundSummaryItem = {
      id: fundSummary.fund.id,
      name: fundSummary.fund.fullName,
      currencyCode: currCurrencyCode,
      quarterAsOf: fundSummary.transactionDetail?.quarter,
      percentCalled: 0,
    };

    if (fundSummary?.transactionDetail?.transactions) {
      for (const trans of fundSummary.transactionDetail.transactions) {
        const label: string = trans.label.toLowerCase();

        newSummaryItem[label] = trans.amount;
      }
      newSummaryItem.percentCalled =
        newSummaryItem.contributions === 0 || newSummaryItem.committed === 0
          ? 0
          : ((newSummaryItem.contributions as number) /
              (newSummaryItem.committed as number)) *
            100;
    }

    const fundListSummary = fundListSummaries.find(
      (x) => x.currencyCode === currCurrencyCode
    );

    if (!fundListSummary) {
      fundListSummaries.push({
        currencyCode: currCurrencyCode,
        items: [newSummaryItem],
      });
    } else {
      fundListSummary.items.push(newSummaryItem);
    }
  }

  fundListSummaries.sort((a, b) => {
    return a.currencyCode === 'USD' || a.currencyCode > b.currencyCode ? -1 : 1;
  });

  const fundListSummariesWithTotals = fundListSummaries.map((fundSummary) => {
    const fundSummaryTotal: FundSummaryItem = {
      id: 'total',
      name: 'Total',
      currencyCode: fundSummary.currencyCode,
      quarterAsOf: '',
      percentCalled: 0,
    };

    fundSummary.items.map((fund) => {
      for (const [key, value] of Object.entries(fund)) {
        if (!(key in fundSummaryTotal)) {
          fundSummaryTotal[key] = value as number;
        } else if (
          ![
            'currencyCode',
            'id',
            'name',
            'quarterAsOf',
            'percentCalled',
          ].includes(key)
        ) {
          fundSummaryTotal[key] =
            (fundSummaryTotal[key] as number) + (value as number);
        }
      }
    });

    if (
      'contributions' in fundSummaryTotal &&
      'committed' in fundSummaryTotal
    ) {
      fundSummaryTotal.percentCalled =
        fundSummaryTotal.contributions === 0 || fundSummaryTotal.committed === 0
          ? 0
          : ((fundSummaryTotal.contributions as number) /
              (fundSummaryTotal.committed as number)) *
            100;
    }

    fundSummary.items.push(fundSummaryTotal);
    return {
      ...fundSummary,
    };
  });

  return fundListSummariesWithTotals;
};

export const getHasMappingAdmin = async () =>
  await apiClient().get<boolean>(`/general-ledger/hasMappingAdminRights`);

export const getAccountsForGLPlatform = async (
  fundId: string,
  glPlatform: string
) =>
  await apiClientV2().get<GlMapping>(
    `/ledgers/accounts?fundId=${fundId}&glPlatform=${glPlatform}`
  );

export const syncAccountsForFund = async (fundId: string, glPlatform: string) =>
  await apiClientV2().get<GlMapping>(
    `/ledgers/accounts?fundId=${fundId}&sync=true&glPlatform=${glPlatform}`
  );

export const getMappedFunds = async () =>
  await apiClient().get<any>(`/general-ledger/mappedFunds`);

export const getTransactionTypes = async (currentClientId: string) =>
  await apiClient().get<TransType[]>(`/transactions/types/${currentClientId}`);

export const postMapsForFund = async (data: any) =>
  await apiClientV2().post<any>(`/ledgers/accounts`, data);

export const getTransactionBuckets = async (currentClientId: string) =>
  await apiClient().get<TransTypeBucket[]>(
    `transactionType/${currentClientId}/buckets`
  );

export const postNewTransType = async (data: NewTransType) =>
  await apiClient().post<NewTransType>(`/transactionType`, data);

export const getFundDetails = async (fundId: string) =>
  await apiClient().get<FundDetails>(`funds/${fundId}/details`);

export const updateFundDetails = async (fundId: string, data: any) =>
  await apiClient().put<FundDetails>(`funds/${fundId}/details`, data);

export const postNewFund = async (data: any) =>
  await apiClientV2().post<FundDetails>(`/entities/funds`, data);

export const getFundCurrencyList = async () =>
  await apiClient().get<ListItem[]>(`funds/currencies`);

export const getFundEntityTypeList = async () =>
  await apiClient().get<ListItem[]>(`funds/entityTypes`);

export const getFundLegalTypeList = async () =>
  await apiClient().get<ListItem[]>(`funds/legalTypes`);

export const getFundTypeList = async () =>
  await apiClient().get<ListItem[]>(`funds/types`);

export const getFundNameAvailability = async (fundName: string) =>
  await apiClient().get<boolean>(`funds/${fundName}/available`);

export const getFundNames = async () =>
  await apiClient().get<NameIdPair[]>('funds/names');

export const downloadData = async (data: DownloadFileParam) =>
  await apiClient().post('downloads/', data);

export const getGLAuthQuickbooks = async (fundId: string, url: string) =>
  await apiClientV2().get<any>(
    `/ledgers/qbo/auth-url?fundId=${fundId}&returnUrl=${url}`
  );

export const disconnectGL = async (generalLedgerId: string) =>
  await apiClientV2().post<any>(`/ledgers/disconnect`, {
    generalLedgerId,
  });

export const deleteFund = async (clientId: string, fundId: string) =>
  await apiClient().delete<any>(`funds/${fundId}`);
