import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, IconButton } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { FC, useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import Progress from "../../components/Progress/ProgressModal/ProgressModal";
import { AppContext } from "../../core/context/appContextProvider";
import RoutingPaths from "../../core/routing/routingPaths";
import {
  authorizeUser,
  maxAllowedAttempts,
  totalAttempts,
} from "../../services/login.service";
import {
  checkTrustedDeviceURL,
  currentUserPhoneURL,
  getHeaders,
} from "../../utils/constants/login.constants";
import { useEffectAsync } from "../../utils/hooks/useEffectAsync.hook";
import { PageType } from "../../utils/types/login.type";
import AccountRecovery from "./AccountRecovery";
import ConfirmCode from "./ConfirmCode";
import ForgotPassword from "./ForgotPassword";
import { LoginView, WhiteIcon } from "./Login.styles";
import LoginScreen from "./LoginScreen";
import LoginSidebar from "./LoginSidebar";
import MfaSetup from "./MfaSetup";
import MultiFactorAuth from "./MultiFactorAuth";
import RightSection from "./RightSection";
import Thankyou from "./Thankyou";

const url = window.location.hostname;
const AUTHORIZED = "AUTHORIZED";

const loginFormValidation = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

interface LocationState {
  from: string;
}

export const Login: FC = () => {
  const location = useLocation<LocationState>();

  const loginForm = useForm<{
    email: string;
    password: string;
  }>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginFormValidation),
  });
  const [showLoginLoader, setShowLoginLoader] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<PageType>("LoginScreen");
  const {
    token,
    proxyToken,
    onLogin,
    onProxyLogin,
    preAuthClientConfigs,
    informationAlert,
  } = useContext(AppContext);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [loginToken, setLoginToken] = useState<string>("");
  const [userInfo, setUserInfo] = useState<any>();
  const history = useHistory();

  const host = process.env.REACT_APP_API_ENDPOINT_URL;

  const [cookies, setCookie] = useCookies([
    "matClientConfig",
    "matClientToken",
    "matClientTokenSubdomain",
    "matProxyToken",
    "matTokenDurationMinutes",
    "matTokenExpirationCountdown",
  ]);

  useEffectAsync(
    async (isCanceled) => {
      if (
        !isEmpty(cookies) &&
        cookies.matClientConfig?.subdomain &&
        cookies.matClientConfig?.baseDomain
      ) {
        const urlPattern =
          cookies.matClientConfig?.subdomain +
          "." +
          cookies.matClientConfig?.baseDomain;

        if (urlPattern.search(url) === 0) {
          const loginUser = {
            email: cookies.matClientConfig.email,
            clientId: cookies.matClientConfig.clientId,
          };

          if (
            token !== cookies.matClientToken ||
            proxyToken !== cookies.matProxyToken
          ) {
            await onProxyLogin(
              loginUser,
              cookies.matClientToken,
              cookies.matProxyToken
            );
            if(isCanceled()) return;
            setShowLoginLoader(false);
            history.push(RoutingPaths.CapitalAccounts);
          }
        }
      } else if (token) {
        location?.state?.from
          ? history.push(location?.state?.from)
          : history.push(RoutingPaths.AppDashboard);
      }
    },
    [cookies, token]
  );

  async function getToken(formData: any) {
    try {
      const url = `${host}auth-api/oauth/token`;
      const data = await fetch(url, {
        headers: {
          accept: "application/json",
          authorization: "Basic d2ViYXBwOjZFai1rWUElVi1hRE5OR1M=",
          "content-type": "application/x-www-form-urlencoded",
        },

        body: new URLSearchParams({
          username: formData?.email,
          password: formData?.password,
          clientName:
            preAuthClientConfigs?.clientName || "Allocations Partners LP",
          ...(preAuthClientConfigs?.clientId
            ? {
                clientId: preAuthClientConfigs.clientId,
              }
            : {}),
          grant_type: "password",
        }),

        method: "POST",
      });

      const response = await data.json();
      const token = response.access_token;

      if (!token) {
        loginForm.setError("email", {
          type: "custom",
          message: response.error_description,
        });
      }
      return token;
    } catch (ex) {
      return null;
    }
  }

  async function getMfaSettings(token: string) {
    try {
      const url = `${host}api/clients/${preAuthClientConfigs?.clientId}/mfaSettings`;
      const headers = getHeaders("GET", {}, token);
      const mfaResponse = await fetch(url, headers);
      const response = await mfaResponse.json();

      return response;
    } catch (ex) {
      return null;
    }
  }

  async function isTrustedDevice(token: string) {
    try {
      const url = `${host}${checkTrustedDeviceURL}`;
      const headers = getHeaders("GET", {}, token);
      const isTrustedDevice = await fetch(url, headers);
      const response = await isTrustedDevice.json();

      return response;
    } catch (ex) {
      return null;
    }
  }

  async function getUserPhoneInfo(token: string) {
    try {
      const url = `${host}${currentUserPhoneURL}`;
      const headers = getHeaders("GET", {}, token);
      const currentInfo = await fetch(url, headers);
      const response = await currentInfo.json();

      return response;
    } catch (ex) {
      return null;
    }
  }

  async function processMFA(data: any, token: string) {
    const isTrusted = await isTrustedDevice(token);
    const userInfo = await getUserPhoneInfo(token);
    const maxAttempts = await maxAllowedAttempts(token);
    const total = await totalAttempts(token);

    // authorize user
    let authStatus;

    if (isTrusted?.trusted) {
      try {
        authStatus = await authorizeUser(token);
      } catch (err) {
        authStatus = "Error";
      }
    }

    if (authStatus && authStatus !== AUTHORIZED) {
      // error on login
      informationAlert("Login failed.", "error");
      setShowLoginLoader(false);
      return;
    }

    if (userInfo) {
      if (userInfo?.phoneNumber) {
        setUserInfo({
          ...userInfo,
          username: data?.email,
          trustedDevice: isTrusted?.trusted,
          showloginWarning: total !== 0,
          loginRemainingCount: maxAttempts - total,
        });

        if (authStatus === AUTHORIZED) {
          const payload = {
            email: data?.username,
            clientId: "",
          };

          await onLogin(payload, token);
          setShowLoginLoader(false);
          return;
        }
        setCurrentPage("RequestCode");
        setShowLoginLoader(false);
      } else {
        setCurrentPage("MfaSetup");
        setShowLoginLoader(false);
      }
    }
  }

  async function onLoginUser(data: any) {
    setShowLoginLoader(true);
    const token = await getToken(data);

    if (token) {
      setLoginToken(token);

      if (preAuthClientConfigs?.clientId) {
        const mfa = await getMfaSettings(token);

        if (mfa?.loginValidation) {
          await processMFA(data, token);
        } else {
          const loginPayload = {
            email: data?.username,
            clientId: preAuthClientConfigs?.clientId,
          };

          await onLogin(loginPayload, token);
          setShowLoginLoader(false);
        }
      } else {
        await processMFA(data, token);
      }
    } else {
      setShowLoginLoader(false);
    }
  }

  return (
    <>
      <Progress id="login_loader" showProgress={showLoginLoader} />
      <div className="container">
        <LoginView container>
          <Grid item md={3}>
            <LoginSidebar
              headers={
                <>
                  {currentPage !== "LoginScreen" && (
                    <Box my={1}>
                      <IconButton
                        onClick={() => {
                          setCurrentPage("LoginScreen");
                        }}
                      >
                        <WhiteIcon />
                      </IconButton>
                      Go Back to Login
                    </Box>
                  )}
                </>
              }
            >
              {currentPage === "LoginScreen" && (
                <LoginScreen
                  onLoginUser={onLoginUser}
                  rememberMe={rememberMe}
                  setRememberMe={setRememberMe}
                  setCurrentPage={setCurrentPage}
                  loginForm={loginForm}
                />
              )}
              {currentPage === "ForgotPassword" && (
                <ForgotPassword setCurrentPage={setCurrentPage} />
              )}
              {currentPage === "Thankyou" && (
                <Thankyou setCurrentPage={setCurrentPage} />
              )}
              {currentPage === "RequestCode" && !userInfo.trustedDevice && (
                <MultiFactorAuth
                  loginToken={loginToken}
                  setShowLoginLoader={setShowLoginLoader}
                  userDetailInfo={userInfo}
                  setUserDetailInfo={setUserInfo}
                  setCurrentPage={setCurrentPage}
                />
              )}
              {currentPage === "MfaSetup" && (
                <MfaSetup
                  loginToken={loginToken}
                  setCurrentPage={setCurrentPage}
                  setUserDetailInfo={setUserInfo}
                  userDetailInfo={userInfo}
                />
              )}
              {currentPage === "ConfirmCode" && (
                <ConfirmCode
                  loginToken={loginToken}
                  setShowLoginLoader={setShowLoginLoader}
                  userDetailInfo={userInfo}
                  setUserDetailInfo={setUserInfo}
                  setCurrentPage={setCurrentPage}
                />
              )}

              {currentPage === "AccountRecovery" && <AccountRecovery />}
            </LoginSidebar>
          </Grid>
          <Grid item md={9}>
            <RightSection />
          </Grid>
        </LoginView>
      </div>
    </>
  );
};
