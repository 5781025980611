import { Autocomplete, Box, FormControlLabel, InputAdornment, List, ListItem, TextField, Typography } from "@mui/material";
import { Button as MuiButton } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import React, { useEffect, useRef, useState } from "react";

import { DataGrid, ProgressModal } from "../../../components";
import { DataWrapperBox } from "../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import HorizontalBox from "../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../components/Buttons/Button/Button";
import { DateRangePicker } from "../../../components/DateRangePicker/DateRangePicker";
import ConfirmationDialog from "../../../components/Modal/ConfirmationDialog";
import FullScreenModal from "../../../components/Modal/FullScreenModal/FullScreenModal";
import { SaveExitModal } from "../../../components/Modal/SaveExitModal/SaveExitModal";
import MultiSelectFilters from "../../../components/MultiSelectFilters/MultiSelectFilters";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import Switch from "../../../components/Switch/Switch";
import { useScheduleOfInvestments } from "../../fund/scheduleOfInvestments/ScheduleOfInvestments.hooks";
import { ReportDetails } from "../reports/glReports/glReportDetails/ReportDetails";
import { BalanceSheetCustom } from "../reports/glReports/glReportList/BalanceSheetCustom";
import { IncomeStatementCustom } from "../reports/glReports/glReportList/IncomeStatementCustom";
import { LedgerFilter } from "../setup/setupList/GLSetupList.constants";
import { ButtonBox, FinancialStatementsActionsContainer, FinancialStatementsSelectorButtonBox, FsDataGridBox, FSPackageSelectorButtonBox, MainContainer, NoDataMessageTypography, SingleStyledSwitchFilterBox, StyledDateFilterBox, StyledFilterBox, StyledFilterSpacerBox, StyledSwitchFilterBox } from "./FinancialStatements.style";
import FsExportButton from "./FinancialStatementsExport";
import { FsViews, useFinancialStatements } from "./FinancialStatementsMain.hooks";
import { ChangesInPartnersCapital } from "./financialStatementsViews/changesInPartnersCapital";

export const GLFinancialStatements: React.FC = () => {

    const {
        attributeFilterDisabled,
        attributes,
        changesInPartners,
        changesInPartnersParams,
        checkFsPackageFlag,
        confirmFsNamechange,
        confirmNameChangeDialog,
        currentCurrency,
        dataWrapperRef,
        dateRange,
        editModeActivated,
        filteredAccounts,
        filteredAttributes,
        filteredLedgers,
        filterPackageOptions,
        filtersRef,
        financialStatementsList,
        financialStatementsParams,
        financialStatementsView,
        fsAddPackage,
        fsColumnBuilder,
        fsDetailsApiParams,
        fsPackageFlag,
        fsPackagesList,
        fsPackageNewName,
        fsPackageValue,
        fsSavePackage,
        fsUpdateColumnOrder,
        funds,
        fundsFilter,
        generalAttributes,
        gridData,
        handleAccountFilterChange,
        handleAttributeFilterChange,
        handleCloseReportDetails,
        handleDataGridChange,
        handleDateRangeChange,
        handleEditMode,
        handleExitConfirmation,
        handleFsDataGridChange,
        handleFsNameChange,
        handleFundFilterChange,
        handleLedgerFilterChange,
        handleNewFsPackage,
        handleRoundedReport,
        handleRunFinancialStatementsButtonClick,
        handleSaveFsPackage,
        handleSetFsPackageValue,
        handleStatusFilterChange,
        handleSoiDateChange,
        handleSuppressIfZeroChange,
        handleValueLinkButtonClick,
        handleViewSelection,
        invalidSelectedFunds,
        invalidSelectedLedgers,
        isLoading,
        isPackageNameValid,
        isPackageOptionEqualToValue,
        level1Clicked,
        packageDisabled,
        netRow,
        noDataMessage,
        openFlag,
        readonly,
        reportView,
        retainedRow,
        roundedReportActivated,
        runFinancialStatementsView,
        selectedAccounts,
        selectedAttributes,
        selectedFunds,
        selectedLedgers,
        selectedStatus,
        setConfirmNameChangeDialog,
        setFsPackageFlag,
        setFsPackageNewName,
        setFsPackageSaveName,
        setFsPackageValue,
        setFsSaveCategoryOrder,
        setFsSaveFieldOrder,
        setIsLoading,
        setMainFSListData,
        setOpenFlag,
        setRoundedReportActivated,
        setStatementOfChangeData,
        soiView,
        statusFilter,
        summaryRowClicked,
        suppressIfZero,
        triggerBuilder,
        triggerFsPackageBuilder,
        userClickAway,
        validFilters,
        viewFiltersState,
        viewListRef,
        zeroSumStartBalance,
        isLoadingList,
        currSOIview,
        setCurrSOIview,
        handleSoiViewChange,
        soiGridData,
        handleColumnOrderChange,
        dateFilter: soiDateFilter,
        setDateFilter: setSoiDateFilter,
    } = useFinancialStatements();

    let fsViewComponent;

    switch(runFinancialStatementsView) {
        case FsViews.unrealizedSOI:
            fsViewComponent =
                <DataGrid
                    id="soi_data_grid"
                    dataList={soiGridData.soiGridRows || []}
                    headerList={soiGridData.dataGridColDefs}
                    noDataMessage={isLoadingList ? "Fetching Data..." : undefined}
                    minHeight="1vh"
                    autoHeight={false}
                    activeHeaderFields={soiGridData.dataGridColDefs.length}
                    checkboxSelection={false}
                    onColumnOrderChange={(colDefs) => {
                    handleColumnOrderChange(colDefs.oldIndex, colDefs.targetIndex);
                    }}
                />;
            break;
        case FsViews.realizedSOI:
            fsViewComponent =
                <DataGrid
                    id="soi_data_grid"
                    dataList={soiGridData.soiGridRows || []}
                    headerList={soiGridData.dataGridColDefs}
                    noDataMessage={isLoadingList ? "Fetching Data..." : undefined}
                    minHeight="1vh"
                    autoHeight={false}
                    activeHeaderFields={soiGridData.dataGridColDefs.length}
                    checkboxSelection={false}
                    onColumnOrderChange={(colDefs) => {
                    handleColumnOrderChange(colDefs.oldIndex, colDefs.targetIndex);
                    }}
                />;
            break;
        case FsViews.balanceSheet:
            fsViewComponent =
                <BalanceSheetCustom 
                    params={financialStatementsParams!} 
                    setMainReportListData={setMainFSListData}
                    reportView={financialStatementsView}
                    suppressIfZero={suppressIfZero}
                    dateRange={dateRange}
                    funds={funds}
                    attributes={attributes}
                    viewFilters={viewFiltersState}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    handleValueLinkButtonClick={handleValueLinkButtonClick}
                    onDataGridChange={handleDataGridChange}
                    handleDateRangeChange={handleDateRangeChange}
                    fsView={financialStatementsView === FsViews.balanceSheet ? true : false}
                    handleFsNameChange={handleFsNameChange}
                    triggerBuilder={triggerBuilder}
                    editModeActivated={editModeActivated}
                    roundedReportActivated={roundedReportActivated}
                    generalAttributes={generalAttributes}
                    setRoundedReportActivated={setRoundedReportActivated}
                    fsAddPackage={fsAddPackage}
                    fsSavePackage={fsSavePackage}
                    setFsSaveFieldOrder={setFsSaveFieldOrder}
                    triggerFsPackageBuilder={triggerFsPackageBuilder}
                    fsUpdateColumnOrder={fsUpdateColumnOrder}
                    fsColumnBuilder={fsColumnBuilder}
                    setFsPackageFlag={setFsPackageFlag}
                />;
            break;
        case FsViews.incomeStatement:
            fsViewComponent =
                <IncomeStatementCustom 
                    params={financialStatementsParams!} 
                    setMainReportListData={setMainFSListData}
                    reportView={financialStatementsView}
                    suppressIfZero={suppressIfZero}
                    dateRange={dateRange}
                    funds={funds}
                    attributes={attributes}
                    viewFilters={viewFiltersState}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    handleValueLinkButtonClick={handleValueLinkButtonClick}
                    onDataGridChange={handleDataGridChange}
                    handleDateRangeChange={handleDateRangeChange}
                    fsView={financialStatementsView === FsViews.incomeStatement ? true : false}
                    handleFsNameChange={handleFsNameChange}
                    triggerBuilder={triggerBuilder}
                    editModeActivated={editModeActivated}
                    roundedReportActivated={roundedReportActivated}
                    setRoundedReportActivated={setRoundedReportActivated}
                    fsAddPackage={fsAddPackage}
                    fsSavePackage={fsSavePackage}
                    setFsSaveFieldOrder={setFsSaveFieldOrder}
                    triggerFsPackageBuilder={triggerFsPackageBuilder}
                    fsUpdateColumnOrder={fsUpdateColumnOrder}
                    fsColumnBuilder={fsColumnBuilder}
                    setFsPackageFlag={setFsPackageFlag}
                />;
            break;
        case FsViews.statementOfChangesInPartners:
            fsViewComponent = 
                <ChangesInPartnersCapital
                    params={changesInPartnersParams}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setStatementOfChangeData={setStatementOfChangeData}
                    editModeActivated={editModeActivated}
                    roundedReportActivated={roundedReportActivated}
                    suppressIfZeroActivated={suppressIfZero}
                    currentCurrency={currentCurrency}
                    setFsPackageFlag={setFsPackageFlag}
                    setFsSaveFieldOrder={setFsSaveFieldOrder}
                    fsUpdateColumnOrder={fsUpdateColumnOrder}
                    fsColumnBuilder={fsColumnBuilder}
                />;
            break;
        default:
            fsViewComponent = null;
    }

    return (
        <>
            <MainContainer ref={dataWrapperRef} id='financial_statements_main' role='main'>
                <HorizontalBox fullWidth noPadding>
                        {soiView ? (
                            <HorizontalBox sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }} noPadding>
                            <HorizontalBox fullWidth sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }} noPadding>
                            <Typography variant='h1' sx={{ marginRight: 1.5 }}>Financial Statements</Typography>
                            <SingleSelect
                                    id='fs_selector_view'
                                    value={financialStatementsView}
                                    optionList={financialStatementsList}
                                    label={'Select Financial Statement'}
                                    handleOptionSelection={handleViewSelection}
                                    width='300px'
                            />
                            <SingleStyledSwitchFilterBox>
                                <FormControlLabel
                                id="chk_rounded_report"
                                label="Rounded Report"
                                control={
                                    <Switch
                                    id="switch_rounded_report"
                                    checked={roundedReportActivated}
                                    label="Rounded Report"
                                    onChange={handleRoundedReport}
                                    />
                                }
                            />
                            </SingleStyledSwitchFilterBox>
                            <Autocomplete
                            size="small"
                            sx={{ width: '300px' }}
                            value={fsPackageValue}
                            onChange={(event, newValue: any) => {
                                if(newValue?.isButton){
                                    handleNewFsPackage;
                                } else {
                                    handleSetFsPackageValue(newValue);
                                }
                            }}
                            options={fsPackagesList}
                            disabled={readonly}
                            filterOptions={filterPackageOptions}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="FS Packages"
                                    variant="outlined"
                                    sx={{
                                        '& input': {
                                        paddingRight: '85px',
                                        },
                                    }}
                                    error={!isPackageNameValid}
                                    helperText={!isPackageNameValid ? 'Choose a Unique Package Name' : ''}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end" style={{ position: 'absolute', right: 8, top: '50%', transform: 'translateY(-50%)' }}>
                                                <MuiButton
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={handleSaveFsPackage}
                                                    disabled={!fsPackageValue || readonly}
                                                >
                                                    Save
                                                </MuiButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            getOptionLabel={(option) => option.name || ''}
                            isOptionEqualToValue={isPackageOptionEqualToValue}
                            renderOption={(props, option, { selected }) => {
                                if (option.isButton){
                                    return (
                                        <ListItem {...props} key={option.label} disableGutters>
                                        <MuiButton onClick={handleNewFsPackage} fullWidth>
                                          {option.label}
                                        </MuiButton>
                                      </ListItem>
                                    );
                                }
                                return (
                                <ListItem 
                                    {...props}
                                    key={option.id}
                                    sx={{ backgroundColor: 'white' }}
                                >
                                {option.name}
                                </ListItem>
                                );
                            }}
                            inputValue={fsPackageNewName}
                            onInputChange={(event, newInputValue) => {
                                    setFsPackageNewName(newInputValue);
                                    if(newInputValue) setFsPackageSaveName(newInputValue);
                            }}
                            />
                            </HorizontalBox>
                        </HorizontalBox>
                        ) : (
                            <HorizontalBox sx={{ display: 'flex', flexWrap: 'wrap' }} noPadding>
                            <Typography variant='h1'>Financial Statements</Typography>
                            <FinancialStatementsSelectorButtonBox>
                                <SingleSelect
                                    id='fs_selector_view'
                                    value={financialStatementsView}
                                    optionList={financialStatementsList}
                                    label={'Select Financial Statement'}
                                    handleOptionSelection={handleViewSelection}
                                    width='300px'
                                />
                            <StyledSwitchFilterBox>
                                { !readonly ? 
                                (<FormControlLabel
                                    id="chk_edit_mode"
                                    label="Edit Mode"
                                    control={
                                        <Switch
                                        id="switch_edit_mode"
                                        checked={editModeActivated}
                                        label="Edit Mode"
                                        onChange={!validFilters ? null : handleEditMode}
                                        />
                                    }
                                    disabled={!validFilters}
                                />) : ('')}
                                <FormControlLabel
                                    id="chk_rounded_report"
                                    label="Rounded Report"
                                    control={
                                        <Switch
                                        id="switch_rounded_report"
                                        checked={roundedReportActivated}
                                        label="Rounded Report"
                                        onChange={!validFilters ? null : handleRoundedReport}
                                        />
                                    }
                                    disabled={!validFilters}
                                />
                            </StyledSwitchFilterBox>
                        <Autocomplete
                            size="small"
                            sx={{ width: '300px', marginTop: '10px' }}
                            value={fsPackageValue}
                            onChange={(event, newValue: any) => {
                                if(newValue?.isButton){
                                    handleNewFsPackage;
                                } else {
                                    handleSetFsPackageValue(newValue);
                                }
                            }}
                            options={fsPackagesList}
                            disabled={readonly}
                            filterOptions={filterPackageOptions}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="FS Packages"
                                    variant="outlined"
                                    sx={{
                                        '& input': {
                                        paddingRight: '85px',
                                        },
                                    }}
                                    error={!isPackageNameValid}
                                    helperText={!isPackageNameValid ? 'Choose a Unique Package Name' : ''}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end" style={{ position: 'absolute', right: 8, top: '50%', transform: 'translateY(-50%)' }}>
                                                <MuiButton
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={handleSaveFsPackage}
                                                    disabled={!fsPackageValue || readonly}
                                                >
                                                    Save
                                                </MuiButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            getOptionLabel={(option) => option.name || ''}
                            isOptionEqualToValue={isPackageOptionEqualToValue}
                            renderOption={(props, option, { selected }) => {
                                if (option.isButton){
                                    return (
                                        <ListItem {...props} key={option.label} disableGutters>
                                        <MuiButton onClick={handleNewFsPackage} fullWidth>
                                          {option.label}
                                        </MuiButton>
                                      </ListItem>
                                    );
                                }
                                return (
                                <ListItem 
                                    {...props}
                                    key={option.id}
                                    sx={{ backgroundColor: 'white' }}
                                >
                                {option.name}
                                </ListItem>
                                );
                            }}
                            inputValue={fsPackageNewName}
                            onInputChange={(event, newInputValue) => {
                                    setFsPackageNewName(newInputValue);
                                    if(newInputValue) setFsPackageSaveName(newInputValue);
                            }}
                            />
                        </FinancialStatementsSelectorButtonBox>
                        </HorizontalBox>
                        )}
                    {gridData ||
                    financialStatementsView === FsViews.unrealizedSOI ||
                    financialStatementsView === FsViews.realizedSOI ? (
                    <ButtonBox>
                        <FsExportButton
                            id='fs_export_btn'
                            hidden={false}
                            headers={gridData?.dataGridColDefs || []}
                            data={gridData?.gridRows || []}
                            soiHeaders={soiGridData?.dataGridColDefs || []}
                            soiData={soiGridData?.soiGridRows || []}
                            fsView={financialStatementsView}
                            params={financialStatementsParams}
                            funds={funds}
                            accountList={filteredAccounts}
                            ledgerList={filteredLedgers}
                            setIsLoading={setIsLoading}
                        />
                    </ButtonBox>
                    ) : ('')}
                </HorizontalBox>
                <div/>
                <DataWrapperBox gapSize={0}>
                    { isLoading && <ProgressModal
                        id="gl_report_info_loading"
                        showProgress={isLoading}
                        />}
                    { isLoadingList &&
                    <ProgressModal
                        id='financial_statement_info_loading'
                        showProgress={isLoadingList}
                        />}
                    <HorizontalBox id='financial_statements_filter_box' fullWidth noPadding>
                        {!soiView ? (
                        <FinancialStatementsActionsContainer>
                            <StyledFilterBox>
                            <MultiSelectFilters
                                size="small"
                                id={"fund_filter"}
                                value={selectedFunds}
                                onFilter={handleFundFilterChange}
                                label={`Fund (${selectedFunds.length})`}
                                listData={fundsFilter as []}
                                filterName={"fund_filter"}
                            />
                            </StyledFilterBox>
                            { changesInPartners ? ('') : (
                                <>
                                    <StyledFilterBox>
                                    <MultiSelectFilters
                                        size="small"
                                        id={"ledger_filter"}
                                        value={selectedLedgers}
                                        onFilter={handleLedgerFilterChange}
                                        label={`GL Name (${selectedLedgers.length})`}
                                        listData={filteredLedgers as []}
                                        filterName={"ledger_filter"}
                                    />
                                    </StyledFilterBox>
                                    <StyledFilterBox>
                                    <MultiSelectFilters
                                        size="small"
                                        id={"account_filter"}
                                        value={selectedAccounts}
                                        onFilter={handleAccountFilterChange}
                                        label={`Account (${selectedAccounts.length}/${filteredAccounts.length})`}
                                        listData={filteredAccounts as []}
                                        filterName={"account_filter"}
                                    />
                                    </StyledFilterBox>
                                </>
                            ) }
                            { attributeFilterDisabled || changesInPartners ? ("") :
                            (<StyledFilterBox>
                                <MultiSelectFilters
                                    size="small"
                                    id={"attribute_filter"}
                                    value={selectedAttributes}
                                    onFilter={handleAttributeFilterChange}
                                    label={`Account Attributes (${selectedAttributes.length})`}
                                    listData={filteredAttributes as []}
                                    filterName={"attribute_filter"}
                                />
                            </StyledFilterBox>)}
                            { changesInPartners ? ('') : (
                            <StyledFilterBox>
                                <MultiSelectFilters
                                    size="small"
                                    id={"status_filter"}
                                    value={selectedStatus}
                                    onFilter={handleStatusFilterChange}
                                    label={`Status (${selectedStatus.length})`}
                                    listData={statusFilter as []}
                                    filterName={"status_filter"}
                                />
                            </StyledFilterBox>
                            )}
                            <StyledFilterBox>
                                <DateRangePicker 
                                    disabled={false}
                                    dateRange={dateRange} 
                                    onChange={handleDateRangeChange}
                                />
                            </StyledFilterBox>
                            <StyledFilterBox>
                                <FormControlLabel
                                    id="chk_supress_if_zero"
                                    label="Suppress 0 Lines"
                                    control={
                                        <Switch
                                        id="switch_supress_if_zero"
                                        checked={suppressIfZero}
                                        label="Suppress 0 Lines"
                                        onChange={!validFilters ? null : handleSuppressIfZeroChange}
                                        />
                                    }
                                    disabled={!validFilters}
                                    />
                            </StyledFilterBox>
                            <StyledFilterSpacerBox/>
                            <StyledFilterBox>
                                <Button
                                    id="run_financial_statements_button"
                                    text="Apply"
                                    color="primary"
                                    size="small"
                                    disabled={!validFilters}                
                                    onClick={handleRunFinancialStatementsButtonClick}
                                />
                            </StyledFilterBox>
                        </FinancialStatementsActionsContainer>
                        ) : (
                            <StyledDateFilterBox>
                            <DatePicker
                            label='Date'
                            renderInput={(params) => (
                                <TextField {...params} size="small" id="filter_date" />
                            )}
                            onChange={(value) => {handleSoiDateChange(value!);}}
                            value={soiDateFilter}
                            />
                            </StyledDateFilterBox>
                        )}
                    </HorizontalBox>
                    {!runFinancialStatementsView && !validFilters ? '' : fsViewComponent}
                    {
                        !noDataMessage || soiView ? 
                            ""
                        :
                            <NoDataMessageTypography color={invalidSelectedLedgers || invalidSelectedFunds ? 'Red' : ''}>{noDataMessage}</NoDataMessageTypography>
                    }
                </DataWrapperBox>
                <ReportDetails
                    open={!!fsDetailsApiParams}
                    reportParams={fsDetailsApiParams}
                    onClose={handleCloseReportDetails}
                    reportView={financialStatementsView}
                    summaryRowClicked={summaryRowClicked}
                    zeroSumStartBalance={zeroSumStartBalance}
                    level1Clicked={level1Clicked}
                    dateRange={dateRange}
                    netRow={netRow}
                    retainedRow={retainedRow}
                />
                <ConfirmationDialog
                    open={confirmNameChangeDialog}
                    onClose={() => setConfirmNameChangeDialog(false)}
                    id="fs_name_change_confirmation"
                    actions={[
                    {
                        label: 'Continue',
                        onClick: () => confirmFsNamechange(),
                        id: 'btn_save_fs_name_change',
                        variant: 'contained',
                        color: 'primary',
                    },
                    {
                        label: 'Cancel',
                        onClick: () => setConfirmNameChangeDialog(false),
                        id: 'btn_account_cancel_save',
                        variant: 'outlined',
                        color: 'error',
                    },
                    ]}
                    content="When updating the FS Display Name, this change will be reflected across all Accounts using the same FS Mapping within the fund(s) selected."
                    title="FS Display Name Change"
                />
                 <SaveExitModal
                    isBlocked={fsPackageFlag}
                    title="Leave Financial Statements"
                    content="Your changes have not been saved. Are you sure you want to leave?"
                    actionTitle="Save Changes"
                    confirmNavigationTitle="Exit Without Saving"
                    onActionClick={handleSaveFsPackage}
                    onNavigateAway={handleExitConfirmation}
                    onCancelClick={() => {setOpenFlag(false);}}
                />
                <FullScreenModal
                    id='save_fs_package_confirmation'
                    open={openFlag}
                    title={'You have unsaved changes'}
                    subtitle={'Unsaved changes will be lost'}
                    confirmButtonText={'Keep Editing'}
                    cancelButtonText={'Discard Changes'}
                    onSuccess={() => {setOpenFlag(false);}}
                    onCancel={handleExitConfirmation}
                />
            </MainContainer>
        </>
    );
};