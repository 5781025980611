import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers-pro';

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gridTemplateColumns: '15% 43%',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

export const FooterRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gridTemplateColumns: '60% 30%',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

export const FormControlCheckbox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(1),
}));

export const StatusBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

export const DatePicker = styled(MUIDatePicker)`
  &.MuiFormControl-root.MuiTextField-root {
    margin: 10px;
  }
`;

export const LineItemBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1.25),
  padding: 0,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

export const LineItemList = styled(List, {
  shouldForwardProp: (prop) => prop !== 'readonly',
})<{
  readonly?: boolean;
}>(({ theme, readonly }) => ({
  margin: 0,
  padding: 0,
  maxHeight: 'none',
  overflowY: 'auto',
}));

export const Line = styled(ListItem)(({ theme }) => ({
  margin: 0,
  padding: 0,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

export const LineHeader = styled(ListItem)(({ theme }) => ({
  margin: 0,
  padding: 0,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

export const LineTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'number' && prop !== 'bold',
})<{
  number?: boolean;
  bold?: boolean;
}>(({ theme, number, bold }) => ({
  '& .Mui-error': {
    border: '1px solid red',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 0,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0,
  },

  '& .MuiInputBase-input.MuiOutlinedInput-input': {
    textAlign: number ? 'right' : 'left',
    padding: number ? `${theme.spacing(1)} ${theme.spacing(2)}` : '',
  },

  '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
    color: 'black',
    fontWeight: '500',
    WebkitTextFillColor: 'black',
  },
}));

export const LineCell = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'br' && prop !== 'readonly',
})<{
  br?: boolean;
  readonly?: boolean;
}>(({ theme, br, readonly }) => ({
  flex: '1 0 auto',
  padding: readonly ? theme.spacing(1) : 0,
  margin: 0,
  height: '100%',
  borderRight: br ? `1px solid ${theme.palette.grey[300]}` : 0,
}));

export const Header = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  margin: 0,
}));

export const GridBlue = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingBottom: theme.spacing(1.5),
}));

export const Label = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'number' && prop !== 'bold',
})<{ number?: boolean; bold?: boolean }>(({ theme, number, bold }) => ({
  margin: 0,
  padding: 0,
  paddingTop: bold ? theme.spacing(1) : 0,
  paddingRight: theme.spacing(1),
  textAlign: number ? 'right' : bold ? 'center' : 'left',
  fontWeight: bold ? '600' : 'normal',
}));

export const KeyValuePair = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const KeyLabel = styled(Box)`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;

export const ValueLabel = styled(Box)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

export const FileSelectorWrapper = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(1.5)}`,
}));

export const AttachmentListBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'readonly',
})<{
  readonly?: boolean;
}>(({ theme, readonly }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: `1px solid ${theme.palette.divider}`,
  paddingRight: '10px',
  paddingLeft: readonly ? '0' : '10px',
}));

export const AttachmentButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
}));

export const DocumentLinkBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'readonly',
})<{
  readonly?: boolean;
}>(({ theme, readonly }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: readonly ? theme.spacing(1) : '0',
}));

export const LinkButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 700,
  fontSize: '16px',
}));

export const LinkBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: theme.spacing(6),
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledLateralBox = styled(Box)`
  height: calc(100vh - 50px);
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0.7em;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #c2c2c6;
    border-radius: 8px;
  }
`;
