import {
  Box as MUIBox, styled
} from "@mui/material";

import { DataGrid } from "../../components";
import { GRID_HEADER_BACKGROUND_COLOR, GRID_HEADER_BORDER_COLOR, ROW_HOVER_BACKGROUND_COLOR } from "../../styles/colors";


export const Box = styled(MUIBox)`
  margin: 10px;
`;

export const ButtonBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));

interface MailingsWrapper {
  wrapperHeight: number | undefined;
}

export const MailingsWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "wrapperHeight",
})<MailingsWrapper>(({ theme, wrapperHeight = "auto" }) => ({
  overflowY: "auto",
  marginTop: theme.spacing(-2),
  marginRight: theme.spacing(-2),
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
  height: wrapperHeight,
}));

export const HeaderRow = styled(Box)(({
    theme
  }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    height:"1vh"
  }));

export const IconBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
  }));

export const CellBox = styled(Box)(() => ({
    width: '100%'
  }));

  export const StyledDataGridPro = styled(DataGrid)`
  margin-top: 10px;
  margin-bottom: 25px;

  border: 1px solid transparent;
  border-radius: 0;

  
  .MuiDataGrid-row.Mui-hovered {
    background-color: ${ROW_HOVER_BACKGROUND_COLOR};
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }

  .MuiDataGrid-cell:focus-within {
    outline: none;
  }

  .MuiDataGrid-columnHeaders {
    background-color: ${GRID_HEADER_BACKGROUND_COLOR};
    border-top: 1px solid ${GRID_HEADER_BORDER_COLOR};
    border-bottom: 1px solid ${GRID_HEADER_BORDER_COLOR};
    min-height: 30px !important;
    max-height: 30px !important;
    line-height: 30px !important;
  }

  .MuiDataGrid-pinnedColumnHeaders--right {
    background-color: ${GRID_HEADER_BACKGROUND_COLOR};
    border-top: 1px solid ${GRID_HEADER_BORDER_COLOR};
    border-bottom: 1px solid ${GRID_HEADER_BORDER_COLOR};
    min-height: 30px !important;
    max-height: 30px !important;
    line-height: 30px !important;
  }

  .MuiDataGrid-columnHeader(:not(:first-of-type)) {
    background-color: ${GRID_HEADER_BACKGROUND_COLOR};
    border: 1px solid ${GRID_HEADER_BORDER_COLOR};
    padding: 1px 10px;
  }

  .MuiDataGrid-columnHeader:focus {
    outline: none;
  }

  .MuiDataGrid-columnSeparator {
    color: transparent;
  }

  .MuiDataGrid-columnSeparator:hover {
    color: transparent;
  }

  .MuiDataGrid-iconButtonContainer {
    visibility: visible;
    width: auto;
  }

  .MuiDataGrid-pinnedColumns {
    box-shadow: -6px 0px 12px -7px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: -6px 0px 12px -7px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -6px 0px 12px -7px rgba(0, 0, 0, 0.5);
  }

  & .MuiDataGrid-cell[data-field="subject"] {
    padding: 0;
  }
`;