import { Edit, Send } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Chip, Typography } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import {
  DataGridProProps,
  GridAlignment,
  GridColDef,
  GridColumns,
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { useContext, useEffect, useMemo, useState } from "react";

import StatusLabel from "../../components/StatusLabel/StatusLabel";
import { AppContext } from "../../core/context/appContextProvider";
import useRole from "../../core/routing/useRole";
import { getAvailableRoles } from "../../services/contact.service";
import { getFundsFilterList } from "../../services/filters.service";
import {
  deleteGeneralMailing,
  getContactsPreviews,
  getDocumentsPreviews,
  getGeneralMailing,
  getGeneralMailingList,
  sendGeneralMailings,
} from "../../services/generalMailings.service";
import { getInvestorFilters } from "../../services/investor.service";
import { M_DASH_UNICODE } from "../../utils/constants/constants";
import { GENERIC_ERROR_MESSAGE } from "../../utils/constants/text.constants";
import { capitalize } from "../../utils/helpers/capitalize";
import { DateTimeFormat } from "../../utils/helpers/format.helper";
import { useEffectAsync } from "../../utils/hooks/useEffectAsync.hook";
import { FundItem } from "../../utils/types/fund.type";
import {
  GeneralMailings,
  GeneralMailingsFilter,
  GeneralMailingsInboxFilter,
  SelectedContacts,
  SelectedDocuments,
  SelectedMailing,
  sendIds,
} from "../../utils/types/generalMailings.type";
import { InvestorFilter } from "../../utils/types/investor.type";
import {
  CustomType,
  DataGridColDef,
  FilterItem,
} from "../../utils/types/listItems";
import { ScopeRole } from "../../utils/types/user.type";
import { CellBox } from "./GeneralMailings.style";
import { ViewType } from "./mailingsComposerPanel/MailingsComposerPanel.constants";

const StatusType = {
  reviewing: "blue",
  editing: "red",
  approved: "green",
  sent: "green",
};

const STATUS_FILTER = [
  {
    id: "reviewing",
    name: "Reviewing",
  },
  {
    id: "editing",
    name: "Editing",
  },
  {
    id: "approved",
    name: "Approved",
  },
  {
    id: "sent",
    name: "Sent",
  },
];

const initialSelectedMailing: SelectedMailing = {
  id: undefined,
  status: undefined,
};

export const useGeneralMailings = () => {
  const { informationAlert, state } = useContext(AppContext);
  const [generalMailingList, setGeneralMailingList] = useState<
    GeneralMailings[]
  >([]);
  const [generalMailingsResponse, setGeneralMailingsResponse] = useState<
    GeneralMailings[]
  >([]);
  const [showMailingPanel, setShowMailingPanel] = useState(false);
  const [mailingList, setMailingList] = useState<GeneralMailings[]>([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedPreviewMailing, setSelectedPreviewMailing] = useState<any>();
  const [draftMailingList, setDraftMailingList] = useState<GeneralMailings[]>(
    []
  );
  const [sentMailingList, setSentMailingList] = useState<GeneralMailings[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sendConfirmationOpen, setSendConfirmationOpen] =
    useState<boolean>(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const [selectedMailingId, setSelectedMailingId] = useState("");
  const [selectedMailingEdit, setSelectedMailingEdit] = useState({});
  const [contactsGeneratedMailing, setContactsGeneratedMailing] =
    useState(false);
  const [search, setSearch] = useState<string>("");
  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const [showSuggestionPopover, setShowSuggestionPopover] = useState(false);
  const [previewCounter, setPreviewCounter] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const [roleFilters, setRoleFilters] = useState<FilterItem[]>([]);
  const [investorFilters, setInvestorFilters] = useState<InvestorFilter[]>([]);
  const [fundFilters, setFundFilters] = useState<FundItem[]>([]);
  const [sendId, setSendId] = useState<string>("");
  const [deleteId, setDeleteId] = useState<string>("");
  const [mailingView, setMailingView] = useState<
    undefined | string | null | ViewType
  >();
  const [selectedMailing, setSelectedMailing] = useState<SelectedMailing>();

  useEffect(() => {
    if (generalMailingList) {
      prepItemsForDataGrid();
    }
  }, [generalMailingList]);

  const defaultSentHeaderList: any = [
    {
      field: "subject",
      index: 1,
      headerName: "Subject",
      hide: false,
      type: "string",
      align: "left" as GridAlignment,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CellBox>
            <Typography
              align={params?.row?.isChild ? "right" : "left"}
              fontWeight={params?.row?.isChild ? "" : "bold"}
            >
              {params?.row?.isParent
                ? params?.row?.subject
                : params?.row?.fundName + " - " + params?.row?.investorName}
            </Typography>
          </CellBox>
        );
      },
    },
    {
      field: "view",
      index: 2,
      headerName: "View",
      type: "string",
      hideable: false,
      sortable: false,
      renderCell: (params: any) => {
        return isSuperAdminOrClientAdmin && params.row.isParent ? (
          ""
        ) : params.row.isChild ? (
          <Button
            startIcon={<VisibilityIcon />}
            onClick={() =>
              handleSinglePreviewAction(
                params?.row?.parentId,
                params?.row?.previewIndex,
                params?.row?.fundName
              )
            }
          >
            View
          </Button>
        ) : (
          ""
        );
      },
    },
    {
      field: "sentDate",
      index: 3,
      headerName: "Sent",
      hide: false,
      sortable: true,
      renderCell: (params: any) => {
        const date = params?.row?.createdOn ?? "";

        if (!date) {
          return "";
        }
        const formattedData = DateTimeFormat.shortDate(new Date(date));

        return <Typography variant="body2">{formattedData}</Typography>;
      },
      align: "left" as GridAlignment,
    },
    {
      field: "action",
      index: 4,
      hide: false,
      hideable: false,
      sortable: false,
      renderCell: (params: any) => {},
      type: "action",
      customType: CustomType.Action,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];

  const defaultDraftsHeaderList: any = [
    {
      field: "subject",
      index: 1,
      headerName: "Subject",
      type: "string",
      cellClassName: (params: any) => {
        return "message-row";
      },
      renderCell: (params: any) => {
        return (
          <CellBox>
            <Typography
              align={params?.row?.isChild ? "right" : "left"}
              fontWeight={params?.row?.isChild ? "" : "bold"}
            >
              {params?.row?.isParent
                ? params?.row?.subject
                : params?.row?.fundName + " - " + params?.row?.investorName}
            </Typography>
          </CellBox>
        );
      },
    },
    {
      field: "compose",
      index: 2,
      headerName: "Compose",
      hide: false,
      hideable: false,
      sortable: false,
      type: "string",
      renderCell: (params: any) => {
        return isSuperAdminOrClientAdmin && params.row.isParent ? (
          <Button
            startIcon={<Edit />}
            onClick={() => handleOnEdit(params.row.id)}
          >
            Edit
          </Button>
        ) : params.row.isChild ? (
          <Button
            startIcon={<VisibilityIcon />}
            onClick={() =>
              handleSinglePreviewAction(
                params?.row?.parentId,
                params?.row?.previewIndex,
                params?.row?.fundName
              )
            }
          >
            Preview
          </Button>
        ) : (
          ""
        );
      },
    },
    {
      field: "status",
      index: 3,
      headerName: "Status",
      renderCell: (params: GridRenderCellParams) => {
        const status = params?.row?.status?.toLowerCase();
        const color = StatusType[status as keyof typeof StatusType];

        return status && params.row.isParent ? (
          <div>
            <StatusLabel
              color={color}
              isUpperCase={false}
              label={capitalize(status ?? M_DASH_UNICODE)}
            />
          </div>
        ) : (
          ""
        );
      },
      type: "string",
      hideable: false,
    },
    {
      field: "createdOn",
      index: 4,
      headerName: "Created",
      type: "string",
      renderCell: (params: any) => {
        if (params.row.isParent) {
          const date = params?.row?.createdOn ?? "";

          if (!date) {
            return <Typography variant="body2">{M_DASH_UNICODE}</Typography>;
          }
          const formattedData = DateTimeFormat.shortDate(new Date(date));

          return <Typography variant="body2">{formattedData}</Typography>;
        } else {
          return "";
        }
      },
    },
    {
      field: "send",
      index: 5,
      headerName: "Send",
      hideable: false,
      type: "string",
      renderCell: (params: any) => {
        return isSuperAdminOrClientAdmin && params?.row?.isParent ? (
          <Button
            id="btn_send_general_mailings"
            variant="contained"
            size="small"
            endIcon={<Send />}
            onClick={() => {
              sendMailing(params?.row?.id);
            }}
          >
            {" "}
            SEND
          </Button>
        ) : (
          ""
        );
      },
      sortable: false,
    },
    {
      field: "action",
      index: 6,
      headerName: "",
      renderCell: (params: any) => {
        if (params?.row && params.row.isParent) {
          return (
            <div>
              {isSuperAdminOrClientAdmin ? (
                <Button
                  id="btn_delete_general_mailing"
                  onClick={() => deleteMailing(params?.row?.id)}
                >
                  <DeleteIcon />
                </Button>
              ) : (
                ""
              )}
            </div>
          );
        }
      },
      type: "action",
      customType: CustomType.Action,
      hideable: false,
      hide: false,
      sortable: false,
    },
  ];

  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [dataGridRows, setDataGridRows] = useState<any[]>([]);
  const [dataGridRowsResponse, setDataGridRowsResponse] = useState<any[]>([]);

  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultDraftsHeaderList.length - 3
  );

  useEffect(() => {
    currentTab === 0
      ? setActiveHeaderFields(defaultDraftsHeaderList.length - 3)
      : setActiveHeaderFields(defaultSentHeaderList.length - 1);
    currentTab === 0
      ? setSortModel([
          {
            field: "createdOn",
            sort: "desc",
          },
        ])
      : setSortModel([
          {
            field: "sentDate",
            sort: "desc",
          },
        ]);
  }, [currentTab]);

  const handleUpdateHeader = async (
    field: string,
    inlineFilterName?: GeneralMailingsInboxFilter
  ) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== "action"
    );

    const updatedHeaders: any = headerList.map((header) => {
      return {
        ...header,
        hide:
          header.field === field && !(!header.hide && activeFields.length <= 1)
            ? !header.hide
            : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }
  };

  const { hasRole: isSuperAdminOrClientAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);

  useEffect(() => {
    setHeaderList(defaultDraftsHeaderList);
    setSortModel([
      {
        field: "createdOn",
        sort: "desc",
      },
    ]);
  }, []);

  const handleSinglePreviewAction = (
    id: any,
    previewCounter: number,
    fund: any
  ) => {
    setPreviewCounter(previewCounter);
    setSelectedPreviewMailing({ id, fundName: fund });
    setShowPreviewModal(true);
  };

  const fetchAllGeneralMailings = async (isCanceled?: () => boolean) => {
    setIsLoading(true);

    try {
      if (isCanceled?.()) return;
      const generalMailingsResponse = await getGeneralMailingList();

      if (!generalMailingsResponse) {
        setIsLoading(false);
        return;
      }

      const contactDrafts: any = [];
      const docDrafts: any = [];
      const capCallDrafts: any = [];

      generalMailingsResponse.map((mail) => {
        switch (mail.type) {
          case "CONTACTS":
            contactDrafts.push(mail);
            break;
          case "DOCUMENTS":
            docDrafts.push(mail);
            break;
          case "CAP_CALL":
            capCallDrafts.push(mail);
            break;
        }
      });

      const updateDrafts = (drafts: any, type: string): Promise<any> => {
        return Promise.all(
          drafts.map(async (mail: any) => {
            switch (type) {
              case "CONTACTS": {
                if (mail.to.funds.length > 0) {
                  const payload: SelectedContacts = {
                    funds: mail.to.funds,
                    investors: mail.to.investors,
                    roles: mail.to.roles,
                  };

                  const response: any = await getContactsPreviews(payload);

                  mail.previews = response.previews;
                  return mail;
                }
                return mail;
                break;
              }
              case "DOCUMENTS": {
                const payload: SelectedDocuments = {
                  fileIds: mail.documents,
                };

                const response: any = await getDocumentsPreviews(payload);

                mail.previews = response.previews;
                return mail;
                break;
              }

              case "CAP_CALL": {
                const payload: SelectedContacts = {
                  funds: mail.to.funds,
                  investors: mail.to.investors,
                  roles: mail.to.roles,
                };

                const response: any = await getContactsPreviews(payload);

                mail.previews = response.previews;
                return mail;
                break;
              }
            }
          })
        );
      };

      const updatedDocDrafts = await updateDrafts(docDrafts, "DOCUMENTS");
      const updatedContactDrafts = await updateDrafts(
        contactDrafts,
        "CONTACTS"
      );
      const updatedCapCallDrafts = await updateDrafts(
        capCallDrafts,
        "CAP_CALL"
      );

      const updatedGeneralMailingList = updatedDocDrafts
        .concat(updatedContactDrafts)
        .concat(updatedCapCallDrafts);

      if (updatedGeneralMailingList) {
        setGeneralMailingsResponse(updatedGeneralMailingList);
        setGeneralMailingList(updatedGeneralMailingList);
        setDraftMailingList(
          updatedGeneralMailingList.filter(
            (mail: any) => mail.status === "DRAFT"
          )
        );
        setSentMailingList(
          updatedGeneralMailingList.filter(
            (mail: any) => mail.status === "SENT"
          )
        );
      }
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllGeneralMailings();
  }, []);

  useEffect(() => {
    setGeneralMailingList(draftMailingList);
  }, [draftMailingList]);

  const handleTabChange: any = (event: any, newValue: any) => {
    setCurrentTab(newValue);
    switch (newValue) {
      case 0:
        setGeneralMailingList(draftMailingList);
        setHeaderList(defaultDraftsHeaderList);
        setSortModel([
          {
            field: "createdOn",
            sort: "desc",
          },
        ]);
        break;
      case 1:
        setGeneralMailingList(sentMailingList);
        setHeaderList(defaultSentHeaderList);
        setSortModel([
          {
            field: "sentDate",
            sort: "desc",
          },
        ]);
        break;
    }
  };

  useEffectAsync(async (isCanceled) => {
    try {
      const roleFiltersResponse = await getAvailableRoles();

      if (isCanceled()) return;
      setRoleFilters(roleFiltersResponse);

      const investorFiltersResponse = await getInvestorFilters();

      if (isCanceled()) return;
      setInvestorFilters(investorFiltersResponse);

      const fundsFilterResponse = await getFundsFilterList();

      if (isCanceled()) return;
      setFundFilters(fundsFilterResponse);
    } catch (e) {
      alert("error");
    }
  }, []);

  const funds = useMemo(
    () => fundFilters?.map((item) => item.id),
    [fundFilters]
  );
  const roles = useMemo(
    () => roleFilters?.map((item) => item.id),
    [roleFilters]
  );
  const investors = useMemo(
    () => investorFilters?.map((item) => item.id),
    [investorFilters]
  );

  const confirmedSend = () => {
    handleSend(sendId);
    setSendConfirmationOpen(false);
  };

  const cancelSend = () => {
    setSendConfirmationOpen(false);
    setSendId("");
  };

  const sendMailing = (mailingId: string) => {
    setSendConfirmationOpen(true);
    setSendId(mailingId);
  };

  const handleSend = async (mailingId: string) => {
    setIsLoading(true);
    try {
      const ids: sendIds = {
        draftIds: [mailingId],
      };

      await sendGeneralMailings(ids);

      informationAlert("Successfully sent mailing", "success");
      setSendId("");
      fetchAllGeneralMailings();
    } catch (error) {
      informationAlert("An error occured when sending mailing", "error");
    }
    setIsLoading(false);
  };

  const confirmedDelete = () => {
    handleDelete(deleteId);
    setDeleteConfirmationOpen(false);
  };

  const cancelDelete = () => {
    setDeleteConfirmationOpen(false);
    setDeleteId("");
  };

  const deleteMailing = (mailingId: string) => {
    setDeleteId(mailingId);
    setDeleteConfirmationOpen(true);
  };

  const handleDelete = async (id: string) => {
    setIsLoading(true);
    try {
      await deleteGeneralMailing(id);
      fetchAllGeneralMailings();
      setDeleteId("");
      informationAlert("Mailing successfully deleted", "success");
    } catch (error) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    initializeSearchOptions(dataGridRows);
  }, [dataGridRows]);

  const initializeSearchOptions = (response: any[]) => {
    const options: string[] = [];

    response.forEach((generalMailing) => {
      if (generalMailing?.subject) {
        options.push(generalMailing.subject);
      }
      if (generalMailing?.fundName) {
        options.push(generalMailing.fundName);
      }
      if (generalMailing?.investorName) {
        options.push(generalMailing.investorName);
      }
      if (generalMailing?.fileName) {
        options.push(generalMailing.fileName);
      }
    });

    const uniqueOptions = options.filter(
      (option, idx) => options.indexOf(option) === idx
    );

    setSearchOptions(uniqueOptions);
  };

  const searchMailingList = (searchText: string) => {
    if (generalMailingList) {
      if (searchText === "") {
        if (currentTab === 0) {
          setGeneralMailingList(draftMailingList);
        }
        if (currentTab === 1) {
          setGeneralMailingList(sentMailingList);
        }
      } else if (typeof searchText === "string") {
        const updatedRows: any = [];

        const currentRows = [...dataGridRows];

        currentRows.forEach((mail: any) => {
          if (mail?.subject?.match(new RegExp(searchText, "i"))) {
            if (!mail?.to?.funds.length) {
              updatedRows.push(mail);
            }
            currentRows.map((row: any) => {
              if (mail.id === row.parentId) {
                updatedRows.push(mail);
                updatedRows.push(row);
              }
            });
          }
          if (mail?.fundName?.match(new RegExp(searchText, "i"))) {
            currentRows.map((row: any) => {
              if (row.id === mail.parentId) {
                updatedRows.push(row);
                updatedRows.push(mail);
              }
            });
          }
          if (mail?.investorName?.match(new RegExp(searchText, "i"))) {
            currentRows.map((row: any) => {
              if (row.id === mail.parentId) {
                updatedRows.push(row);
                updatedRows.push(mail);
              }
            });
          }
          if (mail?.fileName?.match(new RegExp(searchText, "i"))) {
            currentRows.map((row: any) => {
              if (row.id === mail.parentId) {
                updatedRows.push(row);
                updatedRows.push(mail);
              }
            });
          }
        });

        const uniqueRows = updatedRows.filter(
          (option: any, idx: any) => updatedRows.indexOf(option) === idx
        );

        setDataGridRows(uniqueRows);
      }
    }
  };

  useEffect(() => {
    searchMailingList(search);
    if (search === "" && generalMailingList.length) {
      setDataGridRows(dataGridRowsResponse);
    }
  }, [search]);

  const onSearch = (
    event: any,
    newValue: React.SetStateAction<string> | null
  ) => {
    if (typeof newValue === "string") {
      setSearch(newValue);
    } else if (newValue === null) {
      setSearch("");
    }

    if (newValue !== null) setShowSuggestionPopover(false);
  };

  const handleOnEdit = async (id: string) => {
    setSelectedMailingId(id);
    const response = await getGeneralMailing(id);

    setSelectedMailingEdit(response);
    setShowMailingPanel(true);
  };

  const apiRef = useGridApiRef();

  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: "",
    filterable: true,
    width: 125,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams) => {
    const { id, field, rowNode } = props;
    const apiRef = useGridApiContext();

    const filteredDescendantCountLookup: any = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector
    );
    const filteredDescendantCount =
      filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick: ButtonProps["onClick"] = (event) => {
      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    };

    return (
      <Box sx={{ ml: rowNode.depth * 4 }}>
        <div>
          {filteredDescendantCount > 0 ? (
            <Button
              startIcon={<VisibilityIcon />}
              type="button"
              onClick={handleClick}
            >
              {currentTab === 0
                ? `Preview (${filteredDescendantCount})`
                : `View (${filteredDescendantCount})`}
            </Button>
          ) : (
            <span />
          )}
        </div>
      </Box>
    );
  };

  const prepItemsForDataGrid = () => {
    const rows = generalMailingList.reduce((newArray: any[], mail) => {
      const newItem = { ...mail, isParent: true, path: [mail.id] };

      newArray.push(newItem);
      let counter = 1;

      if (mail?.previews?.length! > 1) {
        mail?.previews?.map((preview: any) => {
          const newChildItem = {
            id: mail.id + "-" + counter,
            ...preview,
            parentId: mail.id,
            path: [
              mail.id,
              preview.fundName +
                " - " +
                preview.investorName +
                counter.toString(),
            ],
            isChild: true,
            previewIndex: counter,
          };

          counter++;
          newArray.push(newChildItem);
        });
      } else {
        mail?.previews?.map((preview: any) => {
          const newChildItem = {
            id: mail.id + "-" + counter,
            ...preview,
            parentId: mail.id,
            path: [
              mail.id,
              preview.fundName +
                " - " +
                preview.investorName +
                counter.toString(),
            ],
            isChild: true,
          };

          newArray.push(newChildItem);
        });
      }

      return newArray;
    }, []);

    setDataGridRows(rows);
    setDataGridRowsResponse(rows);
  };

  return {
    dataGridRows,
    prepItemsForDataGrid,
    apiRef,
    groupingColDef,
    CustomGridTreeDataGroupingCell,
    isLoading,
    draftMailingList,
    setDraftMailingList,
    sentMailingList,
    headerList,
    generalMailingList,
    setGeneralMailingList,
    search,
    searchOptions,
    showMailingPanel,
    setShowMailingPanel,
    showSuggestionPopover,
    setShowSuggestionPopover,
    onSearch,
    currentTab,
    handleTabChange,
    investorFilters,
    investors,
    fundFilters,
    funds,
    roleFilters,
    roles,
    mailingList,
    setMailingList,
    selectedMailingId,
    setSelectedMailingId,
    selectedMailingEdit,
    setSelectedMailingEdit,
    showPreviewModal,
    setShowPreviewModal,
    selectedPreviewMailing,
    setSelectedPreviewMailing,
    handleSend,
    sendConfirmationOpen,
    setSendConfirmationOpen,
    confirmedSend,
    cancelSend,
    deleteConfirmationOpen,
    confirmedDelete,
    cancelDelete,
    fetchAllGeneralMailings,
    isSuperAdminOrClientAdmin,
    previewCounter,
    setPreviewCounter,
    sortModel,
    setSortModel,
    setContactsGeneratedMailing,
    activeHeaderFields,
    handleUpdateHeader,
    setMailingView,
    mailingView,
    setSelectedMailing,
    selectedMailing,
  };
};
