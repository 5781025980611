import { FormGroup } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";

import { LoadingStatus } from "../../../utils/types/form.type";
import { FilterItem } from "../../../utils/types/listItems";
import ChipsCell from "../../DataGrid/ChipsCell/ChipsCell";
import {
  FilterCheckBox,
  FilterControlLabel,
  FilterOption,
} from "../../DataGrid/Filter/Filter.style";
import SearchBar from "../../SearchBarWithDebounce/SearchBar";
import {
  ChipBox,
  Dropdown,
  FilterBox,
  OptionList,
  TypeLabel,
} from "./JEEntityFilter.styles";

type Props = {
  error?: any;
  value?: any | null | string;
  setValue: Function;
  setValueField: string;
  onChange?: (entity: any | null) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?: boolean;
  size?: "small" | "medium";
  id?: string;
  textFieldID?: string;
  readonly?: boolean;
  loading?: LoadingStatus;
  options: any[];
  onDropdownClose?: () => void;
};

type OptionItemProps = {
  id: string;
  label: string;
  onSelect: (item: FilterItem, checked: boolean) => void;
  selected: boolean;
};

const OptionItem: React.FC<OptionItemProps> = ({
  id,
  label,
  onSelect,
  selected = false,
}: OptionItemProps): ReactElement => {
  const onCheck = (e: any): void => {
    const isChecked = e.target.checked;

    onSelect(
      {
        id,
        name: label,
      },
      isChecked
    );
  };

  return (
    <FilterOption>
      <FilterControlLabel
        control={
          <FilterCheckBox
            id={`${id}_check`}
            name={label}
            checked={selected}
            onChange={onCheck}
          />
        }
        label={label}
      />
    </FilterOption>
  );
};

export const JEEntityFilter = ({
  onChange,
  value,
  setValue,
  setValueField,
  id = "entity_filter",
  readonly = false,
  options,
  error,
  onDropdownClose
}: Props) => {
  const [search, setSearch] = useState<string>("");
  const [optionList, setOptionList] = useState<Record<string, any>[]>([]);

  const entityTypes = new Set();

  useEffect(() => {
    if (options && options.length) {
      setOptionList(options);
    }
  }, [options]);

  const onSelectChange = (entity: any, isSelected: boolean) => {
    if (isSelected) {
      if (value.length < 10) {
        onChange?.(value.push({ id: entity.id, name: entity.name }));
      }
    } else {
      const updatedList = value.filter(
        (option: any) => option.id !== entity.id
      );

      onChange?.(updatedList);
      setValue(setValueField, updatedList);
    }
  };

  const onSearch = (value: string): void => {
    const regex = new RegExp(value, "igm");
    const listFilter = options.filter((item) => item.name.search(regex) !== -1);

    setOptionList(listFilter);
    setSearch(value);
  };

  return (
    <FilterBox error={error}>
      {value && value.length > 0 && (
        <ChipBox>
          <ChipsCell
            items={value.map((v: any) => v.name)}
            chipLabelField={""}
            chipIDField={""}
            chipCount={2}
          />
        </ChipBox>
      )}
      {!readonly && (
        <>
          <Dropdown
            id={id}
            value={""}
            onChange={() => {}}
            defaultValue=""
            displayEmpty
            disableUnderline
            variant="standard"
            onClose={onDropdownClose}
          >
            <>
              <SearchBar
                id="filter_search_entity"
                onChange={onSearch}
                value={search}
              />
              <FormGroup>
                <OptionList>
                  {optionList && optionList?.length > 0 ? (
                    optionList.map((option) => (
                      <>
                        {!entityTypes.has(option.type) &&
                          entityTypes.add(option.type) && (
                            <>
                              <TypeLabel variant="label">
                                {option.type}
                              </TypeLabel>
                            </>
                          )}

                        <OptionItem
                          key={option.id}
                          label={option.name}
                          id={option.id}
                          onSelect={onSelectChange}
                          selected={value.some((e: any) => option.id === e.id)}
                        />
                      </>
                    ))
                  ) : (
                    <div>No records</div>
                  )}
                </OptionList>
              </FormGroup>
            </>
          </Dropdown>
        </>
      )}
    </FilterBox>
  );
};

export default JEEntityFilter;
