import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import { addMinutes, format } from "date-fns";
import * as React from "react";
import { ReactElement } from "react";
import { RouteProps } from "react-router-dom";

import IconLeftArrow from "../../../assets/images/icons/icon_left_arrow.svg";
import SplitButton from "../../../components/Buttons/SplitButton/SplitButton";
import DataGrid from "../../../components/DataGrid/DataGrid";
import ImgIcon from "../../../components/ImgIcon/ImgIcon";
import ProgressModal from "../../../components/Progress/ProgressModal/ProgressModal";
import TabsPanel from "../../../components/TabsPanel/TabsPanel";
import {
  DateTimeFormat,
  NegativeCurrencyFormat,
  NumberFormat,
} from "../../../utils/helpers/format.helper";
import { SelectedTransaction } from "../../../utils/types/capitalAccounts.type";
import { DownloadTransactionHistoryOptionIds } from "../../../utils/types/common.type";
import { ImageItem } from "../../../utils/types/listItems";
import { FooterBox, TitleBox } from "../statementReport/StatementReport.styles";
import {
  ArrowIconBox,
  DetailPanel,
  DetailSections,
  TabsRow,
  TotalRow,
} from "./TransactionHistory.styles";
import { useTransactionHistory } from "./useTransactionHistory.hooks";

interface Props extends RouteProps {
  onTransactionHistoryClose: Function;
  transaction: SelectedTransaction;
  columnsList: string[];
  handleTabChange: Function;
}

const defaultColumnActions: ImageItem[] = [
  {
    id: DownloadTransactionHistoryOptionIds.DownloadExcel,
    text: "Download (Excel)",
  },
  {
    id: DownloadTransactionHistoryOptionIds.DownloadPdf,
    text: "Download (PDF)",
  },
];

const TransactionHistory: React.FC<Props> = ({
  onTransactionHistoryClose,
  transaction,
  columnsList,
  handleTabChange,
}: Props): ReactElement => {
  const {
    toggleDrawer,
    isLoading,
    transactionHistory,
    headerList,
    transactions,
    amountTotal,
    handleSplitButtonAction,
  } = useTransactionHistory({
    transaction,
    onTransactionHistoryClose,
  });

  const formatter = NegativeCurrencyFormat(transaction.currencyCode || "USD");

  return (
    <React.Fragment>
      <DetailPanel
        id="transaction_drawer"
        anchor="right"
        open={true}
        onClose={toggleDrawer}
      >
        <ProgressModal id="cap_acc_stat_rep_loading" showProgress={isLoading} />
        <Box>
          <TitleBox>
            <Box>
              {!transaction.transactionType && (
                <ArrowIconBox>
                  <ImgIcon icon={IconLeftArrow} onClick={toggleDrawer} />
                  <Typography
                    id="prev_title"
                    variant="subtitle1"
                    display="flex"
                  >
                    {transaction.label}
                  </Typography>
                </ArrowIconBox>
              )}
              <Typography id="title" variant="formTitle" display="flex">
                {transaction.investorName} - {transaction.fundName}
              </Typography>
            </Box>

            <CloseIcon color="primary" onClick={toggleDrawer} />
          </TitleBox>
          <DetailSections>
            <Box>
              <Typography
                id="trans_detail_modal_title"
                variant="modalSubtitle"
                display="flex"
              >
                Transaction Details
              </Typography>
              <Typography
                id="trans_detail_modal_subtitle"
                variant="TextBoxTitle"
                display="flex"
              >
                {transactionHistory?.startDate &&
                  DateTimeFormat.shortDate(transactionHistory?.startDate)}
                &nbsp;-&nbsp;
                {transactionHistory?.endDate &&
                  DateTimeFormat.shortDate(transactionHistory?.endDate)}
              </Typography>
            </Box>
          </DetailSections>
        </Box>
        {columnsList.length > 0 && (
          <TabsRow>
            {transaction.transactionType && (
              <TabsPanel
                id="transaction_history_tab"
                value={
                  transaction.transactionType
                    ? columnsList.findIndex(
                        (column) => column === transaction.transactionType
                      )
                    : 0
                }
                handleChange={handleTabChange}
                options={columnsList}
              />
            )}
            <DataGrid
              id="transaction_history_data_grid"
              headerList={headerList}
              dataList={transactions || []}
              noDataMessage={isLoading ? "Fetching Data..." : undefined}
              minHeight="55vh"
              autoHeight={false}
              activeHeaderFields={6}
              checkboxSelection={false}
              disableColumnReorder
              disableColumnResize
            />
            <TotalRow>
              <Typography
                id="trans_detail_modal_title"
                variant="modalSubtitle"
                display="flex"
              >
                Total
              </Typography>
              { !isLoading && <Typography
                id="trans_detail_modal_title"
                variant="modalSubtitle"
                display="flex"
              >
                {
                  amountTotal.metricData ? 
                  NumberFormat(amountTotal.metricData.metricFractionDigit).format(amountTotal.value) + amountTotal.metricData.metricSign:
                  formatter.format(amountTotal.value)
                }
              </Typography> }
            </TotalRow>
          </TabsRow>
        )}
        <FooterBox>
          <SplitButton
            id={"download_trans_history_options"}
            options={defaultColumnActions}
            hidden={false}
            handleOptionClick={handleSplitButtonAction}
            ariaLabelMessage="Download Transaction History"
          />
        </FooterBox>
      </DetailPanel>
    </React.Fragment>
  );
};

export default TransactionHistory;
