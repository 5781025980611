import {
  Box,
  FormControl as MUIFormControl,
  MenuItem as MUIMenuItem,
  Select as MUISelect,
  styled,
  TextField as MUITextfield,
  Typography,
} from '@mui/material';

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const TitleRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gridTemplateColumns: '15% 61% 23%',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
}));

export const SelectBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),

  [`& .MuiOutlinedInput-root`]: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: '10px',
    width: '320px',
  },
  [`& .MuiTextField-root .MuiInputLabel-shrink`]: {
    transform: 'translate(14px, 0px) scale(0.75)',
  },
  [`& .MuiAutocomplete-root .MuiOutlinedInput-root`]: {
    padding: '0',
  },
  [`& .MuiTextField-root .MuiFormLabel-root`]: {
    top: '-7px',
  },
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
}));

export const LineTextField = styled(MUITextfield, {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'isAmountPaid',
})<{
  error?: boolean;
  isAmountPaid?: boolean;
}>(({ theme, error }) => ({
  border: error ? '1px solid red' : '0',
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0',
  },
  '& .MuiInputBase-input.MuiOutlinedInput-input': {
    textIndent: '0px',
    textAlign: 'right',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    paddingLeft: '30px',
  },
  '& .MuiInputAdornment-root': {
    marginRight: '1px',
  },

  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
}));

export const FilterBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{
  error?: boolean;
}>(({ theme, error }) => ({
  width: '100%',
  border: error ? '1px solid red' : '0',
  '.MuiInput-input:focus': {
    background: 'none',
  },
}));

export const FormControlCheckbox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{
  error?: boolean;
}>(({ theme, error }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  marginLeft: 0,
  marginRight: '0',
  width: '100%',
  border: error ? '1px solid red' : '0',
  '.MuiFormControlLabel-label': {
    fontWeight: 'bold',
  },
}));

export const CheckboxWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%',
}));

export const CheckboxContent = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
}));

export const DialogBox = styled(Box)(({ theme }) => ({
  textAlign: 'left',
}));

export const LinkBox = styled(Box)(({ theme }) => ({
  height: '0',
}));
