import { useCallback, useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { PendingItem } from "../../../utils/types/dashboard.type";
import { getDashboard, getPortalLink } from "../../dashboard.service";

interface Props {
  shouldFetch: boolean;
}

export const useDashboardEffect = ({
  shouldFetch = false
}: Props) => {
  const [pendingItems, setPendingItems] = useState<PendingItem[]>([]);
  const [portalLink, setPortalLink] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const {
    state,
    informationAlert,
  } = useContext(AppContext);

  const clientId = state?.loginUser?.clientId;

  const fetchDashboardData = useCallback(async ( currentClientId: string, isCanceled?: () => boolean) => {
    try {
      setLoading(true);
      const [pendingItemsResp, portalLinkResp] = await Promise.all([getDashboard(currentClientId), getPortalLink(currentClientId)]);

      setPendingItems(pendingItemsResp);
      setPortalLink(portalLinkResp);
      if (isCanceled?.()) return;
    } catch (e) {
      informationAlert("Error in getting dashboard data", "error");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffectAsync(async (isCanceled) => {
    if(shouldFetch && clientId) {
      await fetchDashboardData(clientId, isCanceled);
    }
  }, [clientId, shouldFetch]);

  return {
    loading,
    pendingItems,
    portalLink,
  };
};
