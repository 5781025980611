import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { RouteProps } from 'react-router-dom';

import IconLeftArrow from '../../../../assets/images/icons/icon_left_arrow.svg';
import HorizontalBox from '../../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../../components/Buttons/Button/Button';
import { DeleteButton } from '../../../../components/Buttons/DeleteButton/DeleteButton.styles';
import ButtonWithOptions from '../../../../components/ButtonWithOptions/ButtonWithOptions';
import DetailPanel from '../../../../components/DetailPanel/DetailPanel';
import {
  FormBox,
  StyledBackdrop,
} from '../../../../components/DetailPanel/DetailPanel.styles';
import ImgIcon from '../../../../components/ImgIcon/ImgIcon';
import { ActionLink } from '../../../../components/Link/ActionLink/ActionLink';
import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import ProgressPanel from '../../../../components/Progress/ProgressPanel/ProgressPanel';
import FundFilter from '../../../../components/Selectors/FundsFilter';
import PortfolioCompanyFilter from '../../../../components/Selectors/PortfolioCompanyFilter/PortfolioCompanyFilter';
import PurchaseTransactionFilter from '../../../../components/Selectors/PurchaseTransactionFilter/PurchaseTransactionFilter';
import SecurityTypeFilter from '../../../../components/Selectors/SecurityTypeFilter/SecurityTypeFilter';
import SingleSelect from '../../../../components/SingleSelect/SingleSelect';
import DecimalFormatCustom from '../../../../components/TextFieldNumeric/DecimalFormat';
import NumberFormatCustom from '../../../../components/TextFieldNumeric/NumberFormat';
import { DateTimeFormat } from '../../../../utils/helpers/format.helper';
import { DetailsType } from '../../../../utils/types/form.type';
import {
  InvestmentTransactionNewView,
  SelectedInvestmentTransaction,
} from '../../../../utils/types/investmentTransaction.type';
import { JournalEntryDetails } from '../../../arkGL/journalEntries/journalEntryDetails/JournalEntryDetails';
import { DatePicker } from '../../fundDetails/FundDetails.style';
import { PortfolioCompanyDetails } from '../../portfolioCompanies/portfolioCompanyDetails/portfolioCompanyDetails';
import { PortfolioCompanyOptions } from '../../portfolioCompanies/PortfolioCompanyList.constants';
import {
  ERROR_TAKEN_INVESTMENT_TRANSACTION_NAME,
  InvestmentTransactionSubTypes,
  InvestmentTransactionTypes,
  NewInvestmentTransactionOptions,
} from '../InvestmentTransactionList.constants';
import {
  useInvestmentTransactionDetails,
  useTabs,
} from './investmentTransactionDetails.hooks';
import {
  ButtonBox,
  CheckboxFormControl,
  HeaderRow,
  LinkButton,
  Panel,
  TransactionListBox,
  Typography,
} from './investmentTransactionDetails.styles';

interface Props extends RouteProps {
  onDetailClose: Function;
  selectedInvestmentTransaction: SelectedInvestmentTransaction;
  fetchAllInvestmentTransactions: Function;
  setSelectedInvestmentTransaction: Function;
}

export const InvestmentTransactionDetails: React.FC<Props> = ({
  onDetailClose,
  selectedInvestmentTransaction,
  fetchAllInvestmentTransactions,
  setSelectedInvestmentTransaction,
}: Props) => {
  const { type, investmentTransaction, viewPurchaseTransactionId, fromView } =
    selectedInvestmentTransaction;
  const fundId: string = investmentTransaction?.fund?.id || '';

  const isNewInvestmentTransaction = type === DetailsType.New;

  const {
    currentTab,
    detailsRef,
    transactionsRef,
    journalEntriesRef,
    handleTabChange,
  } = useTabs({
    isNew: isNewInvestmentTransaction,
  });

  const {
    loading,
    register,
    handleSubmit,
    setValue,
    followingTransactionList,
    errors,
    control,
    register2,
    handleSubmit2,
    setValue2,
    errors2,
    control2,
    investmentTransactionType,
    initialPurchaseTransaction,
    latestValuationAmount,
    onSubmitFollowingTransaction,
    onSubmitPurchaseTransaction,
    toggleDrawer,
    toBeDeleted,
    handleAddSingleValuation,
    handleAddMultipleValuation,
    handleChangeTransactionType,
    handleViewTransactionDetails,
    handleDelete,
    handleGoBack,
    handleCancelDelete,
    handleConfirmDelete,
    handleInvestmentTransactionNameValidation,
    handleNewInvestmentTransactionButtonAction,
    handleNewPortfolioCompanyButtonAction,
    setSelectedPurchaseTransactionId,
    closeDrawer,
    selectedSecurityType,
    selectedPurchaseTransactionId,
    showExitConfirmation,
    showApplyMultipleConfirmation,
    keepDrawerOpen,
    matchingPurchaseTransactionsText,
    isNewButtonVisible,
    newInvestmentTransactionTypeList,
    transactionNameUnavailableError,
    totalAmount,
    quantity,
    amountPerQuantity,
    totalAmountOther,
    quantityOther,
    amountPerQuantityOther,
    newTotalAmount,
    newSelectedSecurityType,
    onPortfolioCompanyPanelClose,
    refetchPorfolioCompanyFilter,
    selectedPortfolioCompany,
    sendToGL,
    setSendToGL,
    showSaleInfo,
    setShowSaleInfo,
    saleInfo,
    setSaleInfo,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
    journalEntryList,
    handleViewJournalEntryDetails,
    arkGlLocked,
    showAssociatedJENotice,
    setShowAssociatedJENotice,
    associatedJENoticeActionList,
    associatedJENoticeContent,
    isSendToGLDisabled,
    sendToGLTooltip,
  } = useInvestmentTransactionDetails({
    investmentTransaction,
    onClose: onDetailClose,
    fetchAllInvestmentTransactions,
    isNewInvestmentTransaction,
    selectedInvestmentTransaction,
    setSelectedInvestmentTransaction,
    viewPurchaseTransactionId,
    fundId,
    fromView,
  });

  return (
    <>
      <DetailPanel
        id="investment_transaction_details_panel"
        title={
          isNewInvestmentTransaction ? (
            'Add New Investment Transaction'
          ) : investmentTransaction?.name ? (
            investmentTransaction?.name
          ) : (
            <LinkButton
              id="btn_panel_previous_transaction"
              variant="text"
              disableElevation
              onClick={handleGoBack}
              name="Previous Investment Transaction Details"
            >
              <ImgIcon icon={IconLeftArrow} />
              &nbsp;&nbsp;Back to Original Transaction
            </LinkButton>
          )
        }
        open={Boolean(type)}
        onClose={toggleDrawer}
        tabsPanelValue={currentTab}
        hasTabsPanel={true}
        tabsPanelHandleChange={handleTabChange}
        variant={'temporary'}
        tabsPanelOptions={
          isNewInvestmentTransaction
            ? ['Details']
            : ['Details', 'Investment Transactions', 'Journal Entries']
        }
      >
        <>
          {isNewInvestmentTransaction && (
            <FormBox mt={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SingleSelect
                    id="investment_transaction_type"
                    noGutter
                    optionList={newInvestmentTransactionTypeList}
                    idField="id"
                    fullWidth
                    labelField="name"
                    handleOptionSelection={(event: SelectChangeEvent) => {
                      handleChangeTransactionType(event.target.value);
                    }}
                    value={investmentTransactionType ?? ''}
                    label="Investment Transaction Type"
                  />
                </Grid>
              </Grid>
            </FormBox>
          )}
          {/* Following Transaction Form */}
          {investmentTransactionType &&
            investmentTransactionType !== 'PURCHASE' && (
              <form
                autoComplete="off"
                onSubmit={handleSubmit2((data, e) => {
                  const displaySalePrompt =
                    (investmentTransactionType === 'SALE_PARTIAL' ||
                      investmentTransactionType === 'SALE_FULL' ||
                      investmentTransactionType === 'SALE') &&
                    sendToGL;

                  setShowSaleInfo(displaySalePrompt);

                  if (!displaySalePrompt) {
                    onSubmitFollowingTransaction(data, e);
                  } else {
                    setSaleInfo({ data, e });
                  }
                })}
                noValidate
              >
                <ConfirmationDialog
                  open={showSaleInfo}
                  content="The proposed journal entry for this Sale transaction does not include any unrealized gains or losses. Reverse those unrealized gains or losses accordingly to ensure accurate financial reporting."
                  title="Note"
                  id="sale_info_modal_window"
                  actions={[
                    {
                      label: 'Continue',
                      onClick: () => {
                        setShowSaleInfo(false);
                        handleSubmit2(saleInfo?.data, saleInfo?.e);
                      },
                      id: 'btn_close_sale_window',
                      variant: 'contained',
                      color: 'primary',
                    },
                  ]}
                />
                <FormBox>
                  <Panel id="details_panel" ref={detailsRef}>
                    <Grid container spacing={2}>
                      {isNewInvestmentTransaction &&
                        (selectedInvestmentTransaction.newView ===
                          InvestmentTransactionNewView.Purchase ||
                          selectedInvestmentTransaction.newView ===
                            InvestmentTransactionNewView.All) && (
                          <Grid item xs={12}>
                            <Controller
                              name="purchaseTransactionId"
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <>
                                  <PurchaseTransactionFilter
                                    size="small"
                                    idSelectOnly
                                    id={'purchase_transaction_filter'}
                                    value={selectedPurchaseTransactionId ?? ''}
                                    onChange={(purchaseTransaction: any) => {
                                      setSelectedPurchaseTransactionId(
                                        purchaseTransaction
                                      );
                                      onChange(purchaseTransaction);
                                    }}
                                    error={error}
                                    placeholder="Investment Transaction"
                                    selectedInvestmentTransactionType={
                                      investmentTransactionType
                                    }
                                  />
                                </>
                              )}
                              control={control2}
                            />
                          </Grid>
                        )}
                      {initialPurchaseTransaction &&
                        initialPurchaseTransaction.securityType && (
                          <>
                            <Grid item xs={12}>
                              <Typography variant="label">
                                {initialPurchaseTransaction.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="txt_purchase_security_type"
                                size="small"
                                fullWidth
                                disabled
                                value={initialPurchaseTransaction.securityType}
                                aria-describedby="securityType"
                                label="Security Type"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="txt_purchase_investment_security"
                                size="small"
                                fullWidth
                                disabled
                                value={
                                  initialPurchaseTransaction.investmentSecurity ||
                                  ''
                                }
                                aria-describedby="investmentSecurity"
                                label="Investment Security"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="txt_purchase_quantity"
                                size="small"
                                fullWidth
                                disabled
                                value={initialPurchaseTransaction.quantity}
                                aria-describedby="quantity"
                                label="Quantity"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="txt_purchase_total_amount"
                                size="small"
                                fullWidth
                                disabled
                                value={initialPurchaseTransaction.totalAmount}
                                aria-describedby="Total Amount"
                                label="Total Amount"
                                placeholder="Total Amount"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="txt_purchase_amount_per_quantity"
                                size="small"
                                fullWidth
                                disabled
                                value={
                                  initialPurchaseTransaction.amountPerQuantity
                                }
                                aria-describedby="amountPerQuantity"
                                label="Amount Per Quantity (calculated)"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid xs={12} item mt={2}>
                              <Typography variant="h4">Details</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="label">
                                {
                                  investmentTransaction?.investmentTransactionType
                                }
                                {investmentTransaction?.entryType
                                  ? ` ${
                                      InvestmentTransactionSubTypes[
                                        investmentTransaction.entryType as any
                                      ]
                                    }`
                                  : ''}
                                {investmentTransaction?.saleType
                                  ? ` ${
                                      InvestmentTransactionSubTypes[
                                        investmentTransaction.saleType as any
                                      ]
                                    }`
                                  : ''}
                              </Typography>
                            </Grid>
                            <Grid xs={6} item>
                              <Controller
                                name="date"
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <DatePicker
                                      label="Date"
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size="small"
                                          id="new_investment_transaction_date"
                                          error={!!error}
                                          helperText={error?.message ?? ''}
                                        />
                                      )}
                                      onChange={(value) => {
                                        onChange({
                                          target: {
                                            value: value,
                                          },
                                        });
                                      }}
                                      value={
                                        value
                                          ? DateTimeFormat.shortDate(
                                              value as Date
                                            )
                                          : null
                                      }
                                    />
                                  );
                                }}
                                rules={{
                                  required: 'Date is required',
                                }}
                                control={control2}
                                defaultValue={undefined}
                              />
                            </Grid>
                            <Grid xs={6} item></Grid>
                            {investmentTransactionType === 'CONVERSION' && (
                              <>
                                <Grid item xs={6}>
                                  <Controller
                                    name="newSecurityType"
                                    render={({
                                      field: { onChange, value },

                                      fieldState: { error },
                                    }) => (
                                      <SecurityTypeFilter
                                        id="new_security_type_filter"
                                        size="small"
                                        value={value || ''}
                                        onChange={(securityType: any) => {
                                          onChange(securityType);
                                        }}
                                        error={error}
                                        placeholder="Security Type"
                                      />
                                    )}
                                    rules={{
                                      required: 'Security Type is required',
                                    }}
                                    control={control2}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Controller
                                    name="newInvestmentSecurity"
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        id="txt_new_investment_security"
                                        size="small"
                                        fullWidth
                                        value={value ?? ''}
                                        aria-describedby="security"
                                        label="Security"
                                        placeholder="Security"
                                        variant="outlined"
                                        onChange={(e) => {
                                          onChange(e.target.value);
                                        }}
                                        error={!!error}
                                      />
                                    )}
                                    control={control2}
                                  />
                                </Grid>
                              </>
                            )}
                            {investmentTransactionType !==
                              'NET_EFFECT_VALUATION' &&
                              investmentTransactionType !== 'TOTAL_VALUATION' &&
                              investmentTransaction?.investmentTransactionType !==
                                'VALUATION' && (
                                <Grid xs={6} item>
                                  <Controller
                                    name="quantityOther"
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => {
                                      return (
                                        <TextField
                                          id="txt_quantity_other"
                                          label="Quantity"
                                          variant="outlined"
                                          fullWidth
                                          size="small"
                                          disabled={
                                            investmentTransactionType ===
                                              'SALE_FULL' ||
                                            investmentTransactionType ===
                                              'WRITE_OFF' ||
                                            investmentTransaction?.saleType ===
                                              'WRITE_OFF' ||
                                            investmentTransaction?.saleType ===
                                              'FULL'
                                          }
                                          value={value ?? ''}
                                          onChange={(event) => {
                                            const quantityValue = parseFloat(
                                              event.target.value
                                            ) as number;

                                            onChange(event);
                                            if (
                                              quantityValue > 0 &&
                                              totalAmountOther &&
                                              (totalAmountOther as number) > 0
                                            ) {
                                              setValue2(
                                                'amountPerQuantityOther',
                                                (totalAmountOther as number) /
                                                  quantityValue
                                              );
                                            } else if (
                                              quantityValue > 0 &&
                                              !totalAmountOther &&
                                              amountPerQuantityOther &&
                                              (amountPerQuantityOther as number) >
                                                0
                                            ) {
                                              setValue2(
                                                'totalAmountOther',
                                                quantityValue *
                                                  Number(amountPerQuantityOther)
                                              );
                                            }
                                          }}
                                          InputProps={{
                                            inputComponent: DecimalFormatCustom,
                                          }}
                                          InputLabelProps={{
                                            shrink: !!value || value === 0,
                                          }}
                                          error={!!error}
                                          helperText={error?.message ?? ''}
                                        />
                                      );
                                    }}
                                    rules={{
                                      required: 'Quantity is required',
                                    }}
                                    control={control2}
                                  />
                                </Grid>
                              )}
                            {investmentTransactionType === 'CONVERSION' && (
                              <Grid item xs={6}></Grid>
                            )}
                            {investmentTransactionType !== 'SPLIT' && (
                              <Grid xs={6} item>
                                <Controller
                                  name="totalAmountOther"
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => {
                                    return (
                                      <TextField
                                        id="txt_total_amount_other"
                                        label="Amount"
                                        variant="outlined"
                                        type="number"
                                        disabled={
                                          investmentTransaction?.saleType ===
                                            'WRITE_OFF' ||
                                          investmentTransactionType ===
                                            'WRITE_OFF'
                                        }
                                        fullWidth
                                        size="small"
                                        value={value}
                                        onChange={(event) => {
                                          const amountValue = parseFloat(
                                            event.target.value
                                          ) as number;

                                          onChange(event);
                                          if (
                                            quantityOther &&
                                            (quantityOther as number) > 0
                                          ) {
                                            setValue2(
                                              'amountPerQuantityOther',
                                              amountValue /
                                                Number(quantityOther)
                                            );
                                          } else if (
                                            amountPerQuantityOther &&
                                            (amountPerQuantityOther as number) >
                                              0
                                          ) {
                                            setValue2(
                                              'amountPerQuantityOther',
                                              amountValue
                                            );
                                            setValue2('quantityOther', 1);
                                          }
                                          if (
                                            investmentTransaction?.entryType ===
                                              'NET_EFFECT_VALUATION' ||
                                            investmentTransactionType ===
                                              'NET_EFFECT_VALUATION'
                                          ) {
                                            setValue2(
                                              'newTotalAmount',
                                              amountValue +
                                                latestValuationAmount
                                            );
                                            if (
                                              initialPurchaseTransaction.quantity !==
                                              0
                                            ) {
                                              setValue2(
                                                'newAmountPerQuantity',
                                                parseFloat(
                                                  (
                                                    (amountValue +
                                                      latestValuationAmount) /
                                                    Number(quantityOther!)
                                                  ).toFixed(2)
                                                )
                                              );
                                            }
                                          }
                                        }}
                                        InputLabelProps={{
                                          shrink: !!value || value === 0,
                                        }}
                                        error={!!error}
                                        helperText={error?.message ?? ''}
                                      />
                                    );
                                  }}
                                  rules={{
                                    required: 'Amount is required',
                                  }}
                                  control={control2}
                                />
                              </Grid>
                            )}
                            {investmentTransactionType !== 'WRITE_OFF' &&
                              investmentTransaction?.saleType !==
                                'WRITE_OFF' && (
                                <Grid xs={6} item>
                                  <Controller
                                    name="amountPerQuantityOther"
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => {
                                      return (
                                        <TextField
                                          id="txt_amount_per_quantity_other"
                                          label="Amount Per Quantity (calculated)"
                                          variant="outlined"
                                          type="number"
                                          disabled={
                                            investmentTransactionType ===
                                              'SPLIT' ||
                                            initialPurchaseTransaction.quantity ===
                                              0
                                          }
                                          fullWidth
                                          size="small"
                                          value={value ?? ''}
                                          onChange={(event) => {
                                            const amountPerQuantityValue =
                                              parseFloat(
                                                event.target.value
                                              ) as number;

                                            onChange(event);
                                            if (
                                              quantityOther &&
                                              (quantityOther as number) > 0 &&
                                              amountPerQuantityValue
                                            ) {
                                              setValue2(
                                                'totalAmountOther',
                                                amountPerQuantityValue *
                                                  Number(quantityOther)
                                              );
                                            } else if (
                                              amountPerQuantityValue &&
                                              amountPerQuantityValue > 0
                                            ) {
                                              setValue2(
                                                'totalAmountOther',
                                                amountPerQuantityValue
                                              );
                                              setValue2('quantityOther', 1);
                                            }
                                            if (
                                              investmentTransaction?.entryType ===
                                                'NET_EFFECT_VALUATION' ||
                                              investmentTransactionType ===
                                                'NET_EFFECT_VALUATION'
                                            ) {
                                              setValue2(
                                                'newTotalAmount',
                                                amountPerQuantityValue *
                                                  Number(quantityOther!) +
                                                  latestValuationAmount
                                              );
                                              setValue2(
                                                'newAmountPerQuantity',
                                                parseFloat(
                                                  (
                                                    amountPerQuantityValue +
                                                    latestValuationAmount /
                                                      Number(quantityOther!)
                                                  ).toFixed(2)
                                                )
                                              );
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: !!value || value === 0,
                                          }}
                                          error={!!error}
                                          helperText={error?.message ?? ''}
                                        />
                                      );
                                    }}
                                    rules={{
                                      required:
                                        initialPurchaseTransaction.quantity !==
                                          0 &&
                                        'Amount per quantity is required',
                                    }}
                                    control={control2}
                                  />
                                </Grid>
                              )}
                            {investmentTransactionType ===
                              'NET_EFFECT_VALUATION' && (
                              <>
                                <Grid xs={6} item>
                                  <Controller
                                    name="newTotalAmount"
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => {
                                      return (
                                        <TextField
                                          id="txt_new_valuation_amount"
                                          label="New Amount (calculated)"
                                          variant="outlined"
                                          fullWidth
                                          disabled
                                          size="small"
                                          value={value ?? ''}
                                          InputProps={{
                                            inputComponent: NumberFormatCustom,
                                          }}
                                          InputLabelProps={{ shrink: !!value }}
                                          error={!!error}
                                          helperText={error?.message ?? ''}
                                        />
                                      );
                                    }}
                                    control={control2}
                                  />
                                </Grid>
                                <Grid xs={6} item>
                                  <Controller
                                    name="newAmountPerQuantity"
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => {
                                      return (
                                        <TextField
                                          id="txt_new_valuation_amount_per_quantity"
                                          label="New Amount Per Quantity (calculated)"
                                          type="number"
                                          variant="outlined"
                                          fullWidth
                                          disabled={
                                            initialPurchaseTransaction.quantity ===
                                            0
                                          }
                                          size="small"
                                          value={value ?? ''}
                                          InputLabelProps={
                                            isNewInvestmentTransaction
                                              ? { shrink: !!value }
                                              : {}
                                          }
                                          error={!!error}
                                          helperText={error?.message ?? ''}
                                        />
                                      );
                                    }}
                                    control={control2}
                                  />
                                </Grid>
                              </>
                            )}
                            {(investmentTransactionType === 'SALE_FULL' ||
                              investmentTransactionType === 'WRITE_OFF' ||
                              investmentTransaction?.saleType === 'FULL' ||
                              investmentTransaction?.saleType ===
                                'WRITE_OFF') && (
                              <Grid xs={12} item>
                                <Controller
                                  name="removeFromHistoricalSoi"
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => {
                                    return (
                                      <CheckboxFormControl fullWidth>
                                        <FormControlLabel
                                          label="Remove from historical SOI"
                                          control={
                                            <Checkbox
                                              id="chk_remove_from_soi"
                                              checked={value || false}
                                              onChange={onChange}
                                            />
                                          }
                                        ></FormControlLabel>
                                      </CheckboxFormControl>
                                    );
                                  }}
                                  control={control2}
                                />
                              </Grid>
                            )}
                            {newSelectedSecurityType ===
                              'Convertible Notes' && (
                              <>
                                <Grid xs={6} item>
                                  <Controller
                                    name="newNoteAgreement"
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => {
                                      return (
                                        <TextField
                                          id="txt_new_note_agreement"
                                          size="small"
                                          fullWidth
                                          value={value}
                                          onChange={(e) => {
                                            onChange(e.target.value);
                                          }}
                                          aria-describedby="txt_note_agreement"
                                          label="Note Agreement (days)"
                                          placeholder="Note Agreement (days)"
                                          variant="outlined"
                                          error={!!error}
                                          helperText={error?.message ?? ''}
                                        />
                                      );
                                    }}
                                    rules={{
                                      required: 'Note Agreement is required',
                                    }}
                                    control={control2}
                                  />
                                </Grid>
                                <Grid xs={6} item>
                                  <Controller
                                    name="newInterestRate"
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => {
                                      return (
                                        <TextField
                                          id="txt_new_interest_rate"
                                          size="small"
                                          fullWidth
                                          value={value}
                                          onChange={(e) => {
                                            onChange(e.target.value);
                                          }}
                                          aria-describedby="txt_interest_rate"
                                          label="Interest Rate"
                                          placeholder="Interest Rate"
                                          variant="outlined"
                                          error={!!error}
                                          helperText={error?.message ?? ''}
                                        />
                                      );
                                    }}
                                    rules={{
                                      required: 'Interest rate is required',
                                    }}
                                    control={control2}
                                  />
                                </Grid>
                              </>
                            )}
                            {newSelectedSecurityType ===
                              'Partnership Interest' && (
                              <Grid xs={6} item>
                                <Controller
                                  name="newCommitmentAmount"
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => {
                                    return (
                                      <TextField
                                        id="txt_new_commitment_amount"
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e.target.value);
                                        }}
                                        aria-describedby="txt_commitment_amount"
                                        label="Commitment Amount"
                                        placeholder="Commitment Amount"
                                        error={!!error}
                                        helperText={error?.message ?? ''}
                                      />
                                    );
                                  }}
                                  rules={{
                                    required: 'Commitment Amount is required',
                                  }}
                                  control={control2}
                                />
                              </Grid>
                            )}
                          </>
                        )}
                      {investmentTransactionType !== 'SPLIT' ? (
                        <Grid item xs={12}>
                          <Tooltip
                            title={sendToGLTooltip || ''}
                            arrow
                            placement="bottom"
                          >
                            <CheckboxFormControl>
                              <FormControlLabel
                                label="Send to GL"
                                control={
                                  <Checkbox
                                    id="check_send_to_gl"
                                    checked={sendToGL}
                                    value={sendToGL}
                                    onChange={(e) =>
                                      setSendToGL(e.target.checked)
                                    }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                }
                                disabled={arkGlLocked || isSendToGLDisabled}
                              />
                            </CheckboxFormControl>
                          </Tooltip>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Panel>
                </FormBox>
                <HorizontalBox
                  addTopShadow
                  attachToBottom={true}
                  hidden={false}
                  fullWidth
                >
                  <Button
                    id={'investment_transaction_cancel_button'}
                    variant="outlined"
                    onClick={toggleDrawer}
                    text={'Cancel'}
                    color={'secondary'}
                    fullWidth
                    addSpaceBetweenButtons
                  />
                  {sendToGL ? (
                    <Button
                      id={'btn_investment_transaction_save_and_sendtogl'}
                      variant="contained"
                      text={'Save & Send to GL'}
                      color={'primary'}
                      type="submit"
                      fullWidth
                      addSpaceBetweenButtons
                    />
                  ) : (
                    <Button
                      id={'investment_transaction_save_button'}
                      variant="contained"
                      text={'Save & Close'}
                      color={'primary'}
                      type="submit"
                      fullWidth
                      addSpaceBetweenButtons
                    />
                  )}
                </HorizontalBox>
              </form>
            )}
          {
            <form>
              <ConfirmationDialog
                open={showSaleInfo}
                content="The proposed journal entry for this Sale transaction does not include any unrealized gains or losses. Reverse those unrealized gains or losses accordingly to ensure accurate financial reporting."
                title="Note"
                id="window_sale_info"
                actions={[
                  {
                    label: 'Continue',
                    onClick: () => {
                      setShowSaleInfo(false);
                      onSubmitFollowingTransaction(saleInfo?.data, saleInfo?.e);
                    },
                    id: 'btn_close_window_sale_info',
                    variant: 'contained',
                    color: 'primary',
                  },
                ]}
              />
            </form>
          }
          {investmentTransactionType === 'PURCHASE' && (
            <form
              autoComplete="off"
              onSubmit={handleSubmit((data, e) =>
                onSubmitPurchaseTransaction(data, e)
              )}
              noValidate
            >
              <FormBox>
                <Panel id="details_panel" ref={detailsRef}>
                  <HeaderRow>
                    <Typography variant="h4">Details</Typography>
                    <ButtonBox>
                      <ButtonWithOptions
                        buttonID="add_portfolio_company_button"
                        popoverID="add_portfolio_company_popover"
                        onClick={handleNewPortfolioCompanyButtonAction}
                        buttonLabel="Add New Portfolio Company"
                        buttonIcon={<AddIcon />}
                        options={PortfolioCompanyOptions}
                      />
                    </ButtonBox>
                  </HeaderRow>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="investment_transaction_name"
                        size="small"
                        fullWidth
                        aria-describedby="name"
                        label="Investment Transaction Name"
                        placeholder="Investment Transaction Name"
                        variant="outlined"
                        error={!!errors.name || transactionNameUnavailableError}
                        {...register('name', {
                          required: true,
                          validate: (value: any) => !!value?.trim(),
                          onBlur: (e) =>
                            handleInvestmentTransactionNameValidation(
                              e.target.value
                            ),
                        })}
                        helperText={
                          (errors.name &&
                            errors.name.type === 'required' &&
                            'Transaction Name is required') ||
                          (transactionNameUnavailableError &&
                            ERROR_TAKEN_INVESTMENT_TRANSACTION_NAME)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="fund"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FundFilter
                            size="small"
                            id="transaction_fund_filter"
                            value={value}
                            onChange={(fund) => {
                              onChange({
                                id: fund?.id,
                                name: fund?.name,
                              });
                            }}
                            error={error}
                            placeholder="Fund Name"
                          />
                        )}
                        rules={{
                          required: 'Fund is required',
                        }}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="portfolioCompany"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <PortfolioCompanyFilter
                              size="small"
                              id={'portfolio_company_filter'}
                              value={value}
                              onChange={(portfolioCompany: any) => {
                                onChange({
                                  id: portfolioCompany?.id,
                                  name: portfolioCompany?.name,
                                });
                              }}
                              error={error}
                              placeholder="Portfolio Company Name"
                              refetch={refetchPorfolioCompanyFilter}
                            />
                          </>
                        )}
                        rules={{
                          required: 'Portfolio Company is required',
                        }}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="securityType"
                        defaultValue=""
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <SecurityTypeFilter
                            size="small"
                            id={'security_type_filter'}
                            value={value}
                            onChange={(securityType: any) => {
                              onChange(securityType);
                            }}
                            error={error}
                            placeholder="Security Type"
                          />
                        )}
                        rules={{
                          required: 'Security Type is required',
                        }}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="investmentSecurity"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            id="txt_investment_security"
                            size="small"
                            fullWidth
                            value={value ?? ''}
                            aria-describedby="security"
                            label="Security"
                            placeholder="Security"
                            variant="outlined"
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            error={!!error}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <Controller
                        name="quantity"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <TextField
                              id="txt_quantity"
                              label="Quantity"
                              variant="outlined"
                              fullWidth
                              size="small"
                              value={value ?? ''}
                              onChange={(event) => {
                                const quantityValue = parseFloat(
                                  event.target.value
                                ) as number;

                                onChange(event);
                                if (
                                  quantityValue > 0 &&
                                  totalAmount &&
                                  totalAmount > 0
                                ) {
                                  setValue(
                                    'amountPerQuantity',
                                    (totalAmount as number) / quantityValue
                                  );
                                } else if (
                                  quantityValue > 0 &&
                                  !totalAmount &&
                                  amountPerQuantity &&
                                  amountPerQuantity > 0
                                ) {
                                  setValue(
                                    'totalAmount',
                                    quantityValue * amountPerQuantity
                                  );
                                } else if (quantityValue === 0) {
                                  setValue('amountPerQuantity', null);
                                }
                              }}
                              InputProps={{
                                inputComponent: DecimalFormatCustom,
                              }}
                              InputLabelProps={
                                !isNewInvestmentTransaction
                                  ? {
                                      shrink: !!value || value === 0,
                                    }
                                  : {}
                              }
                              error={!!error}
                              helperText={error?.message ?? ''}
                            />
                          );
                        }}
                        rules={{
                          required: 'Quantity is required',
                        }}
                        control={control}
                      />
                    </Grid>
                    <Grid xs={6} item />
                    <Grid xs={6} item>
                      <Controller
                        name="totalAmount"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <TextField
                              id="txt_investment_transaction_amount"
                              label="Amount"
                              type="number"
                              variant="outlined"
                              fullWidth
                              size="small"
                              value={value ?? ''}
                              onChange={(event) => {
                                const amountValue = parseFloat(
                                  event.target.value
                                ) as number;

                                onChange(event);
                                if (quantity && (quantity as number) > 0) {
                                  setValue(
                                    'amountPerQuantity',
                                    amountValue / Number(quantity)
                                  );
                                } else if (
                                  amountPerQuantity &&
                                  amountPerQuantity > 0
                                ) {
                                  setValue('amountPerQuantity', amountValue);
                                  setValue('quantity', 1);
                                }
                              }}
                              InputLabelProps={
                                !isNewInvestmentTransaction
                                  ? {
                                      shrink: !!value || value === 0,
                                    }
                                  : {}
                              }
                              error={!!error}
                              helperText={error?.message ?? ''}
                            />
                          );
                        }}
                        rules={{
                          required: 'Date is required',
                        }}
                        control={control}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <Controller
                        name="amountPerQuantity"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <TextField
                              id="txt_amount_per_quantity"
                              label="Amount Per Quantity"
                              variant="outlined"
                              type="number"
                              disabled={quantity === '0' || quantity === 0}
                              fullWidth
                              size="small"
                              value={value ?? ''}
                              onChange={(event) => {
                                const amountPerQuantity = parseFloat(
                                  event.target.value
                                ) as number;

                                onChange(event);
                                if (
                                  quantity &&
                                  (quantity as number) > 0 &&
                                  amountPerQuantity
                                ) {
                                  setValue(
                                    'totalAmount',
                                    amountPerQuantity * (quantity as number)
                                  );
                                } else if (
                                  amountPerQuantity &&
                                  amountPerQuantity > 0
                                ) {
                                  setValue('totalAmount', amountPerQuantity);
                                  setValue('quantity', 1);
                                }
                              }}
                              InputLabelProps={
                                !isNewInvestmentTransaction
                                  ? {
                                      shrink: !!value || value === 0,
                                    }
                                  : {}
                              }
                              error={!!error}
                              helperText={error?.message ?? ''}
                            />
                          );
                        }}
                        rules={{
                          required:
                            quantity !== '0' &&
                            'Amount per quantity is required',
                        }}
                        control={control}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <Controller
                        name="date"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <DatePicker
                              label="Date"
                              renderInput={(params) => (
                                <TextField
                                  id="investment_transaction_date"
                                  {...params}
                                  size="small"
                                  error={!!error}
                                  helperText={error?.message ?? ''}
                                />
                              )}
                              onChange={(value) => {
                                onChange({
                                  target: {
                                    value: value,
                                  },
                                });
                              }}
                              value={
                                value
                                  ? DateTimeFormat.shortDate(value as Date)
                                  : null
                              }
                            />
                          );
                        }}
                        rules={{
                          required: 'Date is required',
                        }}
                        control={control}
                        defaultValue={undefined}
                      />
                    </Grid>
                    <Grid xs={6} item />
                    {selectedSecurityType === 'Convertible Notes' && (
                      <>
                        <Grid xs={6} item>
                          <Controller
                            name="noteAgreement"
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => {
                              return (
                                <TextField
                                  id="txt_note_agreement"
                                  size="small"
                                  fullWidth
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e.target.value);
                                  }}
                                  aria-describedby="txt_note_agreement"
                                  label="Note Agreement (days)"
                                  placeholder="Note Agreement (days)"
                                  variant="outlined"
                                  error={!!error}
                                  helperText={error?.message ?? ''}
                                />
                              );
                            }}
                            rules={{
                              required: 'Note Agreement is required',
                            }}
                            control={control}
                          />
                        </Grid>
                        <Grid xs={6} item>
                          <Controller
                            name="interestRate"
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => {
                              return (
                                <TextField
                                  id="txt_interest_rate"
                                  size="small"
                                  fullWidth
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e.target.value);
                                  }}
                                  aria-describedby="txt_interest_rate"
                                  label="Interest Rate"
                                  placeholder="Interest Rate"
                                  variant="outlined"
                                  error={!!error}
                                  helperText={error?.message ?? ''}
                                />
                              );
                            }}
                            rules={{
                              required: 'Interest rate is required',
                            }}
                            control={control}
                          />
                        </Grid>
                      </>
                    )}
                    {selectedSecurityType === 'Partnership Interest' && (
                      <Grid xs={6} item>
                        <Controller
                          name="commitmentAmount"
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => {
                            return (
                              <TextField
                                id="txt_commitment_amount"
                                size="small"
                                fullWidth
                                variant="outlined"
                                value={value}
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                aria-describedby="txt_commitment_amount"
                                label="Commitment Amount"
                                placeholder="Commitment Amount"
                                error={!!error}
                                helperText={error?.message ?? ''}
                              />
                            );
                          }}
                          rules={{
                            required: 'Commitment Amount is required',
                          }}
                          control={control}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <CheckboxFormControl>
                        <Tooltip
                          title={sendToGLTooltip || ''}
                          placement="bottom"
                        >
                          <FormControlLabel
                            label="Send to GL"
                            control={
                              <Checkbox
                                id="check_send_to_gl"
                                checked={sendToGL}
                                value={sendToGL}
                                onChange={(e) => setSendToGL(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            disabled={arkGlLocked || isSendToGLDisabled}
                          />
                        </Tooltip>
                      </CheckboxFormControl>
                    </Grid>
                  </Grid>
                </Panel>
              </FormBox>
              <HorizontalBox
                addTopShadow
                attachToBottom={true}
                hidden={false}
                fullWidth
              >
                <Button
                  id={'investment_transaction_cancel_button'}
                  variant="outlined"
                  onClick={toggleDrawer}
                  text={'Cancel'}
                  color={'secondary'}
                  fullWidth
                  addSpaceBetweenButtons
                />
                {sendToGL ? (
                  <Button
                    id={'btn_investment_transaction_save_and_sendtogl'}
                    variant="contained"
                    text={'Save & Send to GL'}
                    color={'primary'}
                    type="submit"
                    fullWidth
                    addSpaceBetweenButtons
                  />
                ) : (
                  <Button
                    id={'investment_transaction_save_button'}
                    variant="contained"
                    text={'Save & Close'}
                    color={'primary'}
                    type="submit"
                    fullWidth
                    addSpaceBetweenButtons
                  />
                )}
              </HorizontalBox>
            </form>
          )}
          <FormBox>
            {!isNewInvestmentTransaction && (
              <>
                <Panel id="details_panel" ref={transactionsRef}>
                  <HeaderRow>
                    <Typography variant="h4">
                      Investment Transactions
                    </Typography>
                    {isNewButtonVisible && (
                      <ButtonBox>
                        <ButtonWithOptions
                          buttonID="btn_add_investment_transaction"
                          popoverID="popover_add_investment_transaction"
                          onClick={handleNewInvestmentTransactionButtonAction}
                          buttonLabel="Add New Investment Transaction"
                          buttonIcon={<AddIcon />}
                          options={NewInvestmentTransactionOptions}
                        />
                      </ButtonBox>
                    )}
                  </HeaderRow>
                  <Grid container spacing={2}>
                    <Grid xs={12} item>
                      {followingTransactionList &&
                        followingTransactionList.length > 0 &&
                        followingTransactionList.map((transaction) => {
                          return (
                            <TransactionListBox key={transaction.id}>
                              <ActionLink
                                id={`link_${transaction.id}`}
                                onClick={() =>
                                  handleViewTransactionDetails(transaction)
                                }
                              >
                                {
                                  InvestmentTransactionTypes[
                                    transaction.investmentTransactionType
                                  ]
                                }
                                {transaction.entryType
                                  ? ` - ${
                                      InvestmentTransactionSubTypes[
                                        transaction.entryType
                                      ]
                                    }`
                                  : ''}
                                {transaction.saleType
                                  ? ` - ${
                                      InvestmentTransactionSubTypes[
                                        transaction.saleType
                                      ]
                                    }`
                                  : ''}
                              </ActionLink>
                              <Typography>
                                {transaction.date
                                  ? DateTimeFormat.shortDate(transaction.date)
                                  : ''}
                              </Typography>
                            </TransactionListBox>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Panel>
              </>
            )}
          </FormBox>
          <FormBox>
            {!isNewInvestmentTransaction && (
              <>
                <Panel id="details_panel" ref={journalEntriesRef}>
                  <HeaderRow>
                    <Typography variant="h4">Journal Entries</Typography>
                  </HeaderRow>
                  <Grid container spacing={2}>
                    <Grid xs={12} item>
                      {journalEntryList &&
                        journalEntryList.length > 0 &&
                        journalEntryList.map((journalEntry) => {
                          return (
                            <TransactionListBox key={journalEntry.id}>
                              <ActionLink
                                id={`link_${journalEntry.id}`}
                                onClick={() =>
                                  handleViewJournalEntryDetails(
                                    journalEntry.journalEntryId
                                  )
                                }
                              >
                                #{journalEntry.number} -{' '}
                                {journalEntry.ledgerName}
                              </ActionLink>
                              <Typography>
                                {journalEntry.date
                                  ? DateTimeFormat.shortDate(journalEntry.date)
                                  : ''}
                              </Typography>
                            </TransactionListBox>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Panel>
                <DeleteButton
                  id="btn_delete_transaction"
                  variant="text"
                  disableElevation
                  startIcon={<DeleteOutlineOutlinedIcon />}
                  onClick={handleDelete}
                  name="Delete Investment Transaction"
                >
                  Delete Transaction
                </DeleteButton>
              </>
            )}
          </FormBox>
        </>
        <StyledBackdrop open={Boolean(loading)} />
        <ProgressPanel
          id={'progress_investment_transaction_panel'}
          showProgress={Boolean(loading)}
          text={`${loading} Investment Transaction...`}
        />
      </DetailPanel>
      <ConfirmationDialog
        open={!!toBeDeleted}
        onClose={handleCancelDelete}
        id="delete_inv_transaction_confirmation"
        actions={[
          {
            label: 'Delete Permanently',
            onClick: () => handleConfirmDelete(toBeDeleted as string),
            id: 'btn_confirm_delete_transaction',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Cancel',
            onClick: handleCancelDelete,
            id: 'btn_cancel_delete',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content="Are you sure you want to delete this investment transaction? This action cannot be undone."
        title="Delete investment transaction?"
      />
      <ConfirmationDialog
        open={showApplyMultipleConfirmation}
        onClose={handleAddSingleValuation}
        id="apply_multiple_transaction_confirmation"
        whitespace={true}
        actions={[
          {
            label:
              'Apply valuation to all purchases (with matching Portfolio Company/Security)',
            onClick: handleAddMultipleValuation,
            id: 'btn_apply_valuation_multiple',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Apply to selected purchase only',
            onClick: handleAddSingleValuation,
            id: 'btn_apply_valuation_single',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content={`Applying the cost per share value to all will create a valuation transaction for any investment purchase with a matching Portfolio Company and Security. ${'\n'} Matching purchase transactions: ${'\n'} ${matchingPurchaseTransactionsText}`}
        title="Apply Valuation to all?"
      />

      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_inv_transaction_confirmation"
        actions={[
          {
            label: 'Keep Editing',
            onClick: keepDrawerOpen,
            id: 'btn_save_investment_transaction',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Discard Changes',
            onClick: closeDrawer,
            id: 'btn_cancel_save_investment_transaction',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content="Unsaved changes will be lost"
        title="You have unsaved changes"
      />
      <ConfirmationDialog
        open={showAssociatedJENotice}
        id="create_je_confirmation"
        onClose={() => {
          setShowAssociatedJENotice(false);
          closeDrawer();
        }}
        actions={associatedJENoticeActionList}
        content={associatedJENoticeContent}
        title="Notice"
        showXbutton={true}
      />
      {!!selectedPortfolioCompany?.type && (
        <PortfolioCompanyDetails
          selectedPortfolioCompany={selectedPortfolioCompany}
          onDetailClose={onPortfolioCompanyPanelClose}
          fetchAllPortfolioCompanies={() => {}}
        />
      )}
      {!!selectedJournalEntry?.type && (
        <JournalEntryDetails
          selectedJournalEntry={selectedJournalEntry}
          setSelectedJournalEntry={setSelectedJournalEntry}
          onDetailClose={onJournalEntryPanelClose}
          fetchAllJournalEntries={() => {}}
          fundId={selectedJournalEntry.journalEntry?.fundId}
          unsavedChangesContent={
            'You have unsaved Journal Entries associated with your Portfolio Investments\' "Send to GL" selection. These entries will be lost if you navigate away. Do you want to continue?'
          }
          unsavedChangesTitle={'Unsaved Journal Entries'}
        />
      )}
    </>
  );
};
