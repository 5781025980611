import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Button,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { ReactElement, useContext, useEffect, useState } from 'react';

import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import { AppContext } from '../../../../core/context/appContextProvider';
import { getCapitalCallTransactions } from '../../../../services/capitalCalls.service';
import { GET_CAPITAL_CALL_TRANSACTIONS_ERROR } from '../../../../services/hooks/useCapitalCallsEffect/useCapitalCall.constants';
import { M_DASH_UNICODE } from '../../../../utils/constants/constants';
import {
  CurrencyFormat,
  DateTimeFormat,
  FindLengthOfFraction,
} from '../../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../../utils/hooks/useEffectAsync.hook';
import {
  AccountMatchedBankTransaction,
  NameIdPair,
  nameIdTypePair,
} from '../../../../utils/types/bank.type';
import { CapitalCalls } from '../../../../utils/types/capitalCalls.type';
import { CashReceiptTransaction } from '../../../../utils/types/cashReceipt.type';
import {
  BankStatusOptions,
  BankTransactionTypeOptions,
} from '../../bankFeeds/BankFeedList.defaults';
import {
  BlueAddCircleOutlineOutlinedIcon,
  CellBox,
  ProgressBar,
  TwoStepSelectButtonBox,
} from '../../bankFeeds/BankFeedList.styles';
import { EditableCapCallChip, ReadOnlyCapCallChip } from './DataGridChips';

type twoStepSelectType = 'CAP_CALL' | 'TRANSACTION';

type Props = {
  isAdmin: boolean;
  row: AccountMatchedBankTransaction;
  capitalCallList: any[];
  memoEntityList: nameIdTypePair[];
  updateBankFeedsDataList: (updatedRow: AccountMatchedBankTransaction) => void;
};

export const CapitalCallCellRenderer: React.FC<Props> = ({
  isAdmin,
  row,
  capitalCallList = [],
  memoEntityList = [],
  updateBankFeedsDataList,
}: Props): ReactElement => {
  const { informationAlert } = useContext(AppContext);

  const isReadOnly =
    !isAdmin ||
    row.status === BankStatusOptions.Categorized ||
    row.type !== BankTransactionTypeOptions.Inflow;

  const [filteredCapCalls, setFilteredCapCalls] = useState<CapitalCalls[]>();
  const [isHovered, setIsHovered] = useState(false);
  const [step, setStep] = useState<twoStepSelectType>('CAP_CALL');
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [selectedCapitalCall, setSelectedCapitalCall] = useState<NameIdPair>();
  const [transactionList, setTransactionList] = useState<
    CashReceiptTransaction[]
  >([]);
  const [selectedCapCallTransaction, setSelectedCapCallTransaction] =
    useState<CashReceiptTransaction>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const filteredCapCalls = capitalCallList.filter(
      (capCall) => capCall.fundId === row.fundId
    );

    setFilteredCapCalls(filteredCapCalls);
  }, [capitalCallList, row]);

  const fetchCapitalCallTransactions = async (isCanceled?: () => boolean) => {
    if (selectedCapitalCall) {
      try {
        setIsLoading(true);

        const response = await getCapitalCallTransactions(
          selectedCapitalCall.id!!
        );

        if (isCanceled?.()) return;

        setTransactionList(
          response.sort((a, b) => a.investor.localeCompare(b.investor))
        );
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        informationAlert(GET_CAPITAL_CALL_TRANSACTIONS_ERROR, 'error');
      }
    }
  };

  useEffectAsync(
    async (isCanceled) => {
      if (selectedCapitalCall) {
        try {
          await fetchCapitalCallTransactions(isCanceled);

          setStep('TRANSACTION');
        } catch (e) {
          informationAlert('Error getting capital call transactions', 'error');
        }
      }
    },
    [selectedCapitalCall]
  );

  const handleStartEditing = () => {
    setIsEditing(true);
    setOpenDropdown(true);
  };

  const handleCapitalCallSelection = (capCall: NameIdPair) => {
    if (selectedCapitalCall?.id === capCall.id) {
      setStep('TRANSACTION');
    } else {
      setSelectedCapitalCall(capCall);
    }
  };

  const handleBackClick = () => {
    setStep('CAP_CALL');
  };

  const handleTransactionSelection = (
    selectedCapCallTrans: CashReceiptTransaction
  ) => {
    setOpenDropdown(false);
    setIsEditing(false);
    if (
      row.journalEntry[0].entityIds[0] &&
      row.journalEntry[0].entityIds[0] !== selectedCapCallTrans.investorId
    ) {
      setSelectedCapCallTransaction(selectedCapCallTrans);
    } else {
      assignCapCallTransaction(
        selectedCapCallTrans.id,
        selectedCapCallTrans.investorId
      );
    }
  };

  const assignCapCallTransaction = (
    transactionId: string | null,
    investorId?: string
  ) => {
    let updatedRow = {
      ...row,
      receivableTransactionId: transactionId,
      isEditedRow: {
        ...row.isEditedRow,
        isCapCallEdited:
          transactionId === row.isEditedRow.oldValues.receivableTransactionId
            ? false
            : true,
      },
    };

    if (investorId && !row.isNonGlBankAccount) {
      const investorEntity = memoEntityList.find(
        (entity) => entity.id === investorId
      );

      if (investorEntity) {
        updatedRow = {
          ...updatedRow,
          memoEntityId: investorId,
          memoEntityName: investorEntity.name,
          journalEntry: row.journalEntry.map((lineItem) => {
            return {
              ...lineItem,
              entityIds: [investorId],
            };
          }),
          isEditedRow: {
            ...updatedRow.isEditedRow,
            isEntityEdited:
              investorId === row.isEditedRow.oldValues.memoEntityId
                ? false
                : true,
            isEntityRequired:
              row.isEntityRequiredByBankAcc || row.isEditedRow.isEntityRequired,
          },
        };
      }
    }

    updateBankFeedsDataList(updatedRow);
  };

  const handleContinue = () => {
    assignCapCallTransaction(
      selectedCapCallTransaction?.id!,
      selectedCapCallTransaction?.investorId
    );
    setSelectedCapCallTransaction(undefined);
  };

  const handleClose = () => {
    setSelectedCapCallTransaction(undefined);
    setOpenDropdown(false);
    setIsEditing(false);
    setIsHovered(false);
  };

  const handleDelete = () => {
    assignCapCallTransaction(null);
  };

  return (
    <>
      {isReadOnly ? (
        <ReadOnlyCapCallChip value={row.receivableTransactionId} />
      ) : isEditing ? (
        <FormControl size="small" fullWidth onClick={() => {}}>
          <>
            <InputLabel id={`cap_call_input_label`} shrink={false}>
              Capital Call
            </InputLabel>
            <Select
              id={`cap_call_select`}
              fullWidth
              open={openDropdown}
              onOpen={() => setOpenDropdown(true)}
              onClose={() => {}}
              MenuProps={{
                onClose: handleClose,
              }}
            >
              <div>
                {step === 'CAP_CALL' && (
                  <>
                    <Divider>Select Capital Call</Divider>
                    {!filteredCapCalls?.length && (
                      <MenuItem
                        key={'no_cap_calls'}
                        disabled={true}
                        value={'no_cap_calls'}
                      >
                        No Available Capital Calls
                      </MenuItem>
                    )}
                    {filteredCapCalls?.map((option: any, key) => {
                      return (
                        <MenuItem
                          selected={
                            selectedCapitalCall?.id === option.id ? true : false
                          }
                          onClick={() => handleCapitalCallSelection(option)}
                          key={key}
                          value={option.id}
                        >
                          {isLoading &&
                            selectedCapitalCall?.id === option.id && (
                              <ProgressBar />
                            )}
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </>
                )}
                {step === 'TRANSACTION' && (
                  <>
                    <TwoStepSelectButtonBox>
                      <Button
                        id="btn_back_to_cap_calls"
                        variant="text"
                        onClick={handleBackClick}
                        color={'primary'}
                      >
                        <ChevronLeftIcon />
                        Cap Calls
                      </Button>
                    </TwoStepSelectButtonBox>

                    <Divider>Select Transaction</Divider>

                    {transactionList.map((option, key) => {
                      return (
                        <MenuItem
                          selected={
                            row.receivableTransactionId === option.id
                              ? true
                              : false
                          }
                          onClick={() => handleTransactionSelection(option)}
                          key={key}
                          value={option.id}
                          disabled={option.adjustments.length === 2}
                        >
                          {`${
                            option.investor
                          } - ${DateTimeFormat.shortDateString(
                            option.date as string
                          )} - ${CurrencyFormat(
                            row.currency,
                            FindLengthOfFraction(row.currency)
                          ).format(option.amount)}`}
                        </MenuItem>
                      );
                    })}
                  </>
                )}
              </div>
            </Select>
          </>
        </FormControl>
      ) : (
        <CellBox
          onMouseEnter={() => setIsHovered(true)}
          onMouseMove={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {row.receivableTransactionId ? (
            <EditableCapCallChip
              onChipClick={handleStartEditing}
              onDelete={handleDelete}
            />
          ) : isHovered ? (
            <Chip
              label={
                filteredCapCalls?.length ? 'Add Capital Call' : 'None Available'
              }
              icon={<BlueAddCircleOutlineOutlinedIcon />}
              onClick={() => handleStartEditing()}
              disabled={!filteredCapCalls?.length}
            />
          ) : (
            M_DASH_UNICODE
          )}
          {isLoading && <ProgressBar />}
        </CellBox>
      )}
      <ConfirmationDialog
        id="override_existing_memo_entity_prompt"
        open={!!selectedCapCallTransaction}
        title={'Current Memo Entity will be overridden'}
        content={
          'Adding a capital call to this bank transaction will override previously set memo tags. To apply additional memo tags, submit this bank transaction to the GL.'
        }
        actions={[
          {
            label: 'Cancel',
            onClick: handleClose,
            id: 'btn_cancel',
            variant: 'outlined',
            color: 'error',
          },
          {
            label: 'Continue',
            onClick: handleContinue,
            id: 'btn_continue',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        actionDisplayType="inline"
      />
    </>
  );
};
