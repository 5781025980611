import { Box, Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

import HorizontalBox from "../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../components/Buttons/Button/Button";
import { MultiSelect } from "../../../components/MultiSelect/MultiSelect";
import Progress from "../../../components/Progress/ProgressModal/ProgressModal";
import Switch from "../../../components/Switch/Switch";
import TitleBox from "../../../components/TitleBox/TitleBox";
import { SwitchLabel } from "../../clients/Clients.styles";
import { FormBox, MarginFixContainer } from "../Clients.styles";
import { useFeature } from "./useFeature.hooks";

const FeatureConfig: React.FC = () => {
  const {
    control,
    handleSubmit,
    onSubmit,
    isDirty,
    setValue,
    loading,
    onCancel,
    sftpVendorList,
    setSftpVendorList,
    sftpLocked,
  } = useFeature();

  return (
    <Box>
      <Progress id="feature_config_progress" showProgress={loading} />
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormBox>
          <TitleBox title="Feature Configuration">
            <Grid container xs={12} spacing={2}>
              <Grid xs={12} item key="soiLocked">
                <Controller
                  name="soiLocked"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <SwitchLabel
                        control={
                          <Switch
                            label="Schedule of Investments"
                            id={`schedule_of_investments`}
                            checked={!value}
                            onChange={(e: { target: { checked: any } }) => {
                              onChange(!e.target.checked);
                            }}
                          />
                        }
                        label="Schedule of Investments"
                      />
                    );
                  }}
                  control={control}
                />
              </Grid>
              <Grid xs={12} item key="allocationLocked">
                <Controller
                  name="allocationLocked"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <SwitchLabel
                        control={
                          <Switch
                            label="Allocations"
                            id={`allocations`}
                            checked={!value}
                            onChange={(e: { target: { checked: any } }) => {
                              onChange(!e.target.checked);
                            }}
                          />
                        }
                        label="Allocations"
                      />
                    );
                  }}
                  control={control}
                />
              </Grid>
              <Grid xs={12} item key="glLocked">
                <Controller
                  name="glLocked"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <SwitchLabel
                        control={
                          <Switch
                            label="General Ledger"
                            id={`general_ledger`}
                            checked={!value}
                            onChange={(e: { target: { checked: any } }) => {
                              onChange(!e.target.checked);
                            }}
                          />
                        }
                        label="General Ledger"
                      />
                    );
                  }}
                  control={control}
                />
              </Grid>
              <Grid xs={12} item key="arkGlLocked">
                <Controller
                  name="arkGlLocked"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <SwitchLabel
                        control={
                          <Switch
                            label="arkGl"
                            id={`ark_gl`}
                            checked={!value}
                            onChange={(e: { target: { checked: any } }) => {
                              onChange(!e.target.checked);
                            }}
                          />
                        }
                        label="arkGl"
                      />
                    );
                  }}
                  control={control}
                />
              </Grid>
              <Grid xs={12} item key="sftpLocked">
                <Controller
                  name="sftpLocked"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <SwitchLabel
                        control={
                          <Switch
                            label="SFTP"
                            id={`sftp`}
                            checked={!value}
                            onChange={(e: { target: { checked: any } }) => {
                              onChange(!e.target.checked);
                              setValue("clientSftpVendors", []);
                              const vendors = sftpVendorList.map((vendor) => {
                                return {
                                  ...vendor,
                                  selected: false,
                                };
                              });

                              setSftpVendorList(vendors);
                            }}
                          />
                        }
                        label="SFTP"
                      />
                    );
                  }}
                  control={control}
                />
              </Grid>
              {sftpLocked === false && (
                <Grid xs={12} item>
                  <Controller
                    name="clientSftpVendors"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <MultiSelect
                          id="select_sftp_vendors"
                          disabled={loading}
                          label="SFTP Vendor"
                          fullWidth={true}
                          items={sftpVendorList}
                          noGutter={true}
                          onChange={(items) => {
                            const vendorNames: string[] = [];

                            items.map(
                              (v) => v.selected && vendorNames.push(v.name)
                            );

                            onChange(vendorNames);
                          }}
                          error={!!error}
                          helperText={error?.message ?? ""}
                        />
                      );
                    }}
                    control={control}
                  />
                </Grid>
              )}
              
              <Grid xs={12} item key="bankLocked">
                  <Controller
                    name="bankLocked"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <SwitchLabel
                          control={
                            <Switch
                              label="Banks"
                              id={`ark_banks`}
                              checked={!value}
                              onChange={(e: { target: { checked: any } }) => {
                                onChange(!e.target.checked);
                              }}
                            />
                          }
                          label="Banks"
                        />
                      );
                    }}
                    control={control}
                  />
              </Grid>
            </Grid>
          </TitleBox>
        </FormBox>
        <MarginFixContainer>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
            sx={{
              px: "30%",
            }}
          >
            <Button
              id="cancel_feature_config"
              variant="outlined"
              onClick={onCancel}
              text={"Cancel"}
              color={"secondary"}
              disabled={!isDirty}
              fullWidth
              addSpaceBetweenButtons
            />
            <Button
              id="save_feature_config"
              variant="contained"
              text={"Save"}
              color={"primary"}
              disabled={!isDirty}
              type="submit"
              fullWidth
              addSpaceBetweenButtons
            />
          </HorizontalBox>
        </MarginFixContainer>
      </form>
    </Box>
  );
};

export default React.memo(FeatureConfig);
