import { Box, styled, Typography } from "@mui/material";

import DataGrid from "../../../components/DataGrid/DataGrid";

interface FundWrapper {
  wrapperHeight: number | undefined;
}

export const StyledDataGrid = styled(DataGrid)`
  margin: 20px 0 50px 0;

  & .MuiDataGrid-row[data-id="total"] {
    font-weight: bold;
    .MuiDataGrid-cellCheckbox {
      opacity: 0;
    }
  }
`;

export const FundWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "wrapperHeight",
})<FundWrapper>(({ theme, wrapperHeight = "auto" }) => ({
  overflowY: "auto",
  marginTop: theme.spacing(-2),
  marginRight: theme.spacing(-2),
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
  height: wrapperHeight,
}));

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "15% 61% 23%",
  gap: theme.spacing(1),
  justifyContent: "center",
  alignItems: "center",
  mb: theme.spacing(2),
}));

export const ButtonBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: 16,
}));

export const IconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));
