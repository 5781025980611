import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";

import { CurrencyFormat, DateTimeFormat } from "../../../../../utils/helpers/format.helper";
import { DataGridColDef } from "../../../../../utils/types/listItems";
import { TransactionDetails } from "../changesInPartnersCapital.hooks";

type GridItem = {
    id: string,
    fund: string,
    investor: string,
    investorType: string,
    transactionDate: Date | null,
    transactionType: string,
    amount: number,
};

const investorTypes: any = {
    lp: 'LP',
    slp: 'SLP',
    gp: 'GP',
    ilp: 'ILP',
    elp: 'ELP',
    hlp: 'HLP',
    member: 'MEMBER',
    managingMember: 'ManagingMember'
};


export const useChangesInPartnersCapitalDetails = (
    cellInfo: any,
    transactionDetails: TransactionDetails[],
    apiResponse: any,
    roundedReportActivated: boolean,
    currencyCode: string,
    params: any
) => {

    const [gridData, setGridData] = useState<GridItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if(cellInfo && transactionDetails){
            let updatedGridData: GridItem[] = [];

            const balanceLine: GridItem = {
                id: 'balance_line_id',
                fund: 'Balance',
                investor: '',
                investorType: '',
                transactionDate: null,
                transactionType: '',
                amount: cellInfo.value,
            };

            switch (cellInfo.row.transactionName) {
                case 'Starting Balance': {
                    if(cellInfo.field === 'totalBalance'){
                        const filteredTransactions: GridItem[] = transactionDetails
                        .filter(transaction => DateTimeFormat.isDateBetween(transaction.transactionDate!, new Date(1000, 0, 1), params.startDate))
                        .map(transaction => ({
                            id: transaction.transactionId,
                            fund: transaction.fund.name,
                            investor: transaction.investor.name,
                            investorType: transaction.fundInvestorType.investorType.label,
                            transactionDate: transaction.transactionDate,
                            transactionType: transaction.transactionType.name,
                            amount: transaction.amount
                        }));

                        updatedGridData = filteredTransactions;
                    } else {
                        const filteredTransactions: GridItem[] = transactionDetails
                        .filter(transaction => 
                            DateTimeFormat.isDateBetween(transaction.transactionDate!, new Date(1000, 0, 1), params.startDate)
                            && transaction.fundInvestorType.investorType.id === getInvestorType(cellInfo.field))
                        .map(transaction => ({
                            id: transaction.transactionId,
                            fund: transaction.fund.name,
                            investor: transaction.investor.name,
                            investorType: transaction.fundInvestorType.investorType.label,
                            transactionDate: transaction.transactionDate,
                            transactionType: transaction.transactionType.name,
                            amount: transaction.amount
                        }));

                        updatedGridData = filteredTransactions;
                    }
                    };
                    break;
                case 'Ending Balance': {
                    if(cellInfo.field === 'totalBalance'){
                        const filteredTransactions: GridItem[] = transactionDetails
                        .map(transaction => ({
                            id: transaction.transactionId,
                            fund: transaction.fund.name,
                            investor: transaction.investor.name,
                            investorType: transaction.fundInvestorType.investorType.label,
                            transactionDate: transaction.transactionDate,
                            transactionType: transaction.transactionType.name,
                            amount: transaction.amount
                        }));
        
                        updatedGridData = filteredTransactions;
                    } else {
                        const filteredTransactions: GridItem[] = transactionDetails
                        .filter(transaction =>
                            transaction.fundInvestorType.investorType.id === getInvestorType(cellInfo.field))
                        .map(transaction => ({
                            id: transaction.transactionId,
                            fund: transaction.fund.name,
                            investor: transaction.investor.name,
                            investorType: transaction.fundInvestorType.investorType.label,
                            transactionDate: transaction.transactionDate,
                            transactionType: transaction.transactionType.name,
                            amount: transaction.amount
                        }));
        
                        updatedGridData = filteredTransactions;
                    }
                    };
                    break;
                default : {
                    if(cellInfo.field === 'totalBalance'){
                        const filteredTransactions: GridItem[] = transactionDetails
                        .filter(transaction => 
                            DateTimeFormat.isDateBetween(transaction.transactionDate!, params.startDate, params.endDate)
                            && transaction.transactionType.name === cellInfo.row.transactionName)
                        .map(transaction => ({
                            id: transaction.transactionId,
                            fund: transaction.fund.name,
                            investor: transaction.investor.name,
                            investorType: transaction.fundInvestorType.investorType.label,
                            transactionDate: transaction.transactionDate,
                            transactionType: transaction.transactionType.name,
                            amount: transaction.amount
                        }));
        
                        updatedGridData = filteredTransactions;
                    } else {
                        const filteredTransactions: GridItem[] = transactionDetails
                        .filter(transaction =>
                            DateTimeFormat.isDateBetween(transaction.transactionDate!, params.startDate, params.endDate) &&
                            transaction.transactionType.name === cellInfo.row.transactionName &&
                            transaction.fundInvestorType.investorType.id === getInvestorType(cellInfo.field))
                        .map(transaction => ({
                            id: transaction.transactionId,
                            fund: transaction.fund.name,
                            investor: transaction.investor.name,
                            investorType: transaction.fundInvestorType.investorType.label,
                            transactionDate: transaction.transactionDate,
                            transactionType: transaction.transactionType.name,
                            amount: transaction.amount
                        }));
        
                        updatedGridData = filteredTransactions;
                    }
                    };
                    break;
            }

            updatedGridData.unshift(balanceLine);
            setGridData(updatedGridData);
        }
    },[cellInfo]);

    const dataGridColDefs: DataGridColDef[] = [
        {
            index: 1,
            field: 'fund',
            editable: false,
            disableReorder: true,
            headerName: 'Fund Name',
            align: 'left',
            width: 200,
            maxWidth: 200,
            sortable: false,
            resizable: true
        },
        {
            index: 2,
            field: 'investor',
            editable: false,
            disableReorder: true,
            headerName: 'Investor Name',
            align: 'left',
            width: 200,
            maxWidth: 200,
            sortable: false,
            resizable: true
        },
        {
            index: 3,
            field: 'investorType',
            editable: false,
            disableReorder: true,
            headerName: 'Investor Type',
            align: 'left',
            width: 200,
            maxWidth: 200,
            sortable: false,
            resizable: true
        },
        {
            index: 4,
            field: 'transactionDate',
            editable: false,
            disableReorder: true,
            headerName: 'Transaction Date',
            align: 'left',
            width: 200,
            maxWidth: 200,
            sortable: false,
            resizable: true
        },
        {
            index: 5,
            field: 'transactionType',
            editable: false,
            disableReorder: true,
            headerName: 'Transaction Type',
            align: 'left',
            width: 200,
            maxWidth: 200,
            sortable: false,
            resizable: true
        },
        {
            index: 6,
            field: 'amount',
            editable: false,
            disableReorder: true,
            headerName: 'Amount',
            align: 'left',
            width: 200,
            maxWidth: 200,
            sortable: false,
            resizable: true,
            renderCell: (params: any) => {
                if(params.value){
                    return formatCurrency(params.value);
                }
            }
        }
    ];

    function getInvestorType(field: string){
        return investorTypes[field] || null;
    }

    function formatCurrency(value: number){
        if(roundedReportActivated){
            return CurrencyFormat(currencyCode, 0).format(value);
        }
        return CurrencyFormat(currencyCode, 2).format(value);
    }

    return {
        dataGridColDefs,
        gridData,
        isLoading,
    };

};