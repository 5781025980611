import AddIcon from '@mui/icons-material/Add';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import {
  Box,
  Button as ButtonComponent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { DataGridProProps, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';

import DeleteIcon from '../../../../assets/images/icons/icon_delete.svg';
import ExportIcon from '../../../../assets/images/icons/icon_export.svg';
import { DataWrapperBox } from '../../../../components/Boxes/DataWrapperBox/DataWrapperBox.style';
import Button from '../../../../components/Buttons/Button/Button';
import SplitButton from '../../../../components/Buttons/SplitButton/SplitButton';
import ButtonWithOptions from '../../../../components/ButtonWithOptions/ButtonWithOptions';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import { MappingProgressBar } from '../../../../components/GL/MappingProgress/MappingProgress';
import { ActionLink } from '../../../../components/Link/ActionLink/ActionLink';
import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import { PageLock } from '../../../../components/PageLock/PageLock';
import ProgressModal from '../../../../components/Progress/ProgressModal/ProgressModal';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import { ImageItem } from '../../../../utils/types/listItems';
import { AccountDetails } from '../accountDetails/AccountDetails';
import { AccountDetailsReadOnly } from '../accountDetails/AccountDetailsReadOnly';
import { BulkFieldOptions } from './AccountList.constants';
import {
  useAccountList,
  useBulkActionOptionSelectionEffect,
} from './AccountList.hooks';
import {
  ButtonBox,
  GLAccount,
  HeaderRow,
  IconBox,
  MUIBox,
  SelectBox,
  TabRow,
} from './AccountList.styles';

export const AccountList: React.FC = () => {
  const history = useHistory();

  const {
    isLoading,
    isSaving,
    accountFilteredList,
    accountSelectionModel,
    setAccountSelectionModel,
    headerList,
    activeHeaderFields,
    filterModel,
    handleOnView,
    handleUpdateHeader,
    handleFilter,
    onRowColumnOrderChange,
    handleBulkOptionClick,
    handleNewButtonAction,
    search,
    searchOptions,
    handleSearch,
    showSuggestionPopover,
    setShowSuggestionPopover,
    progress,
    hasMappingAdmin,
    tab,
    handleTabChange,
    readonly,
    isPageLocked,
    lockedTooltipText,
    toggleIsLocked,
    fetchAllAccounts,
    selectedAccount,
    onDetailsPanelClose,
    isPreview,
    handleBulkSave,
    fundId,
    NewAccountOptions,
    uploadedFile,
    handleUploadTemplate,
    clearUploadedFile,
    apiRef,
    bulkMappingField,
    bulkMappingValue,
    bulkMappingValueId,
    setBulkMappingField,
    setBulkMappingValue,
    handleApplyChange,
    valueOptions,
    setBulkMappingValueId,
    clearApplyChange,
    csvLinkRef,
    csvHeaders,
    csvData,
    csvFilename,
    resetCsvData,
    showFSNameConfirmation,
    setShowFSNameConfirmation,
    bulkMappingFSName,
    setBulkMappingFSName,
    showBulkConfirmation,
    setShowBulkConfirmation,
    confirmBulkMapUpdate,
    onDeleteCancel,
    onDeleteConfirm,
    deleteError,
    deleteErrorMessage,
    deleteErrorJEAccounts,
    isBasicAdmin,
    arkClientTag,
  } = useAccountList();

  const defaultBulkActions: ImageItem[] = [
    {
      id: 'export',
      text: 'Export',
      icon: <img src={ExportIcon} alt="" height="15" />,
      optionsSelected: 0,
    },
    {
      id: 'delete',
      text: 'Delete',
      icon: <img src={DeleteIcon} alt="" height="15" />,
      optionsSelected: 0,
    },
  ];

  const { bulkActionOptions } = useBulkActionOptionSelectionEffect(
    defaultBulkActions,
    accountSelectionModel
  );

  function CustomGridTreeDataGroupingCell(params: GridRenderCellParams) {
    const { id, field, value, row, rowNode } = params;

    if (rowNode.isAutoGenerated) {
      return (
        <GLAccount depth={rowNode.depth}>
          <Typography variant="label">{value}</Typography>
        </GLAccount>
      );
    } else {
      return (
        <GLAccount depth={rowNode.depth}>
          <ActionLink
            id={`link_account_${row.id}`}
            onClick={() => handleOnView(row.id, row)}
          >
            {value}
          </ActionLink>
          {row.transactionTypeName === null && <FiberNewIcon color="success" />}
        </GLAccount>
      );
    }
  }

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: 'Account Name',
    sortable: true,
    width: 260,
    disableExport: true,
    colSpan: (params) => {
      const nodeHolder = apiRef.current.getRowNode(params.id);

      return nodeHolder?.isAutoGenerated ? activeHeaderFields : undefined;
    },

    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  return (
    <DataWrapperBox id="arkGL_account_list_main" role="main">
      <ProgressModal
        id="modal_arkGL_account_list_loading"
        text={`${isLoading}...`}
        showProgress={!!isLoading}
      />
      <ProgressModal
        id="modal_arkGL_account_list_saving"
        text="Saving..."
        showProgress={isSaving}
      />
      <HeaderRow>
        <IconBox>
          <Typography variant="pageTitle">Chart of Accounts</Typography>
          {/* {client?.soiLocked && (
            <IconButton aria-label="locked" onClick={showLockedDialog}>
              <LockIcon />
            </IconButton>
          )} */}
        </IconBox>
        <SearchBar
          id="search_accounts"
          size="small"
          onChange={handleSearch}
          options={searchOptions}
          searchText={search}
          showPopover={showSuggestionPopover}
          setShowPopover={setShowSuggestionPopover}
        />
        <MappingProgressBar
          value={(progress.mappedAmount / progress.totalAmount) * 100}
          total={progress.totalAmount}
        />
        <ButtonBox>
          <SplitButton
            id={'btn_bulk_action_options'}
            options={bulkActionOptions}
            hidden={accountSelectionModel.length === 0}
            handleOptionClick={handleBulkOptionClick}
            ariaLabelMessage="Select bulk action option"
          />
          {isPreview && (
            <Button
              id="btn_bulk_save"
              variant="contained"
              text={'Save Accounts'}
              color={'primary'}
              onClick={handleBulkSave}
            />
          )}
          {!(
            readonly ||
            (isBasicAdmin && arkClientTag === 'Standish Management')
          ) && (
            <ButtonWithOptions
              buttonID="btn_add_account"
              popoverID="popover_add_account"
              onClick={handleNewButtonAction}
              buttonLabel="Add New"
              buttonIcon={<AddIcon />}
              options={NewAccountOptions}
            />
          )}
        </ButtonBox>
      </HeaderRow>
      {!readonly && accountSelectionModel.length >= 1 && (
        <MUIBox>
          <SelectBox>
            <Typography variant="body1">Bulk Map Selected</Typography>
            <FormControl
              disabled={!(accountSelectionModel.length >= 1)}
              size="small"
            >
              <InputLabel id="label_bulk_mapping_select_field">
                Select Field
              </InputLabel>
              <Select
                labelId="select_bulk_mapping_label"
                id="select_bulk_mapping"
                label="Select Field"
                size="small"
                value={bulkMappingField}
                onChange={(e) => setBulkMappingField(e.target.value)}
              >
                {BulkFieldOptions.map((field: any) => {
                  return (
                    <MenuItem key={field.id} value={field.name}>
                      {field.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              disabled={!(accountSelectionModel.length >= 1)}
              size="small"
            >
              <InputLabel id="label_bulk_mapping_select_value">
                Select Value
              </InputLabel>
              <Select
                labelId="update_bulk_mapping_label"
                id="update_bulk_mapping"
                label="Select Value"
                size="small"
                value={bulkMappingValue}
                onChange={(e) => setBulkMappingValue(e.target.value)}
              >
                {valueOptions.map((value: any) => {
                  return (
                    <MenuItem
                      key={value.id}
                      value={value.name}
                      onClick={() => setBulkMappingValueId(value.id)}
                    >
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {bulkMappingField === 'FS Mapping' &&
              bulkMappingValueId !== '1' && (
                <FormControl
                  disabled={!(accountSelectionModel.length >= 1)}
                  size="small"
                >
                  <TextField
                    value={bulkMappingFSName}
                    onChange={(e) => setBulkMappingFSName(e.target.value)}
                    id="txt_fsname"
                    label="Enter FS Name"
                    variant="outlined"
                    error={
                      bulkMappingField === 'FS Mapping' &&
                      bulkMappingValueId !== '' &&
                      bulkMappingValueId !== '1' &&
                      bulkMappingFSName === ''
                    }
                    required={
                      bulkMappingField === 'FS Mapping' &&
                      bulkMappingValueId !== '1'
                    }
                  />
                </FormControl>
              )}
            <ButtonComponent
              id="btn_apply_bulk_mapping"
              variant="contained"
              onClick={() => {
                setShowBulkConfirmation(true);
              }}
              disableElevation
              disabled={Boolean(!bulkMappingValueId)}
            >
              Apply
            </ButtonComponent>
          </SelectBox>
        </MUIBox>
      )}
      <TabRow>
        <Box>
          <Tabs
            id="tabgroup_notmapped_all"
            value={tab}
            onChange={handleTabChange}
            aria-label="not mapped all tabs"
          >
            <Tab
              label={`NOT MAPPED ${
                progress.totalAmount - progress.mappedAmount
              }`}
              disabled={progress.mappedAmount === progress.totalAmount}
            />
            <Tab label={`ALL ${progress.totalAmount}`} />
          </Tabs>
        </Box>
      </TabRow>
      <DataGrid
        id="data_grid_account_list"
        dataList={
          !isLoading && accountFilteredList?.length > 0
            ? accountFilteredList
            : []
        }
        treeData
        apiRef={apiRef}
        getTreeDataPath={(row) => row.path}
        defaultGroupingExpansionDepth={-1}
        groupingColDef={groupingColDef}
        autoHeight={false}
        headerList={headerList}
        handleOnView={handleOnView}
        actionName={
          isBasicAdmin && arkClientTag === 'Standish Management' ? '' : 'View'
        }
        selectionModel={accountSelectionModel}
        activeHeaderFields={activeHeaderFields}
        setSelectionModel={setAccountSelectionModel}
        handleUpdateHeader={handleUpdateHeader}
        filterModel={filterModel}
        handleFilter={handleFilter}
        onColumnOrderChange={onRowColumnOrderChange}
        isRowSelectable={(params) => {
          const rowNode = apiRef.current.getRowNode(params.id);

          return rowNode?.isAutoGenerated ? false : true;
        }}
      />
      <br />
      <br />
      {!!selectedAccount?.type &&
        (readonly || isPreview ? (
          <AccountDetailsReadOnly
            selectedAccount={selectedAccount}
            onDetailClose={onDetailsPanelClose}
            fetchAllAccounts={fetchAllAccounts}
            fundId={fundId}
          />
        ) : (
          <AccountDetails
            selectedAccount={selectedAccount}
            onDetailClose={onDetailsPanelClose}
            fetchAllAccounts={fetchAllAccounts}
            fundId={fundId}
          />
        ))}
      <ConfirmationDialog
        open={Boolean(uploadedFile)}
        onClose={clearUploadedFile}
        id="confirmation_upload"
        actions={[
          {
            label: 'Upload',
            onClick: handleUploadTemplate,
            id: 'btn_upload',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Cancel',
            onClick: clearUploadedFile,
            id: 'btn_cancel',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content={
          <Box component="span">
            Are you sure you want to upload{' '}
            <strong>{uploadedFile?.name}</strong>?
          </Box>
        }
        title="Confirm Upload"
      />
      {!readonly && (
        <PageLock
          locked={isPageLocked}
          showTooltip={Boolean(lockedTooltipText)}
          tooltipText={lockedTooltipText}
          disabled={hasMappingAdmin}
          onChange={toggleIsLocked}
        />
      )}
      <ConfirmationDialog
        open={showBulkConfirmation}
        onClose={clearApplyChange}
        id="confirmation_apply_change"
        actions={[
          {
            label: 'Confirm',
            onClick: handleApplyChange,
            id: 'btn_confirm',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Cancel',
            onClick: clearApplyChange,
            id: 'btn_cancel',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content={
          <Box component="span">
            Are you sure you want to bulk map selected Accounts for
            {bulkMappingField === 'FS Mapping' ? (
              <>
                <Typography>
                  <strong> FS Mapping </strong>
                  to <strong>{bulkMappingValue}</strong>
                </Typography>
                {bulkMappingValueId !== '1' && (
                  <>
                    <Typography>and</Typography>
                    <Typography>
                      <strong> FS Name </strong>
                      to <strong>{bulkMappingFSName}</strong>?
                    </Typography>
                  </>
                )}
              </>
            ) : (
              <Typography>
                <strong> {bulkMappingField} </strong>
                to <strong>{bulkMappingValue}</strong>?
              </Typography>
            )}
          </Box>
        }
        title="Confirm Bulk Mapping"
      />
      <ConfirmationDialog
        open={showFSNameConfirmation}
        onClose={() => setShowFSNameConfirmation(false)}
        id="fs_name_change_confirmation"
        actions={[
          {
            label: 'Continue',
            onClick: () => confirmBulkMapUpdate(bulkMappingValueId),
            id: 'btn_save_fs_name_change',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Cancel',
            onClick: () => setShowFSNameConfirmation(false),
            id: 'btn_account_cancel_save',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content="Updating the FS Display Name will apply the new name to all accounts associated with the chosen FS Mapping."
        title="FS Display Name Change"
      />
      <ConfirmationDialog
        open={!!deleteError && deleteError !== 'CHILD'}
        id="delete_account_error"
        actions={[
          {
            label: 'OK',
            onClick: onDeleteCancel,
            id: 'btn_close_error',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        title="Delete Error"
        content={
          <>
            <Typography mb={1}>{deleteErrorMessage}</Typography>

            {deleteErrorJEAccounts &&
              deleteErrorJEAccounts.map((account) => {
                const acc = accountFilteredList.find(
                  (acc) => acc.id === account
                ) as any;

                return (
                  <Typography mb={1}>
                    {acc.number} {acc.name}
                  </Typography>
                );
              })}
          </>
        }
      />
      <ConfirmationDialog
        open={!!deleteError && deleteError === 'CHILD'}
        id="delete_account_with_children_error"
        actions={[
          {
            label: 'Delete Parent and Children Accounts',
            onClick: onDeleteConfirm,
            id: 'btn_confirm',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Cancel',
            onClick: onDeleteCancel,
            id: 'btn_cancel',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        title="Delete Error"
        content={deleteErrorMessage}
      />
      <CSVLink
        ref={csvLinkRef}
        headers={csvHeaders}
        data={csvData}
        filename={csvFilename}
        onClick={resetCsvData}
      />
    </DataWrapperBox>
  );
};
