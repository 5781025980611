import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../core/context/appContextProvider';
import { GET_TRANSACTION_LIST_ERROR } from '../../../pages/fund/InvestmentTransactions/InvestmentTransactionList.constants';
import { getInvestmentTransactions } from '../../../services/investmentTransaction.service';
import { DateTimeFormat } from '../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import { PortfolioCompanySimple } from '../../../utils/types/portfolioCompany.type';

export const usePurchaseTransactionFilterEffect = (
  refetch: boolean,
  selectedTransactionType: string
) => {
  const { informationAlert } = useContext(AppContext);
  const [purchaseTransactionList, setPurchaseTransactionList] =
    useState<PortfolioCompanySimple[]>();
  const [loading, setLoading] = useState(false);

  const fetchPurchaseTransactionFilterList = async (
    isCanceled?: () => boolean
  ) => {
    setLoading(true);
    try {
      if (isCanceled?.()) return;

      const purchaseTransactionListResponse = await getInvestmentTransactions();

      const conversionNames = new Set<string>();

      const transactions = purchaseTransactionListResponse.items.filter(
        (transaction: any) =>
          ['PURCHASE', 'CONVERSION'].includes(
            transaction.investmentTransactionType
          )
      );

      const latestTransactionIds: any[] = [];

      transactions.forEach((transaction: any) => {
        const f: any = (current: any) => {
          const followingTransaction = transactions.find(
            (t: any) => t.fromTransaction && t.fromTransaction.id === current.id
          );

          if (followingTransaction === undefined) {
            latestTransactionIds.push(current.id);
            return;
          }
          return f(followingTransaction.id);
        };

        f(transaction);
      });

      const latestTransactions = latestTransactionIds
        .filter((v) => v !== undefined)
        .map((transactionId: any) => {
          const t = transactions.find((t: any) => t.id === transactionId);

          return {
            id: t.id,
            name: `${DateTimeFormat.shortDateString(t.date)}, ${t.name}, ${
              t.portfolioCompany.name
            }, ${t.securityType}, ${t.fund.name}`,
          };
        })
        .sort();

      setPurchaseTransactionList(latestTransactions);
    } catch (e) {
      informationAlert(GET_TRANSACTION_LIST_ERROR, 'error');
    }
    setLoading(false);
  };

  useEffectAsync(async (isCanceled) => {
    await fetchPurchaseTransactionFilterList(isCanceled);
  }, []);

  return {
    purchaseTransactionList,
    loading,
  };
};

export default usePurchaseTransactionFilterEffect;
