import {
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

import usePurchaseTransactionFilterEffect from './usePurchaseTransactionFilter.hook';

type Props = {
  error?: any;
  value?: any | null | string;
  onChange?: (purchaseTransaction: any | null) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  id?: string;
  textFieldID?: string;
  refetch?: boolean;
  selectedInvestmentTransactionType?: string;
};

type PurchaseTransactionValue = any | null | undefined;

export const PurchaseTransactionFilter = ({
  error,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  disabled,
  size = 'small',
  id = 'investment_transaction_filter',
  textFieldID = 'txt_investment_transaction',
  refetch = false,
  selectedInvestmentTransactionType,
}: Props) => {
  const { purchaseTransactionList, loading } =
    usePurchaseTransactionFilterEffect(
      refetch,
      selectedInvestmentTransactionType ? selectedInvestmentTransactionType : ''
    );

  const onSelectChange = (_: any, purchaseTransaction: any) => {
    if (!purchaseTransaction) {
      return;
    }
    onChange?.(
      idSelectOnly ? purchaseTransaction?.id ?? '' : purchaseTransaction
    );
  };

  const selectedValue: PurchaseTransactionValue = idSelectOnly
    ? purchaseTransactionList?.find((item: any) => item.id === value) ?? null
    : (value as PurchaseTransactionValue);

  return (
    <Autocomplete
      id={id}
      options={purchaseTransactionList || []}
      getOptionLabel={(option: any) => option?.name ?? ''}
      isOptionEqualToValue={(option: any, selected: any) =>
        option?.name === selected?.name
      }
      onChange={onSelectChange}
      value={selectedValue ?? {}}
      size={size}
      disabled={disabled || loading}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            label={placeholder}
            id={textFieldID}
            error={!!error}
            helperText={error?.message ?? ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default PurchaseTransactionFilter;
