import { Box, Button, Checkbox, FormGroup, Typography } from "@mui/material";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";

import { FilterSelectionType, Option } from "../../../utils/types/filter.type";
import { TransactionFilter } from "../../../utils/types/transaction.type";
import SearchBar from "../../SearchBarWithDebounce/SearchBar";
import {
  ButtonOption,
  ButtonText,
  FilterAll,
  FilterCheckBox,
  FilterControlLabel,
  FilterOption,
  FormControlCheckAll,
  FundsContent,
  SelectableLabel,
} from "./Filter.style";

type Props = {
  options?: Record<string, any>[];
  selectedOptions?: string[];
  handleFilter?: (
    filter: TransactionFilter,
    selectedOptions: Array<any>,
    selectionType?: FilterSelectionType
  ) => void;
  handleSelection?: (selectedOption: Option, checked: boolean) => void;
  handleAllSelection?: (
    selectedOptions: Option[],
    checked: boolean,
    selectAll?: boolean
  ) => void;
  handleIDSelection?: (
    selected: Array<string> | string | Option,
    selectedOptions?: Option[],
    selectAll?: boolean
  ) => void;
  idField: string;
  labelField: string;
  clearable?: boolean;
  enableApplyAction?: boolean;
  applyOnSelect?: boolean;
  filterName?: TransactionFilter;
  popoverStyle?: object;
  scrollContainerStyle?: object;
  hideButtons?: boolean;
  singleSelect?: boolean;
  optionFormComponent?: boolean;
  showFilterAll?: boolean;
  shouldOptionReturnValue?: boolean;
  emptySelectionOnClear?: boolean;
  returnAllSelected?: boolean;
  applyText?: string;
  clearText?: string;
  selectAllText?: string;
  allSelected?: boolean;
};

type OptionItemProps = {
  id: string;
  label: string;
  onSelect: (item: Option, checked: boolean) => void;
  selected: boolean;
};

type ButtonItemProps = {
  id: string;
  label: string;
  onSelect: (item: string | Option) => void;
  selected: boolean;
  formComponent?: boolean;
  shouldOptionReturnValue?: boolean;
};

const OptionItem: React.FC<OptionItemProps> = ({
  id,
  label,
  onSelect,
  selected = false,
}: OptionItemProps): ReactElement => {
  const onCheck = (e: any): void => {
    const isChecked = e.target.checked;

    onSelect(
      {
        id,
        name: label,
      },
      isChecked
    );
  };

  return (
    <FilterOption>
      <FilterControlLabel
        control={
          <FilterCheckBox
            id={`${id}_check`}
            name={label}
            checked={selected}
            onChange={onCheck}
          />
        }
        label={label}
      />
    </FilterOption>
  );
};

const ButtonItem: React.FC<ButtonItemProps> = ({
  id,
  label,
  onSelect,
  selected = false,
  formComponent = false,
  shouldOptionReturnValue = false,
}: ButtonItemProps): ReactElement => {
  return (
    <FilterOption>
      {!formComponent ? (
        <ButtonOption
          variant={selected ? "outlined" : "text"}
          onClick={() => {
            if (!shouldOptionReturnValue) {
              return onSelect(label);
            }
            onSelect({ id, name: label });
          }}
        >
          {label}
        </ButtonOption>
      ) : (
        <SelectableLabel
          onClick={() => {
            if (!shouldOptionReturnValue) {
              return onSelect(label);
            }
            onSelect({ id, name: label });
          }}
        >
          <Typography variant={selected ? "filterChecked" : "filterUnchecked"}>
            {label}
          </Typography>
        </SelectableLabel>
      )}
    </FilterOption>
  );
};

const Filter: React.FC<Props> = ({
  options = [],
  selectedOptions,
  handleFilter = () => {},
  handleSelection = () => {},
  handleAllSelection = () => {},
  handleIDSelection = () => {},
  idField,
  labelField,
  clearable = true,
  filterName = TransactionFilter.FundName,
  enableApplyAction = true,
  applyOnSelect = false,
  popoverStyle = {},
  scrollContainerStyle = {},
  hideButtons = false,
  singleSelect = false,
  optionFormComponent = false,
  showFilterAll = false,
  shouldOptionReturnValue = false,
  emptySelectionOnClear = true,
  returnAllSelected = false,
  allSelected = false,
  applyText = "Apply Filter",
  selectAllText = "All",
  clearText = "Clear Filter",
}: Props): ReactElement => {
  const [search, setSearch] = useState<string>("");
  const [selectedOptionList, setSelectedOptionList] = useState<string[]>([]);
  const [optionsList, setOptionsList] = useState<Record<string, any>[]>([]);

  const isAllSelected =
    allSelected ||
    (selectedOptionList.length === options.length && options.length !== 0);

  useEffect(() => {
    if (selectedOptions) {
      setSelectedOptionList(selectedOptions);
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (options && options.length) {
      setOptionsList(options);
    }
  }, [options]);

  const onSearch = (value: string): void => {
    const regex = new RegExp(value, "igm");
    const listFilter = options.filter(
      (item) => item[labelField].search(regex) !== -1
    );

    setOptionsList(listFilter);
    setSearch(value);
  };

  const onClear = () => {
    setSearch("");
    setSelectedOptionList(
      emptySelectionOnClear ? [] : options?.map((item) => item[idField]) ?? []
    );
  };

  const handleApply = (selected: string | Option) => {
    const type: FilterSelectionType = isAllSelected ? "All" : "Partial";

    const currentSelection: any = selected
      ? [selected]
      : options.filter((item) => selectedOptionList.includes(item[idField]));

    if (returnAllSelected) {
      handleIDSelection(
        selected || selectedOptionList,
        currentSelection,
        isAllSelected
      );
      return;
    }
    if (singleSelect && selected) {
      handleFilter(filterName, [selected], type);
    } else if (!search) {
      handleFilter(filterName, selectedOptionList, type);
    } else {
      const currentSelectedList = currentSelection.map(
        (item: any) => item[idField]
      );

      handleFilter(filterName, currentSelectedList, type);
    }
  };

  const onSelect = (item: Option, checked: boolean): void => {
    const new_option_id = item.id;
    let selected_options = [...selectedOptionList];

    if (checked) {
      selected_options.push(new_option_id);
    } else {
      selected_options = selected_options.filter(
        (option) => option !== new_option_id
      );
    }

    setSelectedOptionList(selected_options);
    if (applyOnSelect) {
      returnAllSelected
        ? handleIDSelection(selected_options)
        : handleSelection(item, checked);
    }
  };

  const onSelectAll = (
    _: ChangeEvent | React.MouseEvent | null,
    checked: boolean
  ) => {
    const convertToChips =
      options?.map((item) => ({
        id: item[idField],
        name: item[labelField],
      })) ?? [];

    let selectedOptionIDs = [];

    if (checked) {
      selectedOptionIDs = options?.map((item) => item[idField]) ?? [];
      setSelectedOptionList(selectedOptionIDs);
    } else {
      setSelectedOptionList([]);
    }
    if (applyOnSelect) {
      returnAllSelected
        ? handleIDSelection(selectedOptionIDs, convertToChips, true)
        : handleAllSelection(convertToChips, checked, true);
    }
  };

  return (
    <div id="popover_filter_text" style={popoverStyle}>
      <SearchBar id="filter_search" onChange={onSearch} value={search} />
      <FormGroup>
        {!singleSelect && !showFilterAll && (
          <FilterAll>
            <b>
              <FormControlCheckAll
                id="check_all"
                key="All"
                control={
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={
                      options &&
                      selectedOptionList.length > 0 &&
                      selectedOptionList.length < options?.length
                    }
                    onChange={onSelectAll}
                  />
                }
                label={selectAllText}
              />
            </b>
          </FilterAll>
        )}
        {showFilterAll && (
          <FilterAll>
            <Button
              id="select_all"
              variant={isAllSelected ? "outlined" : "text"}
              onClick={(e) => onSelectAll(e, !isAllSelected)}
              color="info"
            >
              {selectAllText}
            </Button>
          </FilterAll>
        )}
        <FundsContent sx={scrollContainerStyle}>
          {options && options?.length > 0 ? (
            optionsList.reduce((accumulator: Record<string, any>[], cur) => {
              return singleSelect
                ? [
                    ...accumulator,
                    <ButtonItem
                      key={cur[idField]}
                      label={cur[labelField]}
                      id={cur[idField]}
                      shouldOptionReturnValue={shouldOptionReturnValue}
                      onSelect={handleApply}
                      selected={
                        shouldOptionReturnValue
                          ? selectedOptionList.includes(cur[idField])
                          : selectedOptionList.includes(cur[labelField])
                      }
                      formComponent={optionFormComponent}
                    />,
                  ]
                : [
                    ...accumulator,
                    <OptionItem
                      key={cur[idField]}
                      label={cur[labelField]}
                      id={cur[idField]}
                      onSelect={onSelect}
                      selected={selectedOptionList.includes(cur[idField])}
                    />,
                  ];
            }, [])
          ) : (
            <div>No records</div>
          )}
        </FundsContent>
      </FormGroup>
      {!hideButtons && (
        <Box display="flex" justifyContent="flex-end">
          {enableApplyAction && (
            <ButtonText
              id="btn_apply"
              variant="text"
              onClick={() => handleApply("")}
            >
              {applyText}
            </ButtonText>
          )}
          {clearable && (
            <ButtonText id="btn_clear" variant="text" onClick={onClear}>
              {clearText}
            </ButtonText>
          )}
        </Box>
      )}
    </div>
  );
};

export default Filter;
