import {
  Box,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { RouteProps } from 'react-router-dom';

import HorizontalBox from '../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../components/Buttons/Button/Button';
import DetailPanel from '../../../components/DetailPanel/DetailPanel';
import {
  FormBox,
  Panel,
  StyledBackdrop,
  Typography,
} from '../../../components/DetailPanel/DetailPanel.styles';
import ConfirmationDialog from '../../../components/Modal/ConfirmationDialog';
import ProgressPanel from '../../../components/Progress/ProgressPanel/ProgressPanel';
import StatusLabel from '../../../components/StatusLabel/StatusLabel';
import NumberFormatCustom from '../../../components/TextFieldNumeric/NumberFormat';
import { NotifiedStatusType } from '../../../services/hooks/useCapitalCallsEffect/useCapitalCall.constants';
import { M_DASH_UNICODE } from '../../../utils/constants/constants';
import {
  CurrencyFormat,
  DateTimeFormat,
} from '../../../utils/helpers/format.helper';
import { SelectedCashReceipt } from '../../../utils/types/cashReceipt.type';
import { DetailsType } from '../../../utils/types/form.type';
import { SelectedCashReceiptTransaction } from '../cashReceipts/CashReceipts.constants';
import { useCashReceiptDetails } from './CashReceiptDetails.hooks';
import {
  DatePicker,
  KeyLabel,
  KeyValuePair,
  StatusControlLabel,
  ValueLabel,
} from './CashReceiptDetails.styles';

interface Props extends RouteProps {
  onDetailClose: Function;
  selectedTransaction: SelectedCashReceiptTransaction;
  fetchCapitalCallTransactions: Function;
  selectedCapitalCall: any;
  arkGlLocked: boolean;
  setSelectedJournalEntry: Function;
  onJournalEntryPanelClose: Function;
  onJournalEntrySuccessfulPostClose: Function;
  setSelectedMailing: Function;
  setNotifyList: Function;
  isNotifyChecked: boolean;
  setIsNotifyChecked: Function;
  isSendToGLChecked: boolean;
  setIsSendToGLChecked: Function;
  notify: Function;
  setSelectedJEList: Function;
}

export const CashReceiptDetails: React.FC<Props> = ({
  onDetailClose,
  selectedTransaction,
  fetchCapitalCallTransactions,
  selectedCapitalCall,
  arkGlLocked,
  setSelectedJournalEntry,
  onJournalEntryPanelClose,
  onJournalEntrySuccessfulPostClose,
  setSelectedMailing,
  setNotifyList,
  isNotifyChecked,
  setIsNotifyChecked,
  isSendToGLChecked,
  setIsSendToGLChecked,
  notify,
  setSelectedJEList,
}: Props) => {
  const { type, transaction } = selectedTransaction;

  const {
    isLoading,
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    updateTransaction,
    closeDrawer,
    keepDrawerOpen,
    toggleDrawer,
    showExitConfirmation,
    notifiedStatus,
    adjustmentValues,
    amountPaid,
    receiptDate,
    isNotifyDisabled,
    setIsNotifyDisabled,
    isSendToGLDisabled,
    setIsSendToGLDisabled,
  } = useCashReceiptDetails({
    transaction,
    onClose: onDetailClose,
    fetchCapitalCallTransactions,
    selectedTransaction,
    selectedCapitalCall,
    arkGlLocked,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
    onJournalEntrySuccessfulPostClose,
    setSelectedMailing,
    setNotifyList,
    isNotifyChecked,
    isSendToGLChecked,
    setIsSendToGLChecked,
    notify,
    setSelectedJEList,
  });

  return (
    <>
      <DetailPanel
        id="detail_panel_cash_receipt"
        title={transaction?.name ? transaction.name : ''}
        open={Boolean(type)}
        variant={'temporary'}
        hasTabsPanel={false}
        onClose={toggleDrawer}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit(updateTransaction)}
          noValidate
        >
          <FormBox>
            <Panel id="details_panel">
              <Typography variant="h4">Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <KeyValuePair>
                    <KeyLabel>Fund Name</KeyLabel>
                    <ValueLabel>
                      {selectedCapitalCall.fundName || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyValuePair>
                    <KeyLabel>Investor Name</KeyLabel>
                    <ValueLabel>
                      {transaction?.investor || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyValuePair>
                    <KeyLabel>Capital Called</KeyLabel>
                    <ValueLabel>
                      {(transaction?.amount &&
                        CurrencyFormat('USD', 2).format(transaction?.amount)) ||
                        M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyValuePair>
                    <KeyLabel>Notified</KeyLabel>
                    <ValueLabel>
                      <StatusLabel
                        color={
                          (notifiedStatus === false
                            ? NotifiedStatusType['NO']
                            : NotifiedStatusType['YES']
                          ).color
                        }
                        isUpperCase={false}
                        label={
                          (notifiedStatus === false
                            ? NotifiedStatusType['NO']
                            : NotifiedStatusType['YES']
                          ).name ?? M_DASH_UNICODE
                        }
                      />{' '}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="amountPaid"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextField
                          id="txt_amount"
                          label="Amount Received"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={value ?? ''}
                          onChange={(e) => {
                            const newValue: any = parseFloat(
                              e.target.value
                            ) as number;

                            onChange(newValue);
                          }}
                          onBlur={(e) => {
                            const newValue = parseFloat(
                              e.target.value.replace(',', '')
                            );

                            if (transaction && isNotifyDisabled) {
                              if (transaction?.amountPaid !== newValue) {
                                setIsNotifyDisabled(false);
                              }
                            }
                          }}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          InputLabelProps={{ shrink: !!value }}
                          error={!!error}
                          helperText={error?.message ?? ''}
                          disabled={Boolean(transaction?.journalEntryId)}
                        />
                      );
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="receiptDate"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <DatePicker
                          label="Date"
                          disabled={Boolean(transaction?.journalEntryId)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              id="amount_received_date"
                              error={!!error}
                              helperText={error?.message ?? ''}
                              disabled={Boolean(transaction?.journalEntryId)}
                            />
                          )}
                          onChange={(value) => {
                            onChange({
                              target: {
                                value: value,
                              },
                            });
                          }}
                          value={value ? DateTimeFormat.shortDate(value) : null}
                        />
                      );
                    }}
                    rules={{
                      required: 'Date is required',
                    }}
                    control={control}
                    defaultValue={undefined}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Controller
                    name="cashReceived"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value}
                              value={value}
                              disabled={Boolean(transaction?.journalEntryId)}
                              onChange={(newValue) => {
                                onChange(newValue);
                              }}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Cash Received"
                        />
                      );
                    }}
                    rules={{
                      validate: (value) => {
                        if (amountPaid && receiptDate && value === false) {
                          return 'Cash Received must be checked';
                        }
                        return true;
                      },
                    }}
                    control={control}
                  />
                  {errors.cashReceived && (
                    <Typography variant="errorText">
                      {errors.cashReceived.message}
                    </Typography>
                  )}
                </Grid>
                {transaction?.amountPaid && transaction.receiptDate && (
                  <>
                    <Grid item xs={12} mt={2}>
                      <Typography variant="h4">
                        Receivable Adjustments
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name={`adjustments.${0}.amountPaid`}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <TextField
                              id="txt_adjustment1_amount"
                              label="Adjustment 1 Amount"
                              variant="outlined"
                              fullWidth
                              size="small"
                              disabled={
                                transaction?.adjustments[0] &&
                                !!transaction?.adjustments[0].journalEntryId
                              }
                              value={
                                adjustmentValues[0]?.amountPaid ? value : ''
                              }
                              onChange={(e) => {
                                const newValue: any = parseFloat(
                                  e.target.value
                                ) as number;

                                onChange(newValue);
                              }}
                              onBlur={(e) => {
                                const newValue = parseFloat(
                                  e.target.value.replace(',', '')
                                );

                                if (transaction && newValue) {
                                  if (isNotifyDisabled) {
                                    if (
                                      transaction?.adjustments[0]
                                        ?.amountPaid !== newValue
                                    ) {
                                      setIsNotifyDisabled(false);
                                    }
                                  }

                                  if (
                                    !arkGlLocked &&
                                    transaction &&
                                    isSendToGLDisabled
                                  ) {
                                    if (
                                      transaction?.adjustments[0]
                                        ?.amountPaid !== newValue
                                    ) {
                                      setIsSendToGLChecked(true);
                                      setIsSendToGLDisabled(false);
                                    }
                                  }
                                }
                              }}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              InputLabelProps={{ shrink: !!value }}
                              error={!!error}
                              helperText={error?.message ?? ''}
                            />
                          );
                        }}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name={`adjustments.${0}.adjustmentDate`}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <DatePicker
                              label="Adjustment 1 Date"
                              disabled={
                                transaction?.adjustments[0] &&
                                !!transaction?.adjustments[0].journalEntryId
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  disabled={
                                    transaction?.adjustments[0] &&
                                    !!transaction?.adjustments[0].journalEntryId
                                  }
                                  value={
                                    adjustmentValues[0]?.adjustmentDate
                                      ? value
                                      : ''
                                  }
                                  size="small"
                                  id="adjustment1_date"
                                  error={!!error}
                                  helperText={error?.message ?? ''}
                                />
                              )}
                              onChange={(value) => {
                                onChange({
                                  target: {
                                    value: value,
                                  },
                                });
                              }}
                              value={
                                value ? DateTimeFormat.shortDate(value) : null
                              }
                            />
                          );
                        }}
                        rules={{
                          required:
                            Boolean(
                              adjustmentValues &&
                                adjustmentValues[0] &&
                                adjustmentValues[0].amountPaid
                            ) && 'Date is required',
                        }}
                        control={control}
                        defaultValue={undefined}
                      />
                    </Grid>
                    {adjustmentValues &&
                      adjustmentValues[0] &&
                      adjustmentValues[0].hasOwnProperty('notified') && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <StatusControlLabel
                              label="Notified"
                              labelPlacement="start"
                              control={
                                <StatusLabel
                                  color={
                                    (adjustmentValues[0].notified === false
                                      ? NotifiedStatusType['NO']
                                      : NotifiedStatusType['YES']
                                    ).color
                                  }
                                  isUpperCase={false}
                                  label={
                                    (adjustmentValues[0].notified === false
                                      ? NotifiedStatusType['NO']
                                      : NotifiedStatusType['YES']
                                    ).name ?? M_DASH_UNICODE
                                  }
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}></Grid>
                        </>
                      )}
                  </>
                )}
                {transaction?.adjustments &&
                  transaction?.adjustments.length > 0 && (
                    <>
                      <Grid item xs={12} sm={6} mt={3}>
                        <Controller
                          name={`adjustments.${1}.amountPaid`}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => {
                            return (
                              <TextField
                                id="txt_adjustment2_amount"
                                label="Adjustment 2 Amount"
                                variant="outlined"
                                fullWidth
                                size="small"
                                disabled={
                                  transaction?.adjustments[1] &&
                                  !!transaction?.adjustments[1].journalEntryId
                                }
                                value={value ?? ''}
                                onChange={(e) => {
                                  const newValue: any = parseFloat(
                                    e.target.value
                                  ) as number;

                                  onChange(newValue);
                                }}
                                onBlur={(e) => {
                                  const newValue = parseFloat(
                                    e.target.value.replace(',', '')
                                  );

                                  if (transaction && newValue) {
                                    if (isNotifyDisabled) {
                                      if (
                                        transaction?.adjustments[1]
                                          ?.amountPaid !== newValue
                                      ) {
                                        setIsNotifyDisabled(false);
                                      }
                                    }

                                    if (!arkGlLocked && isSendToGLDisabled) {
                                      if (
                                        transaction?.adjustments[1]
                                          ?.amountPaid !== newValue
                                      ) {
                                        setIsSendToGLChecked(true);
                                        setIsSendToGLDisabled(false);
                                      }
                                    }
                                  }
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                                InputLabelProps={{ shrink: !!value }}
                                error={!!error}
                                helperText={error?.message ?? ''}
                              />
                            );
                          }}
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} mt={3}>
                        <Controller
                          name={`adjustments.${1}.adjustmentDate`}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => {
                            return (
                              <DatePicker
                                label="Adjustment 2 Date"
                                disabled={
                                  transaction?.adjustments[1] &&
                                  !!transaction?.adjustments[0].journalEntryId
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    id="adjustment2_date"
                                    disabled={
                                      transaction?.adjustments[1] &&
                                      !!transaction?.adjustments[1]
                                        .journalEntryId
                                    }
                                    error={!!error}
                                    helperText={error?.message ?? ''}
                                  />
                                )}
                                onChange={(value) => {
                                  onChange({
                                    target: {
                                      value: value,
                                    },
                                  });
                                }}
                                value={
                                  value ? DateTimeFormat.shortDate(value) : null
                                }
                              />
                            );
                          }}
                          rules={{
                            required:
                              Boolean(
                                adjustmentValues &&
                                  adjustmentValues[1] &&
                                  adjustmentValues[1].amountPaid
                              ) && 'Date is required',
                          }}
                          control={control}
                          defaultValue={undefined}
                        />
                      </Grid>
                      {adjustmentValues &&
                        adjustmentValues[1] &&
                        adjustmentValues[1].hasOwnProperty('notified') && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <StatusControlLabel
                                label="Notified"
                                labelPlacement="start"
                                control={
                                  <StatusLabel
                                    color={
                                      (adjustmentValues[1].notified === false
                                        ? NotifiedStatusType['NO']
                                        : NotifiedStatusType['YES']
                                      ).color
                                    }
                                    isUpperCase={false}
                                    label={
                                      (adjustmentValues[1].notified === false
                                        ? NotifiedStatusType['NO']
                                        : NotifiedStatusType['YES']
                                      ).name ?? M_DASH_UNICODE
                                    }
                                  />
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}></Grid>
                          </>
                        )}
                    </>
                  )}

                {!arkGlLocked && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Send to GL"
                      control={
                        <Checkbox
                          id="check_send_to_gl"
                          checked={isSendToGLChecked}
                          value={isSendToGLChecked}
                          onChange={(e) =>
                            setIsSendToGLChecked(e.target.checked)
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      disabled={arkGlLocked || isSendToGLDisabled}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControlLabel
                    label="Notify"
                    control={
                      <Checkbox
                        id="check_notify"
                        checked={isNotifyChecked}
                        value={isNotifyChecked}
                        onChange={(e) => setIsNotifyChecked(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    disabled={isNotifyDisabled}
                  />
                </Grid>
              </Grid>
            </Panel>
          </FormBox>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
          >
            <Button
              id={'btn_cash_receipt_cancel'}
              variant="outlined"
              onClick={toggleDrawer}
              text={'Cancel'}
              color={'secondary'}
              fullWidth
              addSpaceBetweenButtons
            />
            <Button
              id={'btn_cash_receipt_save'}
              variant="contained"
              text={'Save & Close'}
              color={'primary'}
              type="submit"
              fullWidth
              addSpaceBetweenButtons
            />
          </HorizontalBox>
        </form>
        <StyledBackdrop open={Boolean(isLoading)} />
        <ProgressPanel
          id={'progress_cash_receipt_panel'}
          showProgress={Boolean(isLoading)}
          text={isLoading ? `${isLoading}...` : ''}
        />
      </DetailPanel>
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_cash_receipt_confirmation"
        actions={[
          {
            label: 'Keep Editing',
            onClick: keepDrawerOpen,
            id: 'btn_cash_receipt_save',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Discard Changes',
            onClick: closeDrawer,
            id: 'btn_cash_receipt_cancel_save',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content="Unsaved changes will be lost"
        title="You have Unsaved Changes"
      />
    </>
  );
};
