import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';

import { DataWrapperBox } from '../../../components/Boxes/DataWrapperBox/DataWrapperBox.style';
import Button from '../../../components/Buttons/Button/Button';
import SplitButton from '../../../components/Buttons/SplitButton/SplitButton';
import DataGrid from '../../../components/DataGrid/DataGrid';
import { MultiJEViewer } from '../../../components/GL/MultiJEViewer/MultiJEViewer';
import ConfirmationDialog from '../../../components/Modal/ConfirmationDialog';
import { SaveExitModal } from '../../../components/Modal/SaveExitModal/SaveExitModal';
import ProgressModal from '../../../components/Progress/ProgressModal/ProgressModal';
import SearchBar from '../../../components/SearchBar/SearchBar';
import SingleSelect from '../../../components/SingleSelect/SingleSelect';
import RoutingPaths from '../../../core/routing/routingPaths';
import { JournalEntryDetails } from '../../arkGL/journalEntries/journalEntryDetails/JournalEntryDetails';
import MailingsComposerPanel from '../../generalMailings/mailingsComposerPanel/MailingsComposerPanel';
import { ViewType } from '../../generalMailings/mailingsComposerPanel/MailingsComposerPanel.constants';
import { CashReceiptDetails } from '../cashReceiptDetails/CashReceiptDetails';
import { CashReceiptDetailsReadOnly } from '../cashReceiptDetails/CashReceiptDetailsReadOnly';
import { useCashReceipts } from './CashReceipts.hooks';
import {
  ButtonBox,
  CheckboxContent,
  CheckboxWrapper,
  DialogBox,
  FormControlCheckbox,
  HeaderRow,
  LinkBox,
  SelectBox,
  TitleBox,
  TitleRow,
} from './CashReceipts.styles';

export const CashReceipts: React.FC = () => {
  const history = useHistory();
  const {
    isLoading,
    isDirty,
    capitalCallList,
    selectedCapitalCall,
    setSelectedCapitalCall,
    headerList,
    activeHeaderFields,
    transactionFilteredList,
    transactionSelectionModel,
    setTransactionSelectionModel,
    search,
    searchOptions,
    handleSearch,
    handleUpdateHeader,
    onColumnOrderChange,
    handleFilter,
    showSuggestionPopover,
    setShowSuggestionPopover,
    bulkActionOptions,
    handleBulkAction,
    handleBulkSave,
    handleBulkDiscardChanges,
    showSaveConfirmation,
    saveCancel,
    saveConfirm,
    isSendToGLAvailable,
    isSendToGLChecked,
    isNotifyChecked,
    setIsNotifyChecked,
    setIsSendToGLChecked,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
    csvLinkRef,
    csvHeaders,
    csvData,
    csvFilename,
    resetCsvData,
    errorSendToGL,
    setErrorSendToGL,
    errorTitleSendToGL,
    setErrorTitleSendToGL,
    errorEmptyFields,
    setErrorEmptyFields,
    selectedMailing,
    setSelectedMailing,
    onJournalEntryPostClose,
    onTransactionDetailPanelClose,
    handleOnView,
    setNotifyList,
    selectedTransaction,
    readonly,
    fetchCapitalCallTransactions,
    notify,
    setSelectedJEList,
    selectedJEList,
    setErrorNotify,
    errorNotify,
    errorNotifyActions,
    notifyRest,
    notifyList,
    noticePostSaveDraft,
    setNoticePostSaveDraft,
    onMailingDraftPostClose,
    continueToNotify,
    noticeContinueToNotify,
    setNoticeContinueToNotify,
  } = useCashReceipts();

  return (
    <DataWrapperBox id="cash_receipts_list_main" role="main">
      <ProgressModal
        id="modal_cash_receipts_loading"
        showProgress={Boolean(isLoading)}
      />
      <HeaderRow>
        <TitleRow>
          <TitleBox>
            <Typography variant="pageTitle">Cash Receipts</Typography>
          </TitleBox>
          <SearchBar
            id="cash_receipts_transactions_search"
            size="small"
            onChange={handleSearch}
            options={searchOptions}
            searchText={search}
            showPopover={showSuggestionPopover}
            setShowPopover={setShowSuggestionPopover}
          />
          <ButtonBox>
            {transactionFilteredList &&
              transactionFilteredList.length > 0 &&
              isDirty && (
                <>
                  <Button
                    id="btn_bulk_save"
                    variant="contained"
                    text={'Save'}
                    color={'primary'}
                    onClick={handleBulkSave}
                  />
                  <Button
                    id="btn_bulk_discard_changes"
                    variant="contained"
                    text={'Discard Changes'}
                    color={'primary'}
                    onClick={handleBulkDiscardChanges}
                  />
                </>
              )}
            {selectedCapitalCall && (
              <SplitButton
                id={'btn_bulk_action_options'}
                options={bulkActionOptions}
                hidden={false}
                handleOptionClick={handleBulkAction}
                ariaLabelMessage="Select bulk action option"
              />
            )}
          </ButtonBox>
        </TitleRow>
        <SelectBox>
          {capitalCallList && capitalCallList.length > 0 && (
            <SingleSelect
              id="capital_call_select"
              value={selectedCapitalCall?.id ?? ''}
              optionList={capitalCallList}
              label={'Select Capital Call'}
              isSearchable
              fullWidth
              idField="id"
              labelField="label"
              handleOptionSelection={(e: any, value: any) => {
                const capitalCall = capitalCallList?.find(
                  (c) => c.id === value?.id
                );

                setSelectedCapitalCall(capitalCall);
              }}
            />
          )}
        </SelectBox>
      </HeaderRow>
      {selectedCapitalCall && (
        <DataGrid
          id="cash_receipts_transaction_data_grid"
          dataList={
            !isLoading && transactionFilteredList?.length > 0
              ? transactionFilteredList
              : []
          }
          minHeight="1vh"
          autoHeight={false}
          headerList={headerList}
          handleOnView={handleOnView}
          selectionModel={transactionSelectionModel}
          activeHeaderFields={activeHeaderFields}
          setSelectionModel={setTransactionSelectionModel}
          handleUpdateHeader={handleUpdateHeader}
          handleFilter={handleFilter}
          onColumnOrderChange={onColumnOrderChange}
        />
      )}
      {!!selectedTransaction?.type &&
        (readonly ? (
          <CashReceiptDetailsReadOnly
            selectedTransaction={selectedTransaction}
            onDetailClose={onTransactionDetailPanelClose}
            fetchCapitalCallTransactions={fetchCapitalCallTransactions}
            arkGlLocked={!isSendToGLAvailable}
            setSelectedJournalEntry={() => {}}
            onJournalEntryPanelClose={() => {}}
            onJournalEntrySuccessfulPostClose={() => {}}
            setSelectedMailing={() => {}}
            setNotifyList={() => {}}
            isNotifyChecked={false}
            setIsNotifyChecked={() => {}}
            isSendToGLChecked={false}
            setIsSendToGLChecked={() => {}}
            notify={() => {}}
            selectedCapitalCall={selectedCapitalCall}
            setSelectedJEList={() => {}}
          />
        ) : (
          <CashReceiptDetails
            selectedTransaction={selectedTransaction}
            onDetailClose={onTransactionDetailPanelClose}
            fetchCapitalCallTransactions={fetchCapitalCallTransactions}
            arkGlLocked={!isSendToGLAvailable}
            setSelectedJournalEntry={setSelectedJournalEntry}
            onJournalEntryPanelClose={onJournalEntryPanelClose}
            onJournalEntrySuccessfulPostClose={onJournalEntryPostClose}
            setSelectedMailing={setSelectedMailing}
            setNotifyList={setNotifyList}
            isNotifyChecked={isNotifyChecked}
            setIsNotifyChecked={setIsNotifyChecked}
            isSendToGLChecked={isSendToGLChecked}
            setIsSendToGLChecked={setIsSendToGLChecked}
            notify={notify}
            selectedCapitalCall={selectedCapitalCall}
            setSelectedJEList={setSelectedJEList}
          />
        ))}

      <SaveExitModal
        onActionClick={handleBulkSave}
        isBlocked={isDirty}
        title="Leave Page?"
        content="Your changes have not been saved. Are you sure you want to leave this page?"
        actionTitle="Save changes"
        confirmNavigationTitle="Exit Without Saving"
      />
      <ConfirmationDialog
        actionDisplayType="inline"
        isTextContent={false}
        open={showSaveConfirmation}
        onClose={saveCancel}
        id="save_confirmation"
        actions={[
          {
            label: 'Cancel',
            onClick: saveCancel,
            id: 'btn_save_cancel',
            variant: 'outlined',
            color: 'error',
          },
          {
            label: 'Save',
            onClick: saveConfirm,
            id: 'btn_save_confirm',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        content={
          <>
            <Typography>Confirm actions to take upon saving.</Typography>
            <CheckboxWrapper>
              {isSendToGLAvailable && (
                <>
                  <FormControlCheckbox>
                    <FormControlLabel
                      label="Send to GL"
                      control={
                        <Checkbox
                          id="check_send_to_gl"
                          checked={isSendToGLChecked}
                          value={isSendToGLChecked}
                          onChange={(e) =>
                            setIsSendToGLChecked(e.target.checked)
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      disabled={!isSendToGLAvailable}
                    />
                  </FormControlCheckbox>
                  <CheckboxContent>
                    A journal entry will be generated with debit and credit
                    amounts. Select GL accounts, review, and save to the General
                    Ledger.
                  </CheckboxContent>
                </>
              )}
              <FormControlCheckbox>
                <FormControlLabel
                  label="Notify Investors"
                  control={
                    <Checkbox
                      id="check_notify"
                      checked={isNotifyChecked}
                      value={isNotifyChecked}
                      onChange={(e) => setIsNotifyChecked(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
              </FormControlCheckbox>
              <CheckboxContent>
                Send an email notification to investors confirming the cash
                receipt. A draft General Mailing with a Receipt Notification
                template will be available to review, save and send within
                General Mailings.
              </CheckboxContent>
            </CheckboxWrapper>
          </>
        }
        title="Save changes"
      />
      <ConfirmationDialog
        open={Boolean(errorSendToGL)}
        id="error_gl"
        actions={[
          {
            label: 'OK',
            onClick: () => {
              setErrorSendToGL(false);
              setErrorTitleSendToGL('');
            },
            id: 'btn_close_error_gl',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        title={`Unable to ${errorTitleSendToGL}`}
        content={
          <Typography>{`Entries must have matching dates and may not contain adjustments or associated journal entries to ${errorTitleSendToGL} in bulk.`}</Typography>
        }
      />
      <ConfirmationDialog
        open={Boolean(errorNotify)}
        id="error_notify"
        actions={errorNotifyActions}
        title="Notification Limitation:"
        content={errorNotify}
      />
      <ConfirmationDialog
        open={Boolean(errorEmptyFields)}
        id="error_not_saved"
        actions={[
          {
            label: 'OK',
            onClick: () => setErrorEmptyFields(false),
            id: 'btn_close_not_saved',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        title="Unable to Send to GL or Notify"
        content={
          <DialogBox>
            The following fields must be saved:
            <ul>
              <li>Amount</li>
              <li>Paid Cash Received</li>
              <li>Date Received</li>
            </ul>
          </DialogBox>
        }
      />
      <ConfirmationDialog
        open={Boolean(noticePostSaveDraft)}
        id="notice_post_save_draft"
        actions={[
          {
            label: 'Stay on Cash Receipts Page',
            onClick: () => setNoticePostSaveDraft(false),
            id: 'btn_stay_current_page',
            variant: 'outlined',
            color: 'primary',
          },
          {
            label: 'Go to General Mailings',
            onClick: () => {
              setNoticePostSaveDraft(false);
              history.push(RoutingPaths.GeneralMailings);
            },
            id: 'btn_navigage_to_mailings',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        title="Draft Email Notice"
        content={
          <Typography>
            An email draft has been saved. Navigate to General Mailings to
            review and send the email.
          </Typography>
        }
      />
      <ConfirmationDialog
        open={noticeContinueToNotify}
        id="notice_continue_to_notify"
        actionDisplayType="inline"
        isTextContent={false}
        actions={[
          {
            label: 'Cancel and Return to Cash Receipts',
            onClick: () => {
              setNoticeContinueToNotify(false);
              fetchCapitalCallTransactions();
            },
            id: 'btn_cancel_return',
            variant: 'outlined',
            color: 'primary',
          },
          {
            label: 'Continue to Email Draft',
            onClick: () => continueToNotify(),
            id: 'btn_continue_to_draft',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        title="Journal Entry Cancelled"
        content={
          <Typography>
            The creation of a journal entry has been cancelled. Would you like a
            draft email to be created?
          </Typography>
        }
      />
      {!!selectedJournalEntry?.type && (
        <JournalEntryDetails
          selectedJournalEntry={selectedJournalEntry}
          setSelectedJournalEntry={setSelectedJournalEntry}
          fetchAllJournalEntries={() => {}}
          onDetailClose={onJournalEntryPanelClose}
          onSuccessfulPostClose={onJournalEntryPostClose}
          fundId={selectedJournalEntry.journalEntry?.fundId}
        />
      )}
      {!!selectedJEList && (
        <MultiJEViewer
          list={selectedJEList}
          onClose={onJournalEntryPanelClose}
          onSuccessfulPostClose={onJournalEntryPostClose}
          selectedJournalEntry={selectedJournalEntry}
          setSelectedJournalEntry={setSelectedJournalEntry}
        />
      )}
      <MailingsComposerPanel
        setView={() => {}}
        selectedMailingId={''}
        setSelectedMailingId={() => {}}
        selectedMailing={selectedMailing}
        setSelectedMailing={setSelectedMailing}
        fetchAllGeneralMailings={() => {}}
        view={selectedMailing?.view}
        onSuccessfulPostClose={onMailingDraftPostClose}
      />
      <LinkBox>
        <CSVLink
          ref={csvLinkRef}
          headers={csvHeaders}
          data={csvData}
          filename={csvFilename}
          onClick={resetCsvData}
        />
      </LinkBox>
    </DataWrapperBox>
  );
};
