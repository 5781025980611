import { Typography } from '@mui/material';
import {
  GridAlignment,
  GridCallbackDetails,
  GridColumnOrderChangeParams,
  GridSortModel,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import DeleteIcon from '../../../../assets/images/icons/icon_delete.svg';
import ExportIcon from '../../../../assets/images/icons/icon_export.svg';
import StatusLabel from '../../../../components/StatusLabel/StatusLabel';
import { AppContext } from '../../../../core/context/appContextProvider';
import useRole from '../../../../core/routing/useRole';
import {
  deleteBulkJournalEntries,
  deleteBulkJournalEntriesPreview,
  exportAllJournalEntries,
  exportSelectedJournalEntries,
  getAllEntities,
  getArkAccountNames,
  getArkLedgers,
  searchAllJournalEntries,
} from '../../../../services/arkGL.service';
import {
  getColumnOrder,
  saveColumnOrder,
} from '../../../../services/columnOrder.service';
import { getFundNames } from '../../../../services/fund.service';
import { uploadFile } from '../../../../services/uploads.service';
import {
  DELETE_ACTION_TYPE,
  POST_ACTION_TYPE,
} from '../../../../utils/constants/common.constants';
import {
  API_DATE_FORMAT,
  M_DASH_UNICODE,
} from '../../../../utils/constants/constants';
import { capitalize } from '../../../../utils/helpers/capitalize';
import {
  arrayIndexUpdate as updateArrayIndex,
  arrayVisibilityUpdate,
} from '../../../../utils/helpers/columnOrder.helper';
import downloadFile from '../../../../utils/helpers/fileDownloader';
import {
  CurrencyFormat,
  DateTimeFormat,
} from '../../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../../utils/hooks/useEffectAsync.hook';
import {
  ArkGLJournalEntry,
  SelectedJournalEntry,
} from '../../../../utils/types/arkGLJournalEntry.type';
import { ColumnOrder } from '../../../../utils/types/columnOrder';
import { AddNewButtonOptions } from '../../../../utils/types/common.type';
import { DetailsType } from '../../../../utils/types/form.type';
import {
  CustomType,
  DataGridColDef,
  ImageItem,
} from '../../../../utils/types/listItems';
import { ScopeRole } from '../../../../utils/types/user.type';
import { GET_GL_ACCOUNT_LIST_ERROR } from '../../accounts/accountList/AccountList.constants';
import {
  DELETE_ALL_ERROR,
  DELETE_BULK_ERROR,
  DELETE_BULK_SUCCESS,
  DELETE_SELECTED_ERROR,
  DOWNLOAD_TEMPLATE_ERROR,
  EXPORT_ALL_ERROR,
  EXPORT_ALL_SUCCESS,
  EXPORT_SELECTED_ERROR,
  EXPORT_SELECTED_SUCCESS,
  GET_JOURNAL_ENTRY_LIST_ERROR,
  GET_VIEW_LIST_ERROR,
  JOURNAL_ENTRY_LIST_COLUMN_ORDER_VIEW_KEY,
  JournalEntryAction,
  JournalEntryFilter,
  POST_SELECTED_ERROR,
  SELECTED_POSTED_ERROR,
  UPLOAD_JOURNAL_ENTRY_ERROR,
  UPLOAD_JOURNAL_ENTRY_SUCCESS,
} from './JournalEntryList.constants';
import { defaultHeaderList } from './JournalEntryList.defaultHeaders';

const initialJournalEntry: SelectedJournalEntry = {
  journalEntry: undefined,
  type: undefined,
};

const StatusType = {
  draft: 'yellow',
  posted: 'green',
};

const statusLabelOptions: any = [
  {
    id: 'draft',
    name: 'Draft',
  },
  {
    id: 'posted',
    name: 'Posted',
  },
];

const rowCount = 100;

export const useJournalEntryList = () => {
  const { fundId } = useParams<{ fundId: string }>();
  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;
  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);
  const { hasRole: isSuperAdminOrClientAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);
  const readonly: boolean = !!isFundAdmin;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultHeaderList.length - 1
  );
  const [journalEntryResponse, setJournalEntryResponse] = useState<any>([]);
  const [preppedEntryData, setPreppedEntryData] = useState<any>([]);
  const [journalEntryList, setJournalEntryList] = useState<ArkGLJournalEntry[]>(
    []
  );
  const [journalEntryFilteredList, setJournalEntryFilteredList] = useState<
    any[]
  >([]);
  const [journalEntrySelectionModel, setJournalEntrySelectionModel] = useState(
    []
  );
  const [search, setSearch] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const [showSuggestionPopover, setShowSuggestionPopover] = useState(false);
  const [selectedJournalEntry, setSelectedJournalEntry] =
    useState<SelectedJournalEntry>();
  const [accountListResponse, setAccountListResponse] = useState([]);
  const [fundFiltersResponse, setFundFiltersResponse] = useState<any>([]);
  const [entitiesListResponse, setEntitiesListResponse] = useState([]);
  const [ledgerListResponse, setLedgerListResponse] = useState([]);
  const [fundList, setFundList] = useState<any[]>([]);
  const [statusList, setStatusList] = useState<any[]>([]);
  const [accountList, setAccountList] = useState<any[]>([]);
  const [entityList, setEntityList] = useState<any[]>([]);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const [ledgerList, setLedgerList] = useState<any[]>([]);
  const [deletedFile, setDeletedFile] = useState<boolean>(false);
  const [showPostDialog, setShowPostDialog] = useState<boolean>(false);
  const [journalEntriesToPost, setJournalEntriesToPost] = useState<string[]>(
    []
  );
  const [journalEntriesToDelete, setJournalEntriesToDelete] = useState<
    string[]
  >([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [pageCountState, setPageCountState] = useState(1);
  const [pageSizeState, setPageSizeState] = useState(rowCount);
  const [fundFilterIds, setFundFilterIds] = useState<any>([]);
  const [ledgerFilterIds, setLedgerFilterIds] = useState<any>([]);
  const [accountFilterIds, setAccountFilterIds] = useState<any>([]);
  const [entityFilterIds, setEntityFilterIds] = useState<any>([]);
  const [statusFilterIds, setStatusFilterIds] = useState<any>([]);
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [showExportCsvConfirm, setShowExportCsvConfirm] = useState(false);
  const [deleteAction, setDeleteAction] = useState<
    DELETE_ACTION_TYPE | undefined
  >();
  const [postAction, setPostAction] = useState<POST_ACTION_TYPE | undefined>();
  const [postFiltersRequest, setPostFiltersRequest] = useState<any>();
  const [deleteCount, setDeleteCount] = useState<number>();
  const [deleteError, setDeleteError] = useState<string | undefined>();
  const [filterToggleState, setFilterToggleState] = useState(false);
  const [journalColumnOrder, setJournalColumnOrder] =
    useState<ColumnOrder | null>(null);

  const [activeAccountFilter, setActiveAccountFilter] = useState(false);
  const [activeEntityFilter, setActiveEntityFilter] = useState(false);

  useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  useEffectAsync(async () => {
    if (fundList.length && ledgerList.length && entityList.length) {
      await searchFilteredList();
    }
  }, [fundList, ledgerList, entityList]);

  useEffect(() => {
    createStatusFilter();
    setSortModel([
      { field: 'status', sort: 'asc' },
      { field: 'date', sort: 'desc' },
      { field: 'glName', sort: 'asc' },
      { field: 'fundName', sort: 'asc' },
      { field: 'journalNumber', sort: 'desc' },
    ]);
  }, []);

  useEffectAsync(async () => {
    if (journalEntryResponse.length) {
      await searchFilteredList();
    }
  }, [pageCountState, filterToggleState]);

  const handlePageChange = (page: any, details: any) => {
    setPageCountState(page + 1);
  };

  const searchFilteredList = async (isCanceled?: () => boolean) => {
    setIsLoading(true);
    try {
      const reqBody: any = {
        page: pageCountState,
        pageSize: pageSizeState,
        sort: [
          {
            name: 'state',
            descending: false,
          },
          {
            name: 'date',
            descending: true,
          },
        ],
      };

      if (typeof fundId === 'string') {
        reqBody.fundIds = [fundId];
      }

      if (
        fundFilterIds.length > 0 &&
        fundFilterIds.length !== fundList.length
      ) {
        reqBody.fundIds = fundFilterIds;
      }
      if (
        ledgerFilterIds.length > 0 &&
        ledgerFilterIds.length !== ledgerList.length
      ) {
        reqBody.ledgerIds = ledgerFilterIds;
      }
      if (
        entityFilterIds.length > 0 &&
        entityFilterIds.length !== entityList.length
      ) {
        const updatedIds = entityFilterIds.map((id: any) => {
          if (typeof id === 'undefined') {
            return null;
          } else {
            return id;
          }
        });

        reqBody.entityIds = updatedIds;
        setActiveEntityFilter(true);
      } else {
        setActiveEntityFilter(false);
      }
      if (
        accountFilterIds.length > 0 &&
        accountFilterIds.length !== accountList.length
      ) {
        reqBody.accountIds = accountFilterIds;
        setActiveAccountFilter(true);
      } else {
        setActiveAccountFilter(false);
      }
      if (
        statusFilterIds.length > 0 &&
        statusFilterIds.length !== statusList.length
      ) {
        reqBody.journalEntryState = statusFilterIds[0].toUpperCase();
      }
      if (startDateFilter) {
        reqBody.startDate = startDateFilter;
      }
      if (endDateFilter) {
        reqBody.endDate = endDateFilter;
      }

      const allResponse = await searchAllJournalEntries(reqBody);

      if (allResponse.items.length === 0) {
        setJournalEntryList([]);
      }
      if (allResponse.items.length !== 0) {
        setJournalEntryResponse(allResponse.items);
      }
      setRowCountState(allResponse.pageInfo.totalPages * 2);
      if (isCanceled?.()) return;
      setIsLoading(false);
    } catch (e) {
      informationAlert(GET_JOURNAL_ENTRY_LIST_ERROR, 'error');
    }
  };

  const fetchAllLedgers = async (isCanceled?: () => boolean) => {
    try {
      if (fundId) {
        const response = await getArkLedgers(fundId);

        const ledgerListData: any = [];

        response.items.map((ledger: any) => {
          ledgerListData.push({
            id: ledger.name,
            name: ledger.name,
            ledgerId: ledger.id,
          });
        });

        setLedgerList(ledgerListData);

        setLedgerListResponse(response.items);
      } else {
        const allResponse = await getArkLedgers();

        const ledgerListData: any = [];

        allResponse.items.map((ledger: any) => {
          ledgerListData.push({
            id: ledger.name,
            name: ledger.name,
            ledgerId: ledger.id,
          });
        });

        setLedgerList(ledgerListData);

        setLedgerListResponse(allResponse.items);
      }

      if (isCanceled?.()) return;
    } catch (e) {
      informationAlert(GET_JOURNAL_ENTRY_LIST_ERROR, 'error');
    }
  };

  const fetchEntitiesList = async (isCanceled?: () => boolean) => {
    try {
      const response = await getAllEntities();

      const entityList: any = [];

      if (response.items.length >= 1) {
        response.items.map((item: any) => {
          if (item.type === 'PortfolioCompany') {
            item.type = 'Portfolio Company';
          }
          if (item.type === 'GeneralEntity') {
            item.type = 'General Entity';
          }

          entityList.push({
            id: `${item?.name} -  ${item?.type}`,
            name: `${item?.name} -  ${item?.type}`,
            entityId: item?.id,
          });
        });
      }

      entityList.push({
        id: 'None',
        name: 'None',
        entityId: undefined,
      });

      setEntityList(entityList);
      setEntitiesListResponse(response.items);
      if (isCanceled?.()) return;
    } catch (e) {
      informationAlert(GET_GL_ACCOUNT_LIST_ERROR, 'error');
    }
  };

  const fetchFundFilters = async (isCanceled?: () => boolean) => {
    try {
      const fundNames: any = await getFundNames();

      if (isCanceled?.()) return;
      const fundList: any = [];

      fundNames.map((item: any) => {
        fundList.push({ id: item.name, name: item.name, fundId: item.id });
      });

      if (fundId) {
        const filteredList = fundList.filter(
          (fund: any) => fund.fundId === fundId
        );

        setFundList(filteredList);
      } else {
        setFundList(fundList);
      }

      setFundFiltersResponse(fundNames);
    } catch (e) {
      informationAlert(GET_JOURNAL_ENTRY_LIST_ERROR, 'error');
    }
  };

  const fetchAccountFilters = async (isCanceled?: () => boolean) => {
    try {
      const accountNames: any = await getArkAccountNames();

      if (isCanceled?.()) return;

      const updatedAccountNames: any = [];
      const accountList: any = [];

      accountNames.items.map((account: any) => {
        fundList.forEach((fund: any) => {
          if (fund.fundId === account.fundId) {
            account.fundName = fund.name;
            updatedAccountNames.push(account);
          }
        });
      });

      if (fundId) {
        updatedAccountNames.map((item: any) => {
          accountList.push({
            id: `${item.name}`,
            name: `${item.name}`,
            accountId: item.id,
            fundId: item.fundId,
          });
        });

        const filteredList = accountList.filter(
          (account: any) => account.fundId === fundId
        );

        setAccountList(filteredList);
        setAccountListResponse(filteredList);
      } else {
        updatedAccountNames.map((item: any) => {
          accountList.push({
            id: `${item.name} - ${item.fundName}`,
            name: `${item.name} - ${item.fundName}`,
            accountId: item.id,
            fundId: item.fundId,
          });
        });
        setAccountList(accountList);
        setAccountListResponse(accountList);
      }
    } catch (e) {
      informationAlert(GET_JOURNAL_ENTRY_LIST_ERROR, 'error');
    }
  };

  const fetchColumnOrder = async (isCanceled?: () => boolean) => {
    try {
      const columnOrderResponse = await getColumnOrder(
        JOURNAL_ENTRY_LIST_COLUMN_ORDER_VIEW_KEY,
        clientId
      );

      if (isCanceled?.()) return;

      setJournalColumnOrder(columnOrderResponse || null);
    } catch (e) {
      informationAlert(GET_VIEW_LIST_ERROR, 'error');
    }
  };

  useEffectAsync(async (isCanceled) => {
    setIsLoading(true);
    await fetchFundFilters(isCanceled);
    await fetchEntitiesList(isCanceled);
    await fetchAllLedgers(isCanceled);
    await fetchColumnOrder(isCanceled);
  }, []);

  useEffectAsync(
    async (isCanceled) => {
      if (fundList.length) {
        await fetchAccountFilters(isCanceled);
      }
    },
    [fundList]
  );

  const createStatusFilter = () => {
    const setFilter = statusLabelOptions.map((item: any) => item);

    setStatusList(setFilter);
  };

  const selectedFundList = useMemo(
    () => fundList?.map((item: any) => item.id),
    [fundList]
  );

  const selectedAccountList = useMemo(
    () => accountList?.map((item: any) => item.id),
    [accountList]
  );

  const selectedEntityList = useMemo(
    () => entityList?.map((item: any) => item.id),
    [entityList]
  );

  const selectedStatusList = useMemo(
    () => statusList?.map((item: any) => item.id),
    [statusList]
  );

  const selectedLedgerList = useMemo(
    () => ledgerList?.map((item: any) => item.id),
    [ledgerList]
  );

  useEffect(() => {
    if (journalEntryResponse.length) {
      const prepped = prepEntryListItems(journalEntryResponse);

      setJournalEntryList(prepped);
      setPreppedEntryData(prepped);
    }
  }, [journalEntryResponse]);

  useEffect(() => {
    if (
      preppedEntryData.length &&
      fundList.length &&
      entityList.length &&
      ledgerList.length &&
      accountList.length
    ) {
      const mapped: any = journalEntryList.map((entry: any) => {
        const fund: any = fundList.find(
          (fund: any) => fund.fundId === entry.fundId
        );

        const ledger: any = ledgerList.find(
          (ledger: any) => ledger.ledgerId === entry.ledgerId
        );

        entry.lineItems.ledgerName = ledger?.name;
        entry.lineItems.fundName = fund?.name;
        return entry;
      });

      if (activeEntityFilter || activeAccountFilter) {
        if (activeEntityFilter === true && activeAccountFilter === false) {
          const result: any = [];

          const updatedIds = entityFilterIds.map((id: any) => {
            if (typeof id === 'undefined') {
              return null;
            } else {
              return id;
            }
          });

          updatedIds.map((id: any) => {
            const entry = mapped.filter(
              (entry: any) => entry.lineItems.entityId === id
            );

            result.push(entry);
          });

          if (result.length > 1) {
            const filteredResult: any = [];

            result.map((array: any) => {
              if (array.length) {
                array.map((entry: any) => {
                  filteredResult.push(entry);
                });
              }
            });
            setJournalEntryList(filteredResult);
          } else {
            setJournalEntryList(result[0]);
          }
        }
        if (activeAccountFilter === true && activeEntityFilter === false) {
          const result: any = [];

          accountFilterIds.map((id: any) => {
            const entry = mapped.filter(
              (entry: any) => entry.lineItems.accountId === id
            );

            result.push(entry);
          });

          if (result.length > 1) {
            const filteredResult: any = [];

            result.map((array: any) => {
              if (array.length) {
                array.map((entry: any) => {
                  filteredResult.push(entry);
                });
              }
            });
            setJournalEntryList(filteredResult);
          } else {
            setJournalEntryList(result[0]);
          }
        }
        if (activeEntityFilter && activeAccountFilter) {
          const result: any = [];

          const updatedIds = entityFilterIds.map((id: any) => {
            if (typeof id === 'undefined') {
              return null;
            } else {
              return id;
            }
          });

          updatedIds.map((id: any) => {
            const entry = mapped.filter(
              (entry: any) => entry.lineItems.entityId === id
            );

            result.push(entry);
          });

          const filteredEntities: any = [];

          result.map((array: any) => {
            if (array.length) {
              array.map((entry: any) => {
                filteredEntities.push(entry);
              });
            }
          });

          const newList: any = [];

          accountFilterIds.map((id: any) => {
            const entry = filteredEntities.filter(
              (entry: any) => entry.lineItems.accountId === id
            );

            newList.push(entry);
          });

          const filteredBoth: any = [];

          newList.map((array: any) => {
            if (array.length) {
              array.map((entry: any) => {
                filteredBoth.push(entry);
              });
            }
          });

          setJournalEntryList(filteredBoth);
        }
      } else {
        setJournalEntryList(mapped);
      }
    }
  }, [
    preppedEntryData,
    fundFiltersResponse,
    entitiesListResponse,
    ledgerListResponse,
    accountListResponse,
  ]);

  const prepEntryListItems = (entryResponse: any) => {
    const updatedData: any = [];

    entryResponse.map((journal: any) => {
      const j = { ...journal };

      j.journalId = j.id;
      if (journal.lineItems.length > 1) {
        let counter = 1;

        journal.lineItems.forEach((lineItem: any) => {
          j.lineItems = lineItem;
          const updatedObj = { ...j };

          updatedObj.id = j.id + '-' + counter;
          counter++;
          updatedData.push(updatedObj);
        });
      } else {
        updatedData.push(j);
      }
    });

    return updatedData;
  };

  useEffect(() => {
    setJournalEntryFilteredList(journalEntryList);
  }, [headerList, journalEntryList]);

  useEffect(() => {
    if (fundList && entityList && ledgerList && accountList) {
      initializeHeaderList();
    }
  }, [fundList, entityList, ledgerList, accountList, journalColumnOrder]);

  const handleOnView = (
    journalEntryId: string,
    journalEntry: ArkGLJournalEntry
  ) => {
    const originalJournalEntry = journalEntryResponse.find(
      (j: any) => j.id === journalEntry.journalId || j.id === journalEntry.id
    );

    setSelectedJournalEntry({
      journalEntry: { ...originalJournalEntry },
      type: DetailsType.Edit,
    });
  };

  const onDetailsPanelClose = () => {
    setSelectedJournalEntry(initialJournalEntry);
  };

  const handleNewButtonAction = (actionId: string, event: any) => {
    switch (actionId) {
      case AddNewButtonOptions.AddNew:
        {
          setSelectedJournalEntry({
            journalEntry: undefined,
            type: DetailsType.New,
          });
        }
        break;
      case AddNewButtonOptions.UploadFromTemplate:
        {
          const file = event?.target?.files?.[0];

          if (file) {
            setUploadedFile(file);
          }
        }
        break;
      case AddNewButtonOptions.DownloadTemplate: {
        handleTemplateDownload();
      }
    }
  };

  useEffect(() => {
    if (journalEntryList.length) {
      initializeSearchOptions(journalEntryList);
    }
  }, [journalEntryList]);

  const initializeSearchOptions = (response: any[]) => {
    const options: string[] = [];

    if (response.length > 0) {
      response.forEach((journalEntry) => {
        if (journalEntry.lineItems.fundName) {
          options.push(journalEntry?.lineItems?.fundName);
        }
        if (journalEntry.lineItems.entities) {
          journalEntry?.lineItems?.entities.map((e: any) => {
            if (e.name) {
              options.push(e.name);
            }
          });
        }
        if (journalEntry.number) {
          options.push(journalEntry?.number.toString());
        }
        if (journalEntry.lineItems.accountName) {
          options.push(journalEntry?.lineItems?.accountName);
        }
        if (journalEntry.lineItems.ledgerName) {
          options.push(journalEntry?.lineItems.ledgerName);
        }
      });
    }

    const uniqueOptions = options.filter(
      (option, idx) => options.indexOf(option) === idx
    );

    setSearchOptions(uniqueOptions);
  };

  const searchEntryList = (searchText: string) => {
    if (journalEntryList) {
      if (searchText === '') {
        setJournalEntryList(journalEntryList);
      } else if (typeof searchText === 'string') {
        const entryList: any = [...journalEntryList];

        const updatedEntryList: any = [];

        entryList.forEach((entry: any) => {
          if (entry.lineItems.fundName) {
            if (entry.lineItems.fundName.match(new RegExp(searchText, 'i'))) {
              updatedEntryList.push(entry);
            }
          }

          if (entry.lineItems.entityName) {
            if (entry.lineItems.entityName.match(new RegExp(searchText, 'i'))) {
              updatedEntryList.push(entry);
            }
          }
          if (entry.number) {
            if (entry.number.toString().match(new RegExp(searchText, 'i'))) {
              updatedEntryList.push(entry);
            }
          }
          if (entry.lineItems.accoutName) {
            if (
              entry.lineItems.accountName.match(new RegExp(searchText, 'i'))
            ) {
              updatedEntryList.push(entry);
            }
          }
          if (entry.lineItems.ledgerName) {
            if (entry.lineItems.ledgerName.match(new RegExp(searchText, 'i'))) {
              updatedEntryList.push(entry);
            }
          }
        });

        setJournalEntryList(updatedEntryList);
      }
    }
  };

  useEffect(() => {
    searchEntryList(search);
    if (search === '' && journalEntryResponse.length) {
      setJournalEntryList(preppedEntryData);
    }
  }, [search]);

  const onSearch = (
    event: any,
    newValue: React.SetStateAction<string> | null
  ) => {
    if (typeof newValue === 'string') {
      setSearch(newValue);
    } else if (newValue === null) {
      setSearch('');
    }
    if (newValue !== null) setShowSuggestionPopover(false);
  };

  const bulkAdminActions: ImageItem[] = useMemo(() => {
    const actions = [];
    const postSelected = {
      id: JournalEntryAction.PostSelected,
      text: `Post Selected (${journalEntrySelectionModel?.length || 0})`,
      icon: <img src={ExportIcon} alt="Post Selected" height="15" />,
      optionsSelected: 0,
    };

    const postAll = {
      id: JournalEntryAction.PostAll,
      text: 'Post All',
      icon: <img src={ExportIcon} alt="Post All" height="15" />,
      optionsSelected: 0,
    };

    const deleteSelected = {
      id: JournalEntryAction.DeleteSelected,
      text: `Delete Selected (${journalEntrySelectionModel?.length || 0})`,
      icon: <img src={DeleteIcon} alt="Delete Selected" height="15" />,
      color: 'error',
      optionsSelected: 0,
    };

    const deleteAll = {
      id: JournalEntryAction.DeleteAll,
      text: 'Delete All',
      icon: <img src={DeleteIcon} alt="Delete All" height="15" />,
      optionsSelected: 0,
    };

    const exportSelected = {
      id: JournalEntryAction.ExportSelected,
      text: `Export Selected (${journalEntrySelectionModel?.length || 0})`,
      icon: <img src={ExportIcon} alt="Export Selected" height="15" />,
      optionsSelected: 0,
    };

    const exportAll = {
      id: JournalEntryAction.ExportAll,
      text: 'Export All',
      icon: <img src={ExportIcon} alt="Export All" height="15" />,
      optionsSelected: 0,
    };

    if (journalEntrySelectionModel?.length > 0) {
      actions.push(exportSelected);
    }

    actions.push(exportAll);

    if (journalEntrySelectionModel?.length > 0) {
      actions.push(postSelected);
    }

    actions.push(postAll);
    if (journalEntrySelectionModel?.length > 0 && isSuperAdminOrClientAdmin) {
      actions.push(deleteSelected);
    }
    if (isSuperAdminOrClientAdmin) {
      actions.push(deleteAll);
    }

    return actions;
  }, [journalEntrySelectionModel]);

  const bulkExportActions: ImageItem[] = useMemo(() => {
    const actions = [];
    const exportSelected = {
      id: JournalEntryAction.ExportSelected,
      text: `Export Selected (${journalEntrySelectionModel?.length || 0})`,
      icon: <img src={ExportIcon} alt="Export Selected" height="15" />,
      optionsSelected: 0,
    };
    const exportAll = {
      id: JournalEntryAction.ExportAll,
      text: 'Export All',
      icon: <img src={ExportIcon} alt="Export All" height="15" />,
      optionsSelected: 0,
    };

    if (journalEntrySelectionModel?.length > 0) {
      actions.push(exportSelected);
    }

    actions.push(exportAll);
    return actions;
  }, [journalEntrySelectionModel]);

  const handleBulkAction = (actionId: JournalEntryAction) => {
    setIsLoading(true);
    switch (actionId) {
      case JournalEntryAction.PostAll:
        postAllJournalEntries();
        break;
      case JournalEntryAction.PostSelected:
        postSelectedJournalEntries();
        break;
      case JournalEntryAction.DeleteAll:
        deleteAllPreview();
        break;
      case JournalEntryAction.DeleteSelected:
        deleteSelectedPreview();
        break;
      case JournalEntryAction.ExportAll:
        downloadAllJournalEntries();
        break;
      case JournalEntryAction.ExportSelected:
        downloadSelectedJournalEntries();
        break;
    }
  };

  const checkDraftStatus = () => {
    const copyList = [...journalEntryList];
    const resultArray: any = [];

    copyList.map((entry: any) => {
      journalEntrySelectionModel.forEach((id: any) => {
        if (id === entry.id && entry.state === 'DRAFT') {
          resultArray.push(entry);
        }
      });
    });

    if (journalEntrySelectionModel.length === resultArray.length) {
      return true;
    } else {
      return false;
    }
  };

  const removeDuplicates = (list: any) => {
    const results = list.filter(function (value: any, index: any, array: any) {
      return index === array.indexOf(value);
    });

    return results;
  };

  const requestBodyWithFilters = () => {
    const reqBody: any = {};

    if (fundFilterIds.length > 0 && fundFilterIds.length !== fundList.length) {
      reqBody.fundIds = fundFilterIds;
    }

    if (fundId) {
      reqBody.fundIds = [fundId];
    }

    if (
      ledgerFilterIds.length > 0 &&
      ledgerFilterIds.length !== ledgerList.length
    ) {
      reqBody.ledgerIds = ledgerFilterIds;
    }

    if (
      entityFilterIds.length > 0 &&
      entityFilterIds.length !== entityList.length
    ) {
      reqBody.entityIds = entityFilterIds;
    }

    if (
      accountFilterIds.length > 0 &&
      accountFilterIds.length !== accountList.length
    ) {
      reqBody.accountIds = accountFilterIds;
    }

    if (
      statusFilterIds.length > 0 &&
      statusFilterIds.length !== statusList.length
    ) {
      reqBody.journalEntryState = statusFilterIds[0].toUpperCase();
    }

    if (startDateFilter) {
      reqBody.startDate = startDateFilter;
    }

    if (endDateFilter) {
      reqBody.endDate = endDateFilter;
    }

    return reqBody;
  };

  const postAllJournalEntries = () => {
    const reqBody: any = {};

    reqBody.filters = requestBodyWithFilters();
    setPostFiltersRequest(reqBody);
    setPostAction(POST_ACTION_TYPE.POST_ALL);

    setShowPostDialog(true);
  };

  const postSelectedJournalEntries = () => {
    const testPost = checkDraftStatus();

    if (testPost) {
      const slicedIds: string[] = journalEntrySelectionModel.map((id: any) => {
        return id.slice(0, 36);
      });

      setJournalEntriesToPost(removeDuplicates(slicedIds));
      setPostAction(POST_ACTION_TYPE.POST_SELECTED);
      setShowPostDialog(true);
    } else {
      informationAlert(SELECTED_POSTED_ERROR, 'error');
    }
    setIsLoading(false);
  };

  const deleteAllPreview = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);
      const reqBody: any = {};

      reqBody.filters = requestBodyWithFilters();

      const count = await deleteBulkJournalEntriesPreview(reqBody);

      if (count) {
        setDeleteCount(count.journalEntriesToDelete);
        setDeleteAction(DELETE_ACTION_TYPE.DELETE_ALL);
      }

      if (isCanceled?.()) return;
    } catch (e) {
      informationAlert(DELETE_ALL_ERROR, 'error');
    }
    setIsLoading(false);
  };

  const deleteSelectedPreview = async (isCanceled?: () => boolean) => {
    const testPost = checkDraftStatus();

    if (testPost) {
      try {
        setIsLoading(true);

        const reqBody: any = {};

        const slicedIds: string[] = journalEntrySelectionModel.map(
          (id: any) => {
            return id.slice(0, 36);
          }
        );

        reqBody.ids = removeDuplicates(slicedIds);

        setJournalEntriesToDelete(slicedIds);

        const count = await deleteBulkJournalEntriesPreview(reqBody);

        if (count) {
          setDeleteCount(count.journalEntriesToDelete);
          setDeleteAction(DELETE_ACTION_TYPE.DELETE_SELECTED);
        }

        if (isCanceled?.()) return;
      } catch (e) {
        informationAlert(DELETE_SELECTED_ERROR, 'error');
      }
    } else {
      setDeleteError('POSTED');
    }
    setIsLoading(false);
  };

  const deleteAllJournalEntries = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);

      const reqBody: any = {};

      reqBody.filters = requestBodyWithFilters();

      await deleteBulkJournalEntries(reqBody);
      if (isCanceled?.()) return;
      informationAlert(DELETE_BULK_SUCCESS, 'success');
    } catch (e) {
      informationAlert(DELETE_BULK_ERROR, 'error');
    }
    setIsLoading(false);
    setJournalEntriesToDelete([]);
    await searchFilteredList();
  };

  const deleteSelectedJournalEntries = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);

      const reqBody: any = {};

      const slicedIds: string[] = journalEntrySelectionModel.map((id: any) => {
        return id.slice(0, 36);
      });

      const journalIds: any = removeDuplicates(slicedIds);

      reqBody.ids = journalIds;

      await deleteBulkJournalEntries(reqBody);
      if (isCanceled?.()) return;
      informationAlert(DELETE_BULK_SUCCESS, 'success');
    } catch (e) {
      informationAlert(DELETE_BULK_ERROR, 'error');
    }
    setIsLoading(false);
    setJournalEntriesToDelete([]);
    await searchFilteredList();
  };

  const downloadAllJournalEntries = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);

      const reqBody: any = requestBodyWithFilters();

      await exportAndDownload(reqBody);
      setIsLoading(false);

      if (isCanceled?.()) return;
      informationAlert(EXPORT_ALL_SUCCESS, 'success');
    } catch (e) {
      informationAlert(EXPORT_ALL_ERROR, 'error');
    }
    setIsLoading(false);
  };

  const exportAndDownload = async (reqBody: any) => {
    if (Object.keys(reqBody).length) {
      const response = await exportSelectedJournalEntries(reqBody);

      downloadFile(response, 'Journal Entries', 'csv');
    } else {
      const response = await exportAllJournalEntries();

      downloadFile(response, 'Journal Entries', 'csv');
    }
  };

  const downloadSelectedJournalEntries = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);
      const reqBody: any = requestBodyWithFilters();

      const updatedIds: any = [];

      journalEntrySelectionModel.map((entry: any) => {
        updatedIds.push(entry.slice(0, 36));
      });

      const journalIds: any = removeDuplicates(updatedIds);

      reqBody.journalEntryIds = journalIds;

      //setShowExportCsvConfirm(true);

      const response = await exportSelectedJournalEntries(reqBody);

      downloadFile(response, 'Journal Entries', 'csv');

      if (isCanceled?.()) return;

      informationAlert(EXPORT_SELECTED_SUCCESS, 'success');
    } catch (e) {
      informationAlert(EXPORT_SELECTED_ERROR, 'error');
    }
    setIsLoading(false);
  };

  const apiRef = useGridApiRef();

  const creditDebitSortComparator = (
    v1: any,
    v2: any,
    row1: any,
    row2: any
  ) => {
    if (v1 && v2) return v1 - v2;

    const sortOrder = apiRef.current.getSortModel()[0].sort;

    if (sortOrder === 'asc') {
      if (v1 && !v2) return -1;
      if (!v1 && v2) return 1;
    } else {
      if (v1 && !v2) return 1;
      if (!v1 && v2) return -1;
    }

    const sortPairColumn =
      row1.api.getRow(row1.id).lineItems.amount -
      row2.api.getRow(row2.id).lineItems.amount;

    return sortPairColumn;
  };

  const initializeHeaderList = () => {
    const updatedHeaders: any = [
      {
        field: 'fundName',
        index: 1,
        headerName: 'Fund',
        hide: fundId ? true : false,
        type: 'string',
        sortable: true,
        filterable: true,
        valueGetter: (params: any) => {
          return params.row.lineItems.fundName;
        },
        renderCell: (params: any) => {
          return params.row.lineItems.fundName;
        },
        inlineFilter: true,
        inlineFilterName: JournalEntryFilter.Fund,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: fundList ? fundList : [],
        inlineFilterSelected: selectedFundList,
        emptySelectionOnClear: false,
      },
      {
        field: 'glName',
        index: 2,
        headerName: 'Ledger',
        hide: false,
        type: 'string',
        sortable: true,
        filterable: true,
        width: 170,
        valueGetter: (params: any) => {
          return params.row.lineItems.ledgerName;
        },
        renderCell: (params: any) => {
          return params.row.lineItems.ledgerName;
        },
        inlineFilter: true,
        inlineFilterName: JournalEntryFilter.Ledger,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: ledgerList ? ledgerList : [],
        inlineFilterSelected: selectedLedgerList,
        emptySelectionOnClear: false,
      },
      {
        field: 'journalNumber',
        index: 3,
        headerName: 'Number',
        headerClass: 'wow',
        hide: false,
        type: 'number',
        sortable: true,
        width: 125,
        align: 'center' as GridAlignment,
        valueGetter: (params: any) => {
          return params.row.number.toString();
        },
        renderCell: (params: any) => {
          return <Typography variant="body2">{params.row.number}</Typography>;
        },
      },
      {
        field: 'date',
        index: 4,
        headerName: 'Date',
        hide: false,
        type: 'date',
        width: 150,
        renderCell: (params: any) => {
          const date = params?.row?.date ?? '';

          if (!date) {
            return <Typography variant="body2">{M_DASH_UNICODE}</Typography>;
          }
          const formattedData = DateTimeFormat.shortDate(new Date(date));

          return <Typography variant="body2">{formattedData}</Typography>;
        },
        inlineFilter: true,
        inlineFilterName: JournalEntryFilter.Date,
        inlineFilterOptions: [],
        inlineFilterSelected: ['specific_date', [null, null]],
      },
      {
        field: 'lineNumber',
        index: 5,
        headerName: 'Line Number',
        hide: false,
        type: 'number',
        sortable: false,
        width: 160,
        align: 'center' as GridAlignment,
        valueGetter: (params: any) => {
          return params.row.lineItems.number;
        },
        renderCell: (params: any) => {
          if (journalEntryList.length === 1) {
            return (
              <Typography variant="body2">
                {params.row.lineItems[0].number}
              </Typography>
            );
          }
          return (
            <Typography variant="body2">
              {params.row.lineItems.number}
            </Typography>
          );
        },
      },
      {
        field: 'totalDebit',
        index: 6,
        headerName: 'Total Debit',
        hide: false,
        type: 'number',
        sortable: true,
        width: 150,
        align: 'left' as GridAlignment,
        valueGetter: (params: any) => {
          if (journalEntryList.length === 1) {
            if (params.row.lineItems[0].entryType === 'CREDIT') {
              return '';
            }
            return params.row.lineItems[0].amount;
          }
          if (params.row.lineItems.entryType === 'CREDIT') {
            return '';
          }
          return params.row.lineItems.amount;
        },
        renderCell: (params: any) => {
          if (params.value === '') {
            return '';
          }

          const value: number = Number(
            (params.value / 100).toFixed(params.row.currencyDecimal)
          );

          return CurrencyFormat(
            params.row.currencyName,
            params.row.currencyDecimal
          ).format(value);
        },
        sortComparator: creditDebitSortComparator,
      },
      {
        field: 'totalCredit',
        index: 7,
        headerName: 'Total Credit',
        hide: false,
        type: 'number',
        align: 'left' as GridAlignment,
        sortable: true,
        width: 150,
        valueGetter: (params: any) => {
          if (journalEntryList.length === 1) {
            if (params.row.lineItems[0].entryType === 'DEBIT') {
              return '';
            }
            return params.row.lineItems[0].amount;
          }
          if (params.row.lineItems.entryType === 'DEBIT') {
            return '';
          }
          return params.row.lineItems.amount;
        },
        renderCell: (params: any) => {
          if (params.value === '') {
            return '';
          }

          const value: number = Number(
            (params.value / 100).toFixed(params.row.currencyDecimal)
          );

          return CurrencyFormat(
            params.row.currencyName,
            params.row.currencyDecimal
          ).format(value);
        },
        sortComparator: creditDebitSortComparator,
      },
      {
        field: 'account',
        index: 8,
        headerName: 'Account',
        hide: false,
        type: 'string',
        sortable: true,
        filterable: true,
        width: 170,
        valueGetter: (params: any) => {
          if (journalEntryList.length === 1) {
            return params.row.lineItems[0].accountName;
          }
          return params.row.lineItems.accountName;
        },
        renderCell: (params: any) => {
          if (journalEntryList.length === 1) {
            return params.row.lineItems[0].accountName;
          }
          return params.row.lineItems.accountName;
        },
        inlineFilter: true,
        inlineFilterName: JournalEntryFilter.Account,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: accountList ? accountList : [],
        inlineFilterSelected: selectedAccountList,
        emptySelectionOnClear: false,
      },
      {
        field: 'lineItems.entities',
        index: 9,
        headerName: 'Memo Tags',
        hide: false,
        sortable: true,
        filterable: true,
        type: 'chips',
        chipLabelField: 'name',
        chipIDField: 'id',
        align: 'left' as GridAlignment,
        width: 200,
        inlineFilter: true,
        inlineFilterName: JournalEntryFilter.Entity,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: entityList ? entityList : [],
        inlineFilterSelected: selectedEntityList,
        emptySelectionOnClear: false,
      },
      {
        field: 'status',
        index: 10,
        headerName: 'Status',
        hide: false,
        type: 'string',
        sortable: true,
        filterable: true,
        width: 150,
        valueGetter: (params: any) => {
          return params.row.state.toLowerCase();
        },
        renderCell: (params: any) => {
          const status = params.row.state.toLowerCase();
          const color = StatusType[status as keyof typeof StatusType];

          return (
            <StatusLabel
              color={color}
              isUpperCase={false}
              label={capitalize(status ?? M_DASH_UNICODE)}
            />
          );
        },
        inlineFilter: true,
        inlineFilterName: JournalEntryFilter.Status,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: statusList ? statusList : [],
        inlineFilterSelected: selectedStatusList,
        emptySelectionOnClear: false,
      },
      {
        field: 'action',
        headerName: 'Columns',
        index: 11,
        hide: false,
        hideable: false,
        type: 'action',
        width: 150,
        customType: CustomType.Action,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
      },
    ];

    let sortedHeaders: any;

    if (journalColumnOrder && journalColumnOrder.viewItems) {
      journalColumnOrder.viewItems.map((item) => {
        const header = updatedHeaders.find(
          (header: any) => header.field === item.code
        );

        if (header) {
          header.index = item.order;
          header.hide = !item.visible;
        }
      });

      sortedHeaders = updatedHeaders.sort((a: any, b: any) =>
        a.index > b.index ? 1 : -1
      );
    } else {
      sortedHeaders = updatedHeaders.sort(
        (item1: any, item2: any) => item1.index - item2.index
      );
    }

    setHeaderList(sortedHeaders);
  };

  const handleFilter = (filterName: JournalEntryFilter, selected: string[]) => {
    setHeaderList((prevHeaderList) =>
      prevHeaderList?.map((header) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected.length
              ? selected
              : header.inlineFilterOptions?.map((option) => option.id),
          };
        }
        return header;
      })
    );
  };

  const handleUpdateHeader = async (
    field: string,
    inlineFilterName?: JournalEntryFilter
  ) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== 'action'
    );
    const targetField = headerList.find((header) => header.field === field);

    if (activeFields.length <= 1 && !targetField?.hide) {
      return;
    }

    const updatedHeaders: any = headerList.map((header) => {
      if (header?.inlineFilterName === inlineFilterName) {
        let inlineFilterSelected;

        if (inlineFilterName === JournalEntryFilter.Fund) {
          inlineFilterSelected = selectedFundList;
        } else if (inlineFilterName === JournalEntryFilter.Ledger) {
          inlineFilterSelected = selectedLedgerList;
        } else if (inlineFilterName === JournalEntryFilter.Account) {
          inlineFilterSelected = selectedAccountList;
        } else if (inlineFilterName === JournalEntryFilter.Entity) {
          inlineFilterSelected = selectedEntityList;
        } else if (inlineFilterName === JournalEntryFilter.Status) {
          inlineFilterSelected = selectedStatusList;
        } else if (inlineFilterName === JournalEntryFilter.Date) {
          inlineFilterSelected = ['', [null, null]];
        }

        return {
          ...header,
          hide:
            header.field === field &&
            !(!header.hide && activeFields.length <= 1)
              ? !header.hide
              : header.hide,
          inlineFilterSelected: inlineFilterSelected,
        };
      }
      return {
        ...header,
        hide:
          header.field === field && !(!header.hide && activeFields.length <= 1)
            ? !header.hide
            : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(
        updatedHeaders.sort(
          (item1: any, item2: any) => item1.index - item2.index
        )
      );
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }

    const visiblityUpdate = arrayVisibilityUpdate(
      headerList,
      clientId,
      JOURNAL_ENTRY_LIST_COLUMN_ORDER_VIEW_KEY,
      field
    );

    saveColumnOrder(visiblityUpdate);
  };

  useEffect(() => {
    const filteredList: any[] | undefined = journalEntryList;

    headerList?.map((header: any) => {
      const auxList = filteredList ?? journalEntryList;

      switch (header.inlineFilterName) {
        case JournalEntryFilter.Fund:
          if (header.inlineFilterSelected?.length) {
            const filteredIds: any = [];

            header.inlineFilterSelected.map((fund: any) => {
              fundList?.filter((listItem: any) => {
                if (fund === listItem.name) {
                  filteredIds.push(listItem.fundId);
                }
              });
              setFundFilterIds(filteredIds);
              setFilterToggleState(!filterToggleState);
            });
          } else {
            setFundFilterIds([]);
            header.inlineFilterSelected = selectedFundList;
          }
          break;
        case JournalEntryFilter.Ledger:
          if (header.inlineFilterSelected?.length) {
            const filteredIds: any = [];

            header.inlineFilterSelected.map((ledger: any) => {
              ledgerList?.filter((listItem: any) => {
                if (ledger === listItem.name) {
                  filteredIds.push(listItem.ledgerId);
                }
              });
              setLedgerFilterIds(filteredIds);
              setFilterToggleState(!filterToggleState);
            });
          } else {
            setLedgerFilterIds([]);
            header.inlineFilterSelected = selectedLedgerList;
          }
          break;
        case JournalEntryFilter.Account:
          if (header.inlineFilterSelected?.length) {
            const filteredIds: any = [];

            header?.inlineFilterSelected?.map((account: any) => {
              accountList?.filter((listItem: any) => {
                if (account === listItem.name) {
                  filteredIds.push(listItem.accountId);
                }
              });
              setAccountFilterIds(filteredIds);
              setFilterToggleState(!filterToggleState);
            });
          } else {
            setAccountFilterIds([]);
            header.inlineFilterSelected = selectedAccountList;
          }
          break;
        case JournalEntryFilter.Entity:
          if (header.inlineFilterSelected?.length) {
            const filteredIds: any = [];

            header.inlineFilterSelected.map((entity: any) => {
              entityList?.filter((listItem: any) => {
                if (entity === listItem.name) {
                  filteredIds.push(listItem.entityId);
                }
              });

              setEntityFilterIds(filteredIds);
              setFilterToggleState(!filterToggleState);
            });
          } else {
            setEntityFilterIds([]);
            header.inlineFilterSelected = selectedEntityList;
          }
          break;
        case JournalEntryFilter.Status:
          if (header.inlineFilterSelected?.length) {
            const filterIds: any = [];

            header.inlineFilterSelected.map((status: any) => {
              filterIds.push(status);
            });

            setStatusFilterIds(filterIds);
            setFilterToggleState(!filterToggleState);
          } else {
            setStatusFilterIds([]);
            header.inlineFilterSelected = selectedStatusList;
          }
          break;
        case JournalEntryFilter.Date:
          if (header.inlineFilterSelected.length) {
            if (header.inlineFilterSelected[0] === null) {
              header.inlineFilterSelected[0] = 'specific_date';
            }

            const startDate = header?.inlineFilterSelected?.[1]?.[0];
            const endDate = header?.inlineFilterSelected?.[1]?.[1];

            if (startDate) {
              const start = format(startDate, API_DATE_FORMAT).valueOf();

              setStartDateFilter(start);
            }
            if (startDate === null) {
              setStartDateFilter('');
            }
            if (endDate) {
              const end = format(endDate, API_DATE_FORMAT).valueOf();

              setEndDateFilter(end);
            }

            if (endDate === null) {
              setEndDateFilter('');
            }
            setFilterToggleState(!filterToggleState);
          }
          break;
      }
    });
  }, [headerList]);

  const clearUploadedFile = () => {
    setUploadedFile(undefined);
  };

  const clearUploadCompleted = () => {
    setIsUploadComplete(false);
  };

  const handleTemplateDownload = async () => {
    const cdnEndpoint: string = process.env
      .REACT_APP_CDN_ENDPOINT_URL as string;
    const fileLink = cdnEndpoint.concat('templates/JournalEntryUpload.csv');
    const filename = 'journal_entries_template.csv';

    try {
      const response = await fetch(fileLink);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      informationAlert(DOWNLOAD_TEMPLATE_ERROR, 'error');
    }
  };

  const handleUploadTemplate = async () => {
    if (!uploadedFile) return;
    try {
      setIsLoading(true);
      await uploadFile('journal-entries', uploadedFile);
      informationAlert(UPLOAD_JOURNAL_ENTRY_SUCCESS, 'success');
      setUploadedFile(undefined);
      setIsLoading(false);
      setIsUploadComplete(true);
      await searchFilteredList();
    } catch (exception) {
      setUploadedFile(undefined);
      setIsLoading(false);
      setIsUploadComplete(true);
      informationAlert(UPLOAD_JOURNAL_ENTRY_ERROR, 'error');
    }
  };

  // useEffectAsync(
  //   async (isCanceled) => {
  //     setIsLoading(true);
  //     await searchFilteredList();
  //     await fetchFundFilters(isCanceled);
  //     await fetchEntitiesList(isCanceled);
  //     await fetchAllLedgers(isCanceled);
  //     setIsLoading(false);
  //   },
  //   [isUploadComplete, deletedFile]
  // );

  const onPostCancel = () => {
    setShowPostDialog(false);
    setPostAction(undefined);
    setPostFiltersRequest(undefined);
    setJournalEntriesToPost([]);
    searchFilteredList();
  };

  const onPostComplete = () => {
    setSelectedJournalEntry(undefined);
    setPostFiltersRequest(undefined);
    setShowPostDialog(false);
    setPostAction(undefined);
    setJournalEntriesToPost([]);
    searchFilteredList();
  };

  const closeConfirmExport = () => {
    setShowExportCsvConfirm(false);
  };

  const onDeleteConfirm = () => {
    switch (deleteAction) {
      case DELETE_ACTION_TYPE.DELETE_ALL:
        deleteAllJournalEntries();
        break;
      case DELETE_ACTION_TYPE.DELETE_SELECTED:
        deleteSelectedJournalEntries();
        break;
    }

    setDeleteAction(undefined);
    setDeleteCount(undefined);
  };

  const onDeleteCancel = () => {
    setIsLoading(false);
    setDeleteAction(undefined);
    setDeleteCount(undefined);
    setDeleteError(undefined);
  };

  const onRowColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const newIndex = params.targetIndex;
    const oldIndex = params.oldIndex;

    const columnOrderToSave = updateArrayIndex(
      headerList,
      oldIndex - 1,
      newIndex - 1,
      clientId,
      JOURNAL_ENTRY_LIST_COLUMN_ORDER_VIEW_KEY
    );

    saveColumnOrder(columnOrderToSave);
  };

  return {
    isLoading,
    journalEntryFilteredList,
    journalEntrySelectionModel,
    setJournalEntrySelectionModel,
    headerList,
    activeHeaderFields,
    handleUpdateHeader,
    handleOnView,
    handleFilter,
    handleNewButtonAction,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onDetailsPanelClose,
    fundId,
    readonly,
    onSearch,
    searchOptions,
    search,
    showSuggestionPopover,
    setShowSuggestionPopover,
    journalEntryList,
    bulkAdminActions,
    bulkExportActions,
    handleBulkAction,
    apiRef,
    isFundAdmin,
    isSuperAdminOrClientAdmin,
    handleUploadTemplate,
    clearUploadedFile,
    clearUploadCompleted,
    isUploadComplete,
    uploadedFile,
    showPostDialog,
    setShowPostDialog,
    postAction,
    postFiltersRequest,
    journalEntriesToPost,
    setJournalEntriesToPost,
    setPostAction,
    onPostCancel,
    onPostComplete,
    sortModel,
    setSortModel,
    rowCountState,
    handlePageChange,
    closeConfirmExport,
    showExportCsvConfirm,
    searchFilteredList,
    deleteAction,
    deleteCount,
    onDeleteCancel,
    onDeleteConfirm,
    onRowColumnOrderChange,
    deleteError,
  };
};
