import { useContext, useMemo, useState } from 'react';

import { AppContext } from '../../../core/context/appContextProvider';
import {
  getAllArkAccounts,
  getAllEntities,
  getArkLedgers,
} from '../../../services/arkGL.service';
import { getBankRules, getBanks } from '../../../services/bank.service';
import { getCapitalCalls } from '../../../services/capitalCalls.service';
import { getFundNames } from '../../../services/fund.service';
import { DateTimeFormat } from '../../../utils/helpers/format.helper';
import {
  BankAccount,
  BankRule,
  GlAccountDetails,
  nameIdFundPair,
  NameIdPair,
  nameIdTypePair,
} from '../../../utils/types/bank.type';
import { CapitalCalls } from '../../../utils/types/capitalCalls.type';
import { BANK_STATUS_FILTER_LIST } from '../bankFeeds/BankFeedList.defaults';

export const useBankfilters = (
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { informationAlert } = useContext(AppContext);

  const [bankAccountList, setBankAccountList] = useState<BankAccount[]>([]);
  const [fundList, setFundList] = useState<NameIdPair[]>([]);
  const [ledgerList, setLedgerList] = useState<nameIdFundPair[]>([]);
  const [CoAList, setCoAList] = useState<GlAccountDetails[]>([]);
  const [memoEntityList, setMemoEntityList] = useState<nameIdTypePair[]>([]);
  const [memoEntityFilterList, setMemoEntityFilterList] = useState<any[]>([]);
  const [capitalCallList, setCapitalCallList] = useState<any[]>([]);
  const [bankRulesResponse, setBankRulesResponse] = useState<BankRule[]>([]);

  const fetchAllBankAccounts = async (isCanceled?: () => boolean) => {
    try {
      const allBanksResponse = await getBanks();

      if (isCanceled?.()) return;

      setBankAccountList(allBanksResponse || null);
    } catch (e) {
      informationAlert('Error getting bank accounts list', 'error');
    }
  };

  const fetchAllFundNames = async (isCanceled?: () => boolean) => {
    try {
      const fundsList = await getFundNames();

      if (isCanceled?.()) return;

      setFundList(fundsList);
    } catch (e) {
      informationAlert('Error getting funds list', 'error');
    }
  };

  const fetchArkLedgerNames = async (isCanceled?: () => boolean) => {
    try {
      const ledgersRes = await getArkLedgers();

      if (isCanceled?.()) return;

      setLedgerList(ledgersRes.items);
    } catch (e) {
      informationAlert('Error getting ledger list', 'error');
    }
  };

  const fetchAllCoANames = async (isCanceled?: () => boolean) => {
    try {
      const accountsRes = await getAllArkAccounts();

      if (isCanceled?.()) return;

      setCoAList(accountsRes.items);
    } catch (e) {
      informationAlert('Error getting accounts list', 'error');
    }
  };

  const fetchEntityList = async (isCanceled?: () => boolean) => {
    try {
      const response = await getAllEntities();

      if (isCanceled?.()) return;

      const groupedEntities: { [key: string]: any[] } = {
        fund: [],
        investor: [],
        portCo: [],
        memoEntity: [],
      };

      response.items.forEach((entity: any) => {
        switch (entity.type) {
          case 'Fund':
            groupedEntities.fund.push(entity);
            break;
          case 'Investor':
            groupedEntities.investor.push(entity);
            break;
          case 'PortfolioCompany':
            entity.type = 'Portfolio Company';
            groupedEntities.portCo.push(entity);
            break;
          case 'GeneralEntity':
            entity.type = 'Memo Entity';
            groupedEntities.memoEntity.push(entity);
            break;
          default:
            break;
        }
      });

      const groupedEntityList = [
        ...groupedEntities.fund,
        ...groupedEntities.investor,
        ...groupedEntities.portCo,
        ...groupedEntities.memoEntity,
      ];

      const entityFilterList: NameIdPair[] = groupedEntityList
        .map((entity) => ({
          id: entity.id,
          name: `${entity?.name} - ${entity?.type}`,
        }))
        .concat({
          id: 'None',
          name: 'None',
        });

      setMemoEntityFilterList(entityFilterList);
      setMemoEntityList(groupedEntityList);
    } catch (e) {
      informationAlert('Error getting memo entities list.', 'error');
    }
  };

  const fetchCapitalCallList = async (isCanceled?: () => boolean) => {
    try {
      const response: CapitalCalls[] = await getCapitalCalls();

      if (isCanceled?.()) return;

      if (response) {
        const list = response
          .filter((c) => c.status === 'PUBLISHED')
          .map((c) => {
            return {
              ...c,
              label: `${c.name} - ${DateTimeFormat.shortDateString(
                c.date as string
              )} - ${c.fundName}`,
            };
          })
          .sort((a, b) => {
            const dateA = a.date ? new Date(a.date).getTime() : 0;
            const dateB = b.date ? new Date(b.date).getTime() : 0;

            return dateB - dateA;
          });

        setCapitalCallList(list);
      }
    } catch (e) {
      informationAlert('Error getting capital call list', 'error');
    }
  };

  const fetchAllBankRules = async (isCanceled?: () => boolean) => {
    try {
      const allBankRulesResponse = await getBankRules();

      if (isCanceled?.()) return;

      setBankRulesResponse(allBankRulesResponse);
    } catch (e) {
      informationAlert('Error getting bank rules', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const selectedBankAccountsList = useMemo(
    () => bankAccountList?.map((item) => item.id),
    [bankAccountList]
  );

  const selectedFundsList = useMemo(
    () => fundList?.map((item) => item.id),
    [fundList]
  );

  const selectedCoAList = useMemo(
    () => CoAList?.map((item) => item.id),
    [CoAList]
  );

  const selectedMemoEntityFilterList = useMemo(
    () => memoEntityFilterList?.map((item: any) => item.id),
    [memoEntityFilterList]
  );

  const selectedStatusList = useMemo(
    () => BANK_STATUS_FILTER_LIST?.map((item) => item.id),
    [BANK_STATUS_FILTER_LIST]
  );

  const fetchAllFilters = async (isCanceled?: () => boolean) => {
    const promises = [
      fetchAllBankAccounts(isCanceled),
      fetchAllFundNames(isCanceled),
      fetchArkLedgerNames(isCanceled),
      fetchAllCoANames(isCanceled),
      fetchEntityList(isCanceled),
      fetchCapitalCallList(isCanceled),
      // fetchAllBankRules(isCanceled),
    ];

    await Promise.all(promises);
  };

  return {
    fetchAllFilters,
    fetchAllBankAccounts,
    bankAccountList,
    fundList,
    ledgerList,
    CoAList,
    memoEntityList,
    memoEntityFilterList,
    capitalCallList,
    selectedBankAccountsList,
    selectedFundsList,
    selectedCoAList,
    selectedMemoEntityFilterList,
    selectedStatusList,
  };
};
