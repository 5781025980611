import { Checkbox, Grid } from '@mui/material';
import React from 'react';
import { RouteProps } from 'react-router-dom';

import HorizontalBox from '../../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../../components/Buttons/Button/Button';
import DetailPanel from '../../../../components/DetailPanel/DetailPanel';
import {
  FormBox,
  StyledBackdrop,
} from '../../../../components/DetailPanel/DetailPanel.styles';
import { ActionLink } from '../../../../components/Link/ActionLink/ActionLink';
import ProgressPanel from '../../../../components/Progress/ProgressPanel/ProgressPanel';
import { M_DASH_UNICODE } from '../../../../utils/constants/constants';
import { DateTimeFormat } from '../../../../utils/helpers/format.helper';
import { SelectedInvestmentTransaction } from '../../../../utils/types/investmentTransaction.type';
import { SelectedPortfolioCompany } from '../../../../utils/types/portfolioCompany.type';
import {
  KeyLabel,
  KeyValuePair,
  ValueLabel,
} from '../../../contacts/contactInfo/contactDetailPanel/ContactDetails/ContactDetails.styles';
import { InvestmentTransactionTypes } from '../InvestmentTransactionList.constants';
import {
  useInvestmentTransactionDetails,
  useTabs,
} from './investmentTransactionDetails.hooks';
import {
  CheckboxLabel,
  HeaderRow,
  Panel,
  TransactionListBox,
  Typography,
} from './investmentTransactionDetails.styles';

interface Props extends RouteProps {
  onDetailClose: Function;
  selectedInvestmentTransaction: SelectedInvestmentTransaction;
  fetchAllInvestmentTransactions: Function;
  setSelectedInvestmentTransaction: Function;
  fundId?: string;
}

export const ReadOnlyInvestmentTransactionDetails: React.FC<Props> = ({
  onDetailClose,
  selectedInvestmentTransaction,
  fetchAllInvestmentTransactions,
  setSelectedInvestmentTransaction,
  fundId,
}: Props) => {
  const { type, investmentTransaction } = selectedInvestmentTransaction;

  const { currentTab, detailsRef, transactionsRef, handleTabChange } = useTabs({
    isNew: false,
  });

  const {
    loading,
    followingTransactionList,
    investmentTransactionType,
    initialPurchaseTransaction,
    handleViewTransactionDetails,
    closeDrawer,
    selectedSecurityType,
    totalAmount,
    quantity,
    amountPerQuantity,
    totalAmountOther,
    quantityOther,
    amountPerQuantityOther,
  } = useInvestmentTransactionDetails({
    investmentTransaction,
    onClose: onDetailClose,
    fetchAllInvestmentTransactions,
    isNewInvestmentTransaction: false,
    selectedInvestmentTransaction,
    setSelectedInvestmentTransaction,
    fundId,
  });

  return (
    <React.Fragment>
      <DetailPanel
        id="investment_transaction_details_panel"
        title={investmentTransaction?.name}
        open={Boolean(type)}
        onClose={closeDrawer}
        hasTabsPanel={true}
        tabsPanelValue={currentTab}
        tabsPanelHandleChange={handleTabChange}
        tabsPanelOptions={['Details', 'Investment Transactions']}
      >
        {!!investmentTransactionType &&
          (investmentTransactionType !== 'PURCHASE' ? (
            <>
              <FormBox>
                <Grid container spacing={2}>
                  <Grid xs={12} item>
                    <KeyValuePair>
                      <KeyLabel>Purchase Transaction</KeyLabel>
                      <ValueLabel>{M_DASH_UNICODE}</ValueLabel>
                    </KeyValuePair>
                  </Grid>
                  {!!initialPurchaseTransaction &&
                    initialPurchaseTransaction.securityType && (
                      <>
                        <Grid item xs={12} md={6}>
                          <KeyValuePair>
                            <KeyLabel>Security Type</KeyLabel>
                            <ValueLabel>
                              {initialPurchaseTransaction.securityType ||
                                M_DASH_UNICODE}
                            </ValueLabel>
                          </KeyValuePair>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KeyValuePair>
                            <KeyLabel>Investment Security</KeyLabel>
                            <ValueLabel>
                              {initialPurchaseTransaction.investmentSecurity ||
                                M_DASH_UNICODE}
                            </ValueLabel>
                          </KeyValuePair>
                        </Grid>
                        <Grid item xs={12}>
                          <KeyValuePair>
                            <KeyLabel>Quantity</KeyLabel>
                            <ValueLabel>
                              {initialPurchaseTransaction.quantity ||
                                M_DASH_UNICODE}
                            </ValueLabel>
                          </KeyValuePair>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KeyValuePair>
                            <KeyLabel>Total Amount</KeyLabel>
                            <ValueLabel>
                              {initialPurchaseTransaction.totalAmount ||
                                M_DASH_UNICODE}
                            </ValueLabel>
                          </KeyValuePair>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KeyValuePair>
                            <KeyLabel>
                              Amount Per Quantity (calculated)
                            </KeyLabel>
                            <ValueLabel>
                              {initialPurchaseTransaction.amountPerQuantity ||
                                M_DASH_UNICODE}
                            </ValueLabel>
                          </KeyValuePair>
                        </Grid>
                        <Grid item xs={12}>
                          <KeyValuePair>
                            <KeyLabel>Date</KeyLabel>
                            <ValueLabel>
                              {investmentTransaction?.date
                                ? DateTimeFormat.shortDateString(
                                    investmentTransaction.date as string
                                  )
                                : M_DASH_UNICODE}
                            </ValueLabel>
                          </KeyValuePair>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KeyValuePair>
                            <KeyLabel>Quantity</KeyLabel>
                            <ValueLabel>
                              {quantityOther || M_DASH_UNICODE}
                            </ValueLabel>
                          </KeyValuePair>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KeyValuePair>
                            <KeyLabel>Amount</KeyLabel>
                            <ValueLabel>
                              {totalAmountOther || M_DASH_UNICODE}
                            </ValueLabel>
                          </KeyValuePair>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KeyValuePair>
                            <KeyLabel>
                              Amount Per Quantity (calculated)
                            </KeyLabel>
                            <ValueLabel>
                              {amountPerQuantityOther || M_DASH_UNICODE}
                            </ValueLabel>
                          </KeyValuePair>
                        </Grid>
                        <Grid item xs={12}>
                          <CheckboxLabel>
                            <Checkbox
                              id="chk_remove_from_soi"
                              checked={!!investmentTransaction?.isSoiDisabled}
                            />
                            Remove from historical SOI
                          </CheckboxLabel>
                        </Grid>
                      </>
                    )}
                </Grid>
              </FormBox>
              <HorizontalBox
                addTopShadow
                attachToBottom={true}
                hidden={false}
                fullWidth
              >
                <Button
                  id={'investment_transaction_cancel_button'}
                  variant="outlined"
                  onClick={closeDrawer}
                  text={'Close'}
                  color={'secondary'}
                  fullWidth
                />
              </HorizontalBox>
            </>
          ) : (
            <>
              <FormBox>
                <Panel id="details_panel" ref={detailsRef}>
                  <HeaderRow>
                    <Typography variant="h4">Details</Typography>
                  </HeaderRow>
                  <Grid container xs={12} spacing={2}>
                    <Grid xs={12} item>
                      <KeyValuePair>
                        <KeyLabel>Investment Transaction Name</KeyLabel>
                        <ValueLabel>
                          {investmentTransaction?.name || M_DASH_UNICODE}
                        </ValueLabel>
                      </KeyValuePair>
                    </Grid>
                    <Grid xs={12} item>
                      <KeyValuePair>
                        <KeyLabel>Fund Name</KeyLabel>
                        <ValueLabel>
                          {investmentTransaction?.fund?.name || M_DASH_UNICODE}
                        </ValueLabel>
                      </KeyValuePair>
                    </Grid>
                    <Grid xs={12} item>
                      <KeyValuePair>
                        <KeyLabel>Portfolio Company Name</KeyLabel>
                        <ValueLabel>
                          {investmentTransaction?.portfolioCompany?.name ||
                            M_DASH_UNICODE}
                        </ValueLabel>
                      </KeyValuePair>
                    </Grid>
                    <Grid xs={12} md={6} item>
                      <KeyValuePair>
                        <KeyLabel>Security Type</KeyLabel>
                        <ValueLabel>
                          {investmentTransaction?.securityType ||
                            M_DASH_UNICODE}
                        </ValueLabel>
                      </KeyValuePair>
                    </Grid>
                    <Grid xs={12} item>
                      <KeyValuePair>
                        <KeyLabel>Security</KeyLabel>
                        <ValueLabel>
                          {investmentTransaction?.investmentSecurity ||
                            M_DASH_UNICODE}
                        </ValueLabel>
                      </KeyValuePair>
                    </Grid>
                    <Grid xs={12} item>
                      <KeyValuePair>
                        <KeyLabel>Quantity</KeyLabel>
                        <ValueLabel>{quantity || M_DASH_UNICODE}</ValueLabel>
                      </KeyValuePair>
                    </Grid>
                    <Grid xs={12} item>
                      <KeyValuePair>
                        <KeyLabel>Amount</KeyLabel>
                        <ValueLabel>{totalAmount || M_DASH_UNICODE}</ValueLabel>
                      </KeyValuePair>
                    </Grid>
                    <Grid xs={12} item>
                      <KeyValuePair>
                        <KeyLabel>Amount Per Quantity</KeyLabel>
                        <ValueLabel>
                          {amountPerQuantity || M_DASH_UNICODE}
                        </ValueLabel>
                      </KeyValuePair>
                    </Grid>
                    <Grid xs={12} item>
                      <KeyValuePair>
                        <KeyLabel>Date</KeyLabel>
                        <ValueLabel>
                          {investmentTransaction?.date
                            ? DateTimeFormat.shortDate(
                                investmentTransaction.date
                              )
                            : M_DASH_UNICODE}
                        </ValueLabel>
                      </KeyValuePair>
                    </Grid>
                    {selectedSecurityType === 'Convertible Notes' && (
                      <>
                        <Grid xs={12} md={6} item>
                          <KeyValuePair>
                            <KeyLabel>Note Agreement (days)</KeyLabel>
                            <ValueLabel>
                              {investmentTransaction?.noteAgreement ||
                                M_DASH_UNICODE}
                            </ValueLabel>
                          </KeyValuePair>
                        </Grid>
                        <Grid xs={12} md={6} item>
                          <KeyValuePair>
                            <KeyLabel>Interest Rate</KeyLabel>
                            <ValueLabel>
                              {investmentTransaction?.interestRate ||
                                M_DASH_UNICODE}
                            </ValueLabel>
                          </KeyValuePair>
                        </Grid>
                      </>
                    )}
                    {selectedSecurityType === 'Partnership Interest' && (
                      <Grid xs={12} md={6} item>
                        <KeyValuePair>
                          <KeyLabel>Commitment Amount</KeyLabel>
                          <ValueLabel>
                            {investmentTransaction?.commitmentAmount ||
                              M_DASH_UNICODE}
                          </ValueLabel>
                        </KeyValuePair>
                      </Grid>
                    )}
                  </Grid>
                </Panel>
              </FormBox>
              <HorizontalBox
                addTopShadow
                attachToBottom={true}
                hidden={false}
                fullWidth
              >
                <Button
                  id={'investment_transaction_fund_close_button'}
                  variant="outlined"
                  onClick={closeDrawer}
                  text={'Close'}
                  color={'secondary'}
                  fullWidth
                />
              </HorizontalBox>
            </>
          ))}
        <FormBox>
          {
            <Panel id="details_panel" ref={transactionsRef}>
              <HeaderRow>
                <Typography variant="h4">Investment Transactions</Typography>
              </HeaderRow>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  {followingTransactionList &&
                    followingTransactionList.length > 0 &&
                    followingTransactionList.map((transaction) => {
                      return (
                        <TransactionListBox key={transaction.id}>
                          <ActionLink
                            id={`link_${transaction.id}`}
                            onClick={() =>
                              handleViewTransactionDetails(transaction)
                            }
                          >
                            {
                              InvestmentTransactionTypes[
                                transaction.investmentTransactionType
                              ]
                            }
                          </ActionLink>
                          <Typography>
                            {DateTimeFormat.shortDate(transaction.date)}
                          </Typography>
                        </TransactionListBox>
                      );
                    })}
                </Grid>
              </Grid>
            </Panel>
          }
        </FormBox>
        <StyledBackdrop open={Boolean(loading)} />
        <ProgressPanel
          id={'progress_investment_transaction_panel'}
          showProgress={Boolean(loading)}
          text={`${loading} Investment Transaction...`}
        />
      </DetailPanel>
    </React.Fragment>
  );
};
