import { AvailableDomain, ClientConfigs, ClientTokenResponse } from "../utils/types/client.type";
import { ClientTransMapping } from "../utils/types/clientTransMapping.type";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Api);
};

const authApiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.AuthApi);
};

export const getClientConfigsList = async () => await apiClient().get<ClientConfigs[]>("clients/configs");

export const getClientTransactionMapping = async (clientId:string) => {
  const transMapping = await apiClient().get<ClientTransMapping>(`clients/${clientId}/transactionMapping`);

  return transMapping;
};

export const updateTransactionMapping = async (clientId: string, transactionMapping: ClientTransMapping) => await apiClient().put(`clients/${clientId}/transactionMapping`, transactionMapping);

export const getARKClientTags = async () => await apiClient().get<string[]>("users/arkclients");

export const getAvailableDomains = async () => await apiClient().get<AvailableDomain[]>("domains/available");

export const checkIfSubdomainIsAvailable = async (domain: string, subdomain: string, hostedZoneId?: string) => await apiClient().get<boolean>(`domains/${domain}/subdomains/${subdomain}/hostedZones/${hostedZoneId}`);

export const getClientToken = async (clientId: string) => await authApiClient().get<ClientTokenResponse>(`token/client?clientId=${clientId}`);

export const checkMFASettings = async (clientId: string) => await apiClient().get(`api/clients/${clientId}/mfaSettings`);
