import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TrashIcon from '@mui/icons-material/DeleteOutline';
import { FormControl, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { RouteProps } from 'react-router-dom';

import Button from '../../../../components/Buttons/Button/Button';
import DetailPanel from '../../../../components/DetailPanel/DetailPanel';
import { PanelBoxExpanded } from '../../../../components/DetailPanel/DetailPanel.styles';
import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import ProgressPanel from '../../../../components/Progress/ProgressPanel/ProgressPanel';
import JEAccountFilter from '../../../../components/Selectors/JEAccountFilter/JEAccountFilter';
import JEEntityFilter from '../../../../components/Selectors/JEEntityFilter/JEEntityFilter';
import JEFundFilter from '../../../../components/Selectors/JEFundFilter/JEFundFilter';
import JELedgerFilter from '../../../../components/Selectors/JELedgerFilter/JELedgerFilter';
import StatusLabel from '../../../../components/StatusLabel/StatusLabel';
import TextField from '../../../../components/TextField/TextField';
import DecimalFormatCustom from '../../../../components/TextFieldNumeric/DecimalFormat';
import {
  DateTimeFormat,
  FindLengthOfFraction,
} from '../../../../utils/helpers/format.helper';
import {
  AccountMatchedBankTransaction,
  BankAccount,
  nameIdFundPair,
  NameIdPair,
} from '../../../../utils/types/bank.type';
import { LoadingStatus } from '../../../../utils/types/form.type';
import {
  ButtonBox,
  DatePicker,
  GridBlue,
  Label,
  Line,
  LineCell,
  LineHeader,
  LineItemBox,
  LineItemList,
  LineTextField,
  StatusBox,
  StyledLateralBox,
} from '../../../arkGL/journalEntries/journalEntryDetails/JournalEntryDetails.styles';
import { BANK_TRANS_UI_STATUS_VALUE } from '../../../banks/bankFeeds/BankFeedList.defaults';
import { useBanksJournalEntryPreview } from './banksJournalEntryPreview.hooks';

interface Props extends RouteProps {
  selectedBankTransaction: AccountMatchedBankTransaction;
  isReadOnly: boolean;
  onDetailsClose: () => void;
  fundList: NameIdPair[];
  ledgerList: nameIdFundPair[];
  glAccountList: nameIdFundPair[];
  bankAccountList: BankAccount[];
  memoEntityList: NameIdPair[];
  fetchBankTransactions: () => void;
}

const StatusType = {
  pending: 'yellow',
  excluded: 'red',
};

export const BanksJournalEntryPreview: React.FC<Props> = ({
  selectedBankTransaction,
  isReadOnly,
  onDetailsClose,
  fundList,
  ledgerList,
  glAccountList,
  bankAccountList,
  memoEntityList,
  fetchBankTransactions,
}: Props) => {
  const { journalEntry, isPlaid, status } = selectedBankTransaction;

  const {
    isLoading,
    previewTitle,
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    controlledFields,
    fieldValues,
    accountList,
    totalDebit,
    totalCredit,
    totalError,
    handleAddLine,
    handleDeleteLine,
    showExitConfirmation,
    closeDrawer,
    keepDrawerOpen,
    toggleDrawer,
    onSubmit,
  } = useBanksJournalEntryPreview({
    selectedBankTransaction,
    bankAccountList,
    onDetailsClose,
    fetchBankTransactions,
  });

  return (
    <>
      <DetailPanel
        id="banks_journal_entry_preview"
        title={previewTitle}
        open={Boolean(selectedBankTransaction)}
        hasTabsPanel={false}
        onClose={toggleDrawer}
        isGridDisplay={true}
      >
        <StyledLateralBox overflow="scroll">
          <form
            autoComplete="off"
            onSubmit={handleSubmit((data) => onSubmit(data))}
            noValidate
          >
            <PanelBoxExpanded>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <StatusBox>
                    <StatusLabel
                      color={
                        StatusType[
                          status?.toLowerCase() as keyof typeof StatusType
                        ]
                      }
                      label={BANK_TRANS_UI_STATUS_VALUE[status]}
                    />
                    {status === 'PENDING' && (
                      <Typography>
                        <small>
                          <strong>
                            This Bank transaction is currently pending. To
                            create a journal entry, change the status of the
                            bank transaction to categorized.
                          </strong>
                        </small>
                      </Typography>
                    )}
                  </StatusBox>
                </Grid>

                <GridBlue item xs={12} sm={2} md={2} lg={2}>
                  <Controller
                    name="date"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        label="Journal Date"
                        disabled={isPlaid || isReadOnly}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="txt_journal_preview_date"
                            label="Journal Date"
                            aria-describedby="date"
                            placeholder="Journal Date"
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={!!error}
                            helperText={error?.message ?? ''}
                          />
                        )}
                        onChange={(value) => {
                          onChange(value);
                        }}
                        value={value ? DateTimeFormat.shortDate(value) : null}
                      />
                    )}
                    rules={{
                      required: 'Date is required.',
                    }}
                    control={control}
                  />
                </GridBlue>
                <GridBlue item xs={12} sm={3} md={3} lg={3}>
                  <JEFundFilter
                    size="small"
                    id={'fund_filter'}
                    idSelectOnly
                    value={selectedBankTransaction.fundId}
                    disabled={isPlaid || isReadOnly}
                    placeholder="Fund Name"
                  />
                </GridBlue>
                <GridBlue item xs={12} sm={3} md={3} lg={3}>
                  <Controller
                    name="ledgerId"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <JELedgerFilter
                        size="small"
                        id={'gl_filter'}
                        idSelectOnly
                        value={value || null}
                        onChange={() => {}}
                        ledgerList={ledgerList}
                        loading={undefined}
                        setSelectedLedgerCurrency={() => {}}
                        setSelectedLedgerDecimal={() => {}}
                        error={error}
                        placeholder="GL Name"
                        disabled={isPlaid || isReadOnly}
                      />
                    )}
                    rules={{
                      required: 'GL is required',
                    }}
                    control={control}
                  />
                </GridBlue>
                <Grid item xs={12}>
                  <LineItemBox>
                    <LineItemList>
                      <Grid container>
                        <LineHeader>
                          <LineCell item xs={0.3} br>
                            <Label bold>#</Label>
                          </LineCell>
                          <LineCell item xs={2.5} br>
                            <Label bold>ACCOUNT</Label>
                          </LineCell>
                          <LineCell item xs={1.8} br>
                            <Label bold number>
                              {selectedBankTransaction.currency
                                ? `DEBITS (${selectedBankTransaction.currency})`
                                : 'DEBITS'}
                            </Label>
                          </LineCell>
                          <LineCell item xs={1.8} br>
                            <Label bold number>
                              {selectedBankTransaction.currency
                                ? `CREDITS (${selectedBankTransaction.currency})`
                                : 'CREDITS'}
                            </Label>
                          </LineCell>
                          <LineCell item xs={2.8} br>
                            <Label bold>DESCRIPTION</Label>
                          </LineCell>
                          <LineCell item xs={2.2}>
                            <Label bold>MEMO TAGS</Label>
                          </LineCell>
                          <LineCell item xs={0.5}></LineCell>
                        </LineHeader>
                        {selectedBankTransaction.status !== 'CATEGORIZED' &&
                          controlledFields.map((field: any, index: number) => {
                            return (
                              <Line key={index}>
                                <LineCell item xs={0.3} br>
                                  <Label>{index + 1}</Label>
                                </LineCell>
                                <LineCell item xs={2.5} br>
                                  <Controller
                                    name={`journalEntry.${index}.accountId`}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <JEAccountFilter
                                        {...field}
                                        size="small"
                                        id={`account_${index}`}
                                        idSelectOnly
                                        disabled={
                                          field.isBankTransactionEntry ||
                                          isReadOnly
                                        }
                                        value={
                                          fieldValues[index]?.accountId
                                            ? value
                                            : ''
                                        }
                                        accountList={accountList}
                                        loading={
                                          isLoading === LoadingStatus.Loading
                                        }
                                        onChange={(accountId) => {
                                          onChange(accountId);
                                          const account = accountList.find(
                                            (acc) => acc.id === accountId
                                          );
                                        }}
                                        setValue={setValue}
                                        setValueField={`journalEntry.${index}.isEntityRequired`}
                                        error={error}
                                      />
                                    )}
                                    rules={{
                                      required: !!fieldValues[index]?.amount,
                                    }}
                                    control={control}
                                  />
                                </LineCell>
                                <LineCell item xs={1.8} br>
                                  <Controller
                                    name={`journalEntry.${index}.amount`}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <LineTextField
                                        {...field}
                                        id={`debit_${index}`}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        number
                                        value={
                                          fieldValues[index]?.type === 'DEBIT'
                                            ? value
                                            : ''
                                        }
                                        onChange={(e) => {
                                          let newValue: any = parseFloat(
                                            e.target.value
                                          ) as number;

                                          if (newValue) {
                                            newValue =
                                              newValue > 0
                                                ? newValue
                                                : fieldValues[index].amount;
                                          }

                                          if (newValue >= 0) {
                                            setValue(
                                              `journalEntry.${index}.type`,
                                              'DEBIT'
                                            );
                                          } else {
                                            setValue(
                                              `journalEntry.${index}.type`,
                                              ''
                                            );
                                          }
                                          onChange(newValue);
                                        }}
                                        disabled={
                                          fieldValues[index]?.type ===
                                            'CREDIT' ||
                                          field.isBankTransactionEntry ||
                                          isReadOnly
                                        }
                                        inputProps={{
                                          decimalPlaces: FindLengthOfFraction(
                                            selectedBankTransaction.currency
                                          ),
                                          fixedDecimalScale: true,
                                          allowNegative: false,
                                        }}
                                        onBlur={() => {
                                          if (value === 0) {
                                            setValue(
                                              `journalEntry.${index}.type`,
                                              ''
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          inputComponent: DecimalFormatCustom,
                                        }}
                                        error={!!error}
                                      />
                                    )}
                                    rules={{
                                      required: fieldValues[index]?.accountId,
                                    }}
                                    control={control}
                                  />
                                </LineCell>
                                <LineCell item xs={1.8} br>
                                  <Controller
                                    name={`journalEntry.${index}.amount`}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <LineTextField
                                        {...field}
                                        id={`credit_${index}`}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        number
                                        value={
                                          fieldValues[index]?.type === 'CREDIT'
                                            ? value
                                            : ''
                                        }
                                        onChange={(e) => {
                                          let newValue: any = parseFloat(
                                            e.target.value
                                          ) as number;

                                          if (newValue) {
                                            newValue =
                                              newValue > 0
                                                ? newValue
                                                : fieldValues[index].amount;
                                          }

                                          if (newValue >= 0) {
                                            setValue(
                                              `journalEntry.${index}.type`,
                                              'CREDIT'
                                            );
                                          } else {
                                            setValue(
                                              `journalEntry.${index}.type`,
                                              ''
                                            );
                                          }
                                          onChange(newValue);
                                        }}
                                        disabled={
                                          fieldValues[index]?.type ===
                                            'DEBIT' ||
                                          field.isBankTransactionEntry ||
                                          isReadOnly
                                        }
                                        inputProps={{
                                          decimalPlaces: FindLengthOfFraction(
                                            selectedBankTransaction.currency
                                          ),
                                          fixedDecimalScale: true,
                                          allowNegative: false,
                                        }}
                                        onBlur={() => {
                                          if (value === 0) {
                                            setValue(
                                              `journalEntry.${index}.type`,
                                              ''
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          inputComponent: DecimalFormatCustom,
                                        }}
                                        error={!!error}
                                      />
                                    )}
                                    rules={{
                                      required: fieldValues[index]?.accountId,
                                    }}
                                    control={control}
                                  />
                                </LineCell>
                                <LineCell item xs={2.8} br>
                                  <Controller
                                    name={`journalEntry.${index}.memo`}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <LineTextField
                                        disabled={
                                          field.isBankTransactionEntry ||
                                          isReadOnly
                                        }
                                        {...field}
                                        id={`description_${index}`}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        value={
                                          fieldValues[index]?.memo ? value : ''
                                        }
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error?.message || ''}
                                      />
                                    )}
                                    rules={{
                                      maxLength: 255,
                                      minLength: 0,
                                    }}
                                    control={control}
                                  />
                                </LineCell>
                                <LineCell item xs={2.4}>
                                  <Controller
                                    name={`memoEntity`}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <FormControl
                                        disabled={isReadOnly}
                                        fullWidth
                                      >
                                        <JEEntityFilter
                                          {...field}
                                          size="small"
                                          id={`entity_${index}`}
                                          idSelectOnly
                                          options={memoEntityList}
                                          value={value}
                                          setValue={setValue}
                                          setValueField={`memoEntity`}
                                          onChange={(listLenght) => {
                                            listLenght < 2
                                              ? setValue(`memoEntity`, value)
                                              : setValue(`memoEntity`, [
                                                  value[1],
                                                ]);
                                            trigger('memoEntity');
                                          }}
                                          error={error}
                                        />
                                      </FormControl>
                                    )}
                                    rules={{
                                      required:
                                        fieldValues[index]?.isEntityRequired ||
                                        selectedBankTransaction.isEntityRequiredByBankAcc,
                                    }}
                                    control={control}
                                  />
                                </LineCell>
                                <LineCell item xs={0.5}>
                                  {!field.isBankTransactionEntry &&
                                    !isReadOnly && (
                                      <Label number>
                                        <IconButton
                                          onClick={() =>
                                            handleDeleteLine(index)
                                          }
                                        >
                                          <TrashIcon color="error" />
                                        </IconButton>
                                      </Label>
                                    )}
                                </LineCell>
                              </Line>
                            );
                          })}
                        <LineCell item xs={0.3}>
                          <Label bold></Label>
                        </LineCell>
                        <LineCell item xs={2.5}>
                          <Label bold number>
                            TOTAL
                          </Label>
                        </LineCell>
                        <LineCell item xs={1.8}>
                          <LineTextField
                            id="total_debit"
                            size="small"
                            variant="outlined"
                            fullWidth
                            number
                            disabled={isReadOnly}
                            value={totalDebit}
                            error={totalError}
                            inputProps={{
                              decimalPlaces: FindLengthOfFraction(
                                selectedBankTransaction.currency
                              ),
                              fixedDecimalScale: true,
                            }}
                            InputProps={{
                              inputComponent: DecimalFormatCustom,
                            }}
                          />
                        </LineCell>
                        <LineCell item xs={1.8}>
                          <LineTextField
                            id="total_credit"
                            size="small"
                            variant="outlined"
                            fullWidth
                            number
                            disabled={isReadOnly}
                            value={totalCredit}
                            error={totalError}
                            inputProps={{
                              decimalPlaces: FindLengthOfFraction(
                                selectedBankTransaction.currency
                              ),
                              fixedDecimalScale: true,
                            }}
                            InputProps={{
                              inputComponent: DecimalFormatCustom,
                            }}
                          />
                        </LineCell>
                        <LineCell item xs={3}></LineCell>
                        <LineCell item xs={2.5}></LineCell>
                        <LineCell item xs={0.5}></LineCell>
                      </Grid>
                    </LineItemList>
                  </LineItemBox>
                </Grid>
                <Grid item xs={12}>
                  {!isReadOnly && (
                    <Button
                      id="btn_add_new_je_line"
                      color="secondary"
                      addSpaceBetweenButtons
                      onClick={handleAddLine}
                      icon={<AddCircleOutlineIcon />}
                      text="Add New Line"
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Controller
                    name="description"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextField
                          id="txt_memo"
                          label="Memo"
                          aria-describedby="memo"
                          placeholder="Memo"
                          size="small"
                          multiline
                          rows={2}
                          fullWidth
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          disabled={
                            selectedBankTransaction.isPlaid || isReadOnly
                          }
                        />
                      );
                    }}
                    control={control}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  {true && (
                    <ButtonBox>
                      <Button
                        id={'btn_banks_je_preview_cancel'}
                        variant="outlined"
                        onClick={toggleDrawer}
                        text={'Cancel'}
                        color={'secondary'}
                      />
                      {!isReadOnly && (
                        <Button
                          id={'btn_banks_je_preview_save'}
                          variant="contained"
                          text={'Save'}
                          color="primary"
                          type={'submit'}
                        />
                      )}
                    </ButtonBox>
                  )}
                </Grid>
              </Grid>
            </PanelBoxExpanded>
          </form>
          <ProgressPanel
            id={'progress_journal_entry_preview_panel'}
            showProgress={isLoading === LoadingStatus.Updating}
            text={`${isLoading} journal entry preview...`}
          />
        </StyledLateralBox>
      </DetailPanel>
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_journal_entr_preview_confirmation"
        actions={[
          {
            label: 'Continue Editing',
            onClick: keepDrawerOpen,
            id: 'btn_je_preview_keep',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Discard Changes',
            onClick: closeDrawer,
            id: 'btn_je_preview_cancel_discard',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content={'Unsaved changes will be lost'}
        title={'You have Unsaved Changes'}
      />
    </>
  );
};
