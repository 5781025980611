import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { GridRowModel } from "@mui/x-data-grid-pro";
import { useState } from "react";

import { M_DASH_UNICODE } from "../../../utils/constants/constants";
import { DataGridColDef } from "../../../utils/types/listItems";
import ChipsCell from "./ChipsCell";

type Props = {
  header: DataGridColDef;
  items: any;
  row: GridRowModel;
};

const ChipCellStack = ({ header, items, row }: Props) => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <Stack
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: "100%",
      }}
    >
      {items.length === 0 ? (
        <Typography variant="cells">{M_DASH_UNICODE}</Typography>
      ) : (
        <ChipsCell
          name={row.name || ""}
          items={items}
          chipLabelField={header.chipLabelField ?? ""}
          chipIDField={header.chipIDField ?? ""}
          chipCount={header.chipCount ?? 1}
          onChipClick={header.onChipClick}
          toggleVisibilityOnClick={header.toggleVisibilityOnClick}
        />
      )}
      {header.renderOnHover && hover && header.renderOnHover?.(row)}
    </Stack>
  );
};

export default ChipCellStack;
