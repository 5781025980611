import { Box, Select, styled, Typography } from "@mui/material";

export const FilterBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "error",
})<{
  error?: boolean;
}>(({ theme, error }) => ({
  width: "100%",
  border: error ? "1px solid red" : "0",
  ".MuiInput-input:focus": {
    background: "none",
  },
}));

export const TypeLabel = styled(Typography)(() => ({
  lineHeight: "3",
}));

export const OptionList = styled("div")(() => ({
  maxWidth: "320px",
  maxHeight: "200px",
  overflow: "auto",
  padding: "0 15px",
}));

export const Dropdown = styled(Select)(() => ({
  width: "100%",
}));

export const ChipBox = styled(Box)(() => ({
  width: "90%",
  padding: "5px",

  ".MuiBox-root": {
    flexFlow: "wrap",
  },
}));
