import CloseIcon from '@mui/icons-material/Close';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';
import { RouteProps } from 'react-router-dom';

import HorizontalBox from '../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../components/Buttons/Button/Button';
import ProgressModal from '../../../components/Progress/ProgressModal/ProgressModal';
import SearchBar from '../../../components/SearchBarWithDebounce/SearchBar';
import { AppContext } from '../../../core/context/appContextProvider';
import {
  ArrowIcon,
  BorderBox,
  ColumnsBox,
  DetailPanel,
  FundItem,
  MarginBox,
  QuarterItem,
  ScrollBox,
  SearchBox,
  SectionBox,
  TitleBox
} from './PublishQuarters.styles';
import { usePublishQuarters } from './usePublishQuarters.hooks';

interface Props extends RouteProps {
  onPublishQuartersViewClose: Function;
}

const PublishQuartersView: React.FC<Props> = ({
  onPublishQuartersViewClose,
}: Props): ReactElement => {
  const { state } = React.useContext(AppContext);
  const clientId = state?.loginUser?.clientId ?? '';
  const {
    toggleDrawer,
    isLoading,
    searchQuery,
    setSearchQuery,
    allFunds,
    selectedFund,
    quartersList,
    setSelectedFund,
    onPublish
  } = usePublishQuarters({
    onPublishQuartersViewClose, clientId
  });

  return (
    <React.Fragment>
      <DetailPanel
        id="publish_quarters"
        anchor="right"
        open={true}
        onClose={toggleDrawer}
      >
        <ProgressModal
          id="cap_acc_pub_quarter_loading"
          showProgress={isLoading}
        />
        <TitleBox>
          <Box>
            <Typography
              id="publish_drawer_title"
              variant="h1"
              display="flex"
            >
              Publish
            </Typography>
          </Box>
          <CloseIcon color="primary" onClick={toggleDrawer} />
        </TitleBox>
        <MarginBox>
          <ColumnsBox>
            <SectionBox>
              <Typography
                id="fund_section_title"
                variant="subtitle1"
                display="flex"
              >
                Fund Name
              </Typography>
              <BorderBox>
                <SearchBar
                  id="funds_search_publish"
                  onChange={setSearchQuery}
                  value={searchQuery}
                />
                <ScrollBox>
                  {
                    allFunds.reduce((fundsList: any[], fund) => {
                      //show all when no search query
                      if (!searchQuery ||
                        //lowercase filter for search query
                        fund.fundName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        //to show selected fund also in the "searched" list
                        selectedFund?.fund.id === fund.fund.id)
                        fundsList.push(
                          <FundItem
                            key={fund.fund.id}
                            className={selectedFund?.fund.id === fund.fund.id ? 'selected' : ''}
                            onClick={() => setSelectedFund(fund)}
                          >
                            <Typography
                              id={`fund_name_${fund.fund.id}`}
                              variant="body1"
                              display="flex">
                              {fund.fundName}
                            </Typography>
                            <ArrowIcon />
                          </FundItem>
                        );
                      return fundsList;
                    }, [])
                  }
                </ScrollBox>
              </BorderBox>
            </SectionBox>
            <SectionBox>
              <Typography
                id="quarter_section_title"
                variant="subtitle1"
                display="flex"
              >
                Quarters List
              </Typography>
              <BorderBox>
                <SearchBox />
                <ScrollBox>
                  {quartersList.map(quarter => (
                    <QuarterItem key={quarter.quarterId}>
                      <Typography
                        id={`quarter_${quarter.quarterId}`}
                        variant="body1"
                        display="flex">
                        {quarter.quarter}
                      </Typography>
                      <FormControlLabel
                        id={`${quarter.quarterId}_switch_label`}
                        label={quarter.published ? "Published" : "Unpublished"}
                        control={
                          <Switch
                            id={`${quarter.quarterId}_switch`}
                            checked={quarter.published ?? false}
                            onChange={() => onPublish(quarter.quarterId, !quarter.published)}
                          />
                        }
                      />
                    </QuarterItem>
                  ))}
                </ScrollBox>
              </BorderBox>
            </SectionBox>
          </ColumnsBox>
        </MarginBox>
        <HorizontalBox
          addTopShadow
          attachToBottom={true}
          hidden={false}
          fullWidth
          stickToCenter
        >
          <Button
            id={"publish_close_button"}
            variant="contained"
            text={"Save & Close"}
            color={"primary"}
            type="button"
            addSpaceBetweenButtons
            onClick={toggleDrawer}
          />
        </HorizontalBox>
      </DetailPanel>
    </React.Fragment>
  );
};

export default PublishQuartersView;
