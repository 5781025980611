import { Autocomplete,
  Button,
Card,   Divider,
  FormControlLabel,
FormGroup,   InputAdornment,
  Popover,
styled,  TextField } from "@mui/material";


export const StyledParentCard = styled(Card)`
  max-height: 360px;
  padding: 15px;
  margin-right: 10px;
  margin-left: 20px;
  margin-top: 5px;
`;

export const StyledFormGroup = styled(FormGroup)`
  margin-left: "10px";
  margin-top: "5px";
`;

export const FundContent = styled("div")(() => ({
  marginLeft: "10px",
  marginTop: "5px",
}));

export const ChipsContent = styled("div")(() => ({
  maxWidth: "90%",
}));

export const StyledChecksCard = styled(Card)`
  max-height: 200px;
  border: 0px;
  overflow: auto;
`;



export const SearchBarInput = styled(TextField)`
  padding: 10px;
  fieldset {
    border-radius: 50px;
    border: 1px solid #919195;
  }

  input {
    width: 100%;
    padding-right: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 0px !important;
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #919195;
  }
`;

export const SearchBarInputPopover = styled(TextField)`
  & .MuiInput-input {
    height: 52px;
  }
  padding-top: 2px;
  padding-bottom: 2px;
`;

export const AutoCompleteInput = styled(Autocomplete)`
  width: 300px;
`;

export const SearchInputAdornment = styled(InputAdornment)`
  padding-left: 10px;
`;

export const ButtonText = styled(Button)`
  font-style: normal;
  font-weight: bold;
  text-transform: initial;
  font-size: 16px;
  line-height: 19px;
`;

export const HeaderTitle = styled("div")`
  text-align: end;
`;

export const AutoCompletePopover = styled(Popover)`
  & .MuiPaper-root {
    border: 1px solid #919195;
  }
`;

export const AutoCompleteDivider = styled(Divider)`
  margin-top: -10px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
  background: #919195;
`;

export const FormControlCheckAll = styled(FormControlLabel)`
  & .MuiTypography-root {
    font-weight: bold;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  white-space: nowrap;
`;