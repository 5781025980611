import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  GridAlignment,
  GridCellParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import format from "date-fns/format";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { StringCell } from "../../../components/DataGrid/DataGrid.styles";
import useInvestorFilter from "../../../components/Selectors/InvestorsFilter/useInvestorFilter.hook";
import { AppContext } from "../../../core/context/appContextProvider";
import useRole from "../../../core/routing/useRole";
import {
  downloadAllCapAccReportPdf,
  downloadCapAccReportExcel,
  downloadSelectedCapAccReportPdf,
  downloadSummaryData,
  getCapitalAccounts,
} from "../../../services/capitalAccounts.service";
import { useFundFilterEffect } from "../../../services/hooks/useFundFilterEffect/useFundFilterEffect";
import { useInvestorsFilterEffect } from "../../../services/hooks/useInvestorsFilterEffect/useInvestorsFilterEffect.hooks";
import { useInvestorsNamesEffect } from "../../../services/hooks/useInvestorsNameEffect/useInvestorsNamesEffect.hooks";
import { getInvestorsNames } from "../../../services/investor.service";
import { M_DASH_UNICODE, PAGE_SIZE } from "../../../utils/constants/constants";
import downloadFile from "../../../utils/helpers/fileDownloader";
import {
  CurrencyFormat,
  NumberFormat,
} from "../../../utils/helpers/format.helper";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import {
  CapAccExportActions,
  CapitalAccount,
  CapitalAccountsFilter,
  CapitalAccountsParams,
  Fund,
  SelectedFund,
  SelectedTransaction,
  Transaction,
} from "../../../utils/types/capitalAccounts.type";
import { InvestorsNames } from "../../../utils/types/investor.type";
import {
  CustomType,
  DataGridColDef,
  ImageItem,
} from "../../../utils/types/listItems";
import { ScopeRole } from "../../../utils/types/user.type";
import { CellBox } from "./CapitalAccountList.styles";
import { useCapAccColumnsEffect } from "./useCapAccColumnsEffect.hooks";
import { useCapAccQuartersEffect } from "./useCapAccQuartersEffect.hooks";

const defaultHeaderList: DataGridColDef[] = [
  {
    field: "quarter",
    headerName: "Quarter",
    hide: false,
    index: 10,
    sortable: false,
    type: "string",
    align: "left" as GridAlignment,
    maxWidth: 120,
  },
  {
    field: "action",
    headerName: "Statement",
    hide: false,
    hideable: false,
    index: 11,
    type: "action",
    customType: CustomType.Action,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    width: 100,
  },
];

const initialSelectedFund: SelectedFund = {
  fundId: undefined,
  fundName: undefined,
  investorId: undefined,
  investorName: undefined,
  quarter: undefined,
  currencyCode: "USD",
};

const initialSelectedTransaction: SelectedTransaction = {
  fundId: undefined,
  fundName: undefined,
  investorId: undefined,
  investorName: undefined,
  quarter: undefined,
  transactionType: undefined,
  currencyCode: "USD",
};

type TransactionCode = {
  label: string;
  code: string;
};

export const useCapitalAccounts = () => {
  const [page, setPage] = useState<number>(1);
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [capitalAccountsList, setCapitalAccountsList] = useState<CapitalAccount[]>();
  const [capitalAccountsResponse, setCapitalAccountsResponse] = useState<CapitalAccount[]>();
  const [openPublishQuarters, setOpenPublishQuarters] = useState<boolean>(false);
  const [unformattedCapAccList, setUnformattedCapAccList] = useState<CapitalAccount[]>([]);
  const [selectedFund, setSelectedFund] = useState<SelectedFund>(initialSelectedFund);
  const [selectedQuarter, setSelectedQuarter] = useState<string[]>([]);
  const [selectedTransaction, setSelectedTransaction] = useState<SelectedTransaction>(initialSelectedTransaction);
  const [selectedCapAccRowIds, setSelectedCapAccRowIds] = useState<any[]>([]);
  const [selectedFundIdsForBulkActions, setSelectedFundIdsForBulkActions] = useState<any[]>([]);
  const [selectedFundIdsForParamsLength, setSelectedFundIdsForParamsLength] = useState(0);
  const [showExportPdfCapAccReportPdfConfirm, setShowExportPdfCapAccReportPdfConfirm] = useState(false);

  const [transactionCodes, setTransactionCodes] = useState<TransactionCode[]>([]);

  const { informationAlert } = useContext(AppContext);

  capitalAccountsList?.forEach((account) => {
    if (account.quarter === undefined) {
      account.quarter = " ";
    }
  });

  const { hasRole: isAdmin } = useRole([
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.SUPER_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);

  const { hasRole: isClientPortalLogin } = useRole([ScopeRole.BASIC_USER]);

  const { investorsList, loading: loadingInvestors } =
    useInvestorsFilterEffect();

  const { fundList, loading: loadingFunds } = useFundFilterEffect();

  const { columnsList, loading: loadingColumns } = useCapAccColumnsEffect();

  const { quartersList, loading: loadingQuarters } = useCapAccQuartersEffect(false);

  const investors = useMemo(
    () => investorsList?.map((item) => item.id),
    [investorsList]
  );
  const funds = useMemo(() => fundList?.map((item) => item.id), [fundList]);
  // const quarters = useMemo(() => quartersList?.map(item => item.name), [quartersList]);
  const hasOneFundOnly =
    funds?.length === 1 || selectedFundIdsForParamsLength === 1;

  const bulkActions: ImageItem[] = useMemo(() => {
    const actions = [];
    const allSummaryData = {
      id: CapAccExportActions.AllSummaryData,
      text: "Summary Data, all (Excel)",
      optionsSelected: 0,
    };
    const selectedSummaryData = {
      id: CapAccExportActions.SelectedSummaryData,
      text: `Summary Data, selected [${selectedFundIdsForBulkActions?.length || 0
        }] (Excel)`,
      optionsSelected: 0,
    };
    const allCapAccReportPdf = {
      id: CapAccExportActions.AllCapAccReportPDF,
      text: "Capital Account Report, all (PDF)",
      optionsSelected: 0,
    };
    const allCapAccReportExcel = {
      id: CapAccExportActions.AllCapAccReportExcel,
      text: "Capital Account Report, all (Excel)",
      optionsSelected: 0,
    };
    const selectedCapAccReportPdf = {
      id: CapAccExportActions.SelectedCapAccReportPDF,
      text: `Capital Account Report, selected [${selectedFundIdsForBulkActions?.length || 0
        }] (PDF)`,
      optionsSelected: 0,
    };
    const selectedCapAccReportExcel = {
      id: CapAccExportActions.SelectedCapAccReportExcel,
      text: `Capital Account Report, selected [${selectedFundIdsForBulkActions?.length || 0
        }] (Excel)`,
      optionsSelected: 0,
    };

    actions.push(allSummaryData);
    if (selectedFundIdsForBulkActions?.length > 0) {
      actions.push(selectedSummaryData);
    }
    if (hasOneFundOnly) actions.push(allCapAccReportExcel);
    actions.push(allCapAccReportPdf);
    if (selectedFundIdsForBulkActions?.length > 0) {
      if (hasOneFundOnly) actions.push(selectedCapAccReportExcel);
      actions.push(selectedCapAccReportPdf);
    }

    return actions;
  }, [selectedFundIdsForBulkActions, hasOneFundOnly]);

  const initializeHeaderList = (columns: string[]) => {
    const indexBuffer = 3;
    const startHeaders: DataGridColDef[] =
      headerList.length > 0
        ? [
          {
            ...headerList[0],
            headerName: hasOneFundOnly ? "Investor" : "Investor & Fund",
          },
        ]
        : [
          {
            field: "invFundName",
            headerName: hasOneFundOnly ? "Investor" : "Investor & Fund",
            index: 1,
            sortable: false,
            type: "string",
            width: 250,
            hide: false,
            inlineFilter: true,
            inlineFilterName: "Split filter for Investor and Fund",
            inlineSplitFilters: [
              {
                inlineFilterName: CapitalAccountsFilter.InvestorIds,
                inlineFilterIDField: "id",
                inlineFilterLabelField: "name",
                inlineFilterOptions: investorsList || [],
                inlineFilterSelected: investors || [],
                inlineFilterTitle: "Investors",
              },
              {
                inlineFilterName: CapitalAccountsFilter.FundIds,
                inlineFilterIDField: "id",
                inlineFilterLabelField: "name",
                inlineFilterOptions: fundList || [],
                inlineFilterSelected: funds || [],
                inlineFilterTitle: "Funds",
              },
            ],
            cellClassName: (params) => {
              return `${params?.row?.isParent ? "investor-row" : ""}`;
            },
            renderCell: (params: GridRenderCellParams) => {
              return (
                <CellBox>
                  <Typography align={params?.row?.isChild ? "right" : "left"}>
                    {params?.row?.invFundName || ""}
                  </Typography>
                </CellBox>
              );
            },
          },
        ];

    if (hasOneFundOnly) {
      startHeaders.push({
        field: "fundName",
        headerName: "Fund",
        hide: false,
        index: 2,
        sortable: false,
        type: "string",
        align: "left" as GridAlignment,
        width: 200,
        renderCell: (params: GridRenderCellParams) => {
          return <StringCell>{params?.row?.fundName || ""}</StringCell>;
        },
      });
    }

    const amountColumns = columns.map<DataGridColDef>(
      (col: string, i: number) => {
        return {
          field: col,
          headerName: col,
          hide: false,
          index: i + indexBuffer,
          type: "number",
          customType: CustomType.Currency,
          sortable: false,
          currencyCodeField: "currencyCode",
          align: "right" as GridAlignment,
          width: 175,
          cellClassName: (params) => {
            return `${params?.row?.isParent && !hasOneFundOnly ? "investor-row" : ""
              } cap-amount`;
          },
          renderCell: (params: GridRenderCellParams) => {
            const currencyCode = params.row.currencyCode || "USD";

            //TODO: This whole render cell is only for ARK-475.
            if (params?.row?.[col] !== 0 && !params?.row?.[col]) {
              return (
                <StringCell>
                  {params?.row?.isParent ? "" : M_DASH_UNICODE}
                </StringCell>
              );
            }

            const selfTransaction =
              params.row.isParent && params.row.funds
                ? params.row.funds[0].transactions.find(
                  (transaction: any) => transaction.label === col
                )
                : params.row.transactions.find(
                  (transaction: any) => transaction.label === col
                );

            if (params.row.isParent) {
              if (params.row[col] === 0 && params.row.funds) {
                return "";
              }
              if (selfTransaction.useMetric) {
                return (
                  <StringCell>
                    {NumberFormat(selfTransaction.metricFractionDigit).format(
                      params.row[col]
                    ) + selfTransaction.metricSign}
                  </StringCell>
                );
              }
              return (
                <StringCell>
                  {CurrencyFormat(
                    currencyCode,
                    selfTransaction.metricFractionDigit
                  ).format(params.row[col])}
                </StringCell>
              );
            }
            if (selfTransaction.useMetric) {
              return (
                <StringCell>
                  {NumberFormat(selfTransaction.metricFractionDigit).format(
                    params.row[col]
                  ) + selfTransaction.metricSign}
                </StringCell>
              );
            }
            return (
              <StringCell>
                {CurrencyFormat(
                  currencyCode,
                  selfTransaction.metricFractionDigit
                ).format(params.row[col])}
              </StringCell>
            );
          },
        };
      }
    );

    const updatedRemainingHeaders = defaultHeaderList.map((header, i) => {
      let newHeader: any = {
        ...header,
        index: i + indexBuffer + columns.length,
      };

      if (header.headerName === "Quarter") {
        newHeader = {
          ...newHeader,
          inlineFilter: true,
          inlineFilterName: CapitalAccountsFilter.QuarterName,
          inlineFilterIDField: "id",
          inlineFilterLabelField: "name",
          inlineFilterOptions: quartersList || [],
          inlineFilterSelected: selectedQuarter || [],
          singleSelectFilter: true,
          emptySelectionOnClear: true,
        };
      } else {
        newHeader = {
          ...newHeader,
          renderCell: (params: GridRenderCellParams) => {
            return (params.row.isChild || hasOneFundOnly) &&
              params.row.hasReport ? (
              <CellBox>
                <Button
                  id={`btn_data_grid_${params.row.id}`}
                  variant="text"
                  disableElevation
                  startIcon={<VisibilityIcon />}
                  onClick={() => handleOnViewClick(params.row.id, params.row)}
                  name="View button"
                >
                  View
                </Button>
              </CellBox>
            ) : null;
          },
        };
      }

      return newHeader;
    });

    const updatedHeaders = [
      ...startHeaders,
      ...amountColumns,
      ...updatedRemainingHeaders,
    ];

    setHeaderList(updatedHeaders);
  };

  useEffect(() => {
    if (
      funds &&
      funds?.length > 0 &&
      investors &&
      investors?.length > 0 &&
      columnsList &&
      columnsList?.length > 0 &&
      quartersList &&
      quartersList?.length > 0
    ) {
      initializeHeaderList(columnsList);
    }
  }, [investors, funds, columnsList, quartersList, hasOneFundOnly]);

  const handleFilter = async (filterName: any, selected: any[]) => {
    await setPage(1);
    if (typeof filterName === "string") {
      if (filterName === CapitalAccountsFilter.QuarterName) {
        setSelectedQuarter(selected);
      }
      setHeaderList((prevHeaderList) =>
        prevHeaderList?.map((header) => {
          if (header.inlineFilterName === filterName) {
            if (filterName === CapitalAccountsFilter.QuarterName) {
              return {
                ...header,
                inlineFilterSelected: selected,
                renderCell: (params: GridRenderCellParams) => {
                  return params.row.isChild ||
                    typeof params.row.quarter === "string"
                    ? params.row.quarter || selected[0]
                    : M_DASH_UNICODE;
                },
              };
            }
            return {
              ...header,
              inlineFilterSelected: selected,
            };
          } else if (header.field === "action") {
            return {
              ...header,
              renderCell: (params: GridRenderCellParams) => {
                return (params.row.isChild || hasOneFundOnly) &&
                  params.row.hasReport ? (
                  <CellBox>
                    <Button
                      id={`btn_data_grid_${params.row.id}`}
                      variant="text"
                      disableElevation
                      startIcon={<VisibilityIcon />}
                      onClick={() =>
                        handleOnViewClick(params.row.id, params.row, {
                          [filterName]: selected,
                        })
                      }
                      name="View button"
                    >
                      View
                    </Button>
                  </CellBox>
                ) : null;
              },
            };
          }
          return header;
        })
      );
    } else {
      const newHeaderList: DataGridColDef[] = headerList.map(
        (header: DataGridColDef) => {
          if (
            header.inlineSplitFilters &&
            filterName.includes(header.inlineSplitFilters[0].inlineFilterName)
          ) {
            const newInlineSplitFilters = header.inlineSplitFilters.map(
              (splitFilter) => {
                return {
                  ...splitFilter,
                  inlineFilterSelected:
                    selected[
                    filterName.findIndex(
                      (name: string) => name === splitFilter.inlineFilterName
                    )
                    ],
                };
              }
            );

            return {
              ...header,
              inlineSplitFilters: newInlineSplitFilters,
            };
          }
          if (filterName === CapitalAccountsFilter.QuarterName) {
            return {
              ...header,
              inlineFilterSelected:
                selectedQuarter || (header.inlineFilterSelected ?? []),
              renderCell: (params: GridRenderCellParams) => {
                return params.row.isChild ||
                  typeof params.row.quarter === "string"
                  ? params.row.quarter
                  : M_DASH_UNICODE;
              },
            };
          }
          return header;
        }
      );

      setHeaderList(newHeaderList);
    }
  };

  function getSelectedFilters(currentHeaderList: DataGridColDef[]) {
    return currentHeaderList?.reduce(
      (
        accumulator: Record<CapitalAccountsFilter, string[] | undefined>,
        header
      ) => {
        if (header.inlineFilter) {
          if (header.inlineSplitFilters) {
            let updatedFilters = { ...accumulator };

            header.inlineSplitFilters.forEach((filter) => {
              updatedFilters = {
                ...updatedFilters,
                [filter.inlineFilterName]: filter.inlineFilterSelected,
              };
            });
            return updatedFilters;
          } else if (
            header.inlineFilterSelected &&
            header.inlineFilterSelected?.length > 0
          ) {
            return {
              ...accumulator,
              [header.inlineFilterName ?? ""]: header.inlineFilterSelected,
            };
          }
        }

        return accumulator;
      },
      {
        [CapitalAccountsFilter.FundIds]: undefined,
        [CapitalAccountsFilter.InvestorIds]: undefined,
        [CapitalAccountsFilter.QuarterName]: undefined,
      }
    );
  }

  const getParams = (
    currentHeaderList: DataGridColDef[],
    currentPage: number
  ) => {
    const {
      [CapitalAccountsFilter.FundIds]: selectedFundIds,
      [CapitalAccountsFilter.InvestorIds]: selectedInvestorIds,
      [CapitalAccountsFilter.QuarterName]: quarterName,
    } = getSelectedFilters(currentHeaderList);

    const params: CapitalAccountsParams = {
      quarterName: quarterName?.[0] ?? "MRQ",
      offset: (currentPage - 1) * PAGE_SIZE,
      pageSize: PAGE_SIZE,
      isAll: false,
      fundIds: selectedFundIds || funds,
      investorIds: selectedInvestorIds || investors,
    };

    setSelectedFundIdsForParamsLength(selectedFundIds?.length ?? 0);

    return {
      investors,
      funds,
      params,
    };
  };

  const onNextPage = async () => {
    if (isLastPage || !capitalAccountsList) return;
    await fetchCapitalAccounts(headerList, (page as number) + 1, true);
    await setPage((page as number) + 1);
  };

  const fetchCapitalAccounts = useCallback(
    async (
      currentHeaderList: DataGridColDef[],
      pageNumber: number,
      hasPageChange?: boolean,
      isCanceled?: () => boolean
    ) => {
      const { investors, funds, params } = getParams(
        currentHeaderList,
        pageNumber
      );

      if (funds && investors && funds?.length > 0 && investors?.length > 0) {
        try {
          if (!hasPageChange) {
            setIsLoadingList(true);
          }
          const statusResponse: CapitalAccount[] = (await getCapitalAccounts(
            params
          )) as CapitalAccount[];

          /**
           * Response data: [{investor: id, investorname, funds: {id, fundname, columns: [col1, col2]]}]}]
           * Expected data: [{id, investorName, fundName, col1, col2, col3, quarte}]
           */

          const data = statusResponse.map((capAcc) => {
            let datum: any = {
              id: capAcc.investorId,
              investorId: capAcc.investorId,
              invFundName: capAcc.investorName,
              investorName: capAcc.investorName,
              isParent: true,
            };

            if (transactionCodes.length === 0) {
              const transactionCodeObjects: TransactionCode[] = [];

              capAcc.funds[0].transactions.map(
                (transaction: TransactionCode) => {
                  transactionCodeObjects.push({
                    label: transaction.label,
                    code: transaction.code,
                  });
                }
              );
              setTransactionCodes(transactionCodeObjects);
            }

            if (hasOneFundOnly) {
              // if only one fund, then a simple single row
              const fund = capAcc.funds[0];

              datum = {
                ...datum,
                fundId: fund.fundId,
                currencyCode: fund.currencyCode,
                hasReport: fund.hasReport,
                quarter: fund.quarter,
                fundName: fund.fundName,
                transactions: fund.transactions,
              };
              fund.transactions.map((transaction) => {
                // and the single row will have one value for each column
                datum[transaction.label] = transaction.amount;
              });

              return datum;
            }

            // else a row of investors with a list of funds under them
            datum.funds = [];
            const initialAmounts = columnsList?.reduce(
              (accumulator: { [key: string]: number }, current) => (
                (accumulator[current] = 0), accumulator
              ),
              {}
            );

            // check if funds have different currencies
            let isMultipleCurrencies: boolean = false;

            capAcc.funds.map(fund => {
              if (fund.currencyCode !== capAcc.funds[0].currencyCode) { isMultipleCurrencies = true; }
            });

            // each row of investor will have a aggregated amount for each column (a sum of all values of same column in each fund)
            let finalAmounts: any = capAcc.funds.reduce(
              (totalAmounts, fund: Fund, index: number) => {
                const updatedFund: any = {
                  invFundName: fund.fundName,
                  isChild: true,
                  id: `${capAcc.investorId}--${fund.fundId}`,
                  investorName: capAcc.investorName,
                  investorId: capAcc.investorId,
                  ...fund,
                };

                columnsList?.forEach((col) => {
                  const transaction = fund.transactions.find(
                    (trans) => trans.label === col
                  );

                  if (totalAmounts && !transaction?.useMetric) {
                    totalAmounts[col] += transaction?.amount || 0;
                  }
                  // and each fund will also have a all column values in itself
                  updatedFund[col] = transaction?.amount || 0;
                });

                datum.funds.push(updatedFund);
                return totalAmounts;
              },
              { ...initialAmounts }
            );

            if (isMultipleCurrencies) {
              finalAmounts = null;
            } else {
              datum.currencyCode = capAcc.funds[0].currencyCode;
            }

            if (datum.funds.length > 1) {
              datum = {
                ...datum,
                ...finalAmounts
              };
            }

            return datum;
          });

          let expandedData: any[] = [];

          if (!hasOneFundOnly) {
            data.forEach((investor) => {
              expandedData.push(investor);
              //flattening the object: all funds in each invsetor become a new row under that particular investor
              expandedData = [...expandedData, ...investor.funds];
            });
          }

          setIsLastPage(
            statusResponse?.length === 0 || statusResponse?.length < PAGE_SIZE
          );

          if (isCanceled?.()) return;
          if (hasPageChange) {
            setCapitalAccountsResponse((currentCapAccResponse) => [
              ...(currentCapAccResponse && currentCapAccResponse.length > 0
                ? currentCapAccResponse
                : []),
              ...statusResponse,
            ]);
            setCapitalAccountsList((currentList) => [
              ...(currentList && currentList.length > 0 ? currentList : []),
              ...(hasOneFundOnly ? data : expandedData),
            ]);
            setUnformattedCapAccList((currentData) => [
              ...(currentData && currentData.length > 0 ? currentData : []),
              ...data,
            ]);
          } else {
            setCapitalAccountsResponse(statusResponse);
            setCapitalAccountsList(hasOneFundOnly ? data : expandedData);
            setUnformattedCapAccList(data);
          }
        } catch (e) {
          informationAlert("Error getting capital accounts", "error");
        }
      }
      setIsLoadingList(false);
      setIsLoading(false);
    },
    [getParams]
  );

  useEffectAsync(
    async (isCanceled) => {
      await fetchCapitalAccounts(headerList, page as number, false, isCanceled);
    },
    [headerList]
  );

  const handleOnViewClick = (id: any, fund: any, filter: any = {}) => {
    const { [CapitalAccountsFilter.QuarterName]: quarterName } = filter;

    setSelectedFund({
      fundId: fund.fundId,
      fundName: fund.fundName,
      investorId: fund.investorId,
      investorName: fund.investorName,
      currencyCode: fund.currencyCode,
      id: fund.id,
      quarter: fund.quarter || (quarterName?.[0] ?? "MRQ"),
    });
  };

  const onStatementReportViewClose = () => {
    setSelectedFund(initialSelectedFund);
  };

  const onTransactionHistoryClose = () => {
    setSelectedTransaction(initialSelectedTransaction);
  };

  const onPublishQuartersViewClose = () => {
    setOpenPublishQuarters(false);
  };

  const onPublishQuartersViewOpen = () => {
    setOpenPublishQuarters(true);
  };

  const handleCellClick = (params: GridCellParams) => {
    const fund = params.row;

    if (columnsList?.includes(params.field) && fund) {
      const { [CapitalAccountsFilter.QuarterName]: quarterName } =
        getSelectedFilters(headerList);

      setSelectedTransaction({
        fundId: fund.fundId || null,
        fundName: fund.fundName || "",
        investorId: fund.investorId || null,
        investorName: fund.investorName || "",
        currencyCode: fund.currencyCode || "USD",
        quarter: fund.quarter || (quarterName?.[0] ?? "MRQ"),
        transactionType: params.field,
        transactionCode:
          transactionCodes.find((item: any) => item.label === params.field)
            ?.code || params.field,
      });
    }
  };

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTransaction(
      columnsList
        ? (curTrans) => ({
          ...curTrans,
          transactionType: columnsList[newValue],
          transactionCode: transactionCodes[newValue].code,
        })
        : initialSelectedTransaction
    );
  };

  const setSelectedRows = (updatedArrayofSelectedRowIds: string[]) => {
    if (hasOneFundOnly) {
      setSelectedCapAccRowIds(updatedArrayofSelectedRowIds);
      setSelectedFundIdsForBulkActions(
        updatedArrayofSelectedRowIds.map((id) => {
          const curRow = capitalAccountsList?.filter(
            (capAcc) => capAcc.id === id
          )[0];

          return `${curRow?.investorId}--${curRow?.fundId}`;
        })
      );
      return;
    }

    if (updatedArrayofSelectedRowIds.length === 0) {
      // clear all selected rows
      setSelectedCapAccRowIds([]);
      setSelectedFundIdsForBulkActions([]);
      return;
    } else if (
      capitalAccountsList &&
      updatedArrayofSelectedRowIds.length === capitalAccountsList.length
    ) {
      // select all rows
      setSelectedCapAccRowIds(capitalAccountsList.map((capAcc) => capAcc.id));
      // select all fund ids
      setSelectedFundIdsForBulkActions(
        capitalAccountsList.reduce((fundIds: any[], capAcc) => {
          return capAcc.isChild ? [...fundIds, capAcc.id] : [...fundIds];
        }, [])
      );
      return;
    }

    // Getting difference between both arrays to get currently selected row id
    // if selectedrows is empty, then this is the first selection of the list
    const currentlySelectedRowId =
      selectedCapAccRowIds.length === 0
        ? updatedArrayofSelectedRowIds[0]
        : selectedCapAccRowIds.length > updatedArrayofSelectedRowIds.length
          ? selectedCapAccRowIds.filter(
            (id) => !updatedArrayofSelectedRowIds.includes(id)
          )[0] ?? null
          : updatedArrayofSelectedRowIds.filter(
            (id) => !selectedCapAccRowIds.includes(id)
          )[0] ?? null;
    const currentlySelCapAccRow = capitalAccountsList?.filter(
      (capAcc) => capAcc.id === currentlySelectedRowId
    )[0];

    if (currentlySelCapAccRow?.isParent) {
      // if current selected row is a investor row
      const selectedInvestorId = currentlySelectedRowId;
      const selectedCapAccRow = unformattedCapAccList.find(
        (investorRow) => investorRow.investorId === selectedInvestorId
      );
      const selectedFundIds =
        selectedCapAccRow?.funds.map((fund) => fund.id) ?? [];
      const selectedRowIds = [...selectedFundIds, selectedInvestorId];

      if (selectedCapAccRowIds.includes(selectedInvestorId)) {
        // remove investor id and all fund ids under it from the selected list
        setSelectedCapAccRowIds((prevSelectedRows) => {
          return prevSelectedRows.filter(
            (row) => !selectedRowIds.includes(row)
          );
        });
        setSelectedFundIdsForBulkActions((prevSelectedFundIds) => {
          return prevSelectedFundIds.filter(
            (id) => !selectedFundIds.includes(id)
          );
        });
      } else {
        // add investor id and all fund ids under it to the selected list
        setSelectedCapAccRowIds((prevSelectedRows) => {
          return [...prevSelectedRows, ...selectedRowIds];
        });
        setSelectedFundIdsForBulkActions((prevSelectedFundIds) => {
          return [...prevSelectedFundIds, ...selectedFundIds];
        });
      }
    } else if (currentlySelCapAccRow?.isChild) {
      // if current selected row is a fund row
      const selectedFundId = currentlySelectedRowId;
      const selectedInvestorId = currentlySelCapAccRow?.investorId || null;
      const selectedCapAccRow = unformattedCapAccList.find(
        (investorRow) => investorRow.investorId === selectedInvestorId
      );
      const selectedFundIds =
        selectedCapAccRow?.funds.map((fund) => fund.id) ?? [];

      if (selectedCapAccRowIds.includes(selectedFundId)) {
        // remove fund id from selected rows list
        setSelectedCapAccRowIds((prevSelectedRows) => {
          // remove fund id and the assosciated investor id from the selected rows list
          // even if one fund id is not present in the selected row list, then investor id should not be selected
          return prevSelectedRows.filter(
            (id) => id !== selectedFundId && id !== selectedInvestorId
          );
        });
        setSelectedFundIdsForBulkActions((prevSelectedFundIds) => {
          return prevSelectedFundIds.filter((id) => id !== selectedFundId);
        });
      } else {
        setSelectedCapAccRowIds((prevSelectedRows) => {
          // add fund id to selected rows list
          const newSelectedRowIds = [...prevSelectedRows, selectedFundId];

          if (selectedFundIds.every((id) => newSelectedRowIds.includes(id))) {
            // if all funds under investor are part of the selected rows list
            // add investor id too
            newSelectedRowIds.push(selectedInvestorId);
          }
          return newSelectedRowIds;
        });
        setSelectedFundIdsForBulkActions((prevSelectedFundIds) => {
          return [...prevSelectedFundIds, selectedFundId];
        });
      }
    }
  };

  const handleBulkAction = (actionId: CapAccExportActions) => {
    setIsLoading(true);
    switch (actionId) {
      case CapAccExportActions.AllSummaryData:
      case CapAccExportActions.SelectedSummaryData:
        exportSummaryData();
        break;
      case CapAccExportActions.AllCapAccReportExcel:
        exportAllCapAccReportExcel();
        break;
      case CapAccExportActions.SelectedCapAccReportExcel:
        exportCapAccReportExcel();
        break;
      case CapAccExportActions.AllCapAccReportPDF:
      case CapAccExportActions.SelectedCapAccReportPDF:
        exportAllCapAccReportPdf();
        break;
    }
  };

  const EXPORT_SUMMARYDATA_ERROR = "Error in exporting summary data.";
  const EXPORT_CAP_ACC_REPORT_EXCEL_ERROR =
    "Error in exporting Capital account report in Excel.";
  const EXPORT_CAP_ACC_REPORT_PDF_ERROR =
    "Error in exporting Capital account report in Pdf.";

  const exportSummaryData = async () => {
    const {
      [CapitalAccountsFilter.FundIds]: selectedFundIds,
      [CapitalAccountsFilter.InvestorIds]: selectedInvestorIds,
      [CapitalAccountsFilter.QuarterName]: quarterName,
    } = getSelectedFilters(headerList);

    if (funds && investors) {
      try {
        const exportedData = await downloadSummaryData({
          fundIds: selectedFundIds ?? funds,
          investorFundIds: selectedFundIdsForBulkActions,
          investorIds: selectedInvestorIds ?? investors,
          quarterName: quarterName?.[0] ?? "MRQ",
        });

        downloadFile(exportedData, "financial-summary", "csv");
      } catch (e) {
        informationAlert(EXPORT_SUMMARYDATA_ERROR, "error");
      }
    }
    setIsLoading(false);
  };

  const exportCapAccReportExcel = async () => {
    const { [CapitalAccountsFilter.QuarterName]: quarterName } =
      getSelectedFilters(headerList);

    if (capitalAccountsList && capitalAccountsList.length > 0) {
      try {
        let selectedFundIds = [...selectedFundIdsForBulkActions];
        let selectedQuarterName = "";

        if (selectedFundIdsForBulkActions.length === 0) {
          selectedFundIds = hasOneFundOnly
            ? capitalAccountsList?.map((row) => {
              selectedQuarterName = row.quarter || "";
              return `${row.investorId}--${row.fundId}`;
            })
            : capitalAccountsList
              ?.filter((row) => row.isChild)
              .map((row) => {
                selectedQuarterName = row.quarter || "";
                return row.id;
              });
        }
        const exportedData = await downloadCapAccReportExcel({
          investorFundIds: selectedFundIds,
          quarterName: quarterName?.[0] ?? selectedQuarterName,
        });

        downloadFile(exportedData, "capital-accounts-report", "csv");
      } catch (e) {
        informationAlert(EXPORT_CAP_ACC_REPORT_EXCEL_ERROR, "error");
      }
    }
    setIsLoading(false);
  };
  const exportAllCapAccReportExcel = async () => {
    const {
      [CapitalAccountsFilter.QuarterName]: quarterName,
      [CapitalAccountsFilter.FundIds]: fundIds,
    } = getSelectedFilters(headerList);

    if (capitalAccountsList && capitalAccountsList.length > 0) {
      try {
        const fundId = fundIds ? fundIds[0] : "";
        const selectedQuarterName = capitalAccountsList[0].quarter || "";
        let selectedFundIds = [];

        const filteredInvestorList: InvestorsNames[] = await getInvestorsNames(
          fundId
        );

        if (capitalAccountsList.length < 50) {
          selectedFundIds = capitalAccountsList?.map((row) => {
            return `${row.investorId}--${row.fundId}`;
          });
        } else {
          selectedFundIds =
            filteredInvestorList?.map((investor) => {
              return `${investor.id}--${fundId}`;
            }) || [];
        }
        const exportedData = await downloadCapAccReportExcel({
          investorFundIds: selectedFundIds as string[],
          quarterName: quarterName?.[0] ?? selectedQuarterName,
        });

        downloadFile(exportedData, "capital-accounts-report", "csv");
      } catch (e) {
        informationAlert(EXPORT_CAP_ACC_REPORT_EXCEL_ERROR, "error");
      }
    }
    setIsLoading(false);
  };

  const exportAllCapAccReportPdf = async () => {
    const {
      [CapitalAccountsFilter.FundIds]: selectedFundIds,
      [CapitalAccountsFilter.InvestorIds]: selectedInvestorIds,
      [CapitalAccountsFilter.QuarterName]: quarterName,
    } = getSelectedFilters(headerList);

    if (
      capitalAccountsList &&
      capitalAccountsList.length > 0 &&
      funds &&
      investors
    ) {
      try {
        const allFundCount = hasOneFundOnly
          ? capitalAccountsList.length
          : capitalAccountsList.filter((row) => row.isChild).length;

        const isAllSelected =
          selectedFundIdsForBulkActions.length === 0 ||
          selectedFundIdsForBulkActions.length === allFundCount;
        const exportPdf = isAllSelected
          ? downloadAllCapAccReportPdf
          : downloadSelectedCapAccReportPdf;
        const exportedData = await exportPdf({
          async: isAllSelected,
          clientId: "",
          fundIds: selectedFundIds ?? funds,
          ids: selectedFundIdsForBulkActions,
          investorIds: selectedInvestorIds ?? investors,
          quarter: quarterName?.[0] ?? "MRQ",
          transactionCode: "",
          type: "CAPITAL_ACCOUNT",
          username: "",
        });

        if (isAllSelected) {
          setShowExportPdfCapAccReportPdfConfirm(true);
        } else {
          downloadFile(
            exportedData,
            `capital_accounts_${format(new Date(), "MM/dd/yyyy")}`,
            "zip"
          );
        }
      } catch (e) {
        informationAlert(EXPORT_CAP_ACC_REPORT_PDF_ERROR, "error");
      }
    }
    setIsLoading(false);
  };

  const closeConfirmDialog = () => {
    setShowExportPdfCapAccReportPdfConfirm(false);
  };

  return {
    capitalAccountsList,
    headerList,
    setHeaderList,
    isLoadingList,
    page,
    setIsLastPage,
    isLoading:
      isLoading ||
      loadingInvestors ||
      loadingFunds ||
      loadingColumns ||
      loadingQuarters,
    hasOneFundOnly,
    selectedFund,
    setSelectedFund,
    onStatementReportViewClose,
    onTransactionHistoryClose,
    handleOnViewClick,
    handleCellClick,
    selectedTransaction,
    setSelectedTransaction,
    columnsList,
    handleTabChange,
    handleFilter,
    selectedCapAccRowIds,
    setSelectedRows,
    bulkActions,
    handleBulkAction,
    showExportPdfCapAccReportPdfConfirm,
    closeConfirmDialog,
    onPublishQuartersViewClose,
    onPublishQuartersViewOpen,
    openPublishQuarters,
    isAdmin,
    onNextPage,
    isClientPortalLogin,
  };
};
