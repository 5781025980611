import { GridColDef } from "@mui/x-data-grid-pro";

export type ListItem = {
  id: string;
  label: string;
};

export type DataGridHeaderItem = {
  field: string;
  label: string;
  checked: boolean;
  index?: number;
  hide?: boolean;
};

export type ImageItem = {
  id: string;
  text: string;
  icon?: any;
  optionsSelected?: number;
  textColor?: string;
  type?: string;
  hidden?: boolean;
  disabled?: boolean;
};

export type SelectionOptionItem = {
  id: string;
  label: string;
  value: string;
};

export enum CustomType {
  Currency = "currency",
  PositiveCurrency = "positiveCurrency",
  NegativeCurrency = "negativeCurrency",
  Action = "action",
  Percent = "percent",
  Chips = "chips",
}

//This needs to be an interface, not a type
export interface DataGridColDef extends GridColDef {
  index: number;
  inlineFilter?: boolean;
  inlineFilterType?: string;
  inlineFilterOptions?: Record<string, any>[];
  inlineFilterSelected?: any[];
  inlineFilterIDField?: string;
  inlineFilterLabelField?: string;
  inlineFilterName?: any;
  inlineSplitFilters?: inlineFilterTypes[];
  inlinePopoverFilters?: inlinePopoverFilterOptions[];
  inlineToggleSwitch?: boolean;
  singleSelectFilter?: boolean;
  customType?: CustomType;
  currencyCodeField?: string;
  chipLabelField?: string;
  chipIDField?: string;
  decimalPlaces?: number;
  chipCount?: number;
  onChipClick?: () => void;
  toggleVisibilityOnClick?: boolean;
  renderChipForFilesOnly?: boolean;
  renderChipForMultiItemsOnly?: boolean;
  arrowPopover?: boolean;
  optionFormComponent?: boolean;
  scrollContainerStyle?: object;
  showFilterAll?: boolean;
  shouldOptionReturnValue?: boolean;
  renderOnHover?: Function;
  emptySelectionOnClear?: boolean;
  fieldNames?: any[];
  fieldName?: string;
  csvHeaderName?: string;
}

export type inlineFilterTypes = {
  inlineFilterOptions: any[];
  inlineFilterSelected: any[];
  inlineFilterIDField: string;
  inlineFilterLabelField: string;
  inlineFilterName: any;
  inlineFilterTitle?: string;
};

export type inlinePopoverFilterOptions = {
  id: string;
  icon?: string;
  label: string;
  divider?: boolean;
  value: any;
};

export type FilterItem = {
  id: string;
  name: string;
};

export type CountryItem = {
  id: string;
  label: string;
  countryId: string;
};
