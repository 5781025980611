import { Table, TableCell,TableContainer, TableRow } from "@mui/material";

export const InfoLabelNumber = (
    <div>
      Suggested Numbering Convention: <br/><br/>
      <b>Attribute:</b> millionth <br/>
      <b>Parent:</b> ten thousands <br/>
      <b>Sub:</b> hundreds <br/>
      <b>Sub sub:</b> ones <br/><br/>
      Example Naming Convention: <br/>
      <TableContainer>
        <Table>
            <TableRow>
              <TableCell sx={{ fontSize: '10px', fontWeight: 'bold' }}> Number </TableCell>
              <TableCell sx={{ fontSize: '10px', fontWeight: 'bold' }}> Parent Account </TableCell>
              <TableCell sx={{ fontSize: '10px', fontWeight: 'bold' }}> Account Name </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>3010000</TableCell>
              <TableCell sx={{ fontSize: '10px' }}></TableCell>
              <TableCell sx={{ fontSize: '10px' }}>Contributions</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>3010100</TableCell>
              <TableCell sx={{ fontSize: '10px' }}>Contributions</TableCell>
              <TableCell sx={{ fontSize: '10px' }}>Cash Contributions</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>3010101</TableCell>
              <TableCell sx={{ fontSize: '10px' }}>Cash Contributions</TableCell>
              <TableCell sx={{ fontSize: '10px' }}>Contribution - General</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>3010102</TableCell>
              <TableCell sx={{ fontSize: '10px' }}>Cash Contributions</TableCell>
              <TableCell sx={{ fontSize: '10px' }}>Contribution - Investment</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>3010200</TableCell>
              <TableCell sx={{ fontSize: '10px' }}>Contributions</TableCell>
              <TableCell sx={{ fontSize: '10px' }}>Deemed Contributions</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>3010201</TableCell>
              <TableCell sx={{ fontSize: '10px' }}>Deemed Contributions</TableCell>
              <TableCell sx={{ fontSize: '10px' }}>Deemed Contributions - General</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>3010202</TableCell>
              <TableCell sx={{ fontSize: '10px' }}>Deemed Contributions</TableCell>
              <TableCell sx={{ fontSize: '10px' }}>Deemed Contributions - Investment</TableCell>
            </TableRow>
        </Table>
      </TableContainer>
    </div>
  );