import { Box, Chip, Link, Typography } from "@mui/material";
import {
  GridAlignment,
  GridCallbackDetails,
  GridColumnOrderChangeParams,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";

import CheckCircleIcon from "../../../assets/images/icons/icon_check_circle.svg";
import DeleteIcon from "../../../assets/images/icons/icon_delete_red.svg";
import ExportIcon from "../../../assets/images/icons/icon_export.svg";
import GreenEllipse from "../../../assets/images/icons/icon_green_ellipse.svg";
import RedEllipse from "../../../assets/images/icons/icon_red_ellipse.svg";
import SplitButton from "../../../components/Buttons/SplitButton/SplitButton";
import ChipCellStack from "../../../components/DataGrid/ChipsCell/ChipCellStack";
import { useClientConfig } from "../../../components/NavBar/useClientConfig.hooks";
import StatusLabel from "../../../components/StatusLabel/StatusLabel";
import useRole from "../../../core/routing/useRole";
import { saveColumnOrder } from "../../../services/columnOrder.service";
import {
  activeContact,
  approvePendingContacts,
  declineChange,
  deleteContact,
  emailContacts,
  exportContacts,
  getContact,
  getContactFilterList,
  getContactFunds,
  getContactInvestors,
  getContactRoleFilter,
  getContacts,
  inactiveContact,
} from "../../../services/contact.service";
import { getPortalLink } from "../../../services/dashboard.service";
import {
  getFundsFilterList,
  getStatusFilterList,
} from "../../../services/filters.service";
import { getInvestorList } from "../../../services/investor.service";
import { impersonateUser, unlockUser } from "../../../services/user.service";
import { PAGE_SIZE } from "../../../utils/constants/constants";
import {
  ACTIVATE_CONTACTS_POPUP_DESCRIPTION,
  APPROVE_CONTACT_POPUP_DESCRIPTION,
  CONTACTS_COLUMN_ORDER_VIEW_KEY,
  DECLINE_CONTACT_POPUP_DESCRIPTION,
  GENERIC_ERROR_MESSAGE,
  INACTIVATE_CONTACTS_POPUP_DESCRIPTION,
  SEND_EMAIL_CONTACT_CONFIRMATION,
} from "../../../utils/constants/text.constants";
import {
  arrayIndexUpdate as updateArrayIndex,
  arrayVisibilityUpdate,
} from "../../../utils/helpers/columnOrder.helper";
import downloadFile from "../../../utils/helpers/fileDownloader";
import { DateTimeFormat } from "../../../utils/helpers/format.helper";
import { getHostname } from "../../../utils/helpers/misc.helper";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { ColumnOrder } from "../../../utils/types/columnOrder";
import { Contact, ContactParams } from "../../../utils/types/contact.type";
import StatusTypes from "../../../utils/types/contactStatus.type";
import { FundItem } from "../../../utils/types/fund.type";
import { Investor, InvestorFilter } from "../../../utils/types/investor.type";
import {
  CustomType,
  DataGridColDef,
  FilterItem,
  ImageItem,
} from "../../../utils/types/listItems";
import { StatusFilter } from "../../../utils/types/statusFilter.type";
import { ADMIN_ROLES, ScopeRole } from "../../../utils/types/user.type";
import ContactList from "../contactList/ContactList";
import { TimeLabel } from "./ContactInfo.styles";

export const OPTION_APPROVE_ALL = "Approve All";
export const OPTION_APPROVE = "Approve";
export const OPTION_DECLINE = "Decline";
export const OPTION_SEND_EMAIL = "SendEmail";
export const OPTION_DELETE = "Delete";
export const OPTION_UNLOCK = "Unlock";
export const OPTION_EXPORT = "Export";
export const OPTION_EXPORT_ALL = "Export All";
export const OPTION_IMPERSONATE = "Impersonate User";
export const OPTION_ACTIVATE = "Activate";
export const OPTION_INACTIVATE = "Inactivate";

const PrimaryRole = "Primary Contact/Decision Maker";

const defaultBulkActions: ImageItem[] = [
  {
    id: OPTION_EXPORT_ALL,
    text: "Export All",
    icon: <img src={ExportIcon} alt="" height="15" />,
    optionsSelected: 0,
  },
  {
    id: OPTION_APPROVE_ALL,
    text: "Approve All",
    icon: <img src={CheckCircleIcon} alt="" height="15" />,
    optionsSelected: 0,
  },
  {
    id: OPTION_EXPORT,
    text: "Export",
    icon: <img src={ExportIcon} alt="" height="15" />,
    optionsSelected: 0,
  },
  {
    id: OPTION_APPROVE,
    text: "Approve",
    icon: <img src={CheckCircleIcon} alt="" height="15" />,
    optionsSelected: 0,
  },
  {
    id: OPTION_ACTIVATE,
    text: "Activate",
    icon: <img src={GreenEllipse} alt="" width="10" height="10" />,
    optionsSelected: 0,
  },
  {
    id: OPTION_INACTIVATE,
    text: "Inactivate",
    icon: <img src={RedEllipse} alt="" width="10" height="10" />,
    optionsSelected: 0,
  },
  {
    id: OPTION_DELETE,
    text: OPTION_DELETE,
    icon: <img src={DeleteIcon} alt="" height="15" />,
    optionsSelected: 0,
  },
];

enum ContactFilter {
  Name = "name",
  FundName = "fundName",
  Email = "email",
  Status = "status",
  LastLogin = "lastLogin",
  Investor = "investor",
  Roles = "roles",
}

type EmailType = {
  id: string;
  email: string;
};

type ContactType = {
  id: string;
  name: string;
  email: string;
};

type FilterNameType =
  | InvestorFilter
  | "investor_filter"
  | "fund_filter"
  | "role_filter"
  | "status";

const defaultColumnActions: ImageItem[] = [
  {
    id: OPTION_APPROVE,
    text: "Approve Change",
    icon: undefined,
    optionsSelected: 0,
  },
  {
    id: OPTION_DECLINE,
    text: "Decline Change",
    icon: undefined,
    optionsSelected: 0,
  },
  {
    id: OPTION_DELETE,
    text: "Delete",
    icon: undefined,
    optionsSelected: 0,
  },
];

const impersonateAction: ImageItem = {
  id: OPTION_IMPERSONATE,
  text: "Impersonate User",
  icon: undefined,
  optionsSelected: 0,
};

const activeContactsBulkOptions: ImageItem[] = [
  {
    id: OPTION_SEND_EMAIL,
    text: "Send Activation",
    icon: undefined,
    optionsSelected: 1,
  },
  impersonateAction,
  {
    id: OPTION_DELETE,
    text: "Delete",
    icon: undefined,
    optionsSelected: 2,
  },
];

const pendingContactsBulkOptions: ImageItem[] = [
  {
    id: OPTION_APPROVE,
    text: "Approve",
    icon: undefined,
    optionsSelected: 0,
  },
  {
    id: OPTION_DELETE,
    text: "Delete",
    icon: undefined,
    optionsSelected: 0,
  },
];

const unlockOption: ImageItem[] = [
  {
    id: OPTION_UNLOCK,
    text: "Unlock",
    icon: undefined,
    optionsSelected: 0,
  },
];

const defaultLastLoginOption: any[] = [
  {
    id: "today",
    name: "Today",
  },
  {
    id: "thisWeek",
    name: "This week",
  },
];

type Mapping = Record<StatusTypes, string>;

const StatusType: Mapping = {
  new: "blue",
  pending: "yellow",
  active: "green",
  inactive: "red",
};

export const useContactsInfoEffect = (
  isSubmitting: boolean,
  clientId: string,
  informationAlert: any,
  setIsSubmitting: any,
  investorFilters: InvestorFilter[],
  contactColumnOrder: ColumnOrder | null,
  token: string | undefined | null,
  username?: string
) => {
  const [contactList, setContactList] = useState<Contact[]>([]);
  const [contactFilterList, setContactFilterList] = useState<ContactType[]>([]);
  const [investorFilteredList, setContactFilteredList] = useState<Contact[]>(
    []
  );
  const [investorList, setInvestorList] = useState<Investor[]>([]);
  const [isLoadingContactList, setLoadingContactList] = useState(false);
  const [contactEmailList, setContactEmailList] = useState<EmailType[]>([]);
  const [fundFilters, setFundFilters] = useState<FundItem[]>([]);
  const [statusFilters, setStatusFilters] = useState<StatusFilter[]>([]);
  const [roleFilters, setRoleFilters] = useState<FilterItem[]>([]);
  const [showPanel, setShowPanel] = useState(false);
  const [resetContactPanel, setResetContactPanel] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState("");
  const [selectedContact, setSelectedContact] = useState<Contact>();
  const [searchText, setSearchText] = useState<string>("");
  const [dataUpdate, setDataUpdate] = useState(false);
  const [showSuggestionPopover, setShowSuggestionPopover] = useState(false);
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [loadinGrid, setLoadingGrid] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    headerList.length - 1
  );
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [contactSelectionModel, setContactSelectionModel] = useState<string[]>(
    []
  );
  const [confirmationPopUpText, setConfirmationPopUpText] = useState("");
  const [confirmationButtonText, setConfirmationButtonText] =
    useState("Approve");
  const [confirmationPopupTitle, setConfirmationPopupTitle] =
    useState<string>("");
  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false);
  const [bulkContactActionOptions, setBulkContactActionOptions] =
    useState<ImageItem[]>(defaultColumnActions);
  const [isLoadingImpersonation, setLoadingImpersonation] =
    useState<boolean>(false);

  const [selectedStatusOptions, setSelectedStatusOptions] = useState<string[]>(
    []
  );
  const [selectedRoleOptions, setSelectedRoleOptions] = useState<any>([]);
  const [selectedFundOptions, setSelectedFundOptions] = useState<any>([]);
  const [selectedInvestorOptions, setSelectedInvestorOptions] = useState<any>(
    []
  );
  const [selfContact, setSelfContact] = useState<any>();
  const [primaryInvestors, setPrimaryInvestors] = useState<Set<string>>();
  const { hasRole: isBasicUser } = useRole([ScopeRole.BASIC_USER]);

  const { hasRole: basicOrClientOrSuperAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);

  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const { hasRole: isBasicAdmin } = useRole([ScopeRole.BASIC_ADMIN]);

  const { hasRole: isAdmin } = useRole(ADMIN_ROLES);

  const { clientConfigList } = useClientConfig({
    isAdmin,
  });

  const [cookies, setCookie] = useCookies([
    "matClientConfig",
    "matClientToken",
    "matClientTokenSubdomain",
    "matProxyToken",
    "matTokenDurationMinutes",
    "matTokenExpirationCountdown",
  ]);

  const selectedContactList = useMemo(
    () => contactFilterList?.map((item) => item.id),
    [contactFilterList]
  );

  const isPrimaryUser = useMemo(() => {
    return (
      !!isBasicUser &&
      !!contactList.find((contact: any) => contact.primary === true)
    );
  }, [isBasicUser, contactList]);

  const pendingContacts: string[] = useMemo(() => {
    const contactsNeedApproval: Contact[] = contactList.filter((contact) =>
      ["NEW", "PENDING"].includes(contact.status)
    );

    return contactsNeedApproval?.map((contact: any) => contact.id);
  }, [contactList]);

  const readonly = useMemo(
    () =>
      isFundAdmin ||
      (isBasicUser
        ? !isPrimaryUser || selectedContact?.status === "NEW"
        : false),
    [isBasicUser, isFundAdmin, selectedContact]
  );

  const canEditContact = useMemo(
    () => (isBasicUser ? isPrimaryUser : basicOrClientOrSuperAdmin),
    [isBasicUser, isPrimaryUser, isFundAdmin]
  );

  const fundsSelected = useMemo(
    () => fundFilters?.map((item) => item.id),
    [fundFilters]
  );
  const investorSelected = useMemo(
    () => investorList?.map((item) => item.id),
    [investorList]
  );

  const emailSelected = useMemo(
    () => contactEmailList?.map((item) => item.id),
    [contactEmailList]
  );
  const statusSelectedList = useMemo(
    () =>
      statusFilters
        ?.filter((item) => item.name !== "INACTIVE")
        .map((item) => item.id),
    [statusFilters]
  );

  const rolesSelectedList = useMemo(
    () => roleFilters?.map((item) => item.id),
    [roleFilters]
  );

  const handleImpersonateUser = (params: any) => {
    const selectedClient = clientConfigList.find(
      (client) => client.clientId === params.clientId
    );

    return impersonateUser(params)
      .then(async (res: any) => {
        const matClientConfig = {
          ...selectedClient,
          email: params.userName,
          hostedZoneId: undefined,
        };

        const matClientToken = token;
        const matClientTokenSubdomain = null;
        const matProxyToken = res.access_token;
        const matTokenDurationMinutes = res.tokenDurationMinutes;
        const matTokenExpirationCountdown = res.tokenExpirationCountdownSeconds;

        const clientPortalURL: string = await getPortalLink(params.clientId);

        const hostname = getHostname(clientPortalURL);

        const options = {
          domain: `.${hostname}`,
          path: "/",
          maxAge: 300,
          sameSite: true,
        };

        setCookie("matClientConfig", matClientConfig, options);
        setCookie("matClientToken", matClientToken, options);
        setCookie("matClientTokenSubdomain", matClientTokenSubdomain, options);
        setCookie("matProxyToken", matProxyToken, options);
        setCookie("matTokenDurationMinutes", matTokenDurationMinutes, options);
        setCookie(
          "matTokenExpirationCountdown",
          matTokenExpirationCountdown,
          options
        );

        window.open(clientPortalURL, "_blank");
        setLoadingImpersonation(false);
      })
      .catch((err) => {
        informationAlert("Unable to impersonate user.", "error");
        setLoadingImpersonation(false);
      });
  };

  const handleOnContactSelection = (
    event: any,
    clientId: string,
    contact: any
  ) => {
    event.stopPropagation();
    setSelectedContactId(clientId);
    setSelectedContact(contact);
    setShowPanel(true);
  };
  const [selectedActionOption, setSelectedActionOption] = useState("");

  const handleActionOptionClick = (
    selectedOption: string,
    selectedContacts: string[],
    contact?: Contact
  ) => {
    setSelectedActionOption(selectedOption);
    setContactSelectionModel(selectedContacts);

    const activeOnlyInvestors = investorList.filter(i => i.status === 'ACTIVE').map(i => i.id);
    // make sure we only pass investor IDs with active investors
    const activeOnlySelectedInvestors = (selectedInvestorOptions as string[]).filter(id => activeOnlyInvestors.find(aoi => aoi === id));


    const params: any = {
      contactStatuses: selectedStatusOptions,
      downloadAll: false,
      roleIds: selectedRoleOptions,
      investorIds: activeOnlySelectedInvestors,
      fundIds: selectedFundOptions,
      ids: selectedContacts,
      type: "CONTACT",
      clientId,
    };

    switch (selectedOption) {
      case OPTION_IMPERSONATE:
        handleImpersonateUser({
          userName: contact?.email || "",
          clientId,
        });
        return;
      case OPTION_EXPORT_ALL:
      case OPTION_EXPORT:
        setIsFetchingData(true);
        if (selectedOption === OPTION_EXPORT_ALL) {
          params.downloadAll = true;
        }

        exportContacts(params)
          .then((fileBlob) => {
            if (fileBlob && fileBlob.type) {
              const extension = fileBlob.type.split("/").reverse()[0];

              downloadFile(
                fileBlob,
                `Contacts-${DateTimeFormat.getFormattedDate(new Date())}`,
                extension
              );
            }
            setIsFetchingData(false);
          })
          .catch((err) => {
            setIsFetchingData(false);
            informationAlert("Unable to export contacts.", "error");
          });
        return;
      case OPTION_APPROVE:
      case OPTION_APPROVE_ALL:
        setConfirmationPopupTitle("Approve Contact?");
        setConfirmationButtonText("Approve");
        setConfirmationPopUpText(APPROVE_CONTACT_POPUP_DESCRIPTION);
        break;
      case OPTION_DECLINE:
        // TODO - Need to confirm the text & moved to constant
        setConfirmationPopupTitle("Decline Contact?");
        setConfirmationButtonText("Decline");
        setConfirmationPopUpText(DECLINE_CONTACT_POPUP_DESCRIPTION);
        break;
      case OPTION_SEND_EMAIL:
        setConfirmationPopupTitle("Send Activation Email?");
        setConfirmationButtonText("Send Activation Email");
        setConfirmationPopUpText(SEND_EMAIL_CONTACT_CONFIRMATION);
        break;
      case OPTION_UNLOCK:
        unlockContact(selectedContacts);
        break;
      case OPTION_ACTIVATE:
        setConfirmationPopupTitle("Activate Contact(s)?");
        setConfirmationButtonText("Activate");
        setConfirmationPopUpText(ACTIVATE_CONTACTS_POPUP_DESCRIPTION);
        break;
      case OPTION_INACTIVATE:
        setConfirmationPopupTitle("Inactivate Contact(s)?");
        setConfirmationButtonText("Inactivate");
        setConfirmationPopUpText(INACTIVATE_CONTACTS_POPUP_DESCRIPTION);
        break;
    }
    if (selectedOption !== OPTION_UNLOCK) {
      setShowConfirmationPopUp(true);
    }
  };

  const handleHideConfirmationPopUp = () => {
    setSelectedActionOption("");
    setShowConfirmationPopUp(false);
    setContactSelectionModel([]);
  };

  const unlockContact = async (contacts: string[]) => {
    try {
      const matchedContacts = contactFilterList.filter((contact: any) =>
        contacts.includes(contact.id)
      );
      const selectedContact = matchedContacts.map((contact) => contact.email);

      setLoadingContactList(true);
      setIsSubmitting(true);
      await unlockUser(selectedContact);
      setContactSelectionModel([]);
      await fetchContacts(headerList, 1, "", false);
      setLoadingContactList(false);
      setIsSubmitting(false);
    } catch (exception) {
      postErrorHandler();
    }
  };

  const handleBulkOptionClick = (selectedOption: string) => {
    setSelectedActionOption(selectedOption);
    handleActionOptionClick(selectedOption, contactSelectionModel);
  };

  const postErrorHandler = () => {
    informationAlert(GENERIC_ERROR_MESSAGE, "error");
    setIsSubmitting(false);
    setLoadingContactList(false);
    setShowConfirmationPopUp(false);
  };

  const handleConfirmationPopUpSuccess = async () => {
    switch (selectedActionOption) {
      case OPTION_APPROVE:
        setIsSubmitting(true);
        setIsLastPage(false);
        try {
          await approvePendingContacts(contactSelectionModel);
          await fetchContacts(headerList, 1, "", false);
          setShowConfirmationPopUp(false);
          setContactSelectionModel([]);
          setResetContactPanel(true);
        } catch (exception) {
          postErrorHandler();
        }
        break;
      case OPTION_APPROVE_ALL:
        setIsSubmitting(true);
        setIsLastPage(false);
        try {
          const pendingContactList = await fetchAllFilteredContacts(
            headerList,
            ""
          );

          setShowConfirmationPopUp(false);
          await approvePendingContacts(pendingContactList || []);
          await fetchContacts(headerList, 1, "", false);
          setContactSelectionModel([]);
          setResetContactPanel(true);
        } catch (exception) {
          postErrorHandler();
        }
        break;
      case OPTION_DECLINE:
        setIsSubmitting(true);
        setIsLastPage(false);
        try {
          setShowConfirmationPopUp(false);
          await declineChange(contactSelectionModel?.[0]);
          await fetchContacts(headerList, 1, "", false);
          setContactSelectionModel([]);
          setResetContactPanel(true);
        } catch (exception) {
          postErrorHandler();
        }
        break;
      case OPTION_SEND_EMAIL:
        try {
          await emailContacts(contactSelectionModel);
          setShowConfirmationPopUp(false);
          setContactSelectionModel([]);
        } catch (exception) {
          postErrorHandler();
        }
        break;
      case OPTION_DELETE:
        try {
          setLoadingContactList(true);
          const deleteResponse = await deleteContacts(contactSelectionModel);

          if (
            deleteResponse.find((response) => response.status === "rejected")
          ) {
            throw new Error("Error while deleting contacts");
          }
          setIsSubmitting(true);
          setLoadingContactList(false);
          setShowConfirmationPopUp(false);
          setContactSelectionModel([]);
          handleHideConfirmationPopUp();
          informationAlert("Contact deleted successfully", "success");
          await fetchContacts(headerList, page, "", false);
        } catch (exception) {
          postErrorHandler();
        }
        break;
      case OPTION_ACTIVATE:
        try {
          setLoadingContactList(true);
          await activeContact(contactSelectionModel);
          setIsSubmitting(true);
          setLoadingContactList(false);
          setShowConfirmationPopUp(false);
          setContactSelectionModel([]);
          informationAlert("Contact(s) activated successfully", "success");
          await fetchContacts(headerList, page, "", false);
        } catch (exception) {
          postErrorHandler();
        }
        break;
      case OPTION_INACTIVATE:
        try {
          setLoadingContactList(true);
          await inactiveContact(contactSelectionModel);
          setIsSubmitting(true);
          setLoadingContactList(false);
          setShowConfirmationPopUp(false);
          setContactSelectionModel([]);
          informationAlert("Contact(s) inactivated successfully", "success");
          await fetchContacts(headerList, page, "", false);
        } catch (exception) {
          postErrorHandler();
        }
        break;
    }
    setIsSubmitting(false);
  };

  const FundsHeader = {
    field: "funds",
    headerName: "Funds",
    hide: false,
    index: 5,
    flex: 1,
    sortable: true,
    type: "chips",
    /** NEED TO ADD INLINE FILTERS  ONCE WE HAVE CONTACT 2.0 API */
    //inlineFilter: false,
    // inlineFilterName: ContactFilter.FundName,
    // inlineFilterIDField: "id",
    // inlineFilterLabelField: "name",
    // inlineFilterOptions: fundFilters,
    // inlineFilterSelected: fundsSelected,
    chipLabelField: "name",
    chipIDField: "id",
    align: "left" as GridAlignment,
    width: 350,
  };

  const InvestorHeader = {
    field: "investors",
    headerName: "Investor",
    hide: false,
    index: 6,
    flex: 1,
    sortable: false,
    type: "chips",
    /** NEED TO ADD INLINE FILTERS  ONCE WE HAVE CONTACT 2.0 API */
    // inlineFilter: true,
    // inlineFilterName: ContactFilter.Investor,
    // inlineFilterIDField: "id",
    // inlineFilterLabelField: "name",
    // inlineFilterOptions: investorList,
    // inlineFilterSelected: investorSelected,
    chipLabelField: "name",
    chipIDField: "id",
    align: "left" as GridAlignment,
    width: 350,
  };

  const initializeHeaderList = async () => {
    setSelectedStatusOptions(statusSelectedList);
    let gridHeaders = [
      {
        field: "name",
        headerName: "Name",
        hide: false,
        index: 1,
        sortable: true,
        type: "string",
        align: "left" as GridAlignment,
        width: 200,
        flex: 1,
        renderCell: (params: any) => {
          return (
            <Box>
              <Link
                id="btn_data_grid_columns_filter"
                underline="none"
                component="button"
                onClick={(event) =>
                  handleOnContactSelection(event, params.row.id, params.row)
                }
              >
                {params.value}
              </Link>
            </Box>
          );
        },
        inlineFilter: !isBasicUser,
        inlineFilterName: ContactFilter.Name,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "name",
        inlineFilterOptions: contactFilterList,
        inlineFilterSelected: selectedContactList,
      },
      {
        field: "email",
        headerName: "Email",
        hide: false,
        index: 2,
        width: 260,
        type: "string",
        flex: 1,
        sortable: true,
        inlineFilter: !isBasicUser,
        inlineFilterName: ContactFilter.Email,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "label",
        inlineFilterOptions: contactEmailList,
        inlineFilterSelected: emailSelected,
      },
      {
        field: "loginStatus",
        headerName: "Last Login",
        hide: false,
        sortable: true,
        index: 3,
        width: 200,
        flex: 1,
        type: "date",
        renderCell: (params: any) => {
          const lastLogin =
            params.row.loginStatus?.lastLoginAttempt?.split(" ") ?? [];

          return (
            <>
              {params.row.loginStatus?.lastLoginAttempt ? (
                <div>
                  <label className="rowitem">{lastLogin[0]}</label>
                  <br />
                  <TimeLabel>{lastLogin[1]}</TimeLabel>
                </div>
              ) : (
                "-"
              )}
            </>
          );
        },
        inlineFilter: true,
        inlineFilterName: ContactFilter.LastLogin,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "label",
        inlineFilterOptions: [],
        inlineFilterSelected: [],
      },
      {
        field: "attempts",
        headerName: "Login Attempts",
        hide: false,
        index: 4,
        flex: 1,
        sortable: true,
        type: "string",
        inlineFilter: true,
        align: "left" as GridAlignment,
        width: 350,
        renderCell: (params: any) => {
          const isLocked = params.row.loginStatus?.locked || false;
          const loginAttempts = params.row.loginStatus?.attempts ?? "-";

          return isLocked ? <Chip label={"Locked"} /> : loginAttempts;
        },
      },
      {
        ...FundsHeader,
        renderCell: (params: any) => {
          const filteredFund = params.row?.funds?.filter((fund: any) =>
            fundsSelected.includes(fund.id)
          );

          return (
            <ChipCellStack
              header={FundsHeader}
              items={filteredFund ?? []}
              row={params?.row}
            />
          );
        },
      },
      {
        ...InvestorHeader,
        renderCell: (params: any) => {
          const filteredInvestor = params.row?.investors?.filter((fund: any) =>
            investorSelected.includes(fund.id)
          );

          return (
            <ChipCellStack
              header={InvestorHeader}
              items={filteredInvestor ?? []}
              row={params?.row}
            />
          );
        },
      },
      /** NEED TO ADD THE ROLES COLUMN ONCE WE HAVE 2.0 API */
      // {
      //   field: "roles",
      //   headerName: "Roles",
      //   hide: false,
      //   index: 7,
      //   sortable: false,
      //   type: "chips",
      //   inlineFilter: true,
      //   inlineFilterName: ContactFilter.Roles,
      //   inlineFilterIDField: "id",
      //   inlineFilterLabelField: "name",
      //   inlineFilterOptions: roleFilters,
      //   inlineFilterSelected: rolesSelectedList,
      //   chipLabelField: "name",
      //   chipIDField: "id",
      //   align: "left" as GridAlignment,
      //   width: 350,
      // },
      {
        field: "status",
        headerName: "Status",
        hide: false,
        index: 7,
        width: 200,
        type: "string",
        flex: 1,
        sortable: true,
        align: "left" as GridAlignment,
        renderCell: (params: any) => {
          const status = params.row.status.toLowerCase();
          const color = StatusType[status as keyof typeof StatusType];

          return <StatusLabel color={color} label={status} />;
        },
        inlineFilter: true,
        inlineFilterName: ContactFilter.Status,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "name",
        inlineFilterOptions: statusFilters,
        inlineFilterSelected: statusSelectedList,
      },
      {
        field: "action",
        headerName: "add Column",
        hide: false,
        hideable: false,
        index: 8,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        width: 180,
        minWidth: 180,
        type: "action",
        pinnable: true,
        customType: CustomType.Action,
        renderCell: (params: any) => {
          const status = params.row.status.toLowerCase();
          const activeContactOptions: ImageItem[] = params.row.loginStatus
            ?.locked
            ? [...activeContactsBulkOptions, ...unlockOption]
            : activeContactsBulkOptions;

          switch (status) {
            case "pending":
              return (
                <SplitButton
                  id={`btn_contact_info_action_options_${params.row.id}`}
                  options={
                    isFundAdmin
                      ? [impersonateAction]
                      : (isBasicAdmin)
                        ? bulkContactActionOptions.filter(
                          ({ id }) => id !== OPTION_DELETE)
                        : bulkContactActionOptions}
                  hidden={false}
                  size="small"
                  handleOptionClick={(
                    selectedOption: string,
                    selectedContacts: string[]
                  ) =>
                    handleActionOptionClick(
                      selectedOption,
                      selectedContacts,
                      params.row
                    )
                  }
                  ariaLabelMessage="Select bulk action option"
                  selectedValue={params.row.id}
                />
              );
            case "new":
              return (
                <SplitButton
                  id={`btn_contact_info_action_options_${params.row.id}`}
                  options={
                    isFundAdmin
                      ? [impersonateAction]
                      : (isBasicAdmin)
                        ? pendingContactsBulkOptions.filter(
                          ({ id }) => id !== OPTION_DELETE
                        )
                        : pendingContactsBulkOptions}
                  hidden={false}
                  size="small"
                  handleOptionClick={(
                    selectedOption: string,
                    selectedContacts: string[]
                  ) =>
                    handleActionOptionClick(
                      selectedOption,
                      selectedContacts,
                      params.row
                    )
                  }
                  ariaLabelMessage="Select bulk action option"
                  selectedValue={params.row.id}
                />
              );
            case "active":
              activeContactOptions.sort((a, b) =>
                (a?.optionsSelected || 0) > (b?.optionsSelected || 0) ? 1 : -1
              );
              return (
                <SplitButton
                  id={`btn_contact_info_send_activation_${params.row.id}`}
                  options={
                    isFundAdmin
                      ? [impersonateAction]
                      : (isBasicAdmin)
                        ? activeContactOptions.filter(
                          ({ id }) => id !== OPTION_DELETE
                        )
                        : activeContactOptions}
                  hidden={false}
                  size="small"
                  handleOptionClick={(
                    selectedOption: string,
                    selectedContacts: string[]
                  ) =>
                    handleActionOptionClick(
                      selectedOption,
                      selectedContacts,
                      params.row
                    )
                  }
                  ariaLabelMessage="Select bulk action option for new contacts"
                  selectedValue={params.row.id}
                />
              );
          }
        },
      },
    ];

    if (isBasicUser) {
      gridHeaders = gridHeaders.filter((header) => header.field !== "action");
    }
    if (!isAdmin && !isPrimaryUser) {
      gridHeaders = gridHeaders.filter(
        (header) => !["funds", "investors"].includes(header.field)
      );
    }

    if (contactColumnOrder && contactColumnOrder.viewItems) {
      contactColumnOrder.viewItems.map((item) => {
        const header = gridHeaders.find((header) => header.field === item.code);

        if (header) {
          header.index = item.order;
          header.hide = !item.visible;
        }
      });

      gridHeaders.sort((a, b) => (a.index > b.index ? 1 : -1));
    }

    setHeaderList(gridHeaders);
    await fetchContacts(gridHeaders, page, "", false);
  };

  const onRowColumnOrderChange = (
    params: GridColumnOrderChangeParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    const newIndex = params.targetIndex;
    const oldIndex = params.oldIndex;

    const columnOrderToSave = updateArrayIndex(
      headerList,
      oldIndex - 1,
      newIndex - 1,
      clientId,
      CONTACTS_COLUMN_ORDER_VIEW_KEY
    );

    saveColumnOrder(columnOrderToSave);
  };

  const handleUpdateHeader = async (
    field: string,
    inlineFilterName?: ContactFilter
  ) => {
    const visiblityUpdate = arrayVisibilityUpdate(
      headerList,
      clientId,
      CONTACTS_COLUMN_ORDER_VIEW_KEY,
      field
    );

    saveColumnOrder(visiblityUpdate);
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== "action"
    );

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      if (header?.inlineFilterName === inlineFilterName) {
        let inlineFilterSelected;

        if (inlineFilterName === ContactFilter.Name) {
          inlineFilterSelected = selectedContactList;
        } else if (inlineFilterName === ContactFilter.Email) {
          inlineFilterSelected = emailSelected;
        } else if (inlineFilterName === ContactFilter.Investor) {
          inlineFilterSelected = investorSelected;
        } else if (inlineFilterName === ContactFilter.Status) {
          inlineFilterSelected = statusSelectedList;
        } else if (inlineFilterName === ContactFilter.FundName) {
          inlineFilterSelected = fundsSelected;
        } // else if (inlineFilterName === ContactFilter.Roles) {
        //   inlineFilterSelected = rolesSelectedList;
        // }

        return {
          ...header,
          hide:
            header.field === field &&
              !(!header.hide && activeFields.length <= 1)
              ? !header.hide
              : header.hide,
          inlineFilterSelected: inlineFilterSelected,
        };
      }
      return {
        ...header,
        hide:
          header.field === field && !(!header.hide && activeFields.length <= 1)
            ? !header.hide
            : header.hide,
        flex: header.field !== "col6" ? 1 : undefined,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }
  };

  useEffect(() => {
    if (
      //roleFilters &&
      // selectedContactList &&
      // contactEmailList &&
      statusFilters &&
      investorList &&
      fundsSelected &&
      roleFilters.length > 0 &&
      // selectedContactList.length > 0 &&
      // contactEmailList.length > 0 &&
      statusFilters.length > 0 &&
      fundsSelected.length > 0 &&
      investorList.length > 0
    ) {
      initializeHeaderList();
      setSelectedRoleOptions(rolesSelectedList);
      setSelectedFundOptions(fundsSelected);
      setSelectedInvestorOptions(investorSelected);
    }
  }, [
    statusFilters,
    fundsSelected,
    investorList,
    roleFilters,
    contactColumnOrder,
    isPrimaryUser,
  ]);

  useEffect(() => {
    if (contactList[0]?.investors) setLoadingContactList(false);
  }, [contactList]);

  useEffect(() => {
    let filteredList: Contact[] | undefined = contactList;

    headerList?.map((header) => {
      const auxList = filteredList ?? contactList;

      switch (header.inlineFilterName) {
        case ContactFilter.Name:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((contact) => {
              return header?.inlineFilterSelected?.some((selectedInvestor) => {
                return selectedInvestor === contact.id;
              });
            });
          }
          break;
        case ContactFilter.Email:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((contact) => {
              return header?.inlineFilterSelected?.some((selectedInvestor) => {
                return selectedInvestor === contact.email;
              });
            });
          }
          break;
        case ContactFilter.LastLogin:
          if (
            header.inlineFilterSelected &&
            header.inlineFilterSelected.length > 1
          )
            if (
              header.inlineFilterSelected[0] !== null &&
              header.inlineFilterSelected[1] !== null
            ) {
              filteredList = auxList?.filter((contact) => {
                const lastLoginDate = new Date(
                  contact.loginStatus.lastLoginAttempt
                );

                lastLoginDate.setHours(0, 0, 0, 0);
                let filterFrom = new Date();
                let filterTo = new Date();

                if (header?.inlineFilterSelected) {
                  filterFrom =
                    new Date(header?.inlineFilterSelected[0]) || new Date();

                  filterTo =
                    new Date(header?.inlineFilterSelected[1]) || new Date();

                  return (
                    filterFrom <= lastLoginDate && filterTo >= lastLoginDate
                  );
                }
              });
            }
          break;
      }
    });

    setContactFilteredList(filteredList);
  }, [contactList]);

  const handleFilter = async (filterName: FilterNameType, selected: any) => {
    setPage(1);

    if (filterName === "status") {
      setSelectedStatusOptions(selected);
    }
    const updatedHeaderList = headerList?.map((header) => {
      if (header.inlineFilterName === filterName) {
        return {
          ...header,
          inlineFilterSelected: selected,
        };
      }
      if (header.headerName === "Funds" && filterName === "fund_filter") {
        return {
          ...header,
          renderCell: (params: any) => {
            const filteredFund = params.row?.funds?.filter((fund: any) =>
              selected.includes(fund.id)
            );

            return (
              <ChipCellStack
                header={FundsHeader}
                items={filteredFund ?? []}
                row={params?.row}
              />
            );
          },
        };
      }
      if (
        header.headerName === "Investor" &&
        filterName === "investor_filter"
      ) {
        return {
          ...header,
          renderCell: (params: any) => {
            const filteredInvestor = params.row?.investors?.filter(
              (fund: any) => selected.includes(fund.id)
            );

            return (
              <ChipCellStack
                header={InvestorHeader}
                items={filteredInvestor ?? []}
                row={params?.row}
              />
            );
          },
        };
      }
      return header;
    });

    setHeaderList(updatedHeaderList);
    if (
      !["investor_filter", "fund_filter", "role_filter"].includes(
        filterName as string
      )
    ) {
      await fetchContacts(updatedHeaderList, page, "", false);
    }
  };

  useEffectAsync(
    async (isCanceled) => {
      if (isSubmitting) return;
      try {
        setPage(1);
        setLoadingContactList(true);

        const [
          fundsFilterResponse,
          investorResponse,
          statusResponse,
          roleFiltersResponse,
        ] = await Promise.all([
          getFundsFilterList(),
          getInvestorList(),
          getStatusFilterList(),
          getContactRoleFilter(clientId),
        ]);

        const formattedInvestorList = investorResponse?.map((investor) => ({
          ...investor,
          name: investor?.name
            ? investor?.name
            : investor?.firstName +
            " " +
            investor?.middle +
            " " +
            investor?.lastName,
        }));

        if (isCanceled()) return;
        setFundFilters(fundsFilterResponse);
        setInvestorList(formattedInvestorList);
        setStatusFilters(statusResponse);
        setRoleFilters(roleFiltersResponse);
        if (!isBasicUser) {
          const contactsResponse = await getContactFilterList();

          setTotalCount(contactsResponse.length);
          if (isCanceled()) return;

          const selectionList = contactsResponse.map((client) => {
            return {
              id: client.id,
              name: client.name,
              email: client.email,
            } as ContactType;
          });

          setContactFilterList(selectionList);
          getContactEmail(contactsResponse);
        } else {
          setContactFilterList([]);
        }
        setLoadingContactList(false);
      } catch (e) {
        informationAlert(GENERIC_ERROR_MESSAGE, "error");
      }
    },
    [readonly, isSubmitting, investorFilters]
  );

  const fillContactFunds = (pcontact: Contact[]) => {
    return Promise.all(
      pcontact.map(async (contact: any) => {
        const funds: any = (await getContactFunds(contact.id)) ?? [];

        contact.funds = funds;
        return contact;
      })
    );
  };

  const deleteContacts = (pcontact: string[]) => {
    const promises = pcontact.map((contact) => {
      return new Promise((resolve, reject) => {
        deleteContact(contact).then(resolve).catch(reject);
      });
    });

    return Promise.allSettled(promises);
  };

  const fillContactInvestor = (pcontact: Contact[]) => {
    return Promise.all(
      pcontact.map(async (contact: any) => {
        const investor: any = (await getContactInvestors(contact.id)) ?? [];

        contact.investors = investor;
        return contact;
      })
    );
  };

  const onNextPage = async () => {
    if (
      isLastPage ||
      !contactList ||
      (contactList && contactList?.length < PAGE_SIZE)
    ) {
      return;
    }

    await fetchContacts(headerList, (page as number) + 1, "", true);
    await setPage((page as number) + 1);
  };

  function getSelectedFilters(currentHeaderList: DataGridColDef[]) {
    return currentHeaderList?.reduce(
      (acc: Record<ContactFilter, string[] | undefined>, header) => {
        if (
          header.inlineFilter &&
          header.inlineFilterSelected &&
          header.inlineFilterSelected?.length > 0
        ) {
          return {
            ...acc,
            [header.inlineFilterName ?? ""]: header.inlineFilterSelected,
          };
        }
        return acc;
      },
      {
        [ContactFilter.Name]: undefined,
        [ContactFilter.Email]: undefined,
        [ContactFilter.Investor]: undefined,
        [ContactFilter.FundName]: undefined,
        [ContactFilter.Status]: undefined,
        [ContactFilter.LastLogin]: undefined,
        [ContactFilter.Roles]: undefined,
      }
    );
  }

  const getParams = (currentHeaderList: DataGridColDef[]) => {
    const {
      [ContactFilter.Investor]: investorSelected,
      [ContactFilter.FundName]: fundsSelected,
      [ContactFilter.Status]: statusSelected,
      [ContactFilter.Roles]: rolesSelected,
    } = getSelectedFilters(currentHeaderList);

    const params: ContactParams = {
      offset: (page - 1) * PAGE_SIZE,
      pageSize: PAGE_SIZE,
      searchText: searchText ? searchText : undefined,
      statusFilter: statusSelected,
      fundFilter: fundsSelected,
      investorFilter: investorSelected,
    };

    return {
      statusSelected,
      fundsSelected,
      investorSelected,
      rolesSelected,
      params,
    };
  };

  const fetchContacts = useCallback(
    async (
      currentHeaderList: DataGridColDef[],
      page: number,
      search?: string,
      hasPageChange?: boolean,
      isCanceled?: () => boolean
    ) => {
      const {
        statusSelected,
        fundsSelected,
        investorSelected,
        rolesSelected,
        params,
      } = getParams(currentHeaderList);

      if (
        statusSelected &&
        //investorSelected &&
        //fundsSelected &&
        //rolesSelected &&
        //fundsSelected?.length > 0 &&
        statusSelected?.length > 0 //&&
        //investorSelected?.length > 0 //&&
        //rolesSelected?.length > 0
      ) {
        try {
          if (!hasPageChange) {
            setLoadingContactList(true);
          }
          setLoadingGrid(true);
          const paramStatusSelected = statusFilters.filter((item) => {
            return statusSelected.some((selected: any) => item.id === selected);
          });

          const fundObjectSelected = fundFilters.filter((item) => {
            return selectedFundOptions.some(
              (selected: any) => item.id === selected
            );
          });

          const investorObjectSelected = investorFilters.filter((item) => {
            return selectedInvestorOptions.some(
              (selected: any) => item.id === selected
            );
          });

          const selectedRolesObjects = roleFilters.filter((item) => {
            return selectedRoleOptions.some(
              (selected: any) => item.id === selected
            );
          });

          const offset =
            params.searchText !== undefined || params.searchText === ""
              ? 0
              : (page - 1) * PAGE_SIZE < 0
                ? 0
                : (page - 1) * PAGE_SIZE;

          const statusResponse: Contact[] = (await getContacts(
            fundObjectSelected,
            paramStatusSelected,
            investorObjectSelected,
            selectedRolesObjects,
            searchText,
            offset,
            PAGE_SIZE
          )) as Contact[];

          if (!selfContact && username && !!isBasicUser && isPrimaryUser) {
            const currentContact = (await getContacts(
              fundFilters,
              paramStatusSelected,
              investorFilters,
              roleFilters,
              username,
              offset,
              1
            )) as Contact[];

            if (currentContact[0]?.id) {
              const primaryInvestorsIds = new Set<string>();
              const selfContactDetails = await getContact(currentContact[0].id);

              setSelfContact(selfContactDetails);

              for (const investor of selfContactDetails.investorFundRoles) {
                for (const fund of investor.funds) {
                  if (fund.roles.find((role) => role.name === PrimaryRole)) {
                    primaryInvestorsIds.add(investor.id);
                    break;
                  }
                }
              }
              setPrimaryInvestors(primaryInvestorsIds);
            }
          }

          const contactFunds: any =
            isPrimaryUser || isAdmin
              ? await fillContactFunds(statusResponse)
              : [];

          const contact: any =
            isPrimaryUser || isAdmin
              ? await fillContactInvestor(contactFunds)
              : [];

          if (
            (statusResponse?.length === 0 && page >= 2) ||
            (statusResponse?.length > 0 && statusResponse?.length < PAGE_SIZE)
          ) {
            setLoadingContactList(false);

            await setIsLastPage(true);
          }
          if (isCanceled?.()) return;

          if (hasPageChange) {
            setContactList((currentTransactionList) => [
              ...(currentTransactionList && currentTransactionList?.length > 0
                ? currentTransactionList
                : []),
              ...contact,
            ]);

            setContactFilteredList((currentTransactionList) => [
              ...(currentTransactionList && currentTransactionList?.length > 0
                ? currentTransactionList
                : []),
              ...contact,
            ]);

            // const renderHeaders: any = headerList.map((header) => {
            //   if(header.headerName === "Funds") {
            //     return {
            //       ...header,
            //       renderCell: (params: any) => {
            //         const filteredFund = params.row?.funds?.filter((fund: any) =>  selectedFundOptions.includes(fund.id));

            //         return <ChipCellStack header={FundsHeader} items={filteredFund ?? []} row={params?.row} />;
            //       },
            //     };
            //   }
            //   if (header.headerName === "Investor") {
            //     return {
            //       ...header,
            //       renderCell: (params: any) => {
            //         const filteredInvestor = params.row?.investors?.filter((fund: any) =>  selectedInvestorOptions.includes(fund.id));

            //         // eslint-disable-next-line no-console
            //         console.log("filters", filteredInvestor);

            //         return <ChipCellStack header={InvestorHeader} items={filteredInvestor ?? []} row={params?.row} />;
            //       },

            //     };
            //   }
            // });

            //setHeaderList(renderHeaders);
            setLoadingGrid(false);
            setLoadingContactList(false);
          } else {
            setContactList(statusResponse);
            setContactFilteredList(statusResponse);
            setLoadingGrid(false);
            setLoadingContactList(false);
          }
        } catch (e) {
          setLoadingGrid(false);
          setLoadingContactList(false);
          informationAlert("Error in getting contacts", "error");
        }
      }
    },
    [
      getParams,
      selectedRoleOptions,
      selectedInvestorOptions,
      selectedFundOptions,
      isPrimaryUser,
      isBasicUser,
      contactList,
    ]
  );

  const fetchAllFilteredContacts = async (
    currentHeaderList: DataGridColDef[],
    search?: string
  ) => {
    const { statusSelected } = getParams(currentHeaderList);

    if (statusSelected && statusSelected?.length > 0) {
      try {
        const paramStatusSelected = statusFilters.filter((item) => {
          return statusSelected.some((selected: any) => item.id === selected);
        });

        const fundObjectSelected = fundFilters.filter((item) => {
          return selectedFundOptions.some(
            (selected: any) => item.id === selected
          );
        });

        const investorObjectSelected = investorFilters.filter((item) => {
          return selectedInvestorOptions.some(
            (selected: any) => item.id === selected
          );
        });

        const selectedRolesObjects = roleFilters.filter((item) => {
          return selectedRoleOptions.some(
            (selected: any) => item.id === selected
          );
        });

        const statusResponse: Contact[] = (await getContacts(
          fundObjectSelected,
          paramStatusSelected,
          investorObjectSelected,
          selectedRolesObjects,
          searchText,
          0,
          totalCount
        )) as Contact[];

        const contactsNeedApproval: Contact[] = statusResponse.filter(
          (contact) => ["NEW", "PENDING"].includes(contact.status)
        );

        const pendingContactsList: string[] = contactsNeedApproval.map(
          (contact) => contact.id
        );

        return pendingContactsList;
      } catch (e) {
        informationAlert("Error in getting contacts", "error");
      }
    }
  };

  const getContactEmail = (contactList: any) => {
    const emailList = contactList
      .map((p: { email: string }) => {
        const statusObj = Object.assign({});

        statusObj.id = p.email;
        statusObj.label = p.email;

        return statusObj;
      }) //get status property
      .filter(
        (item: any, index: any, ar: any[]) =>
          ar.findIndex((m) => m.id === item.id) === index
      );

    setContactEmailList(emailList);
  };

  useEffectAsync(
    async (isCanceled) => {
      if (!dataUpdate) return;
      setLoadingContactList(true);

      const selectedRoleIds =
        selectedRoleOptions.length > 0 ? selectedRoleOptions : roleFilters;
      const selectedInvestorIds =
        selectedInvestorOptions.length > 0
          ? selectedInvestorOptions
          : investorFilters;
      const selectedFundIds =
        selectedFundOptions.length > 0 ? selectedFundOptions : fundFilters;

      const ContactResponse = await getContacts(
        selectedFundIds,
        statusFilters,
        selectedInvestorIds,
        selectedRoleIds,
        searchText
      );

      if (isCanceled()) return;

      const contactFunds: any = await fillContactFunds(ContactResponse);

      const contactInvestor: any = await fillContactInvestor(contactFunds);

      setContactList(contactInvestor);

      setLoadingContactList(false);
      setDataUpdate(false);
    },
    [searchText, dataUpdate]
  );

  useEffectAsync(
    async (isCanceled) => {
      await fetchContacts(headerList, page, "", false, isCanceled);
    },
    [searchText]
  );

  useEffectAsync(
    async (isCanceled) => {
      await fetchContacts(headerList, page, "", false, isCanceled);
    },
    [selectedRoleOptions, selectedFundOptions, selectedInvestorOptions]
  );

  return {
    isPrimaryUser,
    contactList,
    investorFilteredList,
    setContactList,
    isLoadingContactList,
    isLoadingImpersonation,
    statusFilters,
    investorFilters,
    roleFilters,
    showPanel,
    readonly,
    setShowPanel,
    selectedContact,
    selectedContactId,
    setSelectedContactId,
    headerList,
    activeHeaderFields,
    handleUpdateHeader,
    handleFilter,
    searchText,
    setSearchText,
    showSuggestionPopover,
    setShowSuggestionPopover,
    handleBulkOptionClick,
    showConfirmationPopUp,
    contactSelectionModel,
    setContactSelectionModel,
    confirmationPopUpText,
    setConfirmationPopUpText,
    handleConfirmationPopUpSuccess,
    handleHideConfirmationPopUp,
    setDataUpdate,
    onRowColumnOrderChange,
    onNextPage,
    loadinGrid,
    setLoadingContactList,
    confirmationButtonText,
    confirmationPopupTitle,
    fetchContacts,
    handleImpersonateUser,
    handleActionOptionClick,
    selectedActionOption,
    resetContactPanel,
    investorList,
    setResetContactPanel,
    selectedRoleOptions,
    selectedFundOptions,
    selectedInvestorOptions,
    setSelectedRoleOptions,
    setSelectedFundOptions,
    setSelectedInvestorOptions,
    canEditContact,
    isFetchingData,
    pendingContacts,
    primaryInvestors,
    selfContact,
    setIsLastPage,
    setPage,
  };
};

export const useGeneralActionSelectionEffect = (
  readonly: boolean,
  contactSelectionModel: any,
  isPrimaryUser: boolean,
  pendingContacts: string[]
) => {
  const { hasRole: isAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);

  const { hasRole: allowDeleteOperation } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
  ]);

  const showApproveAllButton = pendingContacts.length > 0 && isAdmin;

  const [bulkActionOptions, setBulkActionOptions] = useState<ImageItem[]>(
    showApproveAllButton
      ? defaultBulkActions.slice(0, 2)
      : defaultBulkActions.slice(0, 1)
  );

  useEffect(() => {
    let availableBulkActions = readonly
      ? defaultBulkActions.slice(0, 2)
      : defaultBulkActions;

    if (!allowDeleteOperation) {
      availableBulkActions = availableBulkActions.filter(
        ({ id }) => id !== OPTION_DELETE
      );
    }
    if (isPrimaryUser) {
      availableBulkActions = defaultBulkActions.filter(
        ({ id }) =>
          ![OPTION_APPROVE, OPTION_ACTIVATE, OPTION_DELETE].includes(id)
      );
    }

    if (contactSelectionModel.length === 0) {
      availableBulkActions = showApproveAllButton
        ? defaultBulkActions.slice(0, 2)
        : defaultBulkActions.slice(0, 1);
    } else {
      if (!showApproveAllButton) {
        availableBulkActions = availableBulkActions.filter(
          (option) => option.id !== OPTION_APPROVE_ALL
        );
      }
    }

    const updatedOptions: ImageItem[] = availableBulkActions.map((option) => {
      if (option.id === OPTION_EXPORT_ALL || option.id === OPTION_APPROVE_ALL) {
        return option;
      }
      const baseText = option.text.split("(");

      option.text = baseText[0] + "(" + contactSelectionModel.length + ")";
      return option;
    });

    setBulkActionOptions(updatedOptions);
  }, [contactSelectionModel, readonly, allowDeleteOperation, pendingContacts]);

  return {
    bulkActionOptions,
  };
};

export const useFundsListEffect = () => {
  const [fundList, setFundList] = useState<FundItem[]>([]);

  useEffectAsync(
    async (isCanceled) => {
      const responseFundList = await getFundsFilterList();

      if (isCanceled()) return;

      setFundList([...responseFundList]);
    },
    [setFundList]
  );
  return {
    fundList,
  };
};

const getContactHeaderType = (headerName: string) => {
  switch (headerName) {
    case "loginStatus":
      return "date";
    case "chips":
    case "investors":
    case "roles":
      return "chips";
    case "status":
      return "status";
    default:
      return "string";
  }
};
