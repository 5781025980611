import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import { createSvgIcon, SelectChangeEvent, useTheme } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { AppContext } from "../../../../core/context/appContextProvider";
import useRole from "../../../../core/routing/useRole";
import {
  getDistributionDocumentUrl,
  getDistributionEmail,
} from "../../../../services/distributionDocuments.service";
import {
  getDistributionReviews,
  updateDistributionStatus,
} from "../../../../services/distributions.service";
import { downloadFilesAndFolder } from "../../../../services/documents.service";
import { useDistributionDetailsEffect } from "../../../../services/hooks/useDistributionsEffect/useDistributionDetailsEffect.hook";
import { DISTRIBUTION_ICON } from "../../../../utils/constants/text.constants";
import downloadFile from "../../../../utils/helpers/fileDownloader";
import { DateTimeFormat } from "../../../../utils/helpers/format.helper";
import { fetchBeforeSave } from "../../../../utils/helpers/misc.helper";
import {
  DistributionReviews,
  DownloadAction,
} from "../../../../utils/types/distributions.type";
import { ImageItem } from "../../../../utils/types/listItems";
import { ScopeRole } from "../../../../utils/types/user.type";
import { REVIEW_FETCH_ERROR } from "../../../capitalCalls/capitalCallDetails/AddTransactions/constants";
import {
  DOCUMENT_GET_ERROR,
  REVIEW_DOCUMENT_ERROR,
  REVIEW_STATUS_UPDATE_ERROR,
} from "../../../capitalCalls/capitalCallDetails/constants";

interface RouteProp {
  id: string;
  section: string;
}

export const useReviewDistribution = () => {
  const theme = useTheme();
  const params = useParams<RouteProp>();
  const [selectedContactMode, setSelectedContactMode] =
    useState("distribution");
  const [distributionReviews, setDistributionReviews] = useState<
    DistributionReviews[]
  >([] || undefined);
  const [distributionInvestors, setDistributionInvestors] = useState<string[]>(
    [] || undefined
  );
  const [documentUrls, setDocumentUrls] = useState<string[]>([]);
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [distributionEmailContent, setDistributionEmailContent] = useState<
    string[]
  >([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { distributions } = useDistributionDetailsEffect(
    params.id !== "new" ? params.id : undefined
  );
  const [isDistributionEditable, setIsDistributionEditable] =
    useState<boolean>(false);
  const DistributionIcon = createSvgIcon(
    <path d={DISTRIBUTION_ICON} fill={theme.palette.primary.main} />,
    "Home"
  );
  const { informationAlert, state } = useContext(AppContext);
  const defaultContactModes: ImageItem[] = [
    {
      id: "distribution",
      text: "distribution",
      icon: <DistributionIcon />,
      optionsSelected: 0,
    },
    {
      id: "email",
      text: "Email",
      icon: <EmailIcon />,
      optionsSelected: 0,
    },
  ];

  const [contactModeOptions, setContactModeOptions] =
    useState<ImageItem[]>(defaultContactModes);

  const { hasRole: isSuperAdminOrClientAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);

  const onContactModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    if (newValue) setSelectedContactMode(newValue);
  };

  const handleDownloadAll = async () => {
    setLoading(true);

    const fileBlob = await downloadFilesAndFolder('', documentIds.join(','));

    if(fileBlob) {
      downloadFile(fileBlob, `Distributions-${DateTimeFormat.getFormattedDate(new Date())}`, 'zip');
    }

    setLoading(false);
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const index = distributionInvestors.indexOf(event.target.value as string);

    setCurrentIndex(index);
  };

  const handleRightSideOnClick = (value: number) => {
    setCurrentIndex(value);
  };

  const fetchReviews = async (id: string) => {
    try {
      setLoading(true);
      const res = await getDistributionReviews(id);

      setDistributionReviews(res);
      await setReviewStateDetails(res);
    } catch (error) {
      informationAlert(REVIEW_FETCH_ERROR, "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchDocumentUrl = async (documentId: string) => {
    try {
      const res = await getDistributionDocumentUrl(documentId);

      return res;
    } catch (error) {
      informationAlert(DOCUMENT_GET_ERROR, "error");
    }
  };
  const setReviewStateDetails = async (reviews: DistributionReviews[]) => {
    try {
      const investors: string[] = [];

      reviews.forEach((review) => {
        if (!investors.includes(review.investorName)) {
          investors.push(review.investorName);
        }
      });

      const urls = await Promise.all(
        reviews.map(async (review) => {
          const document = await fetchDocumentUrl(review.documentId);

          return document?.value || "";
        })
      );

      const docIds = reviews.map(review => review.documentId);

      const emails = await Promise.all(
        reviews.map(async (review) => {
          const emailValues = await getDistributionEmail(review.approvalId);

          return emailValues.value;
        })
      );

      setDistributionInvestors(investors);
      setDocumentUrls(urls);
      setDocumentIds(docIds);
      setDistributionEmailContent(emails);
    } catch (error) {
      informationAlert(REVIEW_DOCUMENT_ERROR);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((index) => index - 1);
    }
  };

  const handleFlag = async (status: boolean | null) => {
    try {
      await updateDistributionStatus(
        params?.id,
        distributionReviews[currentIndex].approvalId,
        {
          status,
        }
      );
      setDistributionReviews((prevReviews) =>
        prevReviews?.map((review, index) => {
          if (index === currentIndex) {
            return {
              ...review,
              checked: status,
            };
          }
          return review;
        })
      );
    } catch (error) {
      informationAlert(REVIEW_STATUS_UPDATE_ERROR, "error");
    }
  };
  const allowEdit = useMemo(() => {
    if (params?.id !== "new") {
      if (
        state.loginUser.currentUser?.username.toLowerCase() ===
          distributions?.createdBy?.toLowerCase() &&
        distributions?.status?.toLowerCase() !== "published"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [params.id, distributions, state]);

  useEffect(() => {
    if (params?.id) {
      fetchReviews(params?.id);
    }
  }, [params?.id]);

  const { isNextDisabled, nextButtonText, currentReview, shouldApprove } =
    useMemo(() => {
      let isNextDisabled = false;
      let nextButtonText = "Approve and Next";
      let shouldApprove = false;
      const isLast = currentIndex === distributionReviews.length - 1;

      if (!isLast && distributionReviews?.[currentIndex]?.checked === null) {
        //Initial State
        isNextDisabled = false;
        nextButtonText = "Approve and Next";
        shouldApprove = true;
      } else if (
        !isLast &&
        distributionReviews?.[currentIndex]?.checked === false
      ) {
        //Flagged
        nextButtonText = "Next";
        isNextDisabled = false;
      } else if (
        isLast &&
        distributionReviews?.[currentIndex]?.checked === null
      ) {
        isNextDisabled = false;
        nextButtonText = allowEdit
          ? "Approve"
          : distributions?.status?.toLowerCase() === "reviewing"
          ? "Approve"
          : "Next";
        shouldApprove = true;
      } else if (
        isLast &&
        distributionReviews?.[currentIndex]?.checked !== null
      ) {
        isNextDisabled = true;
        nextButtonText = allowEdit
          ? "Approve"
          : distributions?.status?.toLowerCase() === "reviewing"
          ? "Approve"
          : "Next";
      }
      return {
        isNextDisabled,
        nextButtonText,
        currentReview: distributionReviews?.[currentIndex],
        shouldApprove,
      };
    }, [distributionReviews, currentIndex, distributions]);

  const handleNext = async () => {
    if (
      allowEdit ||
      (!allowEdit && distributions?.status?.toLowerCase() === "reviewing")
    ) {
      if (shouldApprove && currentReview?.canApprove) {
        try {
          await updateDistributionStatus(
            params?.id,
            distributionReviews[currentIndex].approvalId,
            {
              status: true,
            }
          );
          setDistributionReviews((prevReviews) =>
            prevReviews?.map((review, index) => {
              if (index === currentIndex) {
                return {
                  ...review,
                  checked: true,
                };
              }
              return review;
            })
          );
        } catch (error) {
          informationAlert(REVIEW_STATUS_UPDATE_ERROR, "error");
        }
      }
    }
    if (currentIndex < distributionReviews.length - 1) {
      setCurrentIndex((index) => index + 1);
    }
  };

  return {
    selectedContactMode,
    setSelectedContactMode,
    contactModeOptions,
    onContactModeChange,
    distributionReviews,
    distributionInvestors,
    documentUrls,
    distributionEmailContent,
    handleSelectChange,
    handleRightSideOnClick,
    currentIndex,
    setCurrentIndex,
    handlePrevious,
    handleNext,
    handleFlag,
    fullScreen,
    setFullScreen,
    shouldApprove,
    currentReview,
    isNextDisabled,
    nextButtonText,
    loading,
    isSuperAdminOrClientAdmin,
    distributions,
    isDistributionEditable,
    allowEdit,
    handleDownloadAll,
  };
};
