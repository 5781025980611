import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useHistory,useParams } from "react-router-dom";

import { AppContext } from "../../../core/context/appContextProvider";
import useRole from "../../../core/routing/useRole";
import { checkIfSubdomainIsAvailable } from "../../../services/client.service";
import { createClient, getClientDetails, updateClient, uploadFiles } from "../../../services/client.service.v2";
import { useARKClientTagsEffect } from "../../../services/hooks/useClientsEffect/useARKClientTagsEffect.hooks";
import { useClientEffect } from "../../../services/hooks/useClientsEffect/useClientEffect.hooks";
import { useDomainsEffect } from "../../../services/hooks/useClientsEffect/useDomainsEffect.hooks";
import { Client, Files } from "../../../utils/types/client.type";
import { ScopeRole } from "../../../utils/types/user.type";
import { ClientTabs } from "../constants";

interface RouteProp {
  id: string
  arkTag: string
  section: string;
}

export const useClientDetails = () => {
  const [isLogoSectionUpdated, setIsLogoSectionUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshClientListOnEdit, setRefreshClientListOnEdit] = useState(false);
  const {
    id,
    arkTag
  } = useParams<RouteProp>();
  const history = useHistory();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<Client>({
    defaultValues: {
      theme: {
        mainColorHex1: '001428',
        mainColorHex3: '006EDE',
        mainColorHex5: 'FFFFFF',
        loginColorHex1: '3D3D3D',
        loginColorHex2: '006EDE',
        loginColorHex3: 'FFFFFF',
      },
      active: true,
      mfaSettings: {
        loginValidation: false,
        wireInfoValidation: false,
        contactValidation: false
      }
    }
  });

  const {
    isDirty
  } = useFormState({
    control
  });
  const {
    informationAlert,
    onClientChange,
    clientConfigList,
  } = useContext(AppContext);

  const {
    hasRole: isSuperAdmin,
  } = useRole([ScopeRole.SUPER_ADMIN]);

  const {
    client,
    fetchClient,
    loading
  } = useClientEffect(id !== 'new' ? id : undefined);

  const {
    arkClientTags,
    loading: arkClientTagsLoading
  } = useARKClientTagsEffect();

  const {
    domains,
  } = useDomainsEffect();

  const arkPesDomain = useMemo(() => {
    const domain = domains?.[0];

    if(id === 'new') {
      setValue("baseDomain", domain?.baseDomain);
      setValue("hostedZoneId", domain?.hostedZoneId);
    }
    return domain;
  }, [domains, id]);

  const isNewClient = useMemo(() => id === 'new',[id]);

  useEffect(() => {
    if (client?.mainLogoUrl) {
      client.mainLogoUrl = getUrlWithCachBust(client.mainLogoUrl); // force lastest image to render
    } else if (client) {
      setRefreshClientListOnEdit(true);
    }

    reset(client);
  }, [client]);

  useEffect(() => {
    if(isNewClient && arkTag !== "No Client" && arkTag !== "new"){
      setValue("arkClientTag", arkTag);
    }
  }, [arkTag, isNewClient]);

  const checkSubdomainAvailability = async (subdomain: string) => {
    if(!arkPesDomain || !arkPesDomain.baseDomain) {
      return;
    }
    try {
      await checkIfSubdomainIsAvailable(arkPesDomain.baseDomain, subdomain, arkPesDomain?.hostedZoneId);
    } catch (error) {
      setError("subdomain", {
        type: "Custom",
        message: "Subdomain is not available"
      });
    }
  };

  const addUpdateClient = async (updatedClient: Client) => {
    const {
      files, ...resultClient
    } = updatedClient;

    if(!isNewClient && !isDirty) {
      history.push(`/clients/${client?.id}/${client?.arkClientTag}/${ClientTabs.TransactionMapping}`);
      return;
    }

    const resultClientWithMFA = {
      ...resultClient,
      mfaSettings: {
        loginValidation: resultClient?.mfaSettings?.loginValidation || false,
        wireInfoValidation: resultClient?.mfaSettings?.wireInfoValidation || false,
        contactValidation: resultClient?.mfaSettings?.contactValidation || false
      }
    };

    if(isNewClient){
      await create(resultClientWithMFA, files);
    } else {

      if(isLogoSectionUpdated) {
        // force top nav to render lastest image
        const clientConfig = clientConfigList.find(c => c.clientId === client?.id)!;

        if(clientConfig.logoUrl) {
          clientConfig.logoUrl = getUrlWithCachBust(clientConfig.logoUrl);
        }
      }

      await update(resultClientWithMFA, files);
    }

  };

  const create = async (updatedClient: Client, files?: Files | null) => {
    try {
      setIsLoading(true);
      const newClientResponse = await createClient(updatedClient);

      if(isLogoSectionUpdated) {
        await uploadLogoFiles(newClientResponse.id, files);
      }
      const newClient = await getClientDetails(newClientResponse.id);

      onClientChange(newClient?.id as string, true);

      history.push(`/clients/${newClient?.id}/${newClient?.arkClientTag}/${ClientTabs.TransactionMapping}`);
      informationAlert("Client created successfully", "success");

    } catch (error) {
      informationAlert(typeof ((error as any)?.response?.data) === 'string' ? (error as any)?.response?.data : "Error in creating client", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (updatedClient: Client, files?: Files | null) => {
    try {
      setIsLoading(true);
      await updateClient(id, updatedClient);
      if(isLogoSectionUpdated) {
        await uploadLogoFiles(id, files);
      }

      informationAlert("Client updated successfully.", "success");
      history.push(`/clients/${client?.id}/${client?.arkClientTag}/${ClientTabs.TransactionMapping}`);
    } catch (error:any) {
      if(error?.response?.data && error?.response?.data.includes('Invalid uploaded file size')) {
        informationAlert("Error in uploading logos.  Invalid uploaded file size", "error");
      } else {
        informationAlert(typeof (error?.response?.data) === 'string' ? (error as any)?.response?.data : "Error in updating client", "error");
      }
    } finally {
      setIsLoading(false);

      if(refreshClientListOnEdit) {
        onClientChange(updatedClient?.id as string, true);
      }
    }
  };

  const uploadLogoFiles = async (id:string, files?: Files | null) => {
    if(files && (files?.mainLogo || files?.favicon || files?.reportLogo || files?.background)) {
      return await uploadFiles(id, files);
    }
  };

  const onCancel = () => {
    reset();
    fetchClient();
  };

  const theme = watch("theme");

  const onLogoChange = () => {
    setIsLogoSectionUpdated(true);
  };

  function getUrlWithCachBust(urlStr: string) {
    const url = new URL(urlStr);
    const params = new URLSearchParams(url.search);
    
    params.set('cacheBust', new Date().getTime().toString());
    const newUrlStr = url.href + '?' + params.toString();

    return newUrlStr;
  }

  return {
    client,
    arkClientTags,
    loading: arkClientTagsLoading || loading || isLoading,
    control,
    handleSubmit,
    addUpdateClient,
    theme,
    watch,
    onCancel,
    isDirty,
    setValue,
    checkSubdomainAvailability,
    clearErrors,
    onLogoChange,
    isNewClient,
    isSuperAdmin
  };
};
